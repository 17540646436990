import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate, HashRouter } from "react-router-dom";
import { AuthProvider } from "./shared/provider/authprovider";
import PageRoutes from "./routes/PageRoutes";

function App() {
  
  return (
    <HashRouter>
      <AuthProvider>
        <PageRoutes />
      </AuthProvider>
    </HashRouter>
  );
}

export default App;
