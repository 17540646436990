import React, { useEffect, useState } from 'react'
import { useAuth } from "../../shared/provider/authprovider";
import Header from "../../components/header/Header"
import { ImCheckmark, ImCross } from 'react-icons/im';
import { Button, Dropdown } from "react-bootstrap";
import Dots from '../../assets/action.svg'
import Modal from 'react-bootstrap/Modal';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import './connection.scss';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import XLSX from 'sheetjs-style';
import saveAs from 'file-saver';
import FileSaver from 'file-saver';
import Loader from '../../loader';
import { Box, MenuItem, TextField } from '@mui/material';
import ErrorModal from '../../components/errorModal/ErrorModal';
import { useSkuVaultAPI } from '../../shared/api/connectionSkuVault';
import { useConnectionStatusAPI } from '../../shared/api/index.service';

interface MyFormValues {
    name: string,
    skuVaultNumber: string,
    tenantToken: string,
    userToken: string,
    prefixName: string,
    productPrefix: string,
    type: string,
    isActive: string
}
const DisplayingErrorMessagesSchema = Yup.object().shape({
    name: Yup.string().required('Required').min(2, "Too Short!").max(30, "Too Long!").matches(/^[A-Za-z ]*$/, 'Please enter valid name'),
    skuVaultNumber: Yup.string().required('Required'),
    tenantToken: Yup.string().required('Required'),
    userToken: Yup.string().required('Required'),
    prefixName: Yup.string().required('Required'),
    productPrefix: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    isActive: Yup.string().required('Required'),
});
const ConnectionsSkuValult = () => {

    const { setErrorMsg, errorModalShow, setErrorModalShow, setSelectedIndex } = useAuth();
    const { getSkuVaultById, putSkuVault, postSkuVault, getAllSkuVault, getprefixList, skuVault, skuVaultCount,
        skuVaultById, prefixList } = useSkuVaultAPI()
    const { putActivateConnection, putDeactivateConnection } = useConnectionStatusAPI()
    const getInitialData = async () => {
        await getAllSkuVault(offset, limit);
        await getprefixList()
    }
    useEffect(() => {
        getInitialData()
        setSelectedIndex(11)
    }, []);
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    const [offset, setOffset] = React.useState(0);
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setOffset((value - 1) * limit);
        await getAllSkuVault((value - 1) * limit, limit);
        setPage(value);
    };
    const putInactive = async (id: any) => {
        const res = await putDeactivateConnection(id)
        if (res?.data?.status == 200) {
            setErrorModalShow(true);
            setErrorMsg("Deactivated Successfully");
            await getAllSkuVault(offset, limit)
        }
    }
    const putActive = async (id: any) => {
        await putActivateConnection(id)
        getAllSkuVault(offset, limit)
    }
    const [show, setShow] = useState(false);
    const showAddModal = () => {
        setShow(true)
    }
    const [editState, setEditState] = useState(false);
    let [Id, setId] = useState<any | null>()
    const showEditModal = async (id: any) => {
        setId(id)
        await getSkuVaultById(id)
        setShow(true)
    }
    const putSkewVaultFun = async (Id: any, values: any) => {
        await putSkuVault(Id, values)
        getAllSkuVault(offset, limit);
    }
    const postSkewVaultFun = async (values: any) => {
        await postSkuVault(values)
        getAllSkuVault(offset, limit);
    }
    const initialValues: MyFormValues = { name: '', skuVaultNumber: '', tenantToken: '', userToken: '', prefixName: '', productPrefix: '', type: '', isActive: '' };
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx'

    const ontabExport = () => {
        const ws = XLSX.utils.json_to_sheet(skuVault);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, "SKU Vault Connections" + fileExtension)
    }
    const statusList = [
        { value: true, label: "True" },
        { value: false, label: "False" },
    ];
    return (
        <>
            {errorHandle()}
            <Header />
            <Box className="contentMain"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <div className='connectionContainer'>
                    <div className='startMain'>
                        <h5 className='pageHead mb-0 w-50'>Sku Vault</h5>
                        <div className='d-flex justify-content-end w-50'>
                            <button className="FilledBtn me-3" onClick={() => { showAddModal(); setEditState(false) }}>Add New</button>
                            <button className='FilledBtn' onClick={() => { ontabExport(); }}>Export</button>
                        </div>
                    </div>
                    <div className='heightG'>
                        <table className='tableH'>
                            <thead className='thead rounded'>
                                <tr>
                                    <td>Sr. No.</td>
                                    <td>Name</td>
                                    <td>Sku Vault Number</td>
                                    <td>Tentant Token</td>
                                    <td>User Token</td>
                                    <td>Prefix Name</td>
                                    <td>Product Prefix</td>
                                    <td>Type</td>
                                    <td>Active</td>
                                    <td>Actions</td>
                                </tr>
                            </thead>
                            {skuVault ?
                                <tbody className='tbody'>
                                    {skuVault?.map((value: any, index: any) => {
                                        return (
                                            <tr>
                                                <td>{(limit * (page - 1)) + skuVault?.findIndex((x: any) => x.id === value?.id) + 1}</td>
                                                <td>{value?.name}</td>
                                                <td>{value?.skuVaultNumber}</td>
                                                <td>{value?.tenantToken}</td>
                                                <td>**************</td>
                                                <td>{value?.prefixName}</td>
                                                <td>{value?.productPrefix}</td>
                                                <td>{value?.type}</td>
                                                <td>{value?.isActive == true ?
                                                    <span style={{ color: "green" }}><ImCheckmark /></span>
                                                    :
                                                    <span style={{ color: "red" }}><ImCross /></span>}
                                                </td>
                                                <td>
                                                    <Dropdown className="menuItem">
                                                        <Dropdown.Toggle variant="Warning" id="dropdown-basic"><img src={Dots}></img></Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropMenug">
                                                            <Dropdown.Item className="clickItem"
                                                                onClick={() => { showEditModal(value.id); setEditState(true) }}
                                                            >Edit</Dropdown.Item>
                                                            <Dropdown.Item className="clickItem"
                                                                onClick={() => { value.isActive == true ? putInactive(value.id) : putActive(value.id) }}
                                                            >{value?.isActive == true ? "Deactivate" : "Activate"}</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                :
                                <Loader />
                            }
                        </table>
                        <Modal
                            show={show}
                            onHide={() => setShow(false)}
                            dialogClassName="formModal modalHeight"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton className='border-bottom-0'>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    {editState == true ? "Edit Sku Vault Details" : "Add New Sku Vault"}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Formik
                                    initialValues={editState == true ? skuVaultById : initialValues}
                                    enableReinitialize={true}
                                    validationSchema={DisplayingErrorMessagesSchema}
                                    onSubmit={(values, actions) => {
                                        console.log(values);
                                        // setEditValues(values);
                                        actions.setSubmitting(false);
                                        { editState == true ? putSkewVaultFun(Id, values) : postSkewVaultFun(values) }
                                        setShow(false)
                                    }}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className='formContainer'>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="name">Name</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="name" name="name" placeholder='Enter Name' />
                                                        {touched.name && errors.name && <div className='errorMsg'>{errors.name}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="skuVaultNumber">Sku Vault Number</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="skuVaultNumber" name="skuVaultNumber" placeholder='Enter Sku Vault Number' />
                                                        {touched.skuVaultNumber && errors.skuVaultNumber && <div className='errorMsg'>{errors.skuVaultNumber}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="tenantToken">Tenant Token</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="tenantToken" name="tenantToken" placeholder='Enter Tenant Token' />
                                                        {touched.tenantToken && errors.tenantToken && <div className='errorMsg'>{errors.tenantToken}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="userToken">User Token</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="userToken" name="userToken" placeholder='Enter User Token' />
                                                        {touched.userToken && errors.userToken && <div className='errorMsg'>{errors.userToken}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="prefixName">Prefix Name</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <div className='textfield selectfield'>
                                                            <select className='select' id="prefixName" name="prefixName">
                                                                <option value="" label="Select Prefix">
                                                                    Select prefix
                                                                </option>
                                                                {prefixList?.map((v: any) => {
                                                                    return (
                                                                        <option value={v.prefixName} >{v.prefixName}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        {touched.prefixName && errors.prefixName && <div className='errorMsg'>{errors.prefixName}</div>}
                                                    </div>
                                                </div>

                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="productPrefix">Product Prefix</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="productPrefix" name="productPrefix" placeholder='Enter Product Prefix' />
                                                        {touched.productPrefix && errors.productPrefix && <div className='errorMsg'>{errors.productPrefix}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="type">Type</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="type" name="type" placeholder='Enter Type' />
                                                        {touched.type && errors.type && <div className='errorMsg'>{errors.type}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="isActive">Select Status</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <div className='selectStatusField'>
                                                            <Field name="isActive" >
                                                                {({ field }: any) => (
                                                                    <div>
                                                                        <TextField
                                                                            {...field}
                                                                            select
                                                                            fullWidth
                                                                        >
                                                                            {statusList.map((e: any) => (
                                                                                <MenuItem value={e.value}>{e.label}</MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                    </div>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        {touched.isActive && errors.isActive && <div className='errorMsg'>{errors.isActive}</div>}
                                                    </div>
                                                </div>

                                                <div className='row align-items-center formItem'>
                                                    <div className='col-4'></div>
                                                    <div className='col-8'>
                                                        <Button className="FilledBtn" type="submit">Submit</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <div className='d-flex paginationSect align-items-center justify-content-between'>
                        <Stack spacing={2} className="d-flex flex-row align-items-center">
                            <label className='formLabel'>Showing {offset + 1} to {skuVaultCount > (offset + limit) ? (offset + limit) : skuVaultCount} of {skuVaultCount} entries</label>
                            <Pagination className="m-0 paginatnNav" size="small" shape="rounded" count={Math.ceil(skuVaultCount / limit)} page={page} onChange={handleChange} />
                        </Stack>
                    </div>
                </div>
            </Box>
        </>
    )
}
export default ConnectionsSkuValult