import HeaderListing from "../../../components/headerListing";
import Paypal from "../payPalIntegration/PayPalIntegration";
import { useEffect, useState } from "react";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import * as React from "react";
import { useAuth } from "../../../shared/provider/authprovider";
import Loader from "../../../loader";

import { Divider, Stack, TextField } from "@mui/material";
import { Box, Typography, Button } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import styles from "./index.module.scss";
import SuBscriptionPaypal from "./suBscriptionPaypal";
import UserInvoice from "./userInvoice";
import AccManagementUser from "./accManagementUser";
import { useSubScriptionAPI } from "../../../shared/api/userSubscriptionSAAS";
import ManageSubscriptionUser from "./ManageSubscriptionUser";
export const style = {
  tabContainer: {
    ".MuiTabs-indicator": {
      backgroundColor: "#000000",
      height: "1px",
    },
  },
  tabList: {
    ".MuiButtonBase-root": {
      color: "#68737D",
      fontWeight: 500,
      fontSize: 14,
      textTransform: "capitalize",
      "&.Mui-selected": {
        color: "#1E1F21",
      },
    },
  },
  borderStack: {
    border: "1px solid #E0E0E0",
    borderRadius: 0.5,
    height: 32,
  },
  fiiledBtn: {
    background: "linear-gradient(95.28deg, #0038BA -5.39%, #4F18FF 102.93%)",
    borderRadius: 0.5,
    textTransform: "capitalize",
    height: 32,
    width: 100,
    ml: 2,
    boxShadow: "unset",
    fontSize: 14,
  },
};

const ListingLandingDashboard = () => {
  const [value, setValue] = React.useState("2");
  const [subscriptionId, setSubscriptionId] = useState<any>('')
  const [amount, setAmount] = useState(0)
  const [subscriptionList, setSubscriptionList] = useState<any>([])
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const { orderIdFromBackend, getSubscriptionList, subscriptionIdPass, CreateOrderForSubscription, setFlag, flag
  } = useSubScriptionAPI();
  const { getUserInvoiceDetails } = useSubScriptionAPI()
  const getSub = async () => {
    const result = await getSubscriptionList("", 0, 1, 0, "", "");
    setSubscriptionList(result.data?.result?.items)
    setAmount(result?.data?.result?.items[0].price)
    console.log(result?.data?.result, "55")
    setFlag(result?.data?.result?.items?.map((value: any) => {
      return value?.paymentStatusDto
    }))
    // setSubscription(newData?.data?.result?.items[0].id)
    setSubscriptionId(localStorage.getItem('subcr'))
    // const resultNew = await getUserInvoiceDetails("", 0, 10, localStorage.getItem("userId"))
    // setFlag(resultNew?.map((value: any) => {
    //   return (value?.transactions?.map((item: any) => {
    //     return value?.subscription?.paymentStatusDto
    //   }))
    // }))
    // console.log("Flag",flag[0][0])
    // paymentStatusDto
  };

  const getInit = async () => {
    const result: any = await getSub()

  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  useEffect(() => {

    getInit()
  }, []);
  return (
    <>
      <HeaderListing />
      {/* <div>User Dashboard</div>
            <Paypal /> */}
      <Box
        className={styles.contentMain}
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", py: 3, px: 5 }}
      >
        <Typography variant="h4" ml={2} fontSize={20}>
          Manage Account
        </Typography>
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Box sx={style.tabContainer}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
                sx={style.tabList}
              >
                <Tab label="Account" value="1" sx={{ color: "#fff" }} />
                <Tab label="Subscription" value="2" />
                <Tab label="Invoice" value="3" />
              </TabList>
            </Box>
            <Divider
              sx={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#E0E0E0",
                borderBottomWidth: "inherit",
                opacity: 1,
              }}
            />
            <TabPanel value="1">
              <AccManagementUser />
            </TabPanel>
            <TabPanel value="2">
              {flag && flag?.map((v: any) => {
                return (
                  <>
                    {v === 1 ?
                      <ManageSubscriptionUser subscriptionList={subscriptionList} />
                      :
                      <SuBscriptionPaypal amount={formatter.format(amount)} />
                    }
                  </>
                )
              })}

            </TabPanel>
            <TabPanel value="3">
              <UserInvoice />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
};
export default ListingLandingDashboard;