import {
  Box,    Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { ReactComponent as Close } from "../../../../src/assets/icons/close.svg";
import { ReactComponent as Filter } from "../../../assets/images/icons/filter.svg";
import { ReactComponent as Reject } from "../../../assets/images/icons/reject.svg";
import { ReactComponent as Accept } from "../../../assets/images/icons/accept.svg";
import { ReactComponent as Calendar } from "../../../assets/images/icons/calendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./index.module.scss";
import { useAuth } from "../../../shared/provider/authprovider";
import { useEffect, useState } from "react";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import { Dropdown, Modal } from "react-bootstrap";
import Loader from "../../../loader";
import { ReactComponent as Delete } from "../../../assets/images/icons/delete.svg";
import { ReactComponent as Edit } from "../../../assets/images/icons/edit.svg";
import { style } from "../../listingScreens/superAdminDashboard/index";
import CloseIcon from "@mui/icons-material/Close";
const AproovedUserManagement = () => {
  const { isLoading } = useAuth();
  const {
    getListingUserList,
    putAcceptUser,
    putRejectUser,
    getUser,
    userData,
    setMessageText,
    setOpen,
    deleteListingUser,
  } = useClientAPI();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(200);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [newStartDate, setNewStartDate] = useState<any>(new Date());
  const [newEndDate, setNewEndDate] = useState<any>(new Date());
  const [listingUserCount, setListingUserCount] = useState<any>([]);
  const [listingUserList, setListingUserList] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [valuetoDelete, setValuetoDelete] = useState<any>("");
  const [userStatus, setUsetStatus] = useState<any>(1);

  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setOffset((value - 1) * limit);
    const resultUser = await getListingUserList(
      "",
      (value - 1) * limit,
      limit,
      true,
      1
    );
    setListingUserCount(resultUser?.data?.result?.count);
    setListingUserList(resultUser?.data?.result);
    setPage(value);
  };

  const putAcceptUserRequest = async (id: any) => {
    const response = await putAcceptUser([id]);
    if (response?.status == 200) {
      setShow(false);
      setMessageText("User Request Approved");
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
    const resultUser: any = getListingUserList(
      "",
      offset,
      limit,
      true,
      userStatus
    );
    setListingUserCount(resultUser?.data?.result?.count);
    setListingUserList(resultUser?.data?.result);
  };
  const putRejectUserRequest = async (id: any) => {
    await putRejectUser([id]);
    const resultUser: any = getListingUserList(
      "",
      offset,
      limit,
      true,
      userStatus
    );
    setListingUserCount(resultUser?.data?.result?.count);
    setListingUserList(resultUser?.data?.result);
  };
  const [prefix, setPrefix] = useState("");
  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      onTapSearch();
    }
  };
  const onTapSearch = async () => {
    const resultUser = await getListingUserList(
      prefix,
      offset,
      limit,
      true,
      userStatus
    );
    setListingUserCount(resultUser?.data?.result?.count);
    setListingUserList(resultUser?.data?.result);
    setOffset(0);
    setLimit(50);
    setPage(1);
  };
  const handleStartDate = (value: any) => {
    console.log("SDFG");
  };
  const handleEndDate = (value: any) => {
    console.log("SDFG");
  };
  const loadInitialData = async () => {
    const resultUser = await getListingUserList(
      "",
      offset,
      limit,
      true,
      userStatus
    );
    setListingUserCount(resultUser?.data?.result?.count);
    setListingUserList(resultUser?.data?.result);
  };
  const setDeleteFun = async (value: any) => {
    setDeleteModal(true);
    setValuetoDelete(value);
  };
  const handleDeleteUser = async () => {
    const result = await deleteListingUser(valuetoDelete);
    if (result.data?.status == 200) {
      setDeleteModal(false);
      setMessageText("User Deleted Successfully");
      const resultUser = await getListingUserList(
        "",
        offset,
        limit,
        true,
        userStatus
      );
      setListingUserCount(resultUser?.data?.result?.count);
      setListingUserList(resultUser?.data?.result);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  };
  const [checked, setChecked] = useState(true);
  const userApproovalStatusList = [
    { value: 0, label: "Pending" },
    { value: 1, label: "Active" },
    { value: 2, label: "Rejected" },
    { value: 3, label: "All" },
  ];
  const handleChangeSelect = (event: any) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    loadInitialData();
  }, []);
  return (
    <>
      <div className="startMain">
        <div className="d-flex align-items-center">
          <div className={`${styles.searchInput}`}>
            <Stack direction="row" alignItems="center">
              <Search className={styles.searchIcon} />
              <input
                type="search"
                placeholder="Search"
                value={prefix}
                onKeyPress={(event) => handleKeypress(event)}
                onChange={(event) => setPrefix(event.target.value)}
                style={{ width: "100%" }}
              />
              {prefix.length >> 0 ? (
                <CloseIcon
                  onClick={async () => {
                    setPrefix("");
                    const resultUser = await getListingUserList(
                      "",
                      offset,
                      limit,
                      true,
                      userStatus
                    );
                    setListingUserCount(resultUser?.data?.result?.count);
                    setListingUserList(resultUser?.data?.result);
                  }}
                />
              ) : (
                <></>
              )}
            </Stack>
          </div>

          {/* {/ new code /} */}
          <Stack
            alignItems="center"
            sx={{
              ...style.borderStack,
              flexDirection: "row",
              ml: 2,
              px: "10px",
            }}
          >
            <Filter style={{ marginRight: "10px" }} />
            <span className={styles.numberWithBg}>4</span>
            {/* <Dropdown>
                            <Dropdown.Toggle variant="Warning" id="dropdown-basic" className="outlinegreenBtn" > <Filter style={{ marginRight: "10px" }} />
                                <span className={styles.numberWithBg}>4</span></Dropdown.Toggle>
                            <Dropdown.Menu className="dropMenug">
                                {userApproovalStatusList?.map((v: any) => {
                                    return (
                                        <Dropdown.Item className="clickItem" >
                                            <Input
                                                id="input-with-icon-adornment"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Checkbox
                                                            checked={checked}
                                                            onChange={() => setUsetStatus(v?.value)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        /> {v?.label}
                                                    </InputAdornment>
                                                }
                                            />
                                        </Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown> */}
          </Stack>
          <Stack
            justifyContent="center"
            className={styles.datepickerWrapper}
            sx={{ ...style.borderStack, ml: 2, px: "10px" }}
          >
            <div className=" from d-flex align-items-center">
              {/* <div
                      className="me-2 pointer"
                      onClick={() => getPrevMonthData()}
                    ></div> */}
              <div className="d-flex align-items-center">
                <Calendar className={styles.calendarIcon} />
                <DatePicker
                  className={styles.dateUi}
                  selected={new Date(newStartDate)}
                  onChange={(selectedstart) => handleStartDate(selectedstart)}
                  dateFormat="MM-dd-yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                />
                <span>-</span>
                <DatePicker
                  className={styles.dateUi}
                  selected={new Date(newEndDate)}
                  onChange={(selectedstart) => handleEndDate(selectedstart)}
                  dateFormat="MM-dd-yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                />
              </div>
            </div>
          </Stack>
        </div>
        <div className="">
          <div className="d-flex paginationSect align-items-center justify-content-between mt-0">
            <Stack spacing={2} className="d-flex flex-row align-items-center">
              <label className="formLabel">
                {listingUserCount > 0 ? offset + 1 : 0} -{" "}
                {listingUserCount > offset + limit
                  ? offset + limit
                  : listingUserCount}{" "}
                of {listingUserCount}
              </label>
              <Pagination
                className="m-0 paginatnNav"
                size="small"
                shape="rounded"
                count={Math.ceil(listingUserCount / limit)}
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </div>
        </div>
        {/* new code end*/}
      </div>
      <div className={styles.heightG}>
        <table className={styles.tableH}>
          <thead className={`${styles.thead} rounded`}>
            <tr>
              <td>Sr. No.</td>
              <td>Name</td>
              <td>Comapny</td>
              <td>Email</td>
              <td>Phone</td>
              <td>Acc. Status</td>
              <td>Subscription Type</td>
              <td>Subscription Status</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {!isLoading ? (
              <>
                {
                  // listingUserCount > 0 ?
                  listingUserList &&
                    listingUserList?.items?.map((value: any) => {
                      return (
                        <tr>
                          <td>
                            {listingUserList?.items?.findIndex(
                              (x: any) => x.id === value?.id
                            ) + 1}
                          </td>
                          <td>{value?.firstName + " " + value?.lastName}</td>
                          <td>{value?.company}</td>
                          <td>{value?.email}</td>
                          <td>{value?.mobileNumber}</td>
                          <td>
                            {value?.isActivate == true ? "Active" : "Deactive"}
                          </td>
                          <td>SubscriptionType</td>
                          <td>
                            {value?.isActivate == true
                              ? "Subscribed"
                              : "Awaiting Payment"}
                          </td>
                          <td>
                            {/* <button
                                                        onClick={async () => {
                                                            await getUser(value?.id);
                                                            setModalTitle("Accept");
                                                            setShow(true);
                                                        }}
                                                        className={styles.tBtn}
                                                    >
                                                        <Edit />
                                                        Edit
                                                    </button> */}
                            <button
                              onClick={async () => {
                                setDeleteFun(value?.id);
                              }}
                              className={styles.tBtn}
                            >
                              <Delete />
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  // :
                  // <tr>
                  //     <td colSpan={7}>
                  //         <p className='mb-0 py-2'>No records found</p>
                  //     </td>
                  // </tr>
                }
              </>
            ) : (
              <Loader />
            )}
          </tbody>
          {/* <Modal
                        show={show}
                        onHide={() => setShow(false)}
                        dialogClassName={styles.tableModal}
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                        scrollable={true}
                    >
                        <Modal.Header closeButton className="border-bottom-0">
                            <Modal.Title className="d-flex">
                                <Typography variant="body2" fontWeight={300} fontSize={14}>
                                    {modalTitle}{" "}
                                    <span style={{ fontWeight: "500" }}>
                                        {userData?.firstName}{" "}
                                    </span>
                                    Registartion Request
                                </Typography>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={styles.formContainer}>
                                <Box
                                    sx={{
                                        backgroundColor: "#F5F5F5",
                                        borderRadius: 1,
                                        px: 2,
                                        py: 3,
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Stack>
                                                <Typography>Email Address:</Typography>
                                                <Typography>{userData?.email}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack>
                                                <Typography>Organization Name:</Typography>
                                                <Typography>{userData?.company}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="standard-multiline-static"
                                                label="Multiline"
                                                required
                                                fullWidth
                                                multiline
                                                rows={4}
                                                variant="standard"
                                                placeholder="Placeholder"
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <div>
                                    <button
                                        onClick={() => {
                                            putAcceptUserRequest(userData?.id);
                                        }}
                                    >
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => {
                                            putRejectUserRequest(userData?.id);
                                        }}
                                    >
                                        Reject
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal> */}
          <Dialog
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={style.dialogContainer}
          >
            <DialogTitle
              sx={{ m: 0, py: 2, px: 2.5, fontSize: 18, fontWeight: 300 }}
            >
              Delete User
              <IconButton
                aria-label="close"
                onClick={() => setDeleteModal(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              {/* ) : null} */}
            </DialogTitle>

            <DialogContent sx={{ p: 2.5 }}>
              <Box sx={{ borderRadius: 0.5, background: "#F5F5F5", p: 1.5 }}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    Are you sure to delete ?
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "center",
                pb: 3,
                "&>:not(:first-of-type)": { ml: 2.5 },
              }}
            >
              <Button
                onClick={() => {
                  handleDeleteUser();
                }}
                sx={{
                  ...style.modalBtn,
                  background:
                    "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                  color: "#fff",
                }}
              >
                Delete
              </Button>
              <Button
                onClick={() => setDeleteModal(false)}
                sx={{
                  ...style.modalBtn,
                  ml: 2,
                  border: "1px solid rgba(104, 115, 125, 0.48)",
                  color: "#68737D",
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </table>
      </div>
    </>
  );
};
export default AproovedUserManagement;
