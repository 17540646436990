import { useState } from "react";
import { useAuth } from "../provider/authprovider";

export const useConnectionChannelAdvisorAPI = () => {
  const { api, handleError, setErrorModalShow, setErrorMsg } = useAuth()
  const [channelAdvisor, setChannelAdvisor] = useState<any | null>(null)
  const [channelAdvisorCount, setChannelAdvisorCount] = useState<any | null>(null)
  const [channelAdvisorId, setChannelAdvisorById] = useState<any | null>(null)

  const getAllChannelAdvisor = async (offset: any, limit: any) => {
    try {
      const result = await api.get(`/api/MasterDataContrroller/AllChannelAdvisorTokens?Offset=${offset}&Limit=${limit}`);
      setChannelAdvisor(result?.data?.results)
      setChannelAdvisorCount(result?.data?.count)
      return result
    } catch (error: any) {
      handleError(error)
    };
  }
  const getChannelAdvisorById = async (id: any) => {
    try {
      const result = await api.get(`/api/MasterDataContrroller/ChannelAdvisorToken/${id}`);
      setChannelAdvisorById(result?.data?.results)
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  const putChannelAdvisor = async (id: any, values: any, offset: any, limit: any) => {
    try {
      const result = await api.put(`/api/MasterDataContrroller/ChannelAdvisorToken/${id}`, values);
      if (result?.data?.status == 200) {
        setErrorModalShow(true);
        setErrorMsg("Channel Advisor Edited Successfully");
        await getAllChannelAdvisor(offset, limit)
      }
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  const postChannelAdvisor = async (values: any, offset: any, limit: any) => {
    try {
      const result = await api.post(`/api/MasterDataContrroller/ChannelAdvisorToken`, values);
      if (result?.data?.status == 200) {
        setErrorModalShow(true);
        setErrorMsg("Channel Advisor Added Successfully");
        await getAllChannelAdvisor(offset, limit)
      }
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  return {
    getChannelAdvisorById, putChannelAdvisor, postChannelAdvisor, getAllChannelAdvisor, channelAdvisor,
    channelAdvisorCount, channelAdvisorId
  } as const
}