import { Box, Button, Stack, Typography } from "@mui/material"
import Header from "../../components/header/Header"
import ErrorModal from "../../components/errorModal/ErrorModal";
import { useAuth } from "../../shared/provider/authprovider";
import { useEffect, useState } from "react";
import { Field, FormikProvider, useFormik } from "formik";
import { useSCanUPCAPIs } from "../../shared/api/scanUPC";
import Loader from "../../loader";
import './index.scss'
const ScanUPC = () => {
    const { errorModalShow, setErrorModalShow, setSelectedIndex, isLoading } = useAuth()
    const { scanUPCBarcode } = useSCanUPCAPIs()
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        );
    };
    const formik: any = useFormik({
        initialValues: {
            upc: "",
            brand: "",
        },
        // validationSchema: DisplayingErrorMessagesSchema3,
        onSubmit: () => submitData(),
    });
    const [productData, setProductData] = useState<any>(null)
    const submitData = async () => {
        console.log("formik", formik.values.upc);
        const result = await scanUPCBarcode(formik.values.upc)
        setProductData(result?.data?.result)
    };
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    useEffect(() => {
        setSelectedIndex(35);
    }, []);
    return (
        <>
            {isLoading ? <Loader /> : ""}
            {errorHandle()}
            <Header />
            <Box
                className="contentMain updateProdWrapper guidedProdWrapper"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <div className=''>
                    <div className='startMain d-flex justify-content-between'>
                        <h5 className='w-50 pageHead mb-0'>Scan UPC</h5>

                    </div>
                    <div >
                        <FormikProvider value={formik}>
                            <div className="formContainer">
                                <div className="row align-items-center formItem">
                                    <div className="col-sm-6 col-md-2 col-lg-2">
                                        <label htmlFor="location">Enter UPC : </label>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4">
                                        <Field
                                            autoFocus
                                            className="textfield"
                                            id="upc"
                                            name="upc"
                                            placeholder="Enter UPC"
                                            onKeyPress={(event: any) => {
                                                event.key == "Enter"
                                                    ? formik.handleSubmit()
                                                    : console.log("null");
                                            }}
                                        />
                                        {
                                            <span className="text-danger">
                                                {formik.errors?.upc &&
                                                    formik.touched?.upc &&
                                                    formik.touched?.upc &&
                                                    formik.errors?.upc}
                                            </span>
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="d-flex col-6 justify-content-end">
                                        <Button
                                            onClick={async () => {
                                                formik.resetForm();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={async () => {
                                                formik.handleSubmit();

                                            }}
                                        >
                                            Scan
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </FormikProvider>
                    </div>
                </div>
                {productData == null ?
                    <></>
                    :
                    <div className="productContainerUPC row">
                        <div className="imgSection col-5">
                            {productData?.product_images?.map((imglink: any) => {
                                return <img src={imglink.url} style={{ width: "100%" }} />;
                            })}
                        </div>
                        <div className="productDetails col-7">
                            <Stack direction="row" alignItems="center" mb={1}>
                                <Typography variant="body2" fontSize={14} fontWeight={600}>
                                    Product Name:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {" "}
                                        {productData?.product_title}
                                    </span>
                                </Typography>
                            </Stack>
                            <Typography variant="body2" fontSize={14} fontWeight={600}>
                                Product Description :{" "}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ fontSize: "13px", fontWeight: 400, mb: 1 }}
                            >
                                {productData?.product_description}
                            </Typography>
                            <Stack direction="row" alignItems="center" mb={1}>
                                <Typography variant="body2" fontSize={14} fontWeight={600}>
                                    Brand:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {" "}
                                        {productData?.product_brand}
                                    </span>
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" mb={1}>
                                <Typography variant="body2" fontSize={14} fontWeight={600}>
                                    Category:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {" "}
                                        {productData?.product_category}
                                    </span>
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" mb={1}>
                                <Typography variant="body2" fontSize={14} fontWeight={600}>
                                    Class:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {" "}
                                        {productData?.product_class}
                                    </span>
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" mb={1}>
                                <Typography variant="body2" fontSize={14} fontWeight={600}>
                                    Color:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {" "}
                                        {productData?.product_color}
                                    </span>
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" mb={1}>
                                <Typography variant="body2" fontSize={14} fontWeight={600}>
                                    Style:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {" "}
                                        {productData?.product_style}
                                    </span>
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" mb={1}>
                                <Typography variant="body2" fontSize={14} fontWeight={600}>
                                    Type:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {" "}
                                        {productData?.product_type}
                                    </span>
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" mb={1}>
                                <Typography variant="body2" fontSize={14} fontWeight={600}>
                                    Weight:{" "}
                                    {productData?.product_weight?.map((item: any) => {
                                        return (
                                            <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                                {" "}
                                                {item?.value}{" "}{item?.unit}
                                            </span>
                                        )
                                    })}
                                </Typography>
                            </Stack>
                            <Typography variant="body2" fontSize={14} fontWeight={600}>Band</Typography>
                            <Stack direction="row" className="row d-flex flex-direction-row" alignItems="center" mb={1}>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Color:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.band?.color == null ? <span>--</span> : productData?.band?.color}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Material:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.band?.material == null ? <span>--</span> : productData?.band?.material}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Type:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.band?.type == null ? <span>--</span> : productData?.band?.type}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Clasp:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.band?.clasp == null ? <span>--</span> : productData?.band?.clasp}
                                    </span>
                                </Typography>

                            </Stack>
                            <Typography variant="body2" fontSize={14} fontWeight={600}>Band Size</Typography>
                            <Stack direction="row"  className="row d-flex flex-direction-row" alignItems="center" mb={1}>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Width:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.band?.width_mm == null ? <span>--</span> : productData?.band?.width_mm}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Fits Wrist Up To (inches):{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.band?.fits_wrist_up_to_inches == null ? <span>--</span> : productData?.band?.fits_wrist_up_to_inches}
                                    </span>
                                </Typography>
                            </Stack>
                            <Typography variant="body2" fontSize={14} fontWeight={600}>Size</Typography>
                            <Stack direction="row"  className="row d-flex flex-direction-row" alignItems="center" mb={2}>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Fits Wrist Up To (inches):{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.size?.fits_wrist_up_to_inches == null ? <span>--</span> : productData?.size?.fits_wrist_up_to_inches}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Width:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.size?.width_mm == null ? <span>--</span> : productData?.size?.width_mm}
                                    </span>
                                </Typography>
                            </Stack>
                            <Typography variant="body2" fontSize={14} fontWeight={600}>Case</Typography>
                            <Stack direction="row"  className="row d-flex flex-direction-row" alignItems="center" mb={2}>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Back:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.case?.back == null ? <span>--</span> : productData?.case?.back}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Shape:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.case?.shape == null ? <span>--</span> : productData?.case?.shape}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Thickness:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.case?.thickness_mm == null ? <span>--</span> : productData?.case?.thickness_mm}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Crystal:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.case?.crystal == null ? <span>--</span> : productData?.case?.crystal}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Water Resistance:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.case?.water_resistance == null ? <span>--</span> : productData?.case?.water_resistance}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Width:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.case?.width_mm == null ? <span>--</span> : productData?.case?.width_mm}
                                    </span>
                                </Typography>
                            </Stack>
                            <Typography variant="body2" fontSize={14} fontWeight={600}>Movement</Typography>
                            <Stack direction="row"  className="row d-flex flex-direction-row" alignItems="center" mb={2}>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Country of origin:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.movement?.country_of_origin == null ? <span>--</span> : productData?.movement?.country_of_origin}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Features:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.movement?.features == null ? <span>--</span> : productData?.movement?.features}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Type:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.movement?.type == null ? <span>--</span> : productData?.movement?.type}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Crown:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.movement?.crown == null ? <span>--</span> : productData?.movement?.crown}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Calendar:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.movement?.calendar == null ? <span>--</span> : productData?.movement?.calendar}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Jewels:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.movement?.jewels == null ? <span>--</span> : productData?.movement?.jewels}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Power Reverse:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.movement?.power_reserve == null ? <span>--</span> : productData?.movement?.power_reserve}
                                    </span>
                                </Typography>
                                <Typography variant="body2" fontSize={14} fontWeight={600} className="col-6">
                                    Caliber:{" "}
                                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                        {productData?.movement?.caliber == null ? <span>--</span> : productData?.movement?.caliber}
                                    </span>
                                </Typography>
                            </Stack>

                            <Typography variant="body2" fontSize={14} fontWeight={600} mb={1}>
                                Product Prices :
                            </Typography>
                            <div className="heightG">
                                <table className="tableH">
                                    <thead className="thead rounded">
                                        <tr>
                                            <th>
                                                <strong style={{ fontWeight: 600 }}>Source</strong>
                                            </th>
                                            <th>
                                                <strong style={{ fontWeight: 600 }}>Price</strong>
                                            </th>
                                            <th>
                                                <strong style={{ fontWeight: 600 }}> </strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="tbody">
                                        {productData?.product_prices?.map((price: any, index: any) => {
                                            return (
                                                <>
                                                    <tr style={{ backgroundColor: price?.merchant == "Retail Price" ? "#C8D6F8" : "white" }} key={index}>
                                                        <td>
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    fontSize: "13px",
                                                                    fontWeight: 400,
                                                                    mb: 1,
                                                                }}
                                                            >
                                                                {price?.source}
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    fontSize: "13px",
                                                                    fontWeight: 400,
                                                                    mb: 1,
                                                                }}
                                                            >
                                                                <span>{formatter.format(price?.price)}</span>
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </Box>

        </>
    )
}
export default ScanUPC