import { Box, FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { Field, Form, Formik, } from "formik";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/header/Header";
import * as Yup from 'yup';
import { Button } from "react-bootstrap";
import './user.scss';
import { useAuth } from '../../shared/provider/authprovider'
import Loader from "../../loader";
import ErrorModal from "../../components/errorModal/ErrorModal";
import CurrencyInput from 'react-currency-input-field';
import Input from '@mui/material/Input';
interface MyFormValues {
    "upc": string,
    "fabric": string,
    "size": string,
    "color": string,
    "retailPrice": string
}
const DisplayingErrorMessagesSchema = Yup.object().shape({
    fabric: Yup.string().required('Required'),
    size: Yup.string().required('Required'),
    color: Yup.string().required('Required'),
    retailPrice: Yup.string().required('Required')
});
const UpdateProductForm = () => {
    const { upc } = useParams();
    let navigate = useNavigate();
    const { getManifestProductDetailsCalled, errorModalShow, setSelectedIndex, setErrorModalShow, manifestMissingProductDetails, putManifestMissingProductsCalled } = useAuth()

    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };

    const initialValues: MyFormValues = { fabric: '', size: '', color: '', retailPrice: '', upc: manifestMissingProductDetails?.upc };

    useEffect(() => {
        getManifestProductDetailsCalled(upc)
        setSelectedIndex(22)
    }, [])
    return (
        <>
            {errorHandle()}
            <Header />
            <Box
                className="contentMain updateProdWrapper"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <div className='startMain'>
                    <h5 className="pageHead mb-0 me-2">Update product details of {upc}</h5>

                </div>
                <div className="row">
                    <div className="col-12">
                        <p>Title : {manifestMissingProductDetails?.title}</p>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="mb-3">
                            <img src={manifestMissingProductDetails?.imageUrl} className="prodImg" />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-8">
                        <div className="userModal">
                            <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                validationSchema={DisplayingErrorMessagesSchema}
                                onSubmit={(values, actions) => {
                                    console.log(values);
                                    actions.setSubmitting(false);
                                    putManifestMissingProductsCalled(values)
                                    // navigate(-1)
                                }}
                            >
                                {({ errors, touched }) => (
                                    <Form>
                                        <div className='formContainer'>
                                            <div className='row align-items-center formItem'>
                                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                                    <label htmlFor="upc">UPC :</label>
                                                </div>
                                                <div className='col-sm-12 col-md-8 col-lg-8'>
                                                    <label>{manifestMissingProductDetails?.upc}</label>
                                                    <Field className='textfield d-none' id="faupcbric" name="upc" value={manifestMissingProductDetails?.upc} />
                                                </div>
                                            </div>
                                            <div className='row align-items-center formItem'>
                                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                                    <label htmlFor="fabric">Fabric :</label>
                                                </div>
                                                <div className='col-sm-12 col-md-8 col-lg-8'>
                                                    <Field className='textfield' id="fabric" name="fabric" placeholder="Enter fabric" />
                                                    {touched.fabric && errors.fabric && <div className='errorMsg'>{errors.fabric}</div>}
                                                </div>
                                            </div>
                                            <div className='row align-items-center formItem'>
                                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                                    <label htmlFor="size">Size :</label>
                                                </div>
                                                <div className='col-sm-12 col-md-8 col-lg-8'>
                                                    <Field className='textfield' id="size" name="size" placeholder="Enter size" />
                                                    {touched.size && errors.size && <div className='errorMsg'>{errors.size}</div>}
                                                </div>
                                            </div>
                                            <div className='row align-items-center formItem'>
                                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                                    <label htmlFor="color">Color :</label>
                                                </div>
                                                <div className='col-sm-12 col-md-8 col-lg-8'>
                                                    <Field className='textfield' id="color" name="color" placeholder="Enter color" />
                                                    {touched.color && errors.color && <div className='errorMsg'>{errors.color}</div>}
                                                </div>
                                            </div>
                                            <div className='row align-items-center formItem'>
                                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                                    <label htmlFor="retailPrice">Retail Price :</label>
                                                </div>
                                                <div className='col-sm-12 col-md-8 col-lg-8'>
                                                    {/* <CurrencyInput
                                                        className='textfield' id="retailPrice" name="retailPrice"
                                                        intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                                        placeholder="Enter retail price"
                                                        groupSeparator=","
                                                    /> */}
                                                    {/* <Field
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        className='textfield' name="retailPrice"
                                                        id="retailPrice"
                                                        type="number"
                                                    /> */}
                                                    {/* <Input
                                                    type="number"
                                                        className='textfield'
                                                        name="retailPrice"
                                                        id="retailPrice"
                                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    /> */}
                                                    {/* <FormControl fullWidth sx={{ m: 1 }}>
                                                        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-amount"
                                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                            label="Amount"
                                                        />
                                                    </FormControl> */}
                                                    <Field
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">$</InputAdornment>
                                                            ),
                                                        }}
                                                        type="number" className='textfield' id="retailPrice" name="retailPrice" placeholder="Enter retail price"
                                                    />
                                                    
                                                    {touched.retailPrice && errors.retailPrice && <div className='errorMsg'>{errors.retailPrice}</div>}
                                                </div>
                                            </div>  <div className='row align-items-center formItem'>
                                                <div className='col-sm-12 col-md-8 col-lg-8 offset-md-4'>
                                                    <div className="formBtns">
                                                        <Button className="FilledBtn" type="submit">Save Details</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>

                                )}
                            </Formik>
                        </div>
                    </div>
                </div>

            </Box>
        </>
    )
}
export default UpdateProductForm;