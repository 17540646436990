import { useAuth } from "../provider/authprovider";

export const useWebFormAPI = () => {
    const { setLoading, api, handleError } = useAuth()
    const getProductDetailsList = async (startDate:any, endDate:any,offset: any, limit: any, prefix: any) => {
        try {
            const result = await api.get(`/api/Products/list?fromDate=${startDate}&toDate=${endDate}&offset=${offset}&limit=${limit}&prefix=${prefix}&sortBy=UPC&orderBy=asc`);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const postNewProduct = async(details:any) => {
        setLoading(true)
        try {
            const result = await api.post(`/api/Products`, details);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally {
            setLoading(false)
        }
    }
    return { getProductDetailsList, postNewProduct } as const
}