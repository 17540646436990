import React, { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../provider/authprovider"
import axios, { AxiosPromise } from "axios";
import { environmentUrl, environmentUrlSaaS } from "./index.service";
import { useNavigate } from "react-router-dom";
import { AnyARecord } from "dns";
import { useLocalStorageState } from "ahooks";

export interface IUseAuth {
  token: any | null;
}
export const useClientAPI = () => {
  const { apinew, handleError, setLoading } = useAuth()
  const [messageText, setMessageText] = useState<any>("")
  const [shortMessage, setShortMessage] = useState<any>("")
  const [errorMessageText, setErrorMessageText] = useState<any>("")
  const [listingUserList, setListingUserList] = useState<any>()
  const [listingUserCount, setListingUserCount] = useState<any>(0)
  const [open, setOpen] = useState(false);
  const [token, setToken] = useLocalStorageState<any | null>('');
  const [roles, setRoles] = useState<any | null>(null);
  const [firstName, setFirstName] = useState<any | null>(null);
  const [lastName, setLastName] = useState<any | null>(null);
  const [userData, setUserData] = useState<any | null>(null)
  const navigate = useNavigate()
  useEffect(() => {
    getDataFromStorage();
  }, []);

  const getDataFromStorage = () => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    setRoles(role);
    setToken(token);
    setFirstName(firstName);
    setLastName(lastName);
  };
  //add new client
  const postNewClient = async (details: any) => {
    try {
      const newData = await apinew.post(`/api/User`, details);
      if (newData?.data?.status == 200) {
        setMessageText("Account Created");
        setOpen(true)
        setTimeout(function () {
          setOpen(false)
          navigate("/listing-login")
        }, 3000);
      }
      return newData
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
      setOpen(true)
      setTimeout(function () {
        setOpen(false)
      }, 5000);
    }
  }
  const getUser = async (id: any) => {
    try {
      const data = await axios.get(`${environmentUrlSaaS}/api/User/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}`, } });
      setUserData(data?.data?.result)
      return data
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
      setOpen(true)
      setTimeout(function () {
        setOpen(false)
      }, 5000);
    }
  }
  const signInListing = async (details: any) => {
    setLoading(true)
    try {
      const result = await axios.post(`${environmentUrlSaaS}/api/Authentication/token`, details);
      console.log("Login", result?.data)
      setToken(result?.data?.token);
      setRoles(result?.data?.roles);
      setFirstName(result?.data?.firstName);
      setLastName(result?.data?.lastName);
      localStorage.setItem("role", result?.data?.roles);
      localStorage.setItem("token", result?.data?.token);
      localStorage.setItem("userId", result?.data?.userId)
      localStorage.setItem("email",result?.data?.email)
      localStorage.setItem("mobile",result?.data?.mobileNumber)
      if (result?.data?.roles == "Customer") {
        await getUser(result?.data?.userId)
          .then((result) => {
            if (result?.data?.result?.userApprovalStatus == 1) {
              navigate("/listing-saas-dashboard", { replace: true });
              navigate(0);
            }
            if (result?.data?.result?.userApprovalStatus == 0) {
              navigate("/listing-dashboard-customer", { replace: true });
              navigate(0);
            }
          })
          .catch((error) => {
            console.log("vidya.agre@invimatic.io", error)
          });
      }
      else if (result?.data?.roles == "SuperAdmin") {
        navigate("/listing-saas-main-dashboard", { replace: true });
        navigate(0);
      }
    }
    catch (error: any) {
      handleErrorSaas(error)
    }
    finally {
      setLoading(false)
    }
  };

  const handleErrorSaas = (error: any) => {
    if (error.response.status === 401) {
      setErrorMessageText(error?.response?.data);
      navigate("/listing-login")
    }
    if (error.response.status === 400) {
      setErrorMessageText(error?.response?.data);
      console.log(error?.response?.data)
    } else {
      setErrorMessageText(error?.response?.data);
      console.log(error?.response?.data)
    }
  }
  //forget password
  const forgotPasswordListingUser = async (details: any) => {
    setLoading(true)
    try {
      const result = await apinew.post(`/api/User/ForgotPassword`, details);
      return result
    } catch (error: any) {
      setMessageText(error?.response?.data);
      setOpen(true)
      setTimeout(function () {
        setOpen(false)
      }, 5000);
    }
    finally {
      setLoading(false)
    }
  };
  // const editUserListing = async (id: any, details: any) => {
  //   setLoading(true)
  //   try {
  //     const result = await apinew.post(`/api/User/${id}`, details);
  //     return result
  //   } catch (error: any) {
  //     setMessageText(error?.response?.data);
  //     setOpen(true)
  //     setTimeout(function () {
  //       setOpen(false)
  //     }, 5000);
  //   }
  //   finally {
  //     setLoading(false)
  //   }
  // };
  ///user list


  // deleteuser
  const deleteListingUser = async (id: any) => {
    try {
      const newData = await apinew.delete(`/DeactivateUser?id=${id}`);
      return newData
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
      setOpen(true)
      setTimeout(function () {
        setOpen(false)
      }, 5000);
    }
  }
  const getListingUserList = async (prefix: any, offset: any, limit: any, isActive: any, status: any) => {
    setLoading(true)
    try {
      const result = await apinew?.get(`/api/User/List?prefix=${prefix}&offset=${offset}&limit=${limit}&isActivate=${isActive}&userApprovalStatus=${status}`);
      // setListingUserList(result?.data?.result)
      // setListingUserCount(result?.data?.result?.count)
      return result
    } catch (error: any) {
      handleErrorSaas(error)
    }
    finally {
      setLoading(false)
    }
  };
  const putAcceptUser = async (id: any) => {
    try {
      const newData = await apinew.put(`/ApproveUser`, id);
      if (newData?.data?.status == 200) {
        setMessageText("User Approoved");
        setOpen(true)
        setTimeout(function () {
          setOpen(false)
        }, 3000);
      }
      return newData
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
      setOpen(true)
      setTimeout(function () {
        setOpen(false)
      }, 5000);
    }
  }
  const putRejectUser = async (id: any) => {
    try {
      const newData = await apinew.put(`/DispproveUser?ids=${id}`);
      if (newData?.data?.status == 200) {
        setMessageText("User Rejected");
        setOpen(true)
        setTimeout(function () {
          setOpen(false)
        }, 3000);
      }
      return newData
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
      setOpen(true)
      setTimeout(function () {
        setOpen(false)
      }, 5000);
    }
  }
  // resetPasswordListing api/User/ResetPassword
  const resetPasswordListing = async (details: any) => {
    try {
      const newData = await apinew.post(`/api/User/ResetPassword`, details);
      if (newData?.data?.status == 200) {
        setMessageText("Password Changed");
        setOpen(true)
        setTimeout(function () {
          setOpen(false)
          navigate("/listing-login")
        }, 3000);
      }
      return newData
    } catch (error: any) {
      setMessageText(error?.response?.data?.result);
      setOpen(true)
      setTimeout(function () {
        setOpen(false)
      }, 5000);
    }
  }
  return {
    postNewClient, messageText, setMessageText, open, setOpen, signInListing, errorMessageText,
    forgotPasswordListingUser, getListingUserList, listingUserList, listingUserCount, putAcceptUser,
    putRejectUser, shortMessage,
    getUser,
    userData, apinew, roles, token, handleErrorSaas, deleteListingUser, resetPasswordListing
    //  editUserListing
  } as const
}