import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Snackbar,
  Stack,
  Switch,
  SwitchProps,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Field, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import { ReactComponent as StartDateCalendar } from "../../../assets/images/icons/calendar-green-circle.svg";
import { ReactComponent as EndDateCalendar } from "../../../assets/images/icons/calendar-red-circle.svg";
import { ReactComponent as Calendar } from "../../../assets/images/icons/calendar.svg";
import { ReactComponent as Delete } from "../../../assets/images/icons/delete.svg";
import { ReactComponent as Edit } from "../../../assets/images/icons/edit.svg";
import { ReactComponent as Filter } from "../../../assets/images/icons/filter.svg";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import Loader from "../../../loader";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import { useSubScriptionAPI } from "../../../shared/api/userSubscriptionSAAS";
import { useAuth } from "../../../shared/provider/authprovider";
import { style } from "../../listingScreens/superAdminDashboard/index";
import styles from "./index.module.scss";
import moment from "moment";

const ManageSubscriptionScreen = () => {
  const DisplayingErrorMessagesSchema = Yup.object().shape({
    userId: Yup.string().required("Required"),
    subscriptionName: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    price: Yup.number().required("Required"),
    subscriptionStartDate: Yup.string().required("Required"),
    subscriptionEndDate: Yup.string().required("Required"),
    subscriptionPlanStatusDto: Yup.string().required("Required"),
    billingCycleDto: Yup.number().required("Required"),
  });
  const { isLoading } = useAuth();
  const {
    getSubscriptionList,
    subscriptionList,
    subscriptionCount,
    getSubscriptionById,
    subscriptionByIdNew,
    addNewSubscriptionPlan,
    putSubscriptionPlan,
    deleteSubscription
  } = useSubScriptionAPI();
  const { getListingUserList, setMessageText, setOpen, open, messageText, getUser } =
    useClientAPI();
  const [prefix, setPrefix] = React.useState("");
  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [startDateFilter, setStartDateFilter] = useState<any>("");
  const [formatedStartDateFilter, setFormatedStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState<any>("");
  const [formatedEndDateFilter, setFormatedEndDateFilter] = useState("");

  const [showNewSubscription, setShowNewSubscription] = React.useState(false);
  const [newStartDate, setNewStartDate] = useState<any>(new Date());
  const [newEndDate, setNewEndDate] = useState<any>(new Date(moment().add(1, 'M').format('MM-DD-YYYY')));
  const [listingUserList, setListingUserList] = useState<any>([]);
  const [editState, setEditState] = useState<any>(false)
  const [editData, setEditData] = useState<any>([])
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [valuetoDelete, setValuetoDelete] = useState<any>('')

  const [subStatus, setSubStatus] = useState<any>(0)
  interface Item {
    id: string;
    company: string;
  }
  interface MappedItem {
    label: string;
    value: string;
  }
  const mappedData: MappedItem[] = listingUserList?.items?.map(
    (item: Item): MappedItem => ({
      label: item?.id,
      value: item?.company,
    })
  );

  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setOffset((value - 1) * limit);
    await getSubscriptionList("", (value - 1) * limit, limit, subStatus, startDateFilter, endDateFilter);
    setPage(value);
  };
  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      onTapSearch();
    }
  };
  const onTapSearch = async () => {
    await getSubscriptionList(prefix, offset, limit, subStatus, startDateFilter, endDateFilter);
    setOffset(0);
    setLimit(50);
    setPage(1);
  };
  const handleStartDateFilter = (value: any) => {
    const result: any = moment(value).format("MM-DD-YYYY")
    setStartDateFilter(value);
    setFormatedStartDateFilter(result);
  }
  const handleEndDateFilter = (value: any) => {
    const result: any = moment(value).format("MM-DD-YYYY")
    setEndDateFilter(value);
    setFormatedEndDateFilter(result);
  }
  const onTapApply = async () => {
    // console.log("NewDate", newStartDate, newEndDate)
    console.log("SDFG");
  };
  const loadInitialData = async () => {
    await getSubscriptionList("", offset, limit, subStatus, startDateFilter, endDateFilter);
    const resultUser = await getListingUserList("", 0, 100, true, 1);
    setListingUserList(resultUser?.data?.result);
  };
  const initailValuesCreate = {
    userId: "",
    subscriptionName: "",
    description: "",
    price: "",
    subscriptionStartDate: newStartDate,
    subscriptionEndDate: newEndDate,
    subscriptionPlanStatusDto: 0,
    billingCycleDto: 30,
  }

  const formikSubscription: any = useFormik({
    initialValues: editState == true ? editData : initailValuesCreate,
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: () => submitData(),
  });
  const submitData = async () => {
    console.log("formik", formikSubscription?.values);
    if (editState == false) {
      const result = await addNewSubscriptionPlan(formikSubscription?.values);
      if (result.data?.status == 200) {
        setShowNewSubscription(false);
        setMessageText("Subscription Added Successfully");
        await getSubscriptionList("", offset, limit, subStatus, startDateFilter, endDateFilter);
        setOpen(true);
        setTimeout(function () {
          setOpen(false);
        }, 5000);
      }
    }
    else {
      const detailstoPut = {
        description: formikSubscription.values?.description,
        price: formikSubscription.values?.price,
        subscriptionPlanStatusDto: formikSubscription.values?.billingCycleDto
      }
      const result = await putSubscriptionPlan(formikSubscription.values?.id, detailstoPut);
      if (result.data?.status == 200) {
        setShowNewSubscription(false);
        setMessageText("Subscription Edited Successfully");
        await getSubscriptionList("", offset, limit, subStatus, startDateFilter, endDateFilter);
        setOpen(true);
        setTimeout(function () {
          setOpen(false);
        }, 5000);
      }
    }
    formikSubscription.resetForm()
  };

  /////////////////////////////////////////////////////////modal
  const handleSubscriptionStartDate = (value: any) => {
    formikSubscription.setFieldValue("subscriptionStartDate", value.toISOString());
    setNewStartDate(new Date(value));
  };
  const handleSubscriptionEndDate = (value: any) => {
    formikSubscription.setFieldValue("subscriptionEndDate", value.toISOString());
    setNewEndDate(new Date(value));
  };
  const [checked, setChecked] = React.useState(true);

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked == true) {
      formikSubscription.setFieldValue("subscriptionPlanStatusDto", 0);
    } else if (event.target.checked == false) {
      formikSubscription.setFieldValue("subscriptionPlanStatusDto", 1);
    }
  };
  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      checked={checked}
      onChange={handleChangeSwitch}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 25,
    height: 15,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(10px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 10,
      height: 10,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  useEffect(() => {
    loadInitialData();
    formikSubscription.resetForm();
  }, []);

  // const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const setEditFun = async (value: any) => {
    const result = await getSubscriptionById(value);
    console.log(result?.data?.result, "###")
    formikSubscription.setFieldValue("userId", result?.data?.result?.user?.id)
    formikSubscription.setFieldValue("subscriptionName", result?.data?.result?.subscriptionName)
    formikSubscription.setFieldValue("description", result?.data?.result?.description)
    formikSubscription.setFieldValue("price", result?.data?.result?.price)
    formikSubscription.setFieldValue("subscriptionStartDate", result?.data?.result?.subscriptionStartDate)
    formikSubscription.setFieldValue("subscriptionEndDate", result?.data?.result?.subscriptionEndDate)
    formikSubscription.setFieldValue("subscriptionPlanStatusDto", result?.data?.result?.subscriptionPlanStatusDto)
    formikSubscription.setFieldValue("billingCycleDto", result?.data?.result?.billingCycleDto)
    formikSubscription.setFieldValue("companyName", result?.data?.result?.user?.company)
    formikSubscription.setFieldValue("id", result?.data?.result?.id)
    setShowNewSubscription(true);
    setEditState(true)
  }
  const setDeleteFun = async (value: any) => {
    setDeleteModal(true)
    setValuetoDelete(value)
  }
  const handleDeleteSubscription = async () => {
    const result = await deleteSubscription(valuetoDelete);
    if (result.data?.status == 200) {
      setDeleteModal(false);
      setMessageText("Subscription Deleted Successfully");
      await getSubscriptionList("", offset, limit, subStatus, startDateFilter, endDateFilter);
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={messageText}
        action={action}
      />
      <div className="startMain">
        <div className="d-flex align-items-center">
          <div className={`${styles.searchInput}`}>
            <Stack direction="row" alignItems="center">
              <Search className={styles.searchIcon} />
              <input
                type="search"
                placeholder="Search"
                value={prefix}
                onKeyPress={(event) => handleKeypress(event)}
                onChange={(event) => setPrefix(event.target.value)}
                style={{ width: "100%" }}
              />
            </Stack>
          </div>
          {/* new code */}
          <Stack
            alignItems="center"
            sx={{
              ...style.borderStack,
              flexDirection: "row",
              ml: 2,
              px: "10px",
            }}
          >
            <Filter style={{ marginRight: "10px" }} />
            <span className={styles.numberWithBg}>4</span>
          </Stack>
          <Stack
            justifyContent="center"
            className={styles.datepickerWrapper}
            sx={{ ...style.borderStack, ml: 2, px: "10px" }}
          >
            <div className=" from d-flex align-items-center">
              {/* <div
                        className="me-2 pointer"
                        onClick={() => getPrevMonthData()}
                      ></div> */}
              <div className="d-flex align-items-center">
                <Calendar className={styles.calendarIcon} />
                <DatePicker
                  className={styles.dateUi}
                  selected={startDateFilter}
                  onChange={(selectedstart) => handleStartDateFilter(selectedstart)}
                  placeholderText="MM-dd-yyyy"
                  dateFormat='MM-dd-yyyy'
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                <span>-</span>
                <DatePicker
                  className={styles.dateUi}
                  selected={endDateFilter}
                  onChange={(selectedstart) => handleEndDateFilter(selectedstart)}
                  placeholderText="MM-dd-yyyy"
                  dateFormat='MM-dd-yyyy'
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
          </Stack>
          <Button
            variant="contained"
            sx={style.fiiledBtn}
            onClick={() => { setEditState(false); setShowNewSubscription(true) }}
          >
            <AddIcon />
            New
          </Button>
        </div>
        <div className="">
          <div className="d-flex paginationSect align-items-center justify-content-between mt-0">
            <Stack spacing={2} className="d-flex flex-row align-items-center">
              <label className="formLabel">
                {subscriptionCount > 0 ? offset + 1 : 0} -{" "}
                {subscriptionCount > offset + limit
                  ? offset + limit
                  : subscriptionCount}{" "}
                of {subscriptionCount}
              </label>
              <Pagination
                className="m-0 paginatnNav"
                size="small"
                shape="rounded"
                count={Math.ceil(subscriptionCount / limit)}
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </div>
        </div>
        {/* new code end*/}
      </div>
      <div className={styles.heightG}>
        <table className={styles.tableH}>
          <thead className={styles.thead}>
            <tr>
              <td>Sr. No.</td>
              <td>Subscription Name</td>
              <td>Status</td>
              <td>Price</td>
              <td>User</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {!isLoading ? (
              <>
                {
                  // subscriptionCount > 0 ?
                  subscriptionList?.items?.map((value: any) => {
                    return (
                      <tr>
                        <td>
                          {subscriptionList?.items?.findIndex(
                            (x: any) => x.id === value?.id
                          ) + 1}
                        </td>
                        <td>{value?.subscriptionName}</td>
                        <td>
                          {value?.subscriptionPlanStatusDto === 0 ? (
                            <>
                              <div>active</div>
                            </>
                          ) : (
                            <>
                              <div>Due</div>
                            </>
                          )}
                        </td>
                        <td>{formatter.format(value?.price)}</td>
                        <td>
                          {value?.user?.firstName + " " + value?.user?.lastName}
                        </td>
                        <td>
                          <button
                            onClick={async () => {
                              setEditFun(value?.id)
                            }}
                            className={styles.tBtn}
                          >
                            <Edit />
                            Edit
                          </button>
                          <button
                            onClick={async () => {
                              setDeleteFun(value?.id)
                            }}
                            className={styles.tBtn}
                          >
                            <Delete />
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  // :
                  // <tr>
                  //     <td colSpan={7}>
                  //         <p className='mb-0 py-2'>No records found</p>
                  //     </td>
                  // </tr>
                }
              </>
            ) : (
              <Loader />
            )}
          </tbody>

          <Dialog
            open={showNewSubscription}
            onClose={() => setShowNewSubscription(false)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={style.dialogContainer}
            onBackdropClick={() => formikSubscription.resetForm()}
          >
            <DialogTitle
              sx={{ m: 0, py: 2, px: 2.5, fontSize: 18, fontWeight: 300 }}
            >
              {/* {children} */}
              {/* {onClose ? ( */}
              {editState == true ? "Edit" : "Create"} Subscription
              <IconButton
                aria-label="close"
                onClick={() => {
                  setShowNewSubscription(false);
                  formikSubscription.resetForm();
                }}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              {/* ) : null} */}
            </DialogTitle>

            <DialogContent sx={{ p: 2.5 }}>
              <FormikProvider value={formikSubscription}>
                <Box sx={{ borderRadius: 0.5, background: "#F5F5F5", p: 1.5 }}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required>Comapny Name</InputLabel>
                      <Field name="userId">
                        {({ field, meta }: any) => (
                          <TextField
                            {...field}
                            id="standard-textarea"
                            placeholder="Select company name"
                            multiline
                            variant="standard"
                            select
                            fullWidth
                            required
                            {...{ error: meta.touched && meta.error }}
                            helperText={
                              meta.touched && meta.error && meta.error
                            }
                            sx={{
                              ".MuiSelect-select:focus": { background: "none" },
                            }}
                          >
                            <MenuItem value="" disabled>
                              Select Company Name
                            </MenuItem>
                            {mappedData?.map((e: any) => (
                              <MenuItem value={e.label}>{e.value}</MenuItem>
                            ))}
                          </TextField>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required>Subscription Name</InputLabel>
                      <Field name="subscriptionName">
                        {({ field, meta }: any) => (
                          <TextField
                            {...field}
                            required
                            id="standard-required"
                            autoComplete="off"
                            variant="standard"
                            fullWidth
                            placeholder="Subscription Name"
                            {...{ error: meta.touched && meta.error }}
                            helperText={
                              meta.touched && meta.error && meta.error
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <InputLabel>Description</InputLabel>
                        <Field name="description">
                          {({ field, meta }: any) => (
                            <TextField
                              {...field}
                              id="standard-required"
                              multiline
                              placeholder="Add Features you want to be included in subscription plan"
                              autoComplete="off"
                              fullWidth
                              variant="standard"
                              rows={2}
                              {...{ error: meta.touched && meta.error }}
                              helperText={
                                meta.touched && meta.error && meta.error
                              }
                            />
                          )}
                        </Field>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  mt={2.5}
                  sx={{ borderRadius: 0.5, background: "#F5F5F5", p: 1.5 }}
                >
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required>Billing Cycle</InputLabel>
                      <Field name="billingCycleDto">
                        {({ field, meta }: any) => (
                          <TextField
                            required
                            {...field}
                            select
                            fullWidth
                            {...{ error: meta.touched && meta.error }}
                            helperText={
                              meta.touched && meta.error && meta.error
                            }
                            id="standard-required"
                            variant="standard"
                            placeholder="Billing Cycle"
                            sx={{
                              ".MuiSelect-select:focus": { background: "none" },
                            }}
                          >
                            <MenuItem value="">Select Billing Cycle</MenuItem>
                            <MenuItem value={30}>Billed Monthly</MenuItem>
                            <MenuItem value={360}>Billed Yearly</MenuItem>
                          </TextField>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required>Price</InputLabel>
                      <Field name="price">
                        {({ field, meta }: any) => (
                          <TextField
                            {...field}
                            required
                            autoComplete="off"
                            fullWidth
                            id="standard-required"
                            variant="standard"
                            placeholder="Price"
                            {...{ error: meta.touched && meta.error }}
                            helperText={
                              meta.touched && meta.error && meta.error
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Box className={styles.datepickerWrapper}>
                            <InputLabel required>Start Date</InputLabel>
                            <Stack
                              display="flex"
                              sx={{
                                py: 0.5,
                                pl: 1,
                                borderBottom: "1px solid #E0E0E0",
                                flexDirection: "row",
                              }}
                            >
                              <DatePicker
                                className={styles.dateUi}
                                selected={new Date(newStartDate)}
                                onChange={(selectedstart) =>
                                  handleSubscriptionStartDate(selectedstart)
                                }
                                dateFormat="MM-dd-yyyy"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                              <StartDateCalendar />
                            </Stack>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Box className={styles.datepickerWrapper}>
                            <InputLabel required>End Date</InputLabel>
                            <Stack
                              display="flex"
                              sx={{
                                py: 0.5,
                                pl: 1,
                                borderBottom: "1px solid #E0E0E0",
                                flexDirection: "row",
                              }}
                            >
                              <DatePicker
                                required
                                className={styles.dateUi}
                                selected={new Date(newEndDate)}
                                onChange={(selectedstart) =>
                                  handleSubscriptionEndDate(selectedstart)
                                }
                                dateFormat="MM-dd-yyyy"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                              <EndDateCalendar />
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Stack>
                        <InputLabel required>Subscription Status</InputLabel>
                        <FormControlLabel
                          sx={{
                            mx: 0,
                            pl: 1.5,
                            mt: 0.5,
                            ".MuiTypography-root": {
                              ml: 1.5,
                              fontSize: 14,
                              fontWeight: 300,
                            },
                          }}
                          control={<IOSSwitch defaultChecked />}
                          label={checked == true ? "Active" : "Inactive"}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </FormikProvider>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "center",
                pb: 3,
                "&>:not(:first-of-type)": { ml: 2.5 },
              }}
            >
              <Button
                onClick={() => {
                  console.log("One")
                  formikSubscription.handleSubmit();
                }}
                sx={{
                  ...style.modalBtn,
                  background:
                    "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                  color: "#fff",
                }}
              >
                Save
              </Button>
              <Button
                sx={{
                  ...style.modalBtn,
                  ml: 2,
                  border: "1px solid rgba(104, 115, 125, 0.48)",
                  color: "#68737D",
                }}
                onClick={() => {
                  setShowNewSubscription(false)
                  // putRejectUserRequest(subscriptionByIdNew?.id);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={style.dialogContainer}
          >
            <DialogTitle sx={{ m: 0, py: 2, px: 2.5, fontSize: 18, fontWeight: 300 }}            >
              Delete Subscription
              <IconButton
                aria-label="close"
                onClick={() => setShowNewSubscription(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              {/* ) : null} */}
            </DialogTitle>

            <DialogContent sx={{ p: 2.5 }}>
              <Box sx={{ borderRadius: 0.5, background: "#F5F5F5", p: 1.5 }}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    Are you sure to delete ?
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "center",
                pb: 3,
                "&>:not(:first-of-type)": { ml: 2.5 },
              }}
            >
              <Button
                onClick={() => {
                  handleDeleteSubscription()
                }}
                sx={{
                  ...style.modalBtn,
                  background:
                    "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                  color: "#fff",
                }}
              >
                Delete
              </Button>
              <Button
                onClick={() => setDeleteModal(false)}
                sx={{
                  ...style.modalBtn,
                  ml: 2,
                  border: "1px solid rgba(104, 115, 125, 0.48)",
                  color: "#68737D",
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </table>
      </div>
    </>
  );
};
export default ManageSubscriptionScreen;
