import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './CreateUserModal.css';
import { useAuth } from '../../shared/provider/authprovider';
import { useUsersAPI } from "../../shared/api/user";
// import { useUserAPI } from "../../shared/api/user";

const CreateUserModal = (props: any) => {
  const { token, getUserListCalled, setErrorModalShow, setErrorMsg } = useAuth();
  const { createUser } = useUsersAPI();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("")
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [createNewUser] = useUserAPI()
  const onTapSubmitBtn = async () => {
    let details = {
      "firstName": firstName,
      "lastName": lastName,
      "mobileNumber": mobileNumber,
      "email": email,
      "password": password
    };
    console.log("details..", details);
    const res = await createUser(details)
    if (res?.data?.status == 200) {
      getUserListCalled("", 0, 1000);
      setErrorModalShow(true);
      setErrorMsg("User Added SuccessFully");
    }
    // const res = await createNewUser(details)
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle">
          Add User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="fieldTitle">First Name</p>
        <input className="inputField" placeholder="Enter First Name" onChange={(event) => setFirstName(event.target.value)} />
        <p className="fieldTitle">Last Name</p>
        <input className="inputField" placeholder="Enter Last Name" onChange={(event) => setLastName(event.target.value)} />
        <p className="fieldTitle">Mobile No.</p>
        <input className="inputField" placeholder="Enter Mobile No." onChange={(event) => setMobileNumber(event.target.value)} />
        <p className="fieldTitle">Email Id</p>
        <input className="inputField" placeholder="Enter Email Id" onChange={(event) => setEmail(event.target.value)} />
        <p className="fieldTitle">Create Password</p>
        <input className="inputField" placeholder="Enter Password" onChange={(event) => setPassword(event.target.value)} />
      </Modal.Body>
      <div className="footerDiv">
        <button className="signInBtn w-100" onClick={() => onTapSubmitBtn()}>Submit</button>
      </div>
    </Modal>
  );
}

export default CreateUserModal;