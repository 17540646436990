import React, { useEffect, useState } from 'react'
import Header from "../../components/header/Header";
import ErrorModal from '../../components/errorModal/ErrorModal';
import { useAuth } from '../../shared/provider/authprovider';
import { Dropdown } from "react-bootstrap";
import filter from "../../assets/filter.svg";
import searchCalendar from "../../assets/icons/searchCalendar.svg";
import XLSX from 'sheetjs-style';
import saveAs from 'file-saver';
import FileSaver from 'file-saver';
import Loader from '../../loader';
import { Box } from '@mui/material';
import './dailyMISReport.scss';
import { ReactComponent as ResetFilter } from "../../../src/assets/icons/filter-reset.svg";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { useMisReportsAPI } from '../../shared/api/reports-mis';

const DailyMISReportScreen = () => {
    const [classificationName, setclassificationName] = useState("Manifest");
    const { errorModalShow, setErrorModalShow,
        isLoading, setSelectedIndex } = useAuth();
    const { misClassifications, postMisDailyRecords } = useMisReportsAPI()
    const [misReportsClassification, setMisReportsClassification] = useState<any | null>(null)

    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    const d = new Date();
    let currmonth = d.getMonth() + 1;
    console.log("Month", currmonth)
    const [year, setYear] = useState<any>(d.getFullYear());
    const [month, setMonth] = useState<any>(currmonth)
    const [monthName, setMonthName] = useState<any>(d.toLocaleString('default', { month: 'long' }))
    const [dailyData, setDailyData] = useState<any | null>(null)

    let values3 = {
        "year": year,
        "month": currmonth,
        "classifications": [
            "Manifest"
        ]
    }
    const onTapApply = async () => {
        let values3 = {
            "year": year,
            "month": month,
            "classifications": [
                classificationName
            ]
        }
        const result = await postMisDailyRecords(values3)
        setDailyData(result?.data?.result)
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx'

    const ontabExport = () => {
        const ws = XLSX.utils.json_to_sheet(dailyData?.reports);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, classificationName + fileExtension)
    }
    const loadInitialData = async () => {
        const result = await misClassifications()
        setMisReportsClassification(result?.data?.result)
        const res = await postMisDailyRecords(values3)
        setDailyData(res?.data?.result)
    }
    useEffect(() => {
        loadInitialData()
        setSelectedIndex(8)
    }, []);
    const resetFilter = async () => {
        setYear(d.getFullYear());
        setMonth(currmonth)
        setMonthName(d.toLocaleString('default', { month: 'long' }))
        setclassificationName("Manifest")
        let valuesReset = {
            "year": d.getFullYear(),
            "month": currmonth,
            "classifications": [
                "Manifest"
            ]
        }
        const res = await postMisDailyRecords(valuesReset)
        setDailyData(res?.data?.result)

    }

    return (
        <>
            {errorHandle()}
            <Header />
            <Box className="contentMain"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <div className='dailyMisReportContainer'>
                    <div className=''>
                        {/* <h6>Daily MIS Report</h6> */}
                        <h5 className="pageHead">Daily MIS Report</h5>
                        <div className="startMain">
                            <div className='d-flex align-items-center'>
                                <div className='d-flex align-items-center filterContainer'>
                                    <div className='leftDiv filterItem'>
                                        <img src={filter} className="mRight" />
                                        <h5 className='m-0 text'>Filters</h5>
                                    </div>
                                    <div className='borderDiv filterItem'>
                                        <label className='w-100 formLabel'>Year</label>
                                        <Dropdown className="outlinedInput margin">
                                            <Dropdown.Toggle variant="Warning" id="dropdown-basic"
                                                className="outlinegreenBtn">{year}</Dropdown.Toggle>
                                            <Dropdown.Menu className="dropMenug">
                                                <Dropdown.Item className="clickItem" onClick={() => { setYear(2023) }}>2023</Dropdown.Item>
                                                <Dropdown.Item className="clickItem" onClick={() => { setYear(2022) }}>2022</Dropdown.Item>
                                                <Dropdown.Item className="clickItem" onClick={() => { setYear(2021) }}>2021</Dropdown.Item>
                                                {/* <Dropdown.Item className="clickItem" onClick={() => {setYear("2020"); onClickYearChange('2020')}}>2020</Dropdown.Item> */}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <label className='w-100 formLabel'>Month</label>
                                        <Dropdown className="outlinedInput">
                                            <Dropdown.Toggle variant="Warning" id="dropdown-basic"
                                                className='outlinegreenBtn'>{monthName}</Dropdown.Toggle>
                                            <Dropdown.Menu className="dropMenug dropHeight">
                                                <Dropdown.Item className="clickItem" onClick={() => { setMonth(1); setMonthName("January") }}>January</Dropdown.Item>
                                                <Dropdown.Item className="clickItem" onClick={() => { setMonth(2); setMonthName("Frebruary") }}>Frebruary</Dropdown.Item>
                                                <Dropdown.Item className="clickItem" onClick={() => { setMonth(3); setMonthName("March") }}>March</Dropdown.Item>
                                                <Dropdown.Item className="clickItem" onClick={() => { setMonth(4); setMonthName("April") }}>April</Dropdown.Item>
                                                <Dropdown.Item className="clickItem" onClick={() => { setMonth(5); setMonthName("May") }}>May</Dropdown.Item>
                                                <Dropdown.Item className="clickItem" onClick={() => { setMonth(6); setMonthName("June") }}>June</Dropdown.Item>
                                                <Dropdown.Item className="clickItem" onClick={() => { setMonth(7); setMonthName("July") }}>July</Dropdown.Item>
                                                <Dropdown.Item className="clickItem" onClick={() => { setMonth(8); setMonthName("August") }}>August</Dropdown.Item>
                                                <Dropdown.Item className="clickItem" onClick={() => { setMonth(9); setMonthName("September") }}>September</Dropdown.Item>
                                                <Dropdown.Item className="clickItem" onClick={() => { setMonth(10); setMonthName("October") }}>October</Dropdown.Item>
                                                <Dropdown.Item className="clickItem" onClick={() => { setMonth(11); setMonthName("November") }}>November</Dropdown.Item>
                                                <Dropdown.Item className="clickItem" onClick={() => { setMonth(12); setMonthName("December") }}>December</Dropdown.Item>

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className='filterItem'>
                                        <label className='w-100 formLabel'>Brand</label>
                                        <Dropdown className="outlinedInput">
                                            <Dropdown.Toggle variant="Warning" id="dropdown-basic" className='outlinegreenBtn'>{classificationName}</Dropdown.Toggle>
                                            <Dropdown.Menu className="dropMenug dropHeight">
                                                {misReportsClassification?.map((v: any) => {
                                                    return (
                                                        <Dropdown.Item className="clickItem" onClick={() => { setclassificationName(v) }}>{v}</Dropdown.Item>
                                                    )
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>
                                </div>
                                <Tooltip TransitionComponent={Zoom} title="Reset" arrow placement="top">
                                    <ResetFilter className='ms-3 pointer' onClick={() => resetFilter()} />
                                </Tooltip>
                            </div>
                            <div className="d-flex justify-content-end w-50">
                                <button className='FilledBtn me-3' onClick={() => onTapApply()}>Apply</button>
                                <button className='FilledBtn' onClick={() => { ontabExport(); }}>Export</button>
                            </div>
                        </div>
                        {!isLoading ?
                            <>
                                <div className='d-flex'>
                                    <div className="dailyMiscontainer">
                                        <div className="d-flex firstdailyMis ">
                                            <div className="sameRow w-100">
                                                <p className="misHead">Total Values</p>
                                                <span className='kpiInfo'>SLC GMV</span>
                                            </div>
                                            <div className="sameRow ms-1">
                                                <p className="forcastValue active">{formatter.format(dailyData?.gmvSum)}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex firstdailyMis bottomDiv">
                                            <div className="sameRow w-100">
                                                <p className="misHead">Forecast Values</p>
                                                <span className='kpiInfo'>SLC GMV</span>
                                            </div>
                                            <div className="sameRow ms-1">
                                                <p className="forcastValue">{formatter.format(dailyData?.forecastGMV)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dailyMiscontainer ms-2">
                                        <div className="d-flex firstdailyMis ">
                                            <div className="sameRow w-100">
                                                <p className="misHead">Total Values</p>
                                                <span className='kpiInfo'>Orders</span>
                                            </div>
                                            <div className="sameRow ms-1">
                                                <p className="forcastValue active">{dailyData?.ordersSum.toLocaleString()}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex firstdailyMis bottomDiv">
                                            <div className="sameRow w-100">
                                                <p className="misHead">Forecast Values</p>
                                                <span className='kpiInfo'>Orders</span>
                                            </div>
                                            <div className="sameRow ms-1">
                                                <p className="forcastValue">{dailyData?.forecastOrders.toLocaleString()}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dailyMiscontainer ms-2">
                                        <div className="d-flex firstdailyMis ">
                                            <div className="sameRow w-100">
                                                <p className="misHead">Total Values</p>
                                                <span className='kpiInfo'>Units</span>
                                            </div>
                                            <div className="sameRow ms-1">
                                                <p className="forcastValue active">{dailyData?.unitsSum.toLocaleString()}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex firstdailyMis bottomDiv">
                                            <div className="sameRow w-100">
                                                <p className="misHead">Forecast Values</p>
                                                <span className='kpiInfo'>Units</span>
                                            </div>
                                            <div className="sameRow ms-1">
                                                <p className="forcastValue">{dailyData?.forecastUnits.toLocaleString()}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dailyMiscontainer ms-2">
                                        <div className="d-flex firstdailyMis">
                                            <div className="sameRow  w-100 ">
                                                <p className="misHead">Total Values</p>
                                                <span className='kpiInfo'>AOV</span>
                                            </div>
                                            <div className="sameRow ms-1">
                                                <p className="forcastValue active">{formatter.format(dailyData?.aovSum)}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex firstdailyMis bottomDiv">
                                            <div className="sameRow w-100">
                                                <p className="misHead">Forecast Values</p>
                                                <span className='kpiInfo'>AOV</span>
                                            </div>
                                            <div className="sameRow ms-1">
                                                <p className="forcastValue">{formatter.format(dailyData?.forecastAOV)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dailyMiscontainer ms-2">
                                        <div className="d-flex firstdailyMis">
                                            <div className="sameRow  w-100">
                                                <p className="misHead">Total Values</p>
                                                <span className='kpiInfo'>UPO</span>
                                            </div>
                                            <div className="sameRow ms-1">
                                                <p className="forcastValue active">{dailyData?.upoSum.toLocaleString()}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex firstdailyMis bottomDiv">
                                            <div className="sameRow w-100">
                                                <p className="misHead">Forecast Values</p>
                                                <span className='kpiInfo'>UPO</span>
                                            </div>
                                            <div className="sameRow ms-1">
                                                <p className="forcastValue">{dailyData?.forecastUPO.toLocaleString()}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dailyMiscontainer ms-2">
                                        <div className="d-flex firstdailyMis ">
                                            <div className="sameRow w-100">
                                                <p className="misHead">Total Values</p>
                                                <span className='kpiInfo'>AUV</span>
                                            </div>
                                            <div className="sameRow ms-1">
                                                <p className="forcastValue active">{formatter.format(dailyData?.auvSum)}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex firstdailyMis bottomDiv">
                                            <div className="sameRow w-100">
                                                <p className="misHead">Forecast Values</p>
                                                <span className='kpiInfo'>AUV</span>
                                            </div>
                                            <div className="sameRow ms-1">
                                                <p className="forcastValue">{formatter.format(dailyData?.forecastAUV)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <Loader />
                        }

                        <div className='heightG mt-5'>
                            {!isLoading ?
                                <>
                                    <table className='tableH'>
                                        <thead className='thead rounded'>
                                            <tr style={{ cursor: "pointer" }}>
                                                <td>Date</td>
                                                <td>SLC GMV</td>
                                                <td>Orders</td>
                                                <td>Units</td>
                                                <td>AOV</td>
                                                <td>UPO</td>
                                                <td>AUV</td>
                                            </tr>
                                        </thead>

                                        <tbody className='tbody'>
                                            {dailyData?.reports?.map((v: any) => {
                                                return (
                                                    <tr>
                                                        <td>{v?.date}</td>
                                                        <td>{formatter.format(v?.gmv)}</td>
                                                        <td>{v?.orders.toLocaleString()}</td>
                                                        <td>{v?.units.toLocaleString()}</td>
                                                        <td>{formatter.format(v?.aov)}</td>
                                                        <td>{Math.round(v?.upo * 100) / 100}</td>
                                                        <td>{formatter.format(v?.auv)}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </>
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
            </Box>
        </>
    )
}
export default DailyMISReportScreen