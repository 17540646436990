import React from "react";
import { useAuth } from "../../shared/provider/authprovider";
import UserL from "../../assets/user-1.svg";
import { useNavigate } from "react-router-dom";

//my imports
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { ReactComponent as LogOut } from "../../../src/assets/icons/log-off.svg";
import { ReactComponent as User } from "../../../src/assets/user-1.svg";
import { ReactComponent as Chart } from "../../../src/assets/icons/menuIcons/chart.svg";
import { ReactComponent as Crown } from "../../../src/assets/icons/menuIcons/crown.svg";
import { ReactComponent as Dashboard } from "../../../src/assets/icons/menuIcons/dashboard.svg";
import { ReactComponent as Data } from "../../../src/assets/icons/menuIcons/data.svg";
import { ReactComponent as Share } from "../../../src/assets/icons/menuIcons/share.svg";
import { ReactComponent as Storage } from "../../../src/assets/icons/menuIcons/storage.svg";
import { ReactComponent as Logo } from "../../../src/assets/images/big-logo-icon.svg";
import { ReactComponent as Texonomy } from "../../../src/assets/icons/menuIcons/taxonomy.svg";
import { ReactComponent as Search } from "../../assets/images/icons/search.svg";
import { ReactComponent as Settings } from "../../assets/images/icons/settings.svg";
import { ReactComponent as Bell } from "../../assets/images/icons/bell.svg";
import { Avatar, IconButton, MenuItem, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import { useClientAPI } from "../../shared/api/userManagementSAAS";

const drawerWidth = 60;

const HeaderListing = (props: any) => {
  const {
    signout,
    firstName,
    lastName,
    selectedIndex,
    setSelectedIndex,
  } = useAuth();
  const roles = localStorage.getItem("role")

  let navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    window.location.href = "/#/listing-login";
  };


  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  // profile
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const settings = ["Manage Account", "Admin Console", "Sign out"];
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <div className="sidebarNav">
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
            }}
          >
            <Toolbar>
              <div
                className="logoDiv pointer"
                onClick={() => {
                  navigate("/listing-saas-main-dashboard");
                }}
              >
                <Logo className="resaleLogo" />
              </div>

              <Box sx={{ flexGrow: 0 }}>
                <IconButton aria-label="search">
                  <Search />
                </IconButton>
                <IconButton aria-label="settings" sx={{ ml: 1.5 }}>
                  <Settings />
                </IconButton>
                <IconButton aria-label="notifications" sx={{ ml: 1.5 }}>
                  <Bell />
                </IconButton>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: 1.5 }}>
                  <Avatar
                    alt="Remy Sharp"
                    src="/static/images/avatar/2.jpg"
                    sx={{
                      height: 24,
                      width: 24,
                      fontSize: 12,
                      backgroundColor: "#0178D7",
                    }}
                  />
                </IconButton>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting}>
                      <Typography textAlign="center" onClick={() => { setting == "Sign out" ? logout() : console.log("none") }}>{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            {/* <Toolbar /> */}

            {roles === "SuperAdmin" ? (
              <List>
                {/* dashboard */}
                <ListItem
                  key={"dashboard"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => {
                      navigate("/listing-saas-main-dashboard");
                      handleListItemClick(event, 0);
                    }}
                  >
                    <ListItemIcon>
                      <Dashboard className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"dashboard"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText primary={"Dashboard"} />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>

                {/* logout */}
                <ListItem
                  key={"dashboard"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton onClick={() => logout()}>
                    <ListItemIcon>
                      <LogOut className="hoverColor logout" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          <ListItem
                            className="MainMenu"
                            key={"logout"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText primary={"Logout"} />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
              </List>
            ) : null}
            {roles === "Customer" ? (
              <List>
                {/* User Dashboard */}
                <ListItem
                  key={"dashboard"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => {
                      navigate("/listing-saas-dashboard");
                      handleListItemClick(event, 0);
                    }}
                  >
                    <ListItemIcon>
                      <Dashboard className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"dashboard"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText primary={"Dashboard"} />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>

                {/* logout */}
                <ListItem
                  key={"dashboard"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton onClick={() => logout()}>
                    <ListItemIcon>
                      <LogOut className="hoverColor logout" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"logout"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              onClick={() => logout()}>
                              <ListItemText primary={"Logout"} />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
              </List>
            ) : null}
            {/* <Divider /> */}
          </Drawer>
        </Box>
      </div>
    </>
  );
};

export default HeaderListing;
