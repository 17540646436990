import { useState } from 'react';
import Logo from '../../assets/resale-logo.svg';
import LoginImage from '../../assets/login-img.svg';
import './ScanItemsScreen.css'
import { Button } from 'react-bootstrap';
import { useAuth } from '../../shared/provider/authprovider';

const ScanItemsScreen = () => {
  const { roles } = useAuth();
  return (
    <div>
      <div style={{ backgroundColor: 'gray' }}>
        <img src={Logo} style={{ width: 150, }} />
        <div style={{ flexDirection: 'row', alignContent: 'center' }}>
          <label>Profile</label>
          <div style={{ width: 40, height: 40, borderRadius: 20, backgroundColor: 'white' }}>
            <label></label>
          </div>
        </div>
      </div>
      <div style={{ flexDirection: 'row' }}>
        <div style={{ flexDirection: 'column', width: '40%', alignItems: 'center' }}>
          <label>Warehouse Name</label>
          <img src={LoginImage} style={{ height: 450 }} />
        </div>
        <div style={{ flexDirection: 'column', width: '50%' }}>
          <label>Scan</label>
          <div>
            <div>
              <label>Processing box number</label>
            </div>
            <table>
              <tr>
                <th>UPC</th>
                <th>Warehouse</th>
                <th>Box No.</th>
                <th>Date & Time</th>
                <th>User Name</th>
              </tr>
              <tr>
                <td>Anom</td>
                <td>19</td>
                <td>Male</td>
                <td>19</td>
                <td>Male</td>
              </tr>
              <tr>
                <td>Megha</td>
                <td>19</td>
                <td>Female</td>
                <td>19</td>
                <td>19</td>
              </tr>
              <tr>
                <td>Subham</td>
                <td>25</td>
                <td>Male</td>
                <td>19</td>
                <td>19</td>
              </tr>
            </table>
            <Button>End</Button>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ScanItemsScreen;