import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import ErrorModal from "../../components/errorModal/ErrorModal";
import { useAuth } from "../../shared/provider/authprovider";
import Loader from "../../loader";
import Header from "../../components/header/Header";
import { useEffect } from "react";
import { Field, FormikProvider, useFormik } from "formik";
import { style } from "../listingScreens/RegistrationScreen";
import * as Yup from "yup";
import CustomSelect from "../taxonomyNotFoundScreens/CustomSelect";
import { useTaxonomyNotFoundAPI } from "../../shared/api/taxonomyNotFound";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { useWebFormAPI } from "../../shared/api/webForm";

const FormDetails = (props: any) => {
    const { errorModalShow, setErrorModalShow, isLoading, setSelectedIndex } = useAuth()
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        );
    };
    const { postNewProduct } = useWebFormAPI()
    const DisplayingErrorMessagesSchema = Yup.object().shape({
        title: Yup.string().required("Required").nullable(),
        // sku: Yup.string().nullable(),
        // quantity: Yup.string().nullable(),,
        // heightIn: Yup.string().nullable(),,
        // lengthIn: 0,
        // widthIn: 0,
        // weight: 0,
        // upc: "",
        // asin: null,
        // ean: null,
        // mpn: "",
        // shortDescription: "",
        // description: "",
        // variesBy: "",
        // manufacturer: "",
        // brand: "",
        // condition: "",
        // retailPrice: null,
        // minimumPrice: null,
        // maximumPrice: null,
        // bulletPoints: null,
        // color: "",
        // countryOfOrigin: "",
        // multipackQuantity: 0,
        // size: "",
        // weightOZ: 0
    });
    const formikProductDetails: any = useFormik({
        initialValues: {
            title: "",
            sku: "",
            quantity: 0,
            heightIn: 0,
            lengthIn: 0,
            widthIn: 0,
            weight: 0,
            upc: "",
            asin: null,
            ean: null,
            mpn: "",
            shortDescription: "",
            description: "",
            variesBy: "",
            manufacturer: "",
            brand: "",
            condition: "",
            retailPrice: null,
            minimumPrice: null,
            maximumPrice: null,
            bulletPoints: null,
            // color: "",
            // countryOfOrigin: "",
            // multipackQuantity: 0,
            // size: "",
            // weightOZ: 0
        },
        onSubmit: () => {
            console.log("Formik Data", formikProductDetails.values)
            postNewProduct(formikProductDetails.values)
        },
        validationSchema: DisplayingErrorMessagesSchema,

    })

    return (
        <>
            <Dialog
                open={props.formModal}
                onClose={() => props.setFormModal(false)}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            // sx={style.dialogContainer}
            >
                <DialogTitle
                    sx={{ m: 0, py: 2, px: 2.5, fontSize: 18, fontWeight: 300 }}
                >
                    Product Details Form
                    <IconButton
                        aria-label="close"
                        onClick={() => props.setFormModal(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* ) : null} */}
                </DialogTitle>

                <DialogContent sx={{ p: 2.5 }}>
                    <FormikProvider value={formikProductDetails}>
                        <Field name="title" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Title"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="sku" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Sku"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="quantity" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="number"
                                    required
                                    label="Quantity"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="heightIn" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="number"
                                    required
                                    label="Height"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="lengthIn" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="number"
                                    required
                                    label="Length"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="widthIn" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="number"
                                    required
                                    label="Width"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="weight" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="number"
                                    required
                                    label="Weight"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="upc" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="UPC"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="asin" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    label="ASIN"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="ean" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    label="EAN"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="mpn" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="MPN"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="shortDescription" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Short Description"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="description" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Description"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="variesBy" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Varies By"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="manufacturer" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Manufacturer"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="brand" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Brand"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="condition" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Condition"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="retailPrice" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Retail Price"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="minimumPrice" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Minimum Price"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="maximumPrice" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Maximum Price"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="bulletPoints" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Bullet Points"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="color" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Color"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="countryOfOrigin" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Country of Origin"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="multipackQuantity" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="number"
                                    required
                                    label="Multipack Quantity"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="size" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="string"
                                    required
                                    label="Size"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="weightOZ" >
                            {({ field, meta }: any) => (
                                <TextField
                                    {...field}
                                    type="number"
                                    required
                                    label="Weight OZ"
                                    id="filled-basic"
                                    variant="filled"
                                    // sx={style.textField}
                                    {...{ error: meta.touched && meta.error }}
                                    helperText={meta.touched && meta.error && meta.error}
                                />
                            )}
                        </Field>
                        <Field name="taxonomyReference">
                            {({ field: { value }, form: { setFieldValue } }: any) => (
                                <CustomSelect
                                    value={''}
                                    onChange={(value: any) => setFieldValue(`taxonomyReference`, value.taxonomyName)}
                                    options={props.taxonomyNFDropdown} />
                            )}
                        </Field>
                        <Button
                            variant="contained"
                            onClick={() => formikProductDetails.handleSubmit()}
                            sx={{
                                my: 2,
                                boxShadow: "none",
                                "&:hover": { boxShadow: "none", backgroundColor: "#004EFF" },
                            }}
                        >
                            Submit
                        </Button>
                    </FormikProvider>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default FormDetails