import React from 'react'
import Select from 'react-select'
export default ({ onChange, options, value, className }: any) => {
    const defaultValue = (options: any, value: any) => {
        return options ? options.find((option: any) => option.value === value) : ''
    }
    // console.log("Log",value, options)
    return (
        <div className={className}>
            <Select
                menuPosition="fixed"                
                value={defaultValue(options, value)}
                onChange={value => onChange(value)}
                options={options}
            />

        </div>
    )
}