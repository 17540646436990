import { useState } from "react";
import { useAuth } from "../provider/authprovider";
export const useSquareSpaceAPI = () => {
  const { api, handleError, setErrorModalShow, setErrorMsg } = useAuth()
  const [squareSpaceById, setSquareSpaceById] = useState<any | null>(null)
  const [squareSpace, setSquareSpace] = useState<any | null>(null)
  const [squareSpaceCount, setSquareSpaceCount] = useState<any | null>(null)
  const getSquareSpaceById = async (id: any) => {
    try {
      const result = await api.get(`/api/MasterDataContrroller/SquarespaceToken/${id}`);
      setSquareSpaceById(result?.data?.results)
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  const putSquareSpace = async (id: any, values: any) => {
    try {
      const result = await api.put(`/api/MasterDataContrroller/SquarespaceToken/${id}`, values);
      if (result?.data?.status == 200) {
        setErrorModalShow(true);
        setErrorMsg("Square Space Edited Successfully");
      }
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  const postSquareSpace = async (values: any) => {
    try {
      const result = await api.post(`/api/MasterDataContrroller/SquarespaceToken`, values);
      if (result?.data?.status == 200) {
        setErrorModalShow(true);
        setErrorMsg("Square Space Added Successfully");
      }
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  const getAllSquareSpace = async (offset: any, limit: any) => {
    try {
      const result = await api.get(`/api/MasterDataContrroller/AllSquarespaceTokens?Offset=${offset}&Limit=${limit}`);
      setSquareSpace(result?.data?.results)
      setSquareSpaceCount(result?.data?.count)
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  return {
    getSquareSpaceById, putSquareSpace, postSquareSpace, getAllSquareSpace, squareSpaceById,
    squareSpace, squareSpaceCount
  } as const
}