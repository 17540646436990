import { Box, Button, Pagination, Stack, TextField } from "@mui/material";
import ErrorModal from "../../components/errorModal/ErrorModal";
import { useAuth } from "../../shared/provider/authprovider";
import Loader from "../../loader";
import Header from "../../components/header/Header";
import { useEffect, useState } from "react";
import { useTaxonomyNotFoundAPI } from "../../shared/api/taxonomyNotFound";
import styles from "./index.module.scss";
import { ReactComponent as Search } from "../../assets/images/icons/search.svg";
import { ReactComponent as Close } from "../../../src/assets/icons/close.svg";
import { useNavigate } from "react-router";
import FormDetails from "./FormDetails";
import { useWebFormAPI } from "../../shared/api/webForm";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Calendar } from "../../../src/assets/icons/searchCalendar.svg";
import moment, { ISO_8601 } from "moment";

const ProductDeatilsWebForm = () => {
    const { errorModalShow, setErrorModalShow, isLoading, setSelectedIndex, setErrorMsg } = useAuth()
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        );
    };
    const { taxonomyNFDropdown, getTaxonomyNotFoundRecordsDropdown } = useTaxonomyNotFoundAPI()
    const { getProductDetailsList } = useWebFormAPI()
    const [productsData, setProductsData] = useState([])
    const fetchData = async () => {
        await getTaxonomyNotFoundRecordsDropdown(0, 100)
        const result = await getProductDetailsList(firstDay, todayDate, offset, limit, "")
        setProductsData(result?.data?.result)
    };

    useEffect(() => {
        setNewStartDate(firstDayNew)
        setNewEndDate(moment())
        setStartDate(firstDay)
        setEndDate(todayDate)
        fetchData()
        setSelectedIndex(15)
    }, [])
    const [prefix, setPrefix] = useState("");
    const handleKeypress = (e: any) => {
        if (e.key === "Enter") {
            onTapSearch();
        }
    };
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const handleChange = async (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setOffset((value - 1) * limit);
        const result = await getProductDetailsList(startDate, endDate, (value - 1) * limit, limit, prefix)
        setProductsData(result?.data?.result)
        setPage(value);
    };
    const onTapSearch = async () => {
        const result = await getProductDetailsList(startDate, endDate, offset, limit, prefix)
        setProductsData(result?.data?.result)
        setOffset(0);
        setLimit(50);
        setPage(1);
    };
    const onTapReset = async () => {
        setOffset(0);
        setLimit(50);
        setPage(1)
        setPrefix("");
        const result = await getProductDetailsList(startDate, endDate, 0, 50, "")
        setProductsData(result?.data?.result)
    };
    let navigate = useNavigate();
    const [formModal, setFormModal] = useState<any>(false)

    var day1: any = moment().startOf('month')
    var firstDay = moment(day1, "en-us").format("MM-DD-YYYY")

    var date = new Date();
    var firstDayNew = new Date(date.getFullYear(), date.getMonth(), 1);

    var day2: any = moment()
    var todayDate = moment(day2, "en-us").format("MM-DD-YYYY")
    const [startDate, setStartDate] = useState<any>(new Date());
    const [endDate, setEndDate] = useState<any>(new Date());
    const [newStartDate, setNewStartDate] = useState<any>(new Date())
    const [newEndDate, setNewEndDate] = useState<any>(new Date())
    const handleStartDate = (value: any) => {
        if (value == null) {
            setStartDate(firstDay);
            setNewStartDate(firstDayNew)
        }
        else {
            const result = moment(value, ISO_8601).format("MM-DD-YYYY")
            setStartDate(result);
            setNewStartDate(new Date(value))
        }
    }

    const handleEndDate = (value: any) => {
        if (value == null) {
            setEndDate(todayDate);
            setNewEndDate(moment())
        }
        else {
            const result = moment(value, ISO_8601).format("MM-DD-YYYY")
            setEndDate(result);
            setNewEndDate(new Date(value))
        }
    }

    const onTapApply = async () => {
        if (Date.parse(newStartDate) <= Date.parse(newEndDate)) {
            const result = await getProductDetailsList(startDate, endDate, offset, limit, prefix)
            setProductsData(result?.data?.result)
        }
        else {
            setErrorMsg("Please select start date less than end date");
            setErrorModalShow(true);
        }
    }
    return (
        <>
            <FormDetails setFormModal={setFormModal} formModal={formModal} taxonomyNFDropdown={taxonomyNFDropdown} />
            {errorHandle()}
            {isLoading ? <Loader /> : ""}
            <Header />
            <Box
                className="contentMain ROValidationWrapper"
                component="main"
                sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
            >
                <div>
                    <h5 className="pageHead mb-2 me-2">Set Up Form Table</h5>
                </div>
                <div className="startMain">
                    <div className="d-flex">
                        <div className='borderDiv filterItem d-flex'>
                            <div className={`${styles.searchInput}`}>
                                <Stack direction="row" alignItems="center">
                                    <Search className={styles.searchIcon} />
                                    <input
                                        type="search"
                                        placeholder="Search"
                                        value={prefix}
                                        onKeyPress={(event) => handleKeypress(event)}
                                        onChange={(event) => setPrefix(event.target.value)}
                                        style={{ width: "100%" }}
                                    />
                                    <Close className="pointer" onClick={() => onTapReset()} />
                                </Stack>
                            </div>
                            <div className='dateInput from d-flex align-items-center'>
                                <label className='w-100 formLabel'>From: </label>

                                <div className="d-flex align-items-center outlinedInput">
                                    <Calendar />
                                    <DatePicker className='dateUi' selected={new Date(newStartDate)}
                                        onChange={(selectedstart) => handleStartDate(selectedstart)}
                                        dateFormat='MM-dd-yyyy'
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode='select'
                                        maxDate={new Date()}
                                    />
                                </div>
                            </div>
                            <div className='dateInput d-flex align-items-center'>
                                <label className='w-100 formLabel'>To: </label>
                                <div className="d-flex align-items-center outlinedInput">
                                    <Calendar />
                                    <DatePicker className='dateUi' selected={new Date(newEndDate)}
                                        onChange={(selectedstart) => handleEndDate(selectedstart)}
                                        dateFormat='MM-dd-yyyy'
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode='select'
                                        maxDate={new Date()}
                                    />
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="d-flex">
                        <button className='FilledBtn me-3' onClick={() => onTapApply()}>Apply</button>
                        <button className="FilledBtn me-3" onClick={() => navigate("/setup-tool")}>Form Setup Tool</button>
                        <button className="FilledBtn me-3" onClick={() => setFormModal(true)}>Add New</button>
                    </div>
                </div>
                <div className='heightG'>
                    <table className='tableH'>
                        <thead className='thead rounded'>
                            <tr>
                                <td>Sr. No.</td>
                                <td>Listinng Title</td>
                                <td>SKU</td>
                                <td>UPC</td>
                                <td>QTY</td>
                                <td>Description</td>
                                <td>Condition</td>
                                <td>Manufactorer</td>
                                <td>Brand</td>
                                <td>Retail Price</td>
                                <td>Actions</td>
                            </tr>
                        </thead>
                        <tbody className='tbody'>
                            {productsData?.map((v: any, index: any) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{v.title}</td>
                                        <td>{v?.sku}</td>
                                        <td>{v?.upc}</td>
                                        <td>{v?.quantity}</td>
                                        <td>{v?.description}</td>
                                        <td>{v?.condition}</td>
                                        <td>{v?.manufacturer}</td>
                                        <td>{v?.brand}</td>
                                        <td>{v?.retailPrice}</td>
                                        <td>
                                            <Button className="FilledBtn" onClick={() => { console.log(v?.id) }}>Edit</Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="">
                    <div className="d-flex paginationSect align-items-center justify-content-between mt-0">
                        <Stack spacing={2} className="d-flex flex-row align-items-center">
                            <label className="formLabel">
                                {productsData?.length > 0 ? offset + 1 : 0} -{" "}
                                {productsData?.length > offset + limit
                                    ? offset + limit
                                    : productsData?.length}{" "}
                                of {productsData?.length}
                            </label>
                            <Pagination
                                className="m-0 paginatnNav"
                                size="small"
                                shape="rounded"
                                count={Math.ceil(productsData?.length / limit)}
                                page={page}
                                onChange={handleChange}
                            />
                        </Stack>
                    </div>
                </div>
            </Box>
        </>
    )
}
export default ProductDeatilsWebForm