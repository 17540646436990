import { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import { useAuth } from "../../shared/provider/authprovider";
import { Dropdown } from "react-bootstrap";
import './UserReportScreen.scss';
import ErrorModal from '../../components/errorModal/ErrorModal';
import { Box } from '@mui/material';
import filter from "../../assets/filter.svg";
import { ReactComponent as Calendar } from "../../../src/assets/icons/searchCalendar.svg";
import { ReactComponent as Downarrow } from "../../../src/assets/icons/downArrow.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Loader from '../../loader';
import { useReportsAPI } from '../../shared/api/reports-user';

const UserReportScreen = () => {
  const { getWarehouseListCalled, wareHouseList, setErrorMsg, isLoading,
    errorModalShow, setErrorModalShow, setSelectedIndex } = useAuth()
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [warehouseId, setWarehouseId] = useState<any>(null);
  const [formatedStartDate, setFormatedStartDate] = useState("");
  const [formatedEndDate, setFormatedEndDate] = useState("");
  const [warehouseName, setWarehouseName] = useState("Select Warehouse");
  const [apiPrefix, setApiPrefix] = useState("")
  const { getUserReport } = useReportsAPI()
  const [userReportList, setUserReportList] = useState<any | null>(null);

  useEffect(() => {
    getWarehouseListCalled(apiPrefix, 0, 1000);
    setUserReportList([]);
    setSelectedIndex(6)

  }, [])

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };

  const handleStartDate = (value: any) => {
    const result: any = moment(value).format("MM-DD-YYYY")
    setStartDate(value);
    setFormatedStartDate(result);
  };

  const handleEndDate = (value: any) => {
    const result: any = moment(value).format("MM-DD-YYYY")
    setEndDate(value);
    setFormatedEndDate(result);
  };

  const onTapApply = async () => {
    if (warehouseId == null) {
      setErrorMsg("Please select warehouse");
      setErrorModalShow(true);
    }
    else if (startDate == null && endDate == null) {
      const result = await getUserReport(warehouseId, formatedStartDate, formatedEndDate);
      setUserReportList(result?.data?.results)
    }
    else if (startDate == null && endDate != null) {
      setErrorMsg("Please select start date");
      setErrorModalShow(true);
    }
    else if (endDate == null && startDate != null) {
      setErrorMsg("Please select end date");
      setErrorModalShow(true);
    }
    if (startDate != null && endDate != null) {
      if (Date.parse(startDate) <= Date.parse(endDate)) {
        const result = await getUserReport(warehouseId, formatedStartDate, formatedEndDate);
        setUserReportList(result?.data?.results)
      }
      else {
        setErrorMsg("Please select start date less than end date");
        setErrorModalShow(true);
      }
    }

  };

  return (
    <>
      {errorHandle()}
      <Header />
      <Box className="contentMain"
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <div className='user-report'>


          {/* <span>Sort By Date: </span>
            <div className='start'>
              <label>From</label>
              <input className='dateUi' value={startDate} onChange={(event) => handleStartDate(event?.target?.value)} type="date"></input>
            </div>
            <div className='end'>
              <label>To</label>
              <input className='dateUi' value={endDate} onChange={(event) => handleEndDate(event?.target?.value)} type="date"></input>
            </div> */}
          <h5 className="pageHead">User Report</h5>
          <div className='startMain'>

            <div className='d-flex align-items-center filterContainer'>
              <div className='d-flex align-items-center'>
                <div className='leftDiv filterItem'>
                  <img src={filter} className="mRight" />
                  <h5 className='m-0 text'>Filters</h5>
                </div>
                <div className='borderDiv filterItem'>
                  <label className='w-100 formLabel'>Warehouse</label>
                  <Dropdown className="">
                    <Dropdown.Toggle variant="Warning" id="dropdown-basic" className='role-btn outlinedInput'>
                      {warehouseName}
                      <Downarrow className='margin' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropMenug">
                      {wareHouseList ? wareHouseList.map((item: any) => {
                        return (
                          <Dropdown.Item className="clickItem" onClick={() => {
                            setWarehouseName(item?.wareHouse?.name);
                            setWarehouseId(item?.wareHouse?.id);
                          }} >{item?.wareHouse?.name}</Dropdown.Item>
                        )
                      }) : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className='borderDiv filterItem'>
                  <div className='dateInput from d-flex align-items-center'>
                    <label className='w-100 formLabel'>From: </label>
                    <div className="d-flex align-items-center outlinedInput">
                      <Calendar />
                      <DatePicker className='dateUi'
                        placeholderText="MM-dd-yyyy"
                        dateFormat='MM-dd-yyyy'
                        selected={startDate}
                        onChange={(date) => handleStartDate(date)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        maxDate={moment(new Date()).toDate()}>
                      </DatePicker>
                    </div>
                  </div>
                  <div className='dateInput d-flex align-items-center'>
                    <label className='w-100 formLabel'>To: </label>
                    <div className="d-flex align-items-center outlinedInput">
                      <Calendar />
                      <DatePicker className='dateUi'
                        placeholderText="MM-dd-yyyy"
                        dateFormat='MM-dd-yyyy'
                        selected={endDate}
                        onChange={(date) => handleEndDate(date)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        maxDate={moment(new Date()).toDate()}> </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {(((Date.parse(startDate) < Date.parse(endDate)) || (Date.parse(startDate) == Date.parse(endDate))) && warehouseId) ? */}
            <button className='FilledBtn' onClick={() => onTapApply()}>Apply</button>
            {/* 
              :
              <button className='FilledBtn disabled-btn' disabled>Apply</button>} 
              */}
          </div>


          <div className='heightG'>
            <table className='tableH'>
              <thead className='thead rounded'>
                <tr>
                  <td>Sr. No.</td>
                  <td>User Name</td>
                  <td>Scanned</td>
                </tr>
              </thead>
              <tbody className='tbody'>
                {!isLoading ?
                  <>
                    {
                      userReportList ?
                        userReportList.map((value: any) => {
                          return (
                            <tr>
                              <td>{userReportList.findIndex((x: any) => x.name === value?.name) + 1}</td>
                              <td>{value?.name}</td>
                              <td>{value?.totalScanned}</td>
                            </tr>
                          )
                        }) : null}
                  </>
                  :
                  <Loader />
                }
              </tbody>
            </table>
          </div>
        </div>
      </Box>
    </>
  )
}

export default UserReportScreen;