import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Edit from "../../assets/edit.svg";
import Deleteimg from "../../assets/deleteimg.svg";
import Delete from "../../assets/delete.svg";
import { useAuth } from "../../shared/provider/authprovider";
import './UserDashboard.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Header from "../../components/header/Header";
import { Button, Modal, Spinner } from "react-bootstrap";
import useScanDetection from "./useScanDetection";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import ErrorModal from '../../components/errorModal/ErrorModal';
import { Box } from "@mui/material";

const BoxTableScreen = () => {
  const { scanItemsCalled, scanProductList, isLoading, getUserScanProdListCalled,
    endBoxCalled, editScanItemsCalled, deleteUpcCalled, scanItemsArrayy, setScanItemsArrayy, errorModalShow,
    setErrorModalShow } = useAuth();
  const location: any = useLocation();
  // const data = location.state.data;
  // const id = location.state.id;
  // const warehouseName = location.state.warehouseName;
  // console.log("data>>>>>", data);
  const [upc, setUpc] = useState("");
  const [editUpc, setEditUpc] = useState("");
  const [editUpcModalShow, setEditUpcModalShow] = useState(false);
  const [removeUpcModalShow, setRemoveUpcModalShow] = useState(false);
  const [upcId, setUpcId] = useState("");
  const [upcForEdit, setUpcForEdit] = useState<any | null>(null);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const prefixName = localStorage.getItem("prefixName");
  const prefixId = localStorage.getItem("prefixId");
  const warehouseName = localStorage.getItem("warehouseName");
  const boxNumber = localStorage.getItem("boxNumber");
  useEffect(() => {
    getUserScanProdListCalled();
    // console.log("....  useEffect   .....", scanItemsArrayy);
  }, [])

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };

  useScanDetection({
    onComplete: (c: any) => {
      let details = {
        "warehousePrefixIdDto": {
          "id": prefixId
        },
        "boxName": boxNumber,
        "upc": c
      }
      setUpc("");
      scanItemsCalled(details);
    }
  })

  const addUpcBtn = () => {
    if (upc !== "") {
      let details = {
        "warehousePrefixIdDto": {
          "id": prefixId
        },
        "boxName": boxNumber,
        "upc": upc
      }
      setUpc("");
      scanItemsCalled(details);
    }
  }

  const renderEditUpcModal = () => {
    return (
      <Modal
        className="modalLG"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={editUpcModalShow}
        onHide={() => {
          setEditUpcModalShow(false);
        }}

      >
        <Modal.Header className="modalHeader" closeButton>
          <Modal.Title className="modalTitle">
            Edit UPC
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <p className="fieldTitle">UPC</p>
          <input value={editUpc} className="inputField" placeholder="Prefix" onChange={(event) => setEditUpc(event.target.value)} />

          <div><Button className="signInBtn" >Update</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  };

  const onTapEditUpcBtn = (data: any, index: any) => {
    setUpcForEdit(data?.upc);
    setShowUpdateBtn(true);
    setUpcId(data?.id);
    setEditUpc(data?.upc);

  };

  const onTapUpdateUpc = (data: any) => {
    let details = {
      "upc": upcForEdit
    }
    if (data?.upc !== upcForEdit) {
      editScanItemsCalled(details, upcId)
    }
    setShowUpdateBtn(false);
    // setEditUpcModalShow(false);
  };

  const renderRemoveUpcModal = () => {
    return (
      <Modal
        className="modalLG"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={removeUpcModalShow}
        onHide={() => {
          setRemoveUpcModalShow(false);
        }}
      >
        <Modal.Header className="modalHeader" closeButton>
          <Modal.Title className="modalTitle">
            Remove UPC
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <p className="fieldTitle">Are you sure you want to remove upc:  {editUpc} </p>
          <div className="d-flex align-items-center justify-content-center mt-5">
            <img src={Deleteimg} />
          </div>
          <button className="signInBtn btn" onClick={() => tapRemoveUpcBtn()} >Remove</button>
        </Modal.Body>
      </Modal>
    )
  };

  const onTapDeleteUpcBtn = (data: any) => {
    setUpcId(data?.id);
    setEditUpc(data?.upc);
    setRemoveUpcModalShow(true);
  };

  const tapRemoveUpcBtn = () => {
    deleteUpcCalled(upcId);
    setRemoveUpcModalShow(false);
  }

  return (
    <>
      {errorHandle()}
      <Header />
      <Box className="contentMain vendorDetailContainer"
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <div className="container dashbody">
          {renderEditUpcModal()}
          {renderRemoveUpcModal()}
          {isLoading && <div className="loader">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>}

          <div>
            <h6 className="dashH6">{warehouseName} - {prefixName}</h6>
          </div>
          <div
            className="boxNumber processtable mt-4"
          >
            <div className="process">
              <div className="d-flex align-items-center">
                <h5>Processing :</h5>
                <label className="processInt">{boxNumber}</label>
                <input
                  className="processInt"
                  type="text"
                  placeholder="UPC"
                  onChange={(event) => setUpc(event.target.value)}
                  autoFocus
                  value={upc}
                />
                {upc ?
                  <button className="enterBtn" onClick={() => addUpcBtn()}>
                    Submit
                  </button> :
                  <button className="enterBtn disabled-btn" disabled>Submit</button>}
              </div>
            </div>
            <div className='heightG tableProcessHeight'>
              <table className="tableProcess">
                <thead className="sticky-table-head">
                  <tr className="headerB">
                    <td>Sr. No.</td>
                    <td>UPC</td>
                    <td>Date & Time</td>
                    <td>Edit</td>
                    <td>Delete</td>
                  </tr>
                </thead>
                {scanItemsArrayy.length > 0 ?

                  scanItemsArrayy.map((data: any, index: any) => {
                    // setUpcForEdit(data?.upc);
                    return (
                      <tr>
                        <td>{scanItemsArrayy.findIndex((x: any) => x?.id === data?.id) + 1}</td>
                        {upcId === data?.id && showUpdateBtn ?
                          <td className="d-flex align-items-center justify-content-center">
                            <input className="inputUpdate" value={upcForEdit} onChange={(event) => setUpcForEdit(event.target.value)} />
                            {upcForEdit ?
                              <Button className="btnUpdate" variant="warning" onClick={() => onTapUpdateUpc(data)}>Update</Button> :
                              <Button className="btnUpdate" variant="warning" onClick={() => setShowUpdateBtn(false)}>Update</Button>}
                          </td> :
                          <td>{data?.upc}</td>}
                        <td>{new Date(data?.createdTime).toLocaleString()}</td>
                        <td>
                          {/* <OverlayTrigger
                          delay={{ hide: 300, show: 200 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              Edit
                            </Tooltip>
                          )}
                          placement="top"
                        > */}
                          <button onClick={() => onTapEditUpcBtn(data, index)}><img src={Edit} className="icon editicon" /></button>
                          {/* </OverlayTrigger> */}
                        </td>
                        <td>
                          {/* <OverlayTrigger
                          delay={{ hide: 300, show: 200 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              Delete
                            </Tooltip>
                          )}
                          placement="top"
                        > */}
                          <button onClick={() => onTapDeleteUpcBtn(data)}><img src={Delete} className="icon deleteIcon" /></button>
                          {/* </OverlayTrigger> */}
                        </td>
                      </tr>
                    )
                  })
                  : <div className="norecords">No records</div>}

              </table>
              {/* <Button className="end-btn" onClick={() => { endBoxCalled() }}>End Scan</Button> */}
            </div>
            <Button className="end-btn addD d-flex align-items-center" onClick={() => { endBoxCalled() }}>End Box</Button>
          </div>


        </div>
      </Box>
    </>
  );
};

export default BoxTableScreen;
