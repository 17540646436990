import { useState } from "react";
import { useAuth } from "../provider/authprovider";

export const useSkuVaultAPI = () => {
  const { api, handleError, setErrorModalShow, setErrorMsg } = useAuth()
  const [skuVault, setSkuVault] = useState<any | null>(null)
  const [skuVaultCount, setSkuVaultCount] = useState<any | null>(null)
  const [skuVaultById, setSkuVaultById] = useState<any | null>(null)
  const [prefixList, setPrefixList] = useState<any | null>(null)

  const getAllSkuVault = async (offset: any, limit: any) => {
    try {
      const result = await api.get(`/api/MasterDataContrroller/AllSkuVaultTokens?Offset=${offset}&Limit=${limit}`);
      setSkuVault(result?.data?.results)
      setSkuVaultCount(result?.data?.count)
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  const getSkuVaultById = async (id: any) => {
    try {
      const result = await api.get(`/api/MasterDataContrroller/SkuVaultToken/${id}`);
      setSkuVaultById(result?.data?.results)
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  const putSkuVault = async (id: any, values: any) => {
    try {
      const result = await api.put(`/api/MasterDataContrroller/SkuVaultToken/${id}`, values);
      if (result?.data?.status == 200) {
        setErrorModalShow(true);
        setErrorMsg("Sku Vault Edited Successfully");
      }
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  const postSkuVault = async (values: any) => {
    try {
      const result = await api.post(`/api/MasterDataContrroller/SkuVaultToken`, values);
      if (result?.data?.status == 200) {
        setErrorModalShow(true);
        setErrorMsg("Sku Vault Added Successfully");
      }
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  const getprefixList = async () => {
    try {
      const result = await api.get(`/api/WarehousePrefix/List?Offset=0&Limit=100`);
      setPrefixList(result?.data?.results?.items);
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  return {
    getAllSkuVault, prefixList,
    getSkuVaultById, putSkuVault, postSkuVault, getprefixList, skuVault, skuVaultCount, skuVaultById
  } as const
}