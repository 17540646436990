import { useEffect, useState } from "react";
import Header from "../../components/header/Header"
import { useAuth } from "../../shared/provider/authprovider";
import ErrorModal from "../../components/errorModal/ErrorModal";
import Loader from "../../loader";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useRecAppApiSkuVault } from "../../shared/api/receivingSkuVault";
import { ReactComponent as Quit } from "../../../src/assets/icons/quit.svg";
import { Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as BlueFilledCheckCircle } from "../../../src/assets/icons/blue-filled-check-circle.svg";
import { ReactComponent as UpcScanning } from "../../../src/assets/icons/upc-scanning.svg";
import { ReactComponent as ListableItem } from "../../../src/assets/icons/listable-item.svg";
import { ReactComponent as ROValidation } from "../../../src/assets/icons/ro-validation.svg";

const ReceivingAppSkuVault = () => {
    const { setSelectedIndex, setErrorModalShow, setErrorMsg, errorModalShow, isLoading } = useAuth();
    const { setUpcValidationBoxSkuVault, upcValidationBoxSkuVault, getUPCScanSkuVaultCalled,
        scanUPCProductSkuVault, productDataSkuVaultBox, setProductDataSkuVaultBox, postSkuVaultProductDataCalled,
        roValidationSkuVaultBox, setRoValidationSkuVaultBox } = useRecAppApiSkuVault()
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        );
    };
    const DisplayingErrorMessagesSchema = Yup.object().shape({
        code: Yup.string().required("Required"),
    });
    const formikRO: any = useFormik({
        initialValues: {
            code: "",
        },
        validationSchema: DisplayingErrorMessagesSchema,
        onSubmit: () => submitDataRO(),
    });
    const submitDataRO = async () => {
        setRoValidationSkuVaultBox(false)
        setUpcValidationBoxSkuVault(true)
        formikNewProduct.setFieldValue("ro", formikRO?.values?.code)
    };

    const roValidationBoxUI = () => {
        return (
            <FormikProvider value={formikRO}>
                <div className="formContainer">
                    <div className="row align-items-center formItem">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <label htmlFor="upc">RO/PO Number : </label>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            <Field
                                autoFocus
                                className="textfield"
                                id="code"
                                name="code"
                                placeholder="Enter RO/PO Number"
                                onKeyPress={(event: any) => {
                                    event.key == "Enter"
                                        ? formikRO.handleSubmit()
                                        : console.log("null");
                                }}
                            />
                            {formikRO?.touched?.code && formikRO?.errors?.code && (
                                <div className="errorMsg">{formikRO?.errors?.code}</div>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-lg-4 col-sm-12 col-md-12 col-lg-8 btnGrp">
                            <Button
                                className="FilledBtn outlineBtns"
                                onClick={() => formikRO.resetForm()}
                            >
                                Clear
                            </Button>
                            <Button
                                className="FilledBtn outlineBtns"
                                onClick={() => formikRO.handleSubmit()}
                            >
                                Continue
                            </Button>
                        </div>
                    </div>
                </div>
            </FormikProvider>
        );
    };
    const DisplayingErrorMessagesUPC = Yup.object().shape({
        upc: Yup.string().required("UPC is required"),
    });
    const upcSkuVault: any = useFormik({
        initialValues: {
            upc: "",
        },
        validationSchema: DisplayingErrorMessagesUPC,
        onSubmit: () => submitData(),
    });
    const [textListable, setTextListable] = useState<any>("");
    const submitData = async () => {
        console.log("formik", upcSkuVault.values.upc);
        const result = await getUPCScanSkuVaultCalled(upcSkuVault?.values?.upc);
        console.log(result, "77");
        if (result?.items?.isListable == true) {
            localStorage.setItem("UPC", upcSkuVault?.values.upc);
            setProductDataSkuVaultBox(true);
            setUpcValidationBoxSkuVault(false);
            formikNewProduct.setFieldValue("items", result?.items ?? result?.items)
            formikNewProduct.setFieldValue("message", result?.message)
        } else if (result?.items?.isListable == false) {
            setTextListable("Item is Unlistable");
            setErrorMsg("Item is not listable")
            setErrorModalShow(true)
            // setDisableButtons(true);
            // setMainFunctionButton(false)
        }
        else if (result?.items == null) {
            setErrorMsg(result?.message);
            setErrorModalShow(true);
        }
    };
    const upcScannBoxUI = () => {
        return (
            <FormikProvider value={upcSkuVault}>
                <div className="formContainer">
                    <div className="row align-items-center formItem">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <label htmlFor="upc">Enter UPC : </label>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            <Field
                                autoFocus
                                className="textfield"
                                id="upc"
                                name="upc"
                                placeholder="Enter UPC Number"
                                onKeyPress={(event: any) => {
                                    event.key == "Enter"
                                        ? upcSkuVault.handleSubmit()
                                        : console.log("null");
                                }}
                            />
                            {
                                <span className="text-danger">
                                    {upcSkuVault.errors?.upc &&
                                        upcSkuVault.touched?.upc &&
                                        upcSkuVault.touched?.upc &&
                                        upcSkuVault.errors?.upc}
                                </span>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-lg-4 col-sm-12 col-md-12 col-lg-8 btnGrp">
                            <Button
                                className="FilledBtn outlineBtns"
                                onClick={() => {
                                    setUpcValidationBoxSkuVault(false);
                                    setRoValidationSkuVaultBox(true)
                                    upcSkuVault.resetForm()
                                }}
                            >
                                Back
                            </Button>    <Button
                                className="FilledBtn outlineBtns"
                                onClick={() => upcSkuVault.handleSubmit()}
                            >
                                Continue
                            </Button>
                        </div>
                    </div>
                </div>
            </FormikProvider>
        );
    };
    const formikProductData: any = useFormik({
        initialValues: {
            upc: "",
        },
        onSubmit: () => submitUPC(),
    });
    const [lastScannedUPC, setLastScannedUPC] = useState<any>(null)
    const submitUPC = async () => {
        const result = await getUPCScanSkuVaultCalled(formikProductData?.values?.upc);
        if (result?.items?.isListable == true) {
            localStorage.setItem("UPC", formikProductData?.values?.upc);
            setTextListable("Item is Listable")
            formikNewProduct.setFieldValue("items", result?.items ?? result?.items)
            formikNewProduct.setFieldValue("message", result?.message)
        } else if (result?.items?.isListable == false) {
            setTextListable("Item is Unlistable")
        }
        else if (result?.items == null) {
            setErrorMsg(result?.message);
            setErrorModalShow(true);
        }
    };
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const formikNewProduct: any = useFormik({
        initialValues: {
            message: "",
            ro: formikRO?.values?.code,
            location: "",
            quantity: 0,
            items: {}
        },
        onSubmit: () => console.log("Try")
    });
    const productDataSkuVaultBoxUI = () => {
        return (
            <FormikProvider value={formikProductData}>
                <div className="row align-items-center formItem">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label htmlFor="upc">UPC Scanning : </label>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-4">
                        <Field
                            className="textfield"
                            id="upc"
                            name="upc"
                            placeholder="Enter UPC Number"
                            onKeyPress={(event: any) => {
                                event.key == "Enter"
                                    ? formikProductData.handleSubmit()
                                    : console.log("null");
                            }}
                        />
                        {
                            <span className="text-danger">
                                {formikProductData.errors?.upc &&
                                    formikProductData.touched?.upc &&
                                    formikProductData.touched?.upc &&
                                    formikProductData.errors?.upc}
                            </span>
                        }
                    </div>
                </div>
                <div className="d-flex productContainer">
                    <div className="imgSection">
                        {scanUPCProductSkuVault?.images?.map((imglink: any) => {
                            return <img src={imglink} height={200} width={200} />;
                        })}
                    </div>
                    <div>
                        <Stack direction="row" alignItems="center" mb={1}>
                            <Typography variant="body2" fontSize={14} fontWeight={600}>
                                Product Name:{" "}
                                <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                    {" "}
                                    {scanUPCProductSkuVault?.title}
                                </span>
                            </Typography>
                        </Stack>
                        <Typography variant="body2" fontSize={14} fontWeight={600}>
                            Product Description :{" "}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ fontSize: "13px", fontWeight: 400, mb: 1 }}
                        >
                            {scanUPCProductSkuVault?.description}
                        </Typography>
                        <Typography variant="body2" fontSize={14} fontWeight={600} mb={1}>
                            Product Prices :
                        </Typography>
                        <div className="heightG">
                            <table className="tableH">
                                <thead className="thead rounded">
                                    <tr>
                                        <th>
                                            <strong style={{ fontWeight: 600 }}>Source</strong>
                                        </th>
                                        <th>
                                            <strong style={{ fontWeight: 600 }}>Price</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="tbody">
                                    {scanUPCProductSkuVault?.prices?.map((price: any, index: any) => {
                                        return (
                                            <>
                                                <tr style={{ backgroundColor: price?.merchant == "Retail Price" ? "#C8D6F8" : "white" }} key={index}>
                                                    <td>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: "13px",
                                                                fontWeight: 400,
                                                                mb: 1,
                                                            }}
                                                        >
                                                            {price?.merchant}
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: "13px",
                                                                fontWeight: 400,
                                                                mb: 1,
                                                            }}
                                                        >
                                                            <span className="me-3">{price?.displayPriceFrom}</span>
                                                            <span>{formatter.format(price?.displayPrice)}</span>
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Box className="btnGrp mt-4 mb-4 btnBox" sx={{ textAlign: "right" }}>
                    <Button
                        className="FilledBtn outlineBtns"
                        onClick={async () => {
                            setUpcValidationBoxSkuVault(true);
                            setProductDataSkuVaultBox(false)
                            formikProductData.resetForm()
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        className="FilledBtn outlineBtns"
                        onClick={async () => {
                            const result = await postSkuVaultProductDataCalled(formikNewProduct?.values)
                            if (result.data?.status == 200) {
                                setLastScannedUPC(scanUPCProductSkuVault?.upc)
                                setUpcValidationBoxSkuVault(true)
                                setProductDataSkuVaultBox(false)
                                upcSkuVault.resetForm()
                            }
                        }}
                    >
                        Submit
                    </Button>
                </Box>
            </FormikProvider>
        )
    }
    useEffect(() => {
        setSelectedIndex(27);
    }, []);
    return (
        <>
            {errorHandle()}
            {isLoading ? <Loader /> : ""}
            <Header />
            <Box
                className="contentMain ROValidationWrapper"
                component="main"
                sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
            >
                <div className="startMain">
                    <div>
                        <h5 className="pageHead mb-2 me-2">Receiving Application</h5>
                        {/* <p className="mb-0">Products: 1,345</p> */}
                    </div>
                    <Button
                        className="FilledBtn btnStyleUnset"
                        onClick={() => {
                            setUpcValidationBoxSkuVault(false)
                            setProductDataSkuVaultBox(false)
                            setRoValidationSkuVaultBox(true)
                            formikProductData.resetForm()
                            formikRO.resetForm()
                            upcSkuVault.resetForm()
                        }}
                    >
                        <Quit style={{ height: "30px", width: "30px" }} />
                    </Button>
                </div>
                <div className="stepper d-flex">
                    <div
                        className={`step ${roValidationSkuVaultBox || upcValidationBoxSkuVault ||
                            productDataSkuVaultBox == true
                            ? "active"
                            : ""
                            }`}
                    >
                        <div className="stepperLine"></div>
                        <div className="iconCircle">
                            <ROValidation />
                        </div>
                        <p className="mb-0">RO/PO Validation</p>
                    </div>
                    <div
                        className={`step ${upcValidationBoxSkuVault ||
                            productDataSkuVaultBox == true
                            ? "active"
                            : ""
                            }`}
                    >
                        <div className="stepperLine"></div>
                        <div className="iconCircle">
                            <UpcScanning />
                        </div>
                        <p className="mb-0">UPC/ASIN Scanning</p>
                    </div>
                    <div
                        className={`step ${productDataSkuVaultBox == true
                            ? "active"
                            : ""
                            }`}
                    >
                        <div className="stepperLine"></div>
                        <div className="iconCircle">
                            <ListableItem />
                        </div>
                        <p className="mb-0">Product Data</p>
                    </div>


                </div>
                <div className="row middleRow">
                    <div className="col-sm-12 col-md-9 col-lg-9">
                        {roValidationSkuVaultBox == true ? roValidationBoxUI() : ""}
                        {upcValidationBoxSkuVault == true ? upcScannBoxUI() : ""}
                        {productDataSkuVaultBox == true ? productDataSkuVaultBoxUI() : ""}
                    </div>
                    {/* <div className="col-1"></div> */}
                    <div className="col-sm-12 col-md-3 col-lg-3">
                        <div className="whiteContainer">
                            {productDataSkuVaultBox || upcValidationBoxSkuVault == true ? (
                                <div className="d-flex sucMsgContainer">
                                    <BlueFilledCheckCircle />
                                    <div className="message">
                                        <h6> Entered RO/PO Number</h6>
                                        <p>
                                            RO/PO Number: <span>{formikRO?.values?.code}</span>
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {productDataSkuVaultBox == true ? (
                                <div className="d-flex sucMsgContainer">
                                    <BlueFilledCheckCircle />
                                    <div className="message">
                                        <h6> UPC scan is Successful</h6>
                                        <p>
                                            UPC: <span>{localStorage.getItem("UPC")}</span>
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {lastScannedUPC != null ?
                                upcValidationBoxSkuVault || productDataSkuVaultBox == true ? (
                                    <div className="d-flex sucMsgContainer">
                                        <BlueFilledCheckCircle />
                                        <div className="message">
                                            <h6> Last Scanned UPC</h6>
                                            <p>
                                                UPC: <span>{lastScannedUPC}</span>
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </Box>
        </>
    )
}
export default ReceivingAppSkuVault