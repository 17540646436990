import { useAuth } from "../provider/authprovider";

export const useSummaryListingAPI = () => {
  const { setLoading, api, handleError } = useAuth()
  const postListingReport = async (details: any) => {
    setLoading(true)
    try {
      const result = await api.post(`/api/Report/Procucts/Count`, details);
      return result
    } catch (error: any) {
      handleError(error)
    }
    finally {
      setLoading(false)
    }
  };
  const getExportListingReport = async (details: any) => {
    try {
      const result = await api.post(`/api/Report/Procucts/Count/Export`, details, { responseType: "arraybuffer" });
      return result
    } catch (error: any) {
      handleError(error)
    }
  };
  return { postListingReport, getExportListingReport } as const
}