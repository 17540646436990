import React, { useEffect, useState } from 'react'
import { useAuth } from "../../shared/provider/authprovider";
import Header from "../../components/header/Header"
import { ImCheckmark, ImCross } from 'react-icons/im';
import { Button, Dropdown } from "react-bootstrap";
import Dots from '../../assets/action.svg'
import Modal from 'react-bootstrap/Modal';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import XLSX from 'sheetjs-style';
import saveAs from 'file-saver';
import FileSaver from 'file-saver';
import Loader from '../../loader';
import { Box, MenuItem, TextField } from '@mui/material';
import ErrorModal from '../../components/errorModal/ErrorModal';
import { useConnectionShippingEasyAPI } from '../../shared/api/connectionShippingEasy';
import { useConnectionStatusAPI } from '../../shared/api/index.service';

interface MyFormValues {
    name: string,
    distributionCenterId: string,
    apiKey: string,
    apiSecret: string,
    storeKey: string,
    type: string,
    isActive: string
}
const DisplayingErrorMessagesSchema = Yup.object().shape({
    name: Yup.string().required('Required').min(2, "Too Short!").max(30, "Too Long!").matches(/^[A-Za-z ]*$/, 'Please enter valid name'),
    distributionCenterId: Yup.string().required('Required'),
    apiKey: Yup.string().required('Required'),
    apiSecret: Yup.string().required('Required'),
    storeKey: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    isActive: Yup.string().required('Required')
});
const ConnectionsScreen = () => {
    const { errorModalShow, setErrorModalShow, setSelectedIndex, setErrorMsg } = useAuth();
    const { getShippingEasyById, putShippingEasyById, postShippingEasy, getAllShippingEasy, shippingEasyId,
        shippingEasy, shippingEasyCount } = useConnectionShippingEasyAPI()
    const { putActivateConnection, putDeactivateConnection } = useConnectionStatusAPI()

    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    const loadInitialData = async () => {
        await getAllShippingEasy(offset, limit)
    }
    useEffect(() => {
        loadInitialData()
        setSelectedIndex(10)
    }, []);
    const [offset, setOffset] = React.useState(0);
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const putInactive = async (id: any) => {
        await putDeactivateConnection(id)
        getAllShippingEasy(offset, limit)
    }
    const putActive = async (id: any) => {
        await putActivateConnection(id)
        getAllShippingEasy(offset, limit)
    }
    const [show, setShow] = useState(false);
    const showAddModal = () => {
        setShow(true)
    }
    // const [editValue, setEditValues] = useState<any | null>()
    const [editState, setEditState] = useState(false);
    let [Id, setId] = useState<any | null>()
    const showEditModal = async (id: any) => {
        setId(id)
        await getShippingEasyById(id)
        setShow(true)
    }
    const putShippingEasyFun = async (Id: any, values: any) => {
        await putShippingEasyById(Id, values)
        getAllShippingEasy(offset, limit)
    }
    const postShippingEasyFun = async (values: any) => {
        await postShippingEasy(values)
        getAllShippingEasy(offset, limit)
    }
    const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setOffset((value - 1) * limit);
        await getAllShippingEasy((value - 1) * limit, limit)
        setPage(value);
    };
    const initialValues: MyFormValues = { name: '', distributionCenterId: '', apiKey: '', apiSecret: '', storeKey: '', type: '', isActive: '' }
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx'

    const ontabExport = () => {
        const ws = XLSX.utils.json_to_sheet(shippingEasy);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, "Shipping Easy Connections" + fileExtension)
    }
    const statusList = [
        { value: true, label: "True" },
        { value: false, label: "False" },
    ];
    return (
        <>
            {errorHandle()}
            <Header />
            <Box className="contentMain"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <div className='connectionContainer'>
                    <div className='startMain'>
                        <h5 className='pageHead mb-0 w-50'>Shipping Easy</h5>
                        <div className='d-flex justify-content-end w-50'>
                            <button className="FilledBtn me-3" onClick={() => { showAddModal(); setEditState(false) }}>Add New</button>
                            <button className='FilledBtn' onClick={() => { ontabExport(); }}>Export</button>
                        </div>
                    </div>
                    <div className='heightG'>
                        <table className='tableH'>
                            <thead className='thead rounded'>
                                <tr>
                                    <td>Sr. No.</td>
                                    <td>Name</td>
                                    <td>DC Id</td>
                                    <td>API Key</td>
                                    <td>API Secret</td>
                                    <td>Store Key</td>
                                    <td>Type</td>
                                    <td>Active</td>
                                    <td>Actions</td>
                                </tr>
                            </thead>
                            {shippingEasy ?
                                <tbody className='tbody'>
                                    {shippingEasy?.map((value: any, index: any) => {
                                        return (
                                            <tr key={value.id}>
                                                <td>{(limit * (page - 1)) + shippingEasy?.findIndex((x: any) => x.id === value?.id) + 1}</td>
                                                <td>{value?.name}</td>
                                                <td>{value?.distributionCenterId}</td>
                                                <td>{value?.apiKey}</td>
                                                <td>************</td>
                                                <td>{value?.storeKey}</td>
                                                <td>{value?.type}</td>
                                                <td>{value?.isActive == true ?
                                                    <span style={{ color: "green" }}><ImCheckmark /></span>
                                                    :
                                                    <span style={{ color: "red" }}><ImCross /></span>}
                                                </td>
                                                <td>
                                                    <Dropdown className="menuItem">
                                                        <Dropdown.Toggle variant="Warning" id="dropdown-basic"><img src={Dots}></img></Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropMenug">
                                                            <Dropdown.Item className="clickItem"
                                                                onClick={() => { showEditModal(value.id); setEditState(true) }}
                                                            >Edit</Dropdown.Item>
                                                            <Dropdown.Item className="clickItem"
                                                                onClick={() => { value.isActive == true ? putInactive(value.id) : putActive(value.id) }}
                                                            >{value?.isActive == true ? "Deactivate" : "Activate"}</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                :
                                <Loader />
                            }
                        </table>
                        <Modal
                            show={show}
                            onHide={() => setShow(false)}
                            dialogClassName="formModal modalHeight"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton className='border-bottom-0'>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    {editState == true ? "Edit Shipping Easy Details" : "Add New Shipping Easy"}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Formik
                                    initialValues={editState == true ? shippingEasyId : initialValues}
                                    enableReinitialize={true}
                                    validationSchema={DisplayingErrorMessagesSchema}
                                    onSubmit={(values, actions) => {
                                        console.log(values);
                                        actions.setSubmitting(false);
                                        // { editState == true ? putShippingEasyCalled(Id, values) : postShippingEasyCalled(values) }
                                        { editState == true ? putShippingEasyFun(Id, values) : postShippingEasyFun(values) }
                                        setShow(false)
                                    }}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className='formContainer'>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="name">Name</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="name" name="name" placeholder='Enter Name' />
                                                        {touched.name && errors.name && <div className='errorMsg'>{errors.name}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="distributionCenterId">DC Id</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="distributionCenterId" name="distributionCenterId" placeholder='Enter DC Id' />
                                                        {touched.distributionCenterId && errors.distributionCenterId && <div className='errorMsg'>{errors.distributionCenterId}</div>}
                                                    </div>
                                                </div>

                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="apiKey">Api Key</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="apiKey" name="apiKey" placeholder='Enter Api Key' />
                                                        {touched.apiKey && errors.apiKey && <div className='errorMsg'>{errors.apiKey}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="apiSecret">Api Secret</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="apiSecret" name="apiSecret" placeholder='Enter Api Secret' />
                                                        {touched.apiSecret && errors.apiSecret && <div className='errorMsg'>{errors.apiSecret}</div>}
                                                    </div>
                                                </div>

                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="storeKey">Store Key</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="storeKey" name="storeKey" placeholder='Enter Store Key' />
                                                        {touched.storeKey && errors.storeKey && <div className='errorMsg'>{errors.storeKey}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="type">Type</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="type" name="type" placeholder='Enter Type' />
                                                        {touched.type && errors.type && <div className='errorMsg'>{errors.type}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="isActive">Select Status</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <div className='selectStatusField'>
                                                            <Field name="isActive">
                                                                {({ field }: any) => (
                                                                    <div>
                                                                        <TextField
                                                                            {...field}
                                                                            select
                                                                            fullWidth
                                                                        >
                                                                            {statusList.map((e: any) => (
                                                                                <MenuItem value={e.value}>{e.label}</MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                    </div>
                                                                )}
                                                            </Field>

                                                        </div>
                                                        {touched.isActive && errors.isActive && <div className='errorMsg'>{errors.isActive}</div>}

                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-4'></div>
                                                    <div className='col-8'>
                                                        <Button className="FilledBtn" type="submit">Submit</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Modal.Body>
                        </Modal>
                    </div>


                    <div className='d-flex paginationSect align-items-center justify-content-between'>
                        <Stack spacing={2} className="d-flex flex-row align-items-center">
                            <label className='formLabel'>Showing {offset + 1} to {shippingEasyCount > (offset + limit) ? (offset + limit) : shippingEasyCount} of {shippingEasyCount} entries</label>
                            <Pagination className="m-0 paginatnNav" size="small" shape="rounded" count={Math.ceil(shippingEasyCount / limit)} page={page} onChange={handleChange} />
                        </Stack>
                    </div>
                </div>
            </Box >
        </>
    )
}
export default ConnectionsScreen

