import { useState } from "react";
import Logo from '../../assets/resale-full-logo.svg';
import LoginImage from "../../assets/sign-in-illustration.svg";
import OpenEye from "../../assets/open-eye.svg";
import CloseEye from "../../assets/eye-closed.svg";
import { Button, Modal, Spinner } from "react-bootstrap";
// import './CreatePasswordScreen.scss';
import { useAuth } from '../../shared/provider/authprovider';
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorModal from '../../components/errorModal/ErrorModal';
import { useUsersAPI } from "../../shared/api/user";

const CreatePasswordScreen = () => {
  const { isLoading, errorModalShow, setErrorMsg,
    setErrorModalShow } = useAuth();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validPassword, setValidPassword] = useState(true);
  const [isPasswordMatched, setIsPasswordMatched] = useState(true);
  const [isSecureEntryNewPassword, setisSecureEntryNewPassword] = useState(true);
  const [isSecureEntryConfirmPassword, setisSecureEntryConfirmPassword] = useState(true);
  const { resetPassword } = useUsersAPI();

  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const onTapSaveBtn = async () => {
    if (isValidForm()) {
      let details = {
        "token": searchParams.get("token"),
        "email": searchParams.get("email"),
        "newPassword": newPassword
      };
      const resp = await resetPassword(details)
      if (resp?.data?.status == 200) {
        setErrorMsg("Password updated successfully")
        setErrorModalShow(true)
        setTimeout(function () {
          navigate("/")
        }, 5000);
      }
    }
  };

  const newPasswordIconTapped = () => {
    setisSecureEntryNewPassword(prev => !prev)
  };

  const confirmPasswordIconTapped = () => {
    setisSecureEntryConfirmPassword(prev => !prev)
  }

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };

  const onTapCancelBtn = () => {
    navigate("/");
  }

  const isValidPassword = (value: any) => {
    const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\^&\*])(?=.{8,24})(?!=.[\>\<])/
    setValidPassword(PASSWORD_REGEX.test(value));
    return PASSWORD_REGEX.test(value);
  }

  const isValidForm = () => {
    if (newPassword === confirmPassword) {
      setIsPasswordMatched(true)
      if
        (isValidPassword(newPassword)) { return true }
      else {
        if (isValidPassword(newPassword)) setValidPassword(true)
        return false;
      }
    }
    else {
      setIsPasswordMatched(false);
      return false;
    }
  };

  return (
    <>
      {errorHandle()}
      <div className='mainDiv container-fluid'>
        {isLoading && <div className="loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>}
        {/* {isPasswordMatched ? null : alert("Password does not match ")}
        {validPassword ? null : alert("Password must be at least 8 characters, 1 Uppercase, 1 lowercase, 1 special character and 1 number is mandatory.")} */}
        {/* <div className="loginL">
        <img className='logo' src={Logo} alt="Logo" />
      </div> */}
        <div className="container padding-top-btm">
          <div className="row flex-grow-1">
            <div className="col-lg-6 col-md-6 col-sm-12 loginImg text-center">

              <img className="loginSide img-fluid" src={LoginImage} />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="sign-in-form-sect">
                <div className="brand-section d-flex flex-column align-items-center">
                  <div className="brand text-center">
                    <a className="navbar-brand mr-0" href="#">
                      <img className='logo' src={Logo} alt="Logo" />
                    </a>
                  </div>
                  <h5>Warehouse <span>Utilities</span></h5>
                  {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>  */}
                </div>
                <div className="loginSec">
                  <h6 className="log-in">Create Password</h6>
                  <div className='inputFieldDiv'>
                    <div className="show-hide-pwd">
                      {isSecureEntryNewPassword ?
                        <input className="input-field" placeholder='New Password' type='password'
                          onChange={(event) => {
                            setNewPassword(event.target.value);
                            isValidPassword(event.target.value) == true ? setValidPassword(true) : setValidPassword(false)
                            // newPassword === event.target.value ? setIsPasswordMatched(true) : setIsPasswordMatched(false)
                          }} />
                        :
                        <input className="input-field" placeholder='New Password' onChange={(event) => setNewPassword(event.target.value)} />
                      }
                      {isSecureEntryNewPassword ?
                        <span onClick={() => newPasswordIconTapped()}>
                          <img src={CloseEye} />
                        </span> :
                        <span onClick={() => newPasswordIconTapped()} >
                          <img src={OpenEye} />
                        </span>}
                    </div>
                    <div className="show-hide-pwd">
                      {isSecureEntryConfirmPassword ?
                        <input className="input-field" placeholder='Confirm Password' type='password' onChange={(event) => {
                          setConfirmPassword(event.target.value);
                          newPassword === event.target.value ? setIsPasswordMatched(true) : setIsPasswordMatched(false)
                        }} /> :
                        <input className="input-field" placeholder='Confirm Password' onChange={(event) => { setConfirmPassword(event.target.value); }} />}
                      {isSecureEntryConfirmPassword ?
                        <span onClick={() => confirmPasswordIconTapped()}>
                          <img src={CloseEye} />
                        </span> :
                        <span onClick={() => confirmPasswordIconTapped()} >
                          <img src={OpenEye} />
                        </span>}
                    </div>
                    {isPasswordMatched ? null : <label>Password does not match</label>}
                    {validPassword ? null : <label>Password must be at least 8 characters, 1 Uppercase, 1 lowercase, 1 special character and 1 number is mandatory.</label>}
                    <div className="btn-div d-flex">
                      <button onClick={onTapCancelBtn} className="same-w-btn submitBtn me-4" >Cancel</button>

                      <button onClick={() => onTapSaveBtn()} className="same-w-btn submitBtn" >Save</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6 loginImg">
          <img className="loginSide" src={LoginImage} />
        </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default CreatePasswordScreen;