import { Box, Link } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Checkbox from '@mui/material/Checkbox';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import FileSaver from 'file-saver';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import XLSX from 'sheetjs-style';
import Close from "../../../src/assets/icons/close.svg";
import { ReactComponent as Search } from "../../../src/assets/icons/searchStatus.svg";
import ErrorModal from '../../components/errorModal/ErrorModal';
import Header from '../../components/header/Header';
import Loader from '../../loader';
import { useAuth } from "../../shared/provider/authprovider";
import './WinServices.scss';
import { useWinServicesAPI } from '../../shared/api/winservices';


const WinServicesUserDetails = (propsValue: any, props: any) => {
    let navigate = useNavigate();
    const CronName = localStorage.getItem("CronName");
    const location = useLocation();
    const { index } = useParams();
    const [startDate, setStartDate] = useState(null);
    const [formatedStartDate, setFormatedStartDate] = useState("");
    const [endDate, setEndDate] = useState(null);
    const [formatedEndDate, setFormatedEndDate] = useState("");
    const [show, setShow] = useState(false)
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(50);
    const { getWinServicesDetails, getTransactionLogsErrors, putReolveCheckedErros } = useWinServicesAPI()
    const [winServicesDetails, setWinServicesDetails] = useState<any | null>(null);
    const [winServicesDetailsCount, setWinServicesDetailsCount] = useState<any | null>(null)
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    const [prefix, setPrefix] = React.useState("");
    const onTapSearch = async () => {
        setOffset(0)
        setPage(1)
        if (location.state == 1) {
            const result = await getWinServicesDetails(index, 0, limit, prefix, startDate == null ? "" : formatedStartDate, endDate == null ? "" : formatedEndDate)
            setWinServicesDetails(result?.data?.results)
            setWinServicesDetailsCount(result?.data?.count)
        }
        else {
            const result = await getTransactionLogsErrors(index, 0, limit, prefix, startDate == null ? "" : formatedStartDate, endDate == null ? "" : formatedEndDate)
            setWinServicesDetails(result?.data?.results)
            setWinServicesDetailsCount(result?.data?.count)
        }
    };

    const onTapReset = async () => {
        setPrefix("");
        if (location.state == 1) {
            const result = await getWinServicesDetails(index, offset, limit, "", startDate == null ? "" : formatedStartDate, endDate == null ? "" : formatedEndDate)
            setWinServicesDetails(result?.data?.results)
            setWinServicesDetailsCount(result?.data?.count)
        }
        else if (location.state == 3) {
            const result = await getTransactionLogsErrors(index, offset, limit, "", startDate == null ? "" : formatedStartDate, endDate == null ? "" : formatedEndDate)
            setWinServicesDetails(result?.data?.results)
            setWinServicesDetailsCount(result?.data?.count)
        }
    };

    const { errorModalShow, setErrorModalShow, setSelectedIndex, setErrorMsg, isLoading } = useAuth();
    const loadInintialData = async () => {
        if (location.state == 1) {
            const result = await getWinServicesDetails(index, offset, limit, prefix, startDate == null ? "" : formatedStartDate, endDate == null ? "" : formatedEndDate)
            setWinServicesDetails(result?.data?.results)
            setWinServicesDetailsCount(result?.data?.count)
        }
        else if (location.state == 3) {
            const result = await getTransactionLogsErrors(index, offset, limit, prefix, startDate == null ? "" : formatedStartDate, endDate == null ? "" : formatedEndDate)
            setWinServicesDetails(result?.data?.results)
            setWinServicesDetailsCount(result?.data?.count)
        }
    }
    useEffect(() => {
        loadInintialData()
        setSelectedIndex(1)
    }, []);

    const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setOffset((value - 1) * limit);
        if (location.state == 1) {
            const result = await getWinServicesDetails(index, (value - 1) * limit, limit, prefix, startDate == null ? "" : formatedStartDate, endDate == null ? "" : formatedEndDate)
            setWinServicesDetails(result?.data?.results)
            setWinServicesDetailsCount(result?.data?.count)
        }
        else {
            const result = await getTransactionLogsErrors(index, (value - 1) * limit, limit, prefix, startDate == null ? "" : formatedStartDate, endDate == null ? "" : formatedEndDate)
            setWinServicesDetails(result?.data?.results)
            setWinServicesDetailsCount(result?.data?.count)
        }
        setPage(value);
    };
    const [selected, setSelected] = React.useState<readonly string[]>([])
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = winServicesDetails.map((n: any) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    // console.log("Selected", selected)
    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };
    const isSelected = (id: string) => selected.indexOf(id) !== -1;
    const markSelectedAsResolved = async () => {
        console.log("selected", selected)
        const result = await putReolveCheckedErros(selected)
        if (result?.data?.status == 200) {
            setErrorModalShow(true);
            setErrorMsg("Errors Resolved Successfully");
        }
        const result2 = await getTransactionLogsErrors(index, offset, limit, prefix, startDate == null ? "" : formatedStartDate, endDate == null ? "" : formatedEndDate)
        setWinServicesDetails(result2?.data?.results)
        setWinServicesDetailsCount(result2?.data?.count)
    }
    const handleKeypress = (e: any) => {
        if (e.key === "Enter") {
            onTapSearch();
        }
    };
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx'

    const ontabExport = () => {
        const ws = XLSX.utils.json_to_sheet(winServicesDetails);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, ((CronName?.replace(/[A-Z]/g, ' $&').trim()) + " Logs") + fileExtension)
    }

    return (
        <>
            {errorHandle()}
            <Header />
            <Box className="contentMain vendorDetailContainer"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <div className=''>
                    <div role="presentation">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" onClick={() => navigate(-2)}>Cron List</Link>
                            <Link
                                underline="hover"
                                color="inherit"
                                onClick={() => navigate(-1)}>
                                {CronName?.replace(/[A-Z]/g, ' $&').trim()} Cron Logs
                            </Link>
                            <Link
                                underline="hover"
                                color="text.primary"
                                aria-current="page">
                                {CronName?.replace(/[A-Z]/g, ' $&').trim()} Logs
                            </Link>
                        </Breadcrumbs>
                    </div>
                    <div className='startMain'>
                        <h5 className='pageHead'>{CronName?.replace(/[A-Z]/g, ' $&').trim()}</h5>
                        <div className='d-flex w-100 justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <div className="search-input serchContainer d-inline-flex">
                                    <Search className='searchIcon' />
                                    <input type="search" placeholder="Search" value={prefix}
                                        onKeyPress={(event) => handleKeypress(event)}
                                        onChange={(event) => setPrefix(event.target.value)} />
                                    <img src={Close} className="pointer" onClick={() => onTapReset()} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end align-items-center w-100">
                                <button className='FilledBtn me-3' onClick={() => { ontabExport(); }}>Export</button>
                                {location.state == 3 ?
                                    <button className='FilledBtn' onClick={() => markSelectedAsResolved()}>Mark As Resolved</button>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='heightG'>
                        <Table className='tableH'>
                            <TableHead className='thead'>
                                <TableRow>
                                    {location.state == 3 ?
                                        <TableCell>
                                            <Checkbox
                                                color="primary"
                                                onChange={handleSelectAllClick}
                                                inputProps={{
                                                    'aria-label': 'select all desserts',
                                                }}
                                            />
                                        </TableCell>
                                        : ''}

                                    <TableCell>Sr. No.</TableCell>
                                    <TableCell>Order Number</TableCell>
                                    <TableCell>Order Data</TableCell>
                                    <TableCell>Input</TableCell>
                                    <TableCell>Output</TableCell>
                                    <TableCell>Status</TableCell>
                                    {/* <TableCell>Actions</TableCell> */}
                                </TableRow>
                            </TableHead>

                            <TableBody className='tbody'>
                                {!isLoading ?
                                    <>
                                        {
                                            winServicesDetailsCount > 0 ?
                                                winServicesDetails?.map((v: any, index: any) => {
                                                    const isItemSelected = isSelected(v.id);
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <TableRow
                                                            onClick={(event) => { location.state == 3 ? handleClick(event, v.id) : console.log("No") }}
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={v.id}
                                                            selected={isItemSelected}>
                                                            {
                                                                location.state == 3 ?
                                                                    <TableCell padding="checkbox">
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={isItemSelected}
                                                                            inputProps={{
                                                                                'aria-labelledby': labelId,
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    : ""
                                                            }
                                                            <TableCell>{(limit * (page - 1)) + winServicesDetails.findIndex((x: any) => x.id === v?.id) + 1}</TableCell>
                                                            <TableCell className='OverfLow'>{v.channelOrderID}</TableCell>
                                                            {/* <TableCell>{v.orderData}</TableCell> */}
                                                            <TableCell>
                                                                <div style={{ position: "relative" }}>
                                                                    <div className='txtWidth'>
                                                                        <div className='nonhoverDiv'>{v.orderData}</div>
                                                                        <div className='hoverDiv hovertxt'><p>{v.orderData}</p></div>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <div style={{ position: "relative" }}>
                                                                    <div className='txtWidth'>
                                                                        <div className='nonhoverDiv'>{v.request}</div>
                                                                        <div className='hoverDiv hovertxt'><p>{v.request}</p></div>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <div style={{ position: "relative" }}>
                                                                    <div className='txtWidth'>
                                                                        <div className='nonhoverDiv'>{v.response}</div>
                                                                        <div className='hoverDiv hovertxt'><p>{v.response}</p></div>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>{v.isError == true ? "Failed" : "Success"}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={7}>
                                                        <p className='mb-0 py-2'>No records found</p>
                                                    </td>
                                                </tr>
                                        }
                                    </>
                                    :
                                    <Loader />
                                }
                            </TableBody>
                        </Table>
                    </div>
                    <div className='d-flex paginationSect align-items-center justify-content-between'>
                        <Stack spacing={2} className="d-flex flex-row align-items-center">
                            <label className='formLabel'>Showing &nbsp;
                                {winServicesDetailsCount > 0 ? (offset + 1) : 0}
                                &nbsp;
                                to
                                {winServicesDetailsCount > (offset + limit) ? `${offset + limit} of ${winServicesDetailsCount}` : `${winServicesDetailsCount} of ${winServicesDetailsCount}`}  entries</label>
                            <Pagination className="m-0 paginatnNav" size="small" shape="rounded"
                                count={Math.ceil(winServicesDetailsCount / limit)}
                                page={page} onChange={handleChange} />
                        </Stack>
                    </div>

                </div>
            </Box>
        </>
    )
}
export default WinServicesUserDetails