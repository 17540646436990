import { useState } from "react";
import { useAuth } from "../provider/authprovider";

export const useRecAppApiQuickLotz = () => {
    const { setLoading, api, handleError, setErrorModalShow, setErrorMsg } = useAuth();
    const [upcValidationBoxQuickLotz, setupcValidationBoxQuickLotz] = useState(true)
    const [notScanableBoxQuickLotz, setNotScanableBoxQuickLotz] = useState(false)
    const [productDataBox, setProductDataBox,] = useState(false)
    const [productFlagsBox, setProductFlagsBox] = useState(false)
    const [productWeightBox, setProductWeightBox] = useState(false)
    const [listableLocationBoxQbids, setListableLocationBoxQbids] = useState(false)
    const [unlistableItemBoxQuickLotz, setUnlistableItemBoxQuickLotz] = useState(false)
    const [scanUPCProductQuickLotz, setScanUPCProductQuickLotz] = useState<any>(null)
    const [loadingExport, setLoadingExport] = useState(false)
    const getUPCScanQuickLotzCalled = async (upc: any) => {
        setLoading(true)
        try {
            const result = await api.get(`/api/Qbids/ScanItem?upc=${upc}`);
            setScanUPCProductQuickLotz(result?.data?.result?.items)
            return result?.data?.result
        } catch (error: any) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = '/';
            } else {
                setErrorMsg(error?.response?.data?.results);
                setErrorModalShow(true);
            }
        } finally {
            setLoading(false)
        }
    };
    const postQbidsDataCalled = async (details: any) => {
        try {
            const result = await api.post(`/api/Qbids/Listing`, details);
            return result
        } catch (error: any) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = '/';
            } else {
                setErrorMsg(error?.response?.data?.results);
                setErrorModalShow(true);
            }
        }
    }
    const getQbidsReportCalled = async (limit: any, offset: any, id: any, name: any, status: any, startDate: any, endDate: any) => {
        setLoading(true)
        try {
            const result = await api.get(`/api/Qbids/Report?limit=${limit}&offset=${offset}&id=${id}&name=${name}&status=${status}&fromDate=${startDate}&toDate=${endDate}`);
            return result
        } catch (error: any) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = '/';
            } else {
                setErrorMsg(error?.response?.data?.results);
                setErrorModalShow(true);
            }
        }
        finally {
            setLoading(false)
        }
    }
    const QbidsReportExport = async (id: any, name: any, status: any, startDate: any, endDate: any) => {
        setLoadingExport(true)
        try {
            const result = await api.get(`/api/Qbids/Report/Export?id=${id}&name=${name}&status=${status}&fromDate=${startDate}&toDate=${endDate}`, {
                responseType: "arraybuffer"
            });
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally {
            setLoadingExport(false)
        }
    };
    return {
        getUPCScanQuickLotzCalled,
        scanUPCProductQuickLotz,
        upcValidationBoxQuickLotz,
        setupcValidationBoxQuickLotz,
        productDataBox,
        setProductDataBox,
        productFlagsBox, setProductFlagsBox,
        productWeightBox, setProductWeightBox,
        listableLocationBoxQbids, setListableLocationBoxQbids,
        postQbidsDataCalled,
        getQbidsReportCalled, QbidsReportExport, loadingExport
    } as const
}