import axios, { AxiosPromise } from "axios";
import { environmentUrl } from "./index.service";

//sku vault screen
export const postInsertSkuVaultProducts = (token: any, details: any): AxiosPromise<any> => {
    let url = environmentUrl + `/api/Scan/InsertSkuVaultProducts`;
    const skuVaultProducts = axios.post(url, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return skuVaultProducts;
  };