import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import CreatePasswordScreen from "../screens/createPasswordScreen/CreatePasswordScreen";
import DashBoardScreen from "../screens/dashboardScreen/DashBoardScreen";
import ErrorScreen from "../screens/errorScreen/ErrorScreen";
import ForgotPasswordScreen from "../screens/forgotPasswordScreen/ForgotPasswordScreen";
import LoginScreen from "../screens/loginScreen/LoginScreen";
import ScanItemsScreen from "../screens/scanItemsScreen/ScanItemsScreen";
import BoxNumber from "../screens/userDashboard/BoxNumber";
import BoxTableScreen from "../screens/userDashboard/BoxTableScreen";
import UserDashboard from "../screens/userDashboard/UserDashboard";
import DataManagementScreen from "../screens/dataManagementScreen/DataManagementScreen";
import DashboardListingReportScreen from "../screens/dashboardListingReportScreen/DashboardListingReportScreen";
import WarehouseManagement from "../screens/warehouseManagement/WarehouseManagement";
import UserManagement from "../screens/userManagement/UserManagement";
import ListingReportScreen from "../screens/listingReportScreen/ListingReportScreen";
import UserReportScreen from "../screens/userReportScreen/UserReportScreen";
import SkuVaultScreen from "../screens/skuVaultScreen/SkuVaultScreen";
import WinServicesScreen from "../screens/winServicesScreen/WinServicesScreen";
import WinServicesByName from "../screens/winServicesScreen/WinServicesByName";
import WinServicesUserDetails from "../screens/winServicesScreen/WinServicesUserDetails";
import MISReportScreen from "../screens/misReportScreen/MISReportScreen";
import ConnectionsScreen from "../screens/connections/ConnectionsScreen";
import ChannelAdvisor from "../screens/connections/ChannelAdvisor";
import ConnectionsSkuValult from "../screens/connections/ConnectionsSkuValult";
import ConnectionsSquareSpace from "../screens/connections/ConnectionsSquareSpace";
import VendorDashoard from "../screens/vendorDashboard/VendorDashoard";
import VendorDetailsScreen from "../screens/vendorDetailsScreen/VendorDetailsScreen";
import VendorDetailsOrders from "../screens/vendorDetailsScreen/VendorDetailsOrders";
import DailyMISReportScreen from "../screens/dailyMISReportScreen/DailyMISReportScreen";
import UpdateProductsScreen from "../screens/userUpdateProductsScreen/UpdateProductsScreen";
import ProductByLocation from "../screens/userUpdateProductsScreen/ProductByLocation";
import UpdateProductForm from "../screens/userUpdateProductsScreen/UpdateProductForm";
import SummaryListingReport from "../screens/summaryListingReportSceen/SummaryListingReport";
import UserGuidedProductUpdateScreen from "../screens/userGuidedProductUpdateScreen/UserGuidedProductUpdateScreen";
import TaxonomyNotFoundScreen from "../screens/taxonomyNotFoundScreens/TaxonomyNotFoundScreen";
import ROValidationScree from "../screens/receivingAppScreen/ROValidationScree";
import SettingsScreen from "../screens/settingsScreen/SettingsScreen";
import ResetDataScreen from "../screens/resetDataScreen/ResetDataScreen";
import RegistrationScreen from "../screens/listingScreens/RegistrationScreen";
import ListingLoginScreen from "../screens/listingScreens/LoginScreen";
import ListingForgetPassword from "../screens/listingScreens/ListingForgetPassword";
import OpenEmailApp from "../screens/listingScreens/ListingForgetPassword/OpenEmail";
import SetNewPasswordScreen from "../screens/listingScreens/ListingForgetPassword/SetNewPasswordScreen";
import ListingLandingDashboard from "../screens/listingScreens/listingLandingDashborad";
import SuperAdminDashboard from "../screens/listingScreens/superAdminDashboard";
import PostLoginScreen from "../screens/listingScreens/LoginScreen/postLoginScreens";
import ReceivingAppQuickLotz from "../screens/receivingAppQuickLotz";
import ReportsQbis from "../screens/receivingAppQuickLotz/ReportsQbis";
import ReceivingAppSkuVault from "../screens/recivingappSkuVault";
import ProductDeatilsWebForm from "../screens/productDeatilsWebForm";
import FormDetails from "../screens/productDeatilsWebForm/FormDetails";
import SetUpTool from "../screens/productDeatilsWebForm/SetUpTool";
import ScanUPC from "../screens/ScanUPC";
const PageRoutes = () => {
  // const baseRoute = (localStorage.getItem("token") && localStorage.getItem("role")) ? (localStorage.getItem("role") === "User" ? <Navigate to="/userdashboard" /> : <Navigate to="/dashboard" />) : <LoginScreen />
  // console.log('base route', baseRoute);

  return (
    <Routes>
      {/* <Route
        path="/"
        element={baseRoute}
      /> */}
      <Route
        path="/"
        element={<LoginScreen />}
      />

      <Route
        path="/userdashboard"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" ? (
            <UserDashboard />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/update-products"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" ? (
            <UpdateProductsScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/update-products-guided"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" ? (
            <UserGuidedProductUpdateScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/location/:value"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" ? (
            <ProductByLocation />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="update-product/:upc"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" ? (
            <UpdateProductForm />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/taxonomy-not-found"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" ? (
            <TaxonomyNotFoundScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/receiving-app"

        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" || "RMUser" ? (
            <ROValidationScree />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/product-details-web-form"

        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User"  ? (
            <ProductDeatilsWebForm />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/setup-tool"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User"  ? (
            <SetUpTool />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/product-details-form"

        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User"  ? (
            <FormDetails />
          ) : (
            <Navigate to="/" />
          )
        }
      />
{/* /receiving-app-quicklotz */}
<Route
        path="/receiving-app-quicklotz"

        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" || "RMUser" ? (
            <ReceivingAppQuickLotz />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      {/* /receiving-app-reports */}
      <Route
        path="/receiving-app-reports"

        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" || "RMUser" ? (
            <ReportsQbis />
          ) : (
            <Navigate to="/" />
          )
        }
      />
       <Route
        path="/receiving-skuvault"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" || "RMUser" ? (
            <ReceivingAppSkuVault />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/scan-upc"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" || "Demo"   ? (
            <ScanUPC />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      {/* <Route
        path="/boxscreen"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" ? (
            <BoxNumber />
          ) : (
            <Navigate to="/" />
          )
        }
      /> */}
      <Route
        path="/boxtablescreen"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" ? (
            <BoxTableScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/scan"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") == "User" ? (
            <ScanItemsScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />

      <Route
        path="/dashboard"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <DashBoardScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/winservices"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <WinServicesScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/winservicesbyname/:id"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <WinServicesByName />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/winservicesuserdetails/:index"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <WinServicesUserDetails />
          ) : (
            <Navigate to="/" />
          )
        }
      />

      <Route
        path="/data"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <DataManagementScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />

      <Route
        path="/dashboardlistingreport"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <DashboardListingReportScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />

      <Route
        path="/listingreport"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <ListingReportScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />

      <Route
        path="/misreport"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <MISReportScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/daily-misreport"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <DailyMISReportScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/summary-listing-report"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <SummaryListingReport />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/userreport"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <UserReportScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />

      <Route
        path="/warehouse"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <WarehouseManagement />
          ) : (
            <Navigate to="/" />
          )
        }
      />

      <Route
        path="/user"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <UserManagement />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/settings"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <SettingsScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/reset-data"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <ResetDataScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/skuvault"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <SkuVaultScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/connections-shippingeasy"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <ConnectionsScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/connections-channeladvisor"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <ChannelAdvisor />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/connections-skuvault"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <ConnectionsSkuValult />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/connections-squarespace"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <ConnectionsSquareSpace />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/vendor-dashboard"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" ? (
            <VendorDashoard />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/vendor-details/:id"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" || "Vendor" ? (
            <VendorDetailsScreen />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/order-details/:vendor"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Admin" || "Vendor" ? (
            <VendorDetailsOrders />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/forgotpassword"
        element={<ForgotPasswordScreen />}
      />

      <Route
        path="/createpassword"
        element={<CreatePasswordScreen />}
      />
      <Route
        path="*"
        element={<ErrorScreen />} />

      {/* ////////////////////////////////////Listing Reports Screens//////////////////////////////////// */}
      <Route
        path="/listing-registration"
        element={<RegistrationScreen />}
      />
      <Route
        path="/listing-login"
        element={<ListingLoginScreen />}
      />
      <Route
        path="/listing-forget-password"
        element={<ListingForgetPassword />}
      />
      <Route
        path="/listing-reset-password"
        element={<OpenEmailApp />}
      />
      <Route
        path="/listing-set-new-password"
        element={<SetNewPasswordScreen />}
      />
      <Route
        path="/listing-saas-dashboard"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "Customer" ? (
            <ListingLandingDashboard />
          ) : (
            <Navigate to="/listing-login" />
          )
        }
      />
      <Route
        path="/listing-saas-main-dashboard"
        element={
          localStorage.getItem("token") != null &&
            localStorage.getItem("role") === "SuperAdmin" ? (
            <SuperAdminDashboard />
          ) : (
            <Navigate to="/listing-login" />
          )
        }
      />
      <Route
        path="/listing-dashboard-customer"
        element={<PostLoginScreen />}
      />
    </Routes>
  );
};

export default PageRoutes;
