import { useState } from "react"
import { useAuth } from "../provider/authprovider"
import { useClientAPI } from "./userManagementSAAS"

export const useSubScriptionAPI = () => {
    const { apinew, handleError, setLoading } = useAuth()
    const { handleErrorSaas, roles } = useClientAPI()
    const [messageText, setMessageText] = useState<any>("")
    const [errorMessageText, setErrorMessageText] = useState<any>("")
    const [open, setOpen] = useState(false);
    const [subscriptionList, setSubscriptionList] = useState<any>(null)
    const [subscriptionCount, setSubscriptionCount] = useState<any>()
    const [subscriptionByIdNew, setSubscriptionByIdNew] = useState<any>([])
    const [orderIdFromBackend, setOrderIdFromBackend] = useState<any>(null)
    const [subscriptionIdPass, setSubscriptionIdPass] = useState<any>(null)
    const [flag, setFlag] = useState<any>(null)
    const getSubscriptionList = async (prefix: any, offset: any, limit: any, status: any, startDate: any, endDate: any) => {
        setLoading(true)
        try {
            const newData = await apinew.get(`/api/Subscription/List?prefix=${prefix}&offset=${offset}&limit=${limit}&subscriptionStatus=${status}&sortBy=DSC&orderBy=createdTime&fromDate=${startDate}&toDate=${endDate}`);
            setSubscriptionList(newData?.data?.result)
            if (localStorage.getItem("role") == "Customer") {
                localStorage.setItem("subcr", newData?.data?.result?.items[0].id);
            }
            setSubscriptionCount(newData?.data?.count)
            return newData
        } catch (error: any) {
            handleErrorSaas(error)
        }
        finally {
            setLoading(false)
        }
    }
    const getSubscriptionById = async (id: any) => {
        try {
            const newData = await apinew.get(`/api/Subscription/${id}`);
            setSubscriptionByIdNew(newData?.data?.result)
            return newData
        } catch (error: any) {
            setMessageText(error?.response?.data?.result);
            setOpen(true)
            setTimeout(function () {
                setOpen(false)
            }, 5000);
        }
    }
    // /api/Subscription
    const addNewSubscriptionPlan = async (details: any) => {
        try {
            const result = await apinew.post(`/api/Subscription`, details);
            return result
        } catch (error: any) {
            setMessageText(error?.response?.data);
            setOpen(true)
            setTimeout(function () {
                setOpen(false)
            }, 5000);
        }
    };
    const putSubscriptionPlan = async (id: any, details: any) => {
        try {
            const result = await apinew.put(`/api/Subscription/${id}`, details);
            return result
        } catch (error: any) {
            setMessageText(error?.response?.data);
            setOpen(true)
            setTimeout(function () {
                setOpen(false)
            }, 5000);
        }
    };
    // /api/Subscription?id=e39c9053-0530-4331-b7bf-c48f2399cb1a
    const deleteSubscription = async (id: any) => {
        try {
            const result = await apinew.delete(`/api/Subscription?id=${id}`);
            return result
        } catch (error: any) {
            setMessageText(error?.response?.data);
            setOpen(true)
            setTimeout(function () {
                setOpen(false)
            }, 5000);
        }
    };
    ////////////paypal payment
    const CreateOrderForSubscription = async (subscriptionId: any) => {
        try {
            const result = await apinew.post(`/CreateOrderForSubscription?SubscriptionId=${subscriptionId}`);
            localStorage.setItem("order", result?.data?.id);
            return result
        } catch (error: any) {
            setMessageText(error?.response?.data);
            setOpen(true)
            setTimeout(function () {
                setOpen(false)
            }, 5000);
        }
    };
    const paypalCapturePayment = async (orderId: any, subscriptionId: any) => {
        try {
            const result = await apinew.post(`/CapturePayment?orderId=${orderId}&SubscriptionId=${subscriptionId}`);
            return result
        } catch (error: any) {
            setMessageText(error?.response?.data);
            setOpen(true)
            setTimeout(function () {
                setOpen(false)
            }, 5000);
        }
    };
    const paypalCancelPayment = async (orderId: any) => {
        try {
            const result = await apinew.post(`/CancelPayment?orderId=${orderId}`);
            return result
        } catch (error: any) {
            setMessageText(error?.response?.data);
            setOpen(true)
            setTimeout(function () {
                setOpen(false)
            }, 5000);
        }
    };
    const paypalErrorPayment = async (orderId: any) => {
        try {
            const result = await apinew.post(`/ErrorPayment?orderId=${orderId}`);
            return result
        } catch (error: any) {
            setMessageText(error?.response?.data);
            setOpen(true)
            setTimeout(function () {
                setOpen(false)
            }, 5000);
        }
    };
    ///invoice - transaction
    // Transactions/List?prefix=a&offset=0&limit=10&sortBy=createdTime&orderBy=DESC&userId=526aa8de-d812-4ee4-8b36-fe9370b4dfb6
    const getUserInvoiceDetails = async (prefix: any, offset: any, limit: any, userId: any) => {
        setLoading(true)
        try {
            const newData = await apinew.get(`/Transactions/List?prefix=${prefix}&offset=${offset}&limit=${limit}&sortBy=createdTime&orderBy=DESC&userId=${userId}`);
            // console.log(newData?.data?.result?.items, "9")
            return newData?.data?.result?.items
        } catch (error: any) {
            setMessageText(error?.response?.data?.result);
            setOpen(true)
            setTimeout(function () {
                setOpen(false)
            }, 5000);
        }
        finally {
            setLoading(false)
        }
    }
    return {
        getSubscriptionList, subscriptionList, subscriptionCount, getSubscriptionById,
        subscriptionByIdNew, paypalCapturePayment, paypalCancelPayment, paypalErrorPayment,
        // getOrderId,
        orderIdFromBackend, CreateOrderForSubscription, subscriptionIdPass, addNewSubscriptionPlan,
        putSubscriptionPlan, deleteSubscription, getUserInvoiceDetails, setFlag, flag
    } as const
}