import { Box, Stack, Typography } from "@mui/material";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useSubScriptionAPI } from "../../../shared/api/userSubscriptionSAAS";
import ButtonWrapper from "./paypalButton";
const currency = "USD";
const description = "desc";

const style: any = {
  layout: "vertical",
  color: "gold",
  shape: "rect",
  label: "paypal",
  height: 45,
};

const Paypal = () => {
  const [subscription, setSubscription] = useState<any>(null);
  const [newOrderId, setOrder] = useState<any>(null);
  const subIsByLocal = localStorage.getItem('setSubscriptionIdPass')
  const orderBtLocal = localStorage.getItem("setOrderIdFromBackend")
  const[amoutToshowinSuccess,setAmoutToshowinSuccess]= useState<any>(null)

  const { orderIdFromBackend, getSubscriptionList, subscriptionList, subscriptionIdPass,
    // getOrderId,
    CreateOrderForSubscription
  } = useSubScriptionAPI();
  const getSub = async () => {
    const result = await getSubscriptionList("", 0, 1, 0, "", "");
    setAmoutToshowinSuccess(result?.data?.result?.items[0].price)
    // setSubscription(newData?.data?.result?.items[0].id)
    setSubscription(subIsByLocal)

  };

  const getInit = async () => {
    await getSub()

  }
  useEffect(() => {

    getInit()
  }, []);
  return (
    <Box sx={{ border: "1px solid #E0E0E0", p: 2.5, borderRadius: 0.5 }}>
      <Stack>
        <Typography
          variant="h6"
          fontSize={18}
          fontWeight={600}
          color="#1E1F21"
          mb={2.5}
        >
          Payment Method
        </Typography>
      </Stack>
      <PayPalScriptProvider
        options={{
          clientId:
            "AYQYFTq-Y0cCYglR5Giesp0Mo_NJ3wt_qpBAntA2mMKP9FCUDUaXwUV_7H8ppC4YWeCS-DEwPhzVYWyA",
          components: "buttons",
          currency: "USD",
        }}
      >
        <ButtonWrapper currency={currency} showSpinner={false} amount={amoutToshowinSuccess} />
      </PayPalScriptProvider>
      <Typography variant="body2" color="#000" fontSize={12}>
        To purchase with PayPal, please click the “PayPal” button. A pop-up will
        appear and you will be promoted to complete your payment.
      </Typography>
    </Box>
  );
};
export default Paypal;
