import { useAuth } from "../provider/authprovider";

export const useSCanUPCAPIs = () => {
    const { api, handleError, setLoading } = useAuth()
    const scanUPCBarcode = async (upc: any) => {
        setLoading(true)
        try {
            const result = await api.get(`/api/ProductDetails/UiPath?upc=${upc}`);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally{
            setLoading(false)
        }
    };
    return {
        scanUPCBarcode,
    } as const
}