import { Box } from "@mui/material"
import Header from "../../components/header/Header"
import { useNavigate } from "react-router-dom"

const SetUpTool = () => {
    let navigate = useNavigate()
    return (
        <>
            <Header />
            <Box
                className="contentMain ROValidationWrapper"
                component="main"
                sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
            >
                <div>
                    <h5 className="pageHead mb-2 me-2">Set Up Form -FAQ</h5>
                    <button onClick={() => navigate(-1)}>Close</button>
                </div>
            </Box>

        </>
    )
}
export default SetUpTool