import { useAuth } from "../provider/authprovider";

export const useResetDataAPI = () => {
  const { api, handleError } = useAuth()
  const getResetDataPrefixList = async () => {
    try {
      const result = await api.get(`/api/AdminDashboard/DashboardPrefixes`);
      return result
    } catch (error: any) {
      handleError(error)
    }
  };
  const putResetDataChangeProductStatus = async (values: any) => {
    try {
      const result = await api.put(`/api/AdminDashboard/ChangeProductStatus`, values);
      return result
    } catch (error: any) {
      handleError(error)
    }
  };
  return {
    getResetDataPrefixList, putResetDataChangeProductStatus
  } as const
}