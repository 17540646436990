import Header from "../../components/header/Header";
import Box from '@mui/material/Box';
import moment from 'moment';
import { ReactComponent as Calendar } from "../../../src/assets/icons/searchCalendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import { useAuth } from '../../shared/provider/authprovider';
import XLSX from 'sheetjs-style';
import FileSaver from 'file-saver';
import { ReactComponent as ResetFilter } from "../../../src/assets/icons/filter-reset.svg";
import filter from "../../assets/filter.svg";
import Loader from "../../loader";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { ReactComponent as LeftArrow } from "../../../src/assets/icons/left-right/Left-Arrow.svg";
import { ReactComponent as RightArrow } from "../../../src/assets/icons/left-right/Right-Arrow.svg";
import ErrorModal from "../../components/errorModal/ErrorModal";
import '../dashboardScreen/DashBoardScreen.scss';
import { useSummaryListingAPI } from "../../shared/api/reports-listingSummary";


export default function SummaryListingReport() {
    const { isLoading, setErrorMsg, errorModalShow, setErrorModalShow, setSelectedIndex, } = useAuth();
    const { postListingReport, getExportListingReport } = useSummaryListingAPI()
    const [listingReportBySclassification, setListingReportBySclassification] = useState<any | null>(null)

    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    var firstDay: any = moment().startOf('month').format("YYYY-MM-DD")
    var firstDayNew = moment().startOf('month')

    var todayDate: any = moment().format("YYYY-MM-DD")
    var todayDateNew = moment()
    const [startDate, setStartDate] = useState<any>(firstDay);
    const [endDate, setEndDate] = useState<any>(todayDate);
    const [newStartDate, setNewStartDate] = useState<any>(new Date())
    const [newEndDate, setNewEndDate] = useState<any>(new Date())
    const [isNewExportLoader, setNewExportLoader] = useState(false)

    const detailsReport = {
        "year": 0,
        "month": 0,
        "formDate": firstDay,
        "toDate": todayDate,
    }
    const handleStartDate = (value: any) => {
        if (value == null) {
            setStartDate(firstDay);
            setNewStartDate(firstDayNew)
        }
        else {
            const result = moment(value).format("YYYY-MM-DD")
            setStartDate(result);
            setNewStartDate(new Date(value))
        }
    }

    const handleEndDate = (value: any) => {
        if (value == null) {
            setEndDate(todayDate);
            setNewEndDate(todayDateNew)
        }
        else {
            const result = moment(value).format("YYYY-MM-DD")
            setEndDate(result);
            setNewEndDate(new Date(value))
        }
    }
    const onTapApply = async () => {
        var date1 = moment(startDate);
        var date2 = moment(endDate);
        var days = date2.diff(date1, 'month')
        console.log("Diff", days)
        if (moment(startDate).isSameOrBefore(endDate)) {
            if (days > 0) {
                setErrorMsg("please select dates in same month")
                setErrorModalShow(true)
            }
            else {
                const detailsReport2 = {
                    "year": 0,
                    "month": 0,
                    "formDate": startDate,
                    "toDate": endDate
                }
                const res = await postListingReport(detailsReport2);
                setListingReportBySclassification(res?.data?.result)
            }
        }
        else {
            setErrorMsg("Please select start date less than end date")
            setErrorModalShow(true)
        }
    }
    const resetFilter2 = async () => {
        const detailsReport = {
            "year": 0,
            "month": 0,
            "formDate": firstDay,
            "toDate": todayDate
        }
        const res = await postListingReport(detailsReport);
        setListingReportBySclassification(res?.data?.result)
        setNewStartDate(firstDayNew)
        setNewEndDate(todayDateNew)
        setStartDate(firstDay)
        setEndDate(todayDate)
    }
    
    // const ontabExport2 = () => {
    //     const ws = XLSX.utils.json_to_sheet(listingReportBySclassification);
    //     const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //     const data = new Blob([excelBuffer], { type: fileType })
    //     FileSaver.saveAs(data, "filename" + fileExtension)
    // }

    //prev month start , end date
    var now = new Date(startDate == "" ? firstDay : startDate);
    var nextMonth = new Date(endDate == "" ? todayDate : endDate);
    var prevMonthLastDate = moment(new Date(now.getFullYear(), now.getMonth(), 0)).format("YYYY-MM-DD");
    var prevMonthFirstDate = moment(new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 1 + 12) % 12, 1)).format("YYYY-MM-DD");

    if (now.getMonth() == 11) {
        var nextMonthFirstDate = moment(new Date(nextMonth.getFullYear() + 1, 0, 1)).format("YYYY-MM-DD");
    } else {
        var nextMonthFirstDate = moment(new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 1)).format("YYYY-MM-DD");
    }
    var nextMonthLastDate = moment(new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 2, 0)).format("YYYY-MM-DD");

    const getPrevMonthData = async () => {
        setStartDate(prevMonthFirstDate)
        setEndDate(prevMonthLastDate)
        setNewStartDate(new Date(prevMonthFirstDate))
        setNewEndDate(new Date(prevMonthLastDate))
        const detailsReport2 = {
            "year": 0,
            "month": 0,
            "formDate": prevMonthFirstDate,
            "toDate": prevMonthLastDate
        }
        const res = await postListingReport(detailsReport2);
        setListingReportBySclassification(res?.data?.result)
    }
    const getNextMonthData = async () => {
        if (Date.parse(nextMonthFirstDate)! >= Date.parse(todayDate)) {
            setErrorMsg("Please select month less than current month");
            setErrorModalShow(true);
        }
        else {
            setStartDate(nextMonthFirstDate)
            setEndDate(nextMonthLastDate)
            setNewStartDate(new Date(nextMonthFirstDate))
            setNewEndDate(new Date(nextMonthLastDate))
            const detailsReport2 = {
                "year": 0,
                "month": 0,
                "formDate": nextMonthFirstDate,
                "toDate": nextMonthLastDate
            }
            const res = await postListingReport(detailsReport2);
            setListingReportBySclassification(res?.data?.result)
        }
    }
    const loadInitialData = async () => {
        const res = await postListingReport(detailsReport)
        setListingReportBySclassification(res?.data?.result)
    }
    useEffect(() => {
        loadInitialData()
        setNewStartDate(firstDayNew)
        setNewEndDate(todayDateNew)
        setStartDate(firstDay)
        setEndDate(todayDate)
        setSelectedIndex(13)
    }, [])
    const detailsReportExport = {
        "year": 0,
        "month": 0,
        "formDate": startDate,
        "toDate": endDate
    }
    const exportToExcel = async () => {
        setNewExportLoader(true)
        const res = await getExportListingReport(detailsReportExport)
        const url = URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ListingSummaryReport.xlsx`);
        document.body.appendChild(link);
        link.click();
        setNewExportLoader(false)
    }
    return (
        <>
            {errorHandle()}
            <Header />
            <Box className="contentMain"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <div className='misReportContainer'>
                    <h5 className="pageHead">Listing Summary Report</h5>
                    <div className="startMain">
                        <div className='d-flex align-items-center'>
                            <div className='d-flex align-items-center filterContainer'>
                                <div className='leftDiv filterItem'>
                                    <img src={filter} className="mRight" />
                                    <h5 className='m-0 text'>Filters</h5>
                                </div>
                                <div className='borderDiv filterItem'>
                                    <div className='dateInput from d-flex align-items-center'>
                                        <label className='w-100 formLabel'>From:</label>
                                        <Tooltip TransitionComponent={Zoom} title="Previous Month" arrow placement="top">
                                            <div className='me-2 pointer' onClick={() => getPrevMonthData()}>
                                                <RightArrow className='leftArrow arrow' />
                                            </div>
                                        </Tooltip>
                                        <div className="d-flex align-items-center outlinedInput">
                                            <Calendar />
                                            <DatePicker className='dateUi' selected={new Date(newStartDate)}
                                                onChange={(selectedstart) => handleStartDate(selectedstart)}
                                                dateFormat='MM-dd-yyyy'
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode='select'
                                                maxDate={moment(todayDate).toDate()}
                                            />
                                        </div>
                                    </div>
                                    <div className='dateInput d-flex align-items-center'>
                                        <label className='w-100 formLabel'>To:</label>
                                        <div className="d-flex align-items-center outlinedInput">
                                            <Calendar />
                                            <DatePicker className='dateUi' selected={new Date(newEndDate)}
                                                onChange={(selectedstart) => handleEndDate(selectedstart)}
                                                dateFormat='MM-dd-yyyy'
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode='select'
                                                maxDate={moment(todayDate).toDate()}
                                            />
                                        </div>
                                        <Tooltip TransitionComponent={Zoom} title="Next Month" arrow placement="top">
                                            <div className='ms-2 pointer' onClick={() => getNextMonthData()}>
                                                <RightArrow className='arrow' />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            {/* <Tooltip TransitionComponent={Zoom} title="Reset" arrow placement="top"> */}
                            <ResetFilter className='ms-3 pointer' onClick={() => resetFilter2()} />
                            {/* </Tooltip> */}
                        </div>
                        <div className='d-flex justify-content-end w-50'>
                            <button className='FilledBtn me-3' onClick={() => { onTapApply() }}>Apply</button>
                            {!isNewExportLoader ?
                                <button className='FilledBtn' onClick={() => { exportToExcel(); }}>Export</button>

                                :
                                <button className='FilledBtn processingBtn'>Exporting File...</button>}
                        </div>
                    </div>
                    <div className='heightG'>
                        <table className='tableH'>
                            <thead className='thead rounded'>
                                <tr>
                                    <td>Date</td>
                                    <td>Total</td>
                                    {listingReportBySclassification ?
                                        <>
                                            {
                                                listingReportBySclassification[0]?.classification?.map((record: any) => {
                                                    return (
                                                        <td >{record.classification}</td>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        ""
                                    }
                                </tr>
                            </thead>
                            <tbody className='tbody'>
                                {!isLoading ?
                                    <>
                                        {listingReportBySclassification ?
                                            <>
                                                {listingReportBySclassification[0]?.classification?.length > 0 ?
                                                    <>
                                                        {listingReportBySclassification.map((v: any) => {
                                                            return (
                                                                <tr>
                                                                    <td>{v.day}</td>
                                                                    <td>{v.total}</td>
                                                                    {v?.classification?.map((v: any) => { return <td> {v.count} </td> })}
                                                                </tr>
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <p className='mb-0 py-2'>No records found</p>
                                                        </td>
                                                    </tr>
                                                }
                                            </>
                                            :
                                            null}
                                    </>
                                    :
                                    <Loader />
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Box>
        </>
    )
}
