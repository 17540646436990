import axios, { AxiosPromise } from "axios";
import { environmentUrl } from "./index.service";
import { useAuth } from "../provider/authprovider";
import { useState } from "react";

export const getWarehouseList = (token: any, apiPrefix: any, offset: any, limit: any): AxiosPromise<any> => {
    let url =
        environmentUrl + `/api/Warehouse/List?Prefix=${apiPrefix}&Offset=${offset}&Limit=${limit}`;
    const warehouse = axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return warehouse;
};
export const useWarehousesAPI = () => {
    const { api, handleError, setLoading } = useAuth()
    const createWarehouse = async (details: any) => {
        setLoading(true)
        try {
            const result = await api.post(`/api/Warehouse`, details);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally {
            setLoading(false)
        }
    };
    const deleteWarehouse = async (id: any) => {
        setLoading(true)
        try {
            const result = await api.delete(`/api/Warehouse/${id}`);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally {
            setLoading(false)
        }
    };
    const createWarehousePrefix = async (details: any) => {
        setLoading(true)
        try {
            const result = await api.post(`/api/WarehousePrefix`, details);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally {
            setLoading(false)
        }
    };
    const editWarehousePrefix = async (details: any, id: any) => {
        try {
            const result = await api.put(`/api/WarehousePrefix/${id}`, details);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const deleteWarehousePrefix = async (id: any) => {
        try {
            const result = await api.delete(`/api/WarehousePrefix/${id}`);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const getWarehousePrefixByWarehouseId = async (id: any) => {
        try {
            const result = await api.delete(`/api/WarehousePrefix/WarehouseId/${id}`);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const editWarehouseDetails = async (details: any, id: any) => {
        setLoading(true)
        try {
            const result = await api.put(`/api/Warehouse/${id}`, details);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally {
            setLoading(false)
        }
    };

    return {
        createWarehouse, deleteWarehouse, createWarehousePrefix, editWarehousePrefix,
        deleteWarehousePrefix, getWarehousePrefixByWarehouseId, editWarehouseDetails,
    } as const
}