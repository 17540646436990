import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './createWarehouseModal.scss';
import { useAuth } from '../../shared/provider/authprovider';
import AddUser from "../../assets/add-black.svg";
import ErrorModal from "../errorModal/ErrorModal";
import { useWarehousesAPI } from "../../shared/api/warehouse";

const CreateWarehouseModal = (props: any) => {
  const { token, validWarehouseName, setValidWarehouseName, validLocation, setErrorMsg,
    setValidLocation, validDescription, setValidDescription, errorModalShow, setErrorModalShow, getWarehouseListCalled } = useAuth();
  const [warehouseName, setWarehouseName] = useState("");
  const [location, setLocation] = useState("")
  const [description, setDescription] = useState("");
  const { createWarehouse } = useWarehousesAPI()

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };

  const onTapSubmitBtn = async () => {
    if (warehouseName.trim() && location.trim() && description.trim()) {
      let details = {
        "name": warehouseName.trim(),
        "location": location.trim(),
        "description": description.trim()
      };
      // console.log("details..", details);
      const res = await createWarehouse(details)
      if (res?.data?.status == 200) {
        setErrorModalShow(true);
        setErrorMsg("Warehouse Added Successfully");
        getWarehouseListCalled("", 0, 1000)
      }
      props.onHide();
      setWarehouseName("");
      setLocation("");
      setDescription("");
      // createWarehouseCalled(details);
    } else {
      if (warehouseName.trim() !== "") {
        setValidWarehouseName(false);
      } else {
        setValidWarehouseName(true);
      }

      if (location.trim() !== "") {
        setValidLocation(false);
      } else {
        setValidLocation(true);
      }

      if (description.trim() !== "") {
        setValidDescription(false);
      } else {
        setValidDescription(true);
      }
    }
  };

  return (
    <>
      {errorHandle()}
      <Modal className="formModal addModal"
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        // dialogClassName="add-warehouse-modal user-modal"
        onHide={() => {
          props.onHide();
          setWarehouseName('');
          setLocation('');
          setDescription('');
        }}
      >
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className="modalTitle d-flex">Add new warehouse
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className='formContainer'>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label>Warehouse Name</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input className="textfield" placeholder="Enter Warehouse Name" onChange={(event) => setWarehouseName(event.target.value)} />
                {!validWarehouseName ? null : <label>Invalid Warehouse Name</label>}
              </div>
            </div>



            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label>Location</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input className="textfield" placeholder="Enter Location" onChange={(event) => setLocation(event.target.value)} />
                {!validLocation ? null : <label>Invalid Location</label>}
              </div>
            </div>


            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label>Description</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input className="textfield" placeholder="Enter Description" onChange={(event) => setDescription(event.target.value)} />
                {!validDescription ? null : <label>Invalid Description</label>}
              </div>
            </div>

            <div className='row align-items-center formItem'>
              <div className='col-4'></div>
              <div className='col-8'>
                {warehouseName && location && description ?
                  <button className="FilledBtn" onClick={() => onTapSubmitBtn()}>Save Warehouse</button>
                  :
                  <button className="FilledBtn disabled-btn" disabled>Add</button>}
              </div>
            </div>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
}

export default CreateWarehouseModal;