import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import * as React from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Close } from "../../../src/assets/icons/close.svg";
import { ReactComponent as ResetFilter } from "../../../src/assets/icons/filter-reset.svg";
import { ReactComponent as Search } from "../../../src/assets/icons/searchStatus.svg";
import filter from "../../assets/filter.svg";
import ErrorModal from '../../components/errorModal/ErrorModal';
import Header from "../../components/header/Header";
import Loader from '../../loader';
import { useAuth } from '../../shared/provider/authprovider';
import './DashboardListingReportScreen.scss';
import { useDashboardAPI } from '../../shared/api/dashboardReports';

function Row(props: { item: any, page: number, limit: number, onClickRow: any, open: any, list: any }) {
  const { item, page, limit, onClickRow, open, list } = props;
  const { isLoading, errorModalShow, setErrorModalShow } = useAuth();
  const { adminDashboardScannedProductReport, setAdminDashboardScannedProductReportList,
    adminDashboardScannedProductReportList } = useDashboardAPI()
  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };
  const scannedProducAPiCall = async () => {
    setAdminDashboardScannedProductReportList([]);
    onClickRow(open ? "" : item?.scanId)
    const startDate = localStorage.getItem("StartDatePass")
    const endDate = localStorage.getItem("EndDatePass")
    if (!open) {
      await adminDashboardScannedProductReport(startDate == null ? "" : startDate, endDate == null ? "" : endDate, item?.scanId, 0, 100)
    }
  }

  return (
    <>
      {errorHandle()}
      <React.Fragment>
        {isLoading && <div className="loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>}
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell align="center">{(limit * (page - 1)) + list.findIndex((x: any) => x.id === item?.id) + 1}</TableCell>
          <TableCell align="center">{new Date(item?.dateTime).toLocaleString()}</TableCell>
          <TableCell align="center">{item?.upc}</TableCell>
          <TableCell align="center">{
            item?.status === 0 ? "Pending(0)" :
              item?.status === 1 ? "Pending(1)" :
                item?.status === 2 ? "Pending(2)" :
                  item?.status === 3 ? "Listed(3)" :
                    item?.status === 4 ? "Listed(4)" :
                      item?.status === 5 ? "Listed(5)" :
                        item?.status === 6 ? "Error(6)" :
                          item?.status === 7 ? "Color & Size Missing(7)" :
                            item?.status === 9 ? "Send To Store(9)" :
                              item?.status === 10 ? "Trash(10)" :
                                item?.status === 11 ? "Manifest Missing(11)" :
                                  item?.status === 14 ? "Taxonomy Not Found(14)" :
                                    item?.status === 20 ? "Pending Processing(20)" :
                                      null
          }</TableCell>
          <TableCell align="center">
            <div style={{ position: "relative" }}>
              <div className='txtWidth'>
                <div className='nonhoverDiv'>{item?.comments}</div>
                <div className='hoverDiv hovertxt'><p>{item?.comments}</p></div>
              </div>
            </div>
          </TableCell>
          <TableCell align="center">{item?.sku}</TableCell>
          <TableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              // onClick={() => {
              //   setOpen(!open);
              //   if (!open) {
              //     adminDashboardScannedProductReportCalled(startDate, endDate, item?.scanId, 0, 100)
              //   }
              // }
              // }
              onClick={() => {
                scannedProducAPiCall()
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow className='inner-table'>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Log
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Sr. No.</TableCell>
                      <TableCell align="center">Date & Time</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Comments</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adminDashboardScannedProductReportList ?
                      adminDashboardScannedProductReportList.map((value: any) => (
                        <TableRow key={value.dateTime}>
                          <TableCell align="center" component="th" scope="row">
                            {adminDashboardScannedProductReportList.findIndex((x: any) => x.dateTime === value?.dateTime) + 1}
                          </TableCell>
                          <TableCell align="center">{new Date(value?.dateTime).toLocaleString()}</TableCell>
                          <TableCell align="center">{
                            value?.status === 0 ? "Pending(0)" :
                              value?.status === 1 ? "Pending(1)" :
                                value?.status === 2 ? "Pending(2)" :
                                  value?.status === 3 ? "Listed(3)" :
                                    value?.status === 4 ? "Listed(4)" :
                                      value?.status === 5 ? "Listed(5)" :
                                        value?.status === 6 ? "Error(6)" :
                                          value?.status === 7 ? "Color & Size Missing(7)" :
                                            value?.status === 9 ? "Send To Store(9)" :
                                              value?.status === 10 ? "Trash(10)" :
                                                value?.status === 11 ? "Manifest Missing(11)" :
                                                  value?.status === 14 ? "Taxonomy Not Found(14)" :
                                                    value?.status === 20 ? "Pending Processing(20)" :
                                                      null}</TableCell>
                          <TableCell align="center">
                            <div style={{ position: "relative" }}>
                              <div className='txtWidth'>
                                <div className='nonhoverDiv'>{value?.comments}</div>
                                <div className='hoverDiv hovertxt'><p>{value?.comments}</p></div>
                              </div>
                            </div></TableCell>
                        </TableRow>
                      )) : null}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    </>
  );
}

export default function DashboardListingReportScreen() {
  var newdate = new Date()

  var dd: any = 1;
  var mm: any = newdate.getMonth() + 1;
  var yyyy = newdate.getFullYear();
  if (dd < 10) { dd = '0' + dd }
  if (mm < 10) { mm = '0' + mm };
  var firstDay = (mm + '-' + dd + '-' + yyyy);
  var newdate2 = new Date()
  newdate2.setDate(newdate2.getDate());
  var dd1: any = newdate2.getDate();
  var mm1: any = newdate2.getMonth() + 1;
  if (dd1 < 10) { dd1 = '0' + dd1 }
  if (mm1 < 10) { mm1 = '0' + mm1 };
  let yyyy1 = newdate2.getFullYear();
  const { adminDashboardLatestProductReport, adminDashboardScannedProductReportExport, adminDashboardLatestProductReportList, adminDashboardLatestProductReportListLength } = useDashboardAPI();
  const { setSelectedIndex, errorModalShow, isLoading, setErrorModalShow } = useAuth();
  const prefixId = localStorage.getItem("prefixIdForAdmin");
  const startDate = localStorage.getItem("StartDatePass")
  const endDate = localStorage.getItem("EndDatePass")
  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(50);
  const [openRow, setOpenRow] = React.useState("")
  const [page, setPage] = React.useState(1);
  let navigate = useNavigate()

  const [isNewExportLoader, setNewExportLoader] = React.useState(false)
  const [prefix, setPrefix] = React.useState("");
  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      onTapSearch();
    }
  };
  const onTapSearch = async () => {
    await adminDashboardLatestProductReport(startDate, endDate, prefixId, prefix, 0, limit, ststusFilter);
    setOffset(0)
    setLimit(50)
    setPage(1)
  };

  const onTapReset = async () => {
    setPage(1)
    setPrefix("");
    await adminDashboardLatestProductReport(startDate, endDate, prefixId, "", 0, limit, ststusFilter);
  };
  const loadIninData = async () => {
    await adminDashboardLatestProductReport(startDate, endDate, prefixId, "", 0, limit, ststusFilter);
  }
  React.useEffect(() => {
    loadIninData()
    setSelectedIndex(0)
    // getadminDashboardScannedProductReportExportCalled(prefixId ? prefixId : "",startDate ? startDate : "", endDate ? endDate : "", prefix, ststusFilter)
  }, []);

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };

  const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
    setOffset((value - 1) * limit);
    await adminDashboardLatestProductReport(startDate ? startDate : "", endDate ? endDate : "", prefixId ? prefixId : "", prefix, (value - 1) * limit, limit, ststusFilter);
    setPage(value);

  };

  const exportToExcel = async () => {
    setNewExportLoader(true)
    const res = await adminDashboardScannedProductReportExport(prefixId ? prefixId : "", startDate ? startDate : "", endDate ? endDate : "", prefix, ststusFilter)
    const url = URL.createObjectURL(new Blob([res?.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `ListingLog(${startDate}to${endDate}).xlsx`);
    document.body.appendChild(link);
    link.click();
    setNewExportLoader(false)
  }
  const statusList = [
    { value: 0, label: "UNDER SCANNING" },
    { value: 1, label: "READY TO PROCESS" },
    { value: 2, label: "SEND FOR PROCESSING" },
    { value: 3, label: "SHIPMENTS" },
    { value: 4, label: "SAME PREFIX" },
    { value: 5, label: "NEW SKU CREATED" },
    { value: 6, label: "ERROR" },
    { value: 7, label: "COLOR/SIZE MISSING" },
    { value: 8, label: "ALL" },
    { value: 11, label: "MANIFEST MISSING" },
    { value: 14, label: "TAXONOMY NOT FOUND" },
    { value: 20, label: "PENDING PROCESSING" },
  ]
  const [ststusFilter, setCurrentVal] = React.useState("8");
  const [filterName, setFilterName] = React.useState("")
  const onStatusChange = (v: any) => {
    setCurrentVal(v?.value);
    setFilterName(v?.label);
  };
  const applyFilter = async () => {
    await adminDashboardLatestProductReport(startDate ? startDate : "", endDate ? endDate : "", prefixId, prefix, 0, limit, ststusFilter);
    setOffset(0)
    setLimit(50)
    setPage(1)
  }
  const resetFilter = async () => {
    setFilterName("Select Status")
    setCurrentVal("8")
    setPrefix("")
    setOffset(0);
    setPage(1)
    await adminDashboardLatestProductReport(startDate, endDate, prefixId, "", 0, limit, "8");
  }
  return (
    <>
      {errorHandle()}
      <Header />
      <Box className="contentMain"
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <div className=''>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" onClick={() => navigate(-1)}>Dashboard</Link>
              <Link
                underline="hover"
                color="text.primary"
                aria-current="page">
                Dashboard Listings
              </Link>
            </Breadcrumbs>
          </div>
          <div className='startMain'>
            <div className='d-flex align-items-center'>
              <div className="search-input serchContainer d-inline-flex">
                <Search className='searchIcon' />
                <input type="search" placeholder="Search" value={prefix}
                  onKeyPress={(event) => handleKeypress(event)}
                  onChange={(event) => setPrefix(event.target.value)} />
                <Close className="pointer" onClick={() => onTapReset()} />
              </div>
              {/* new code */}
              <div className='d-flex align-items-center filterContainer ms-3'>
                <div className='leftDiv filterItem'>
                  <img src={filter} className="mRight" alt="Filter" />
                  <h5 className='m-0 text'>Filters</h5>
                </div>
                <div className='borderDiv filterItem'>
                  <label className='w-100 formLabel'>Status</label>
                  <Dropdown className='outlinegreenBtn'>
                    <Dropdown.Toggle variant="Warning" id="dropdown-basic" className="outlinedInput">{filterName === "" ? "Select Status" : filterName}</Dropdown.Toggle>
                    <Dropdown.Menu className="dropMenug">
                      {statusList?.map((v: any) => {
                        return (
                          <Dropdown.Item className="clickItem" onClick={() => onStatusChange(v)} eventKey={v?.value}>{v?.label}</Dropdown.Item>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <Tooltip TransitionComponent={Zoom} title="Reset" arrow placement="top">
                <ResetFilter className='ms-3 pointer' onClick={() => resetFilter()} />
              </Tooltip>
            </div>
            <div className='d-flex justify-content-end w-50'>
              <button onClick={(e) => applyFilter()} className="FilledBtn me-3">
                Apply
              </button>
              {!isNewExportLoader ?
                <button onClick={(e) => exportToExcel()} className="FilledBtn">
                  Export
                </button>
                :
                <button onClick={(e) => exportToExcel()} className="FilledBtn" style={{ cursor: "not-allowed", background: "gray" }}>
                  Exporting data...
                </button>
              }
            </div>
            {/* new code end*/}
          </div>

          {/* table start */}
          <div className='heightG'>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 385 }} component={Paper} className="scanned-table-container">
                <Table stickyHeader aria-label="collapsible table" className='tableH'>
                  <TableHead className='thead'>
                    <TableRow>
                      <TableCell align="center">Sr. No.</TableCell>
                      <TableCell align="center">Date & Time</TableCell>
                      <TableCell align="center">UPC</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Comments</TableCell>
                      <TableCell align="center">SKU</TableCell>
                      <TableCell align="center">Log</TableCell>
                    </TableRow>
                  </TableHead>
                  {!isLoading ?
                    <TableBody className='tbody'>
                      {adminDashboardLatestProductReportList?.length > 0 ?
                        adminDashboardLatestProductReportList.map((item: any) => (
                          <Row key={item.id} open={item?.scanId == openRow} page={page} limit={limit} item={item} onClickRow={(item: any) => setOpenRow(item)} list={adminDashboardLatestProductReportList} />
                        ))
                        :
                        <tr>
                          <td colSpan={7}>
                            <p className='mb-0 py-2'>No records found</p>
                          </td>
                        </tr>
                      }
                    </TableBody>
                    :
                    <Loader />
                  }
                </Table>
              </TableContainer>
            </Paper>
          </div>
          <div className='d-flex paginationSect align-items-center justify-content-between'>
            <Stack spacing={2} className="d-flex flex-row align-items-center">
              <label className='formLabel'>Showing {adminDashboardLatestProductReportListLength > 0 ? offset + 1 : 0} to {adminDashboardLatestProductReportListLength > (offset + limit) ? (offset + limit) : adminDashboardLatestProductReportListLength} of {adminDashboardLatestProductReportListLength} entries</label>
              <Pagination className="m-0 paginatnNav" size="small" shape="rounded" count={Math.ceil(adminDashboardLatestProductReportListLength / limit)} page={page} onChange={handleChange} />
            </Stack>

          </div>
        </div>

      </Box>
    </>
  );
}
