import React, { useState, useEffect } from "react";
import { Button, Spinner, Modal, Pagination } from "react-bootstrap";
import CreateUserModal from "../../components/createUserModal/CreateUserModal";
import CreateWarehouseModal from "../../components/createWarehouseModal/CreateWarehouseModal";
import { useAuth } from "../../shared/provider/authprovider";
import Logo from "../../assets/resale-logo.svg";
import './DataManagementScreen.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Header from '../../components/header/Header';
import { useWarehousesAPI } from "../../shared/api/warehouse";
import { useUsersAPI } from "../../shared/api/user";

const DataManagementScreen = () => {
  const [warehousrModalShow, setWarehouseModalShow] = useState(false);
  const [warehouseList, setWarehouseList] = useState<any[]>([]);
  const [addUserModal, setAddUserModal] = useState(false);
  const [warehouseListOffset, setWarehouseListOffset] = useState(0);
  const [warehouseListLimit, setWarehouseListLimit] = useState(1000);
  const [userListOffset, setUserListOffset] = useState(0);
  const [userListLimit, setUserListLimit] = useState(1000);
  const [removeWarehouseModalShow, setRemoveWarehouseModalShow] = useState(false);
  const [removePrefixModalShow, setRemovePrefixModalShow] = useState(false);
  const [removeUserModalShow, setRemoveUserModalShow] = useState(false);
  const [addPrefixModalShow, setAddPrefixModalShow] = useState(false);
  const [editPrefixModalShow, setEditPrefixModalShow] = useState(false);
  const [editUserModalShow, setEditUserModalShow] = useState(false);
  const [editWarehouseModalShow, setEditWarehouseModalShow] = useState(false);
  const [warehouseId, setWarehouseId] = useState("");
  const [warehouseName, setWareHouseName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("")
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [showAddPrefix, setShowAddPrefix] = useState(false);
  const [prefix, setPrefix] = useState("");
  const [pageCountForWarehousList, setPageCountForWarehousList] = useState(1);
  const [prefixId, setPrefixId] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [prefixListByWarehouseId, setPrefixListByWarehouseId] = useState<any | null>(null);
  const { getUserListCalled, userList, isLoading, setErrorModalShow, setErrorMsg, getWarehouseListCalled } = useAuth();
  const { deleteWarehouse, editWarehousePrefix, createWarehousePrefix, getWarehousePrefixByWarehouseId,
    deleteWarehousePrefix, editWarehouseDetails } = useWarehousesAPI()
  const { editUser, deleteUser } = useUsersAPI()
  const loadInitialData = async () => {
    await getWarehouseListCalled('', 0, 1000)
  }
  useEffect(() => {
    loadInitialData();
    getUserListFun()
  }, []);



  const getUserListFun = async () => {
    await getUserListCalled("", userListOffset, userListLimit);
  };

  const onTapCreateWarehouse = () => {
    setWarehouseModalShow(true);
  };

  const onTapAddUser = () => {
    setAddUserModal(true);
  };

  const renderRemoveWarehouseModal = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={removeWarehouseModalShow}
        onHide={() => {
          setRemoveWarehouseModalShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle">
            Remove Warehouse
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fieldTitle">Are you sure you want to remove warehouse: {warehouseName}</p>
        </Modal.Body>
        <div className="footerDiv">
          <button className="signInBtn btn" onClick={() => onTapRemoveBtn()}>Remove</button>
        </div>
      </Modal>
    )
  };

  const onTapRemoveWarehouseBtn = (id: any, name: any) => {
    setRemoveWarehouseModalShow(true);
    setWarehouseId(id);
    setWareHouseName(name);
  };

  const onTapRemoveBtn = async () => {
    const res = deleteWarehouse(warehouseId);
    await getWarehouseListCalled('', 0, 1000)
    setRemoveWarehouseModalShow(false);
  };

  const renderEditUserDetailsModal = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={editUserModalShow}
        onHide={() => {
          setEditUserModalShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle">
            Edit User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fieldTitle">First Name</p>
          <input value={firstName} className="inputField" placeholder="Enter First Name" onChange={(event) => setFirstName(event.target.value)} />
          <p className="fieldTitle">Last Name</p>
          <input value={lastName} className="inputField" placeholder="Enter Last Name" onChange={(event) => setLastName(event.target.value)} />
          <p className="fieldTitle">Mobile No.</p>
          <input value={mobileNumber} className="inputField" placeholder="Enter Mobile No." onChange={(event) => setMobileNumber(event.target.value)} />
          <p className="fieldTitle">Email Id</p>
          <input value={email} className="inputField" placeholder="Enter Email Id" onChange={(event) => setEmail(event.target.value)} />
        </Modal.Body>
        <div className="footerDiv">
          <button className="signInBtn" onClick={() => onTapSaveEditUserDetailsBtn()} >Save</button>
        </div>
      </Modal>
    )
  };

  const onTapEditUserBtn = (data: any) => {
    setEditUserModalShow(true);
    setFirstName(data?.firstName);
    setLastName(data?.lastName);
    setMobileNumber(data?.mobileNumber);
    setEmail(data?.email);
    setUserId(data?.id);
  };

  const onTapSaveEditUserDetailsBtn = async () => {
    let details = {
      "email": email,
      "firstName": firstName,
      "lastName": lastName,
      "mobileNumber": mobileNumber
    }
    const resp = await editUser(details, userId);
    if (resp?.data?.status == 200) {
      getUserListFun()
      setErrorModalShow(true);
      setErrorMsg("User Details Edited Successfully");
    }
    setEditUserModalShow(false);
    
  };

  const renderRemoveUserModal = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={removeUserModalShow}
        onHide={() => {
          setRemoveUserModalShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle">
            Remove User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fieldTitle">Are you sure you want to remove user: {firstName} {lastName}</p>
        </Modal.Body>
        <div className="footerDiv">
          <button className="signInBtn btn" onClick={() => tapRemoveUserBtn()} >Remove</button>
        </div>
      </Modal>
    )
  };

  const onTapRemoveUserBtn = (data: any) => {
    setRemoveUserModalShow(true);
    setFirstName(data?.firstName);
    setLastName(data?.lastName);
    setUserId(data?.id);
  };

  const tapRemoveUserBtn = async () => {
    const resp = await deleteUser(userId);
    if (resp?.data?.status == 200) {
      getUserListFun()
    }
    setRemoveUserModalShow(false);
  };

  const renderAddPrefixModal = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={addPrefixModalShow}
        onHide={() => {
          setAddPrefixModalShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle">
            {warehouseName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="fieldTitle">Prefix List</h5>
          <div className="field-list-box">
            {prefixListByWarehouseId ?
              prefixListByWarehouseId?.map((data: any) => {
                return (

                  <div className="field-list d-flex justify-content-between align-items-center">
                    <label>{data?.prefixName}</label>
                    <div className="edit-dlt-btns">
                      <button onClick={() => { onTapEditPrefixBtn(data) }} className=" me-3"><EditIcon className="icon editicon" /></button>
                      <button onClick={() => { onTapRemovePrefix(data) }}><DeleteIcon className="icon deleteIcon" /></button>
                    </div>

                  </div>

                );

              }) : <label>No Records</label>}
          </div>
          <hr />

          <Button className="d-block m-auto" onClick={() => onTapAddPrefix()}>Add New Prefix</Button>
          {showAddPrefix ?
            <div className="d-flex align-items-center add-prefix-sect">
              <input className="inputField w-100" onChange={(event) => { setPrefix(event.target.value) }} placeholder="Enter New Prefix Name/ No." />
              <Button onClick={() => onTapSavePrefix()}>Save</Button>
            </div> : null}
        </Modal.Body>
      </Modal>
    )
  };

  const onTapPrefixBtn = async (data: any) => {
    const res = await getWarehousePrefixByWarehouseId(data?.wareHouse?.id);
    setPrefixListByWarehouseId(res?.data?.results)
    setAddPrefixModalShow(true);
    setWareHouseName(data?.wareHouse?.name);
    setWarehouseId(data?.wareHouse?.id);
  };

  const onTapAddPrefix = () => {
    setShowAddPrefix(!showAddPrefix);
  };

  const onTapSavePrefix = async () => {
    let details = {
      "prefixName": prefix,
      "warehouseIdDto": {
        "id": warehouseId
      }
    }
    const resp = await createWarehousePrefix(details);
    if (resp?.data?.status == 200) {
      await getWarehouseListCalled('', 0, 1000)
      setErrorModalShow(true);
      setErrorMsg("Prefix Added Successfully");
    }
    setAddPrefixModalShow(false);
  };

  const onTapEditPrefixBtn = (data: any) => {
    setEditPrefixModalShow(true);
    setPrefixId(data?.id);
    setPrefix(data?.prefixName);
  };

  const onTapRemovePrefix = (data: any) => {
    setRemovePrefixModalShow(true);
    setPrefix(data?.prefixName);
    setPrefixId(data?.id);
  };

  const onTapUpdatePrefix = async () => {
    let details = {
      "prefixName": prefix
    }
    const resp = await editWarehousePrefix(details, prefixId);
    if (resp?.data?.status == 200) {
      await getWarehouseListCalled('', 0, 1000)
      setRemovePrefixModalShow(false);
      setErrorModalShow(true);
      setErrorMsg("Prefix Edited Successfully");
    }
    setEditPrefixModalShow(false);
    const res = await getWarehousePrefixByWarehouseId(warehouseId);
    setPrefixListByWarehouseId(res?.data?.results)

  };

  const renderEditPrefixModal = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={editPrefixModalShow}
        onHide={() => {
          setEditPrefixModalShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle">
            Edit Prefix
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fieldTitle">Prefix</p>
          <input value={prefix} className="inputField" placeholder="Prefix" onChange={(event) => setPrefix(event.target.value)} />
        </Modal.Body>
        <div className="footerDiv">
          <Button onClick={() => onTapUpdatePrefix()} className="signInBtn" >Update</Button>
        </div>
      </Modal>
    )
  };

  const renderRemovePrefixModal = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={removePrefixModalShow}
        onHide={() => {
          setRemovePrefixModalShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle">
            Remove Prefix
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fieldTitle">Are you sure you want to remove prefix: {prefix}</p>
        </Modal.Body>
        <div className="footerDiv">
          <Button className="signInBtn" onClick={() => onTapRemovePrefixBtn()}>Remove</Button>
        </div>
      </Modal>
    )
  };

  const onTapRemovePrefixBtn = async () => {
    const response = await deleteWarehousePrefix(prefixId);
    await getWarehouseListCalled('', 0, 1000)
    setRemovePrefixModalShow(false);
  };

  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }

  const renderEditWarehouseModal = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={editWarehouseModalShow}
        onHide={() => {
          setEditWarehouseModalShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle">
            Edit Warehouse Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fieldTitle">Warehouse Name</p>
          <input value={warehouseName} className="inputField" placeholder="Enter Warehouse Name" onChange={(event) => setWareHouseName(event.target.value)} />
          <p className="fieldTitle">Location</p>
          <input value={location} className="inputField" placeholder="Enter Location" onChange={(event) => setLocation(event.target.value)} />
          <p className="fieldTitle">Description</p>
          <input value={description} className="inputField" placeholder="Enter Description" onChange={(event) => setDescription(event.target.value)} />
        </Modal.Body>
        <div className="footerDiv">
          <button onClick={() => onTapUpdateWarehouseDetails()} className="signInBtn">Update</button>

        </div>
      </Modal>
    );
  }

  const onTapEditWarehouseBtn = (data: any) => {
    setEditWarehouseModalShow(true);
    setWareHouseName(data?.wareHouse?.name);
    setLocation(data?.wareHouse?.location);
    setDescription(data?.wareHouse?.description);
    setWarehouseId(data?.wareHouse?.id);
  };

  const onTapUpdateWarehouseDetails = async () => {
    let details = {
      "name": warehouseName,
      "description": description,
      "location": location
    }
    const resp = await editWarehouseDetails(details, warehouseId);
    if (resp?.data?.status == 200) {
      await getWarehouseListCalled('', 0, 1000)
      setRemovePrefixModalShow(false);
      setErrorModalShow(true);
      setErrorMsg("Warehouse Details Edited Successfully");
    }
    setEditWarehouseModalShow(false);
  }

  return (
    <div className="App">
      {renderRemoveWarehouseModal()}
      {renderEditUserDetailsModal()}
      {renderRemoveUserModal()}
      {renderAddPrefixModal()}
      {renderEditPrefixModal()}
      {renderRemovePrefixModal()}
      {renderEditWarehouseModal()}
      <CreateWarehouseModal
        show={warehousrModalShow}
        onHide={async () => {
          setWarehouseModalShow(false);
          await getWarehouseListCalled('', 0, 1000)
        }}
      />
      <CreateUserModal
        show={addUserModal}
        onHide={() => {
          setAddUserModal(false);
          getUserListFun()
        }}
      />
      <Header />
      <div className="container">
        {isLoading && <div className="loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>}
        <div className="adminDashboard row">
          <div className="col-lg-6 paddingG">
            <div className="warehouseL">
              <h5>Warehouse List</h5>
              <Button className="addD" onClick={() => onTapCreateWarehouse()}>Add Warehouse</Button>
            </div>
            {warehouseList ? warehouseList?.map((data: any) => {
              return (
                <div
                  className="warehousedetail"
                  key={data.id}
                >
                  <h6 className="firstN">{data?.wareHouse?.name}</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <label>{data?.wareHouse?.location}</label>
                    <button onClick={() => onTapPrefixBtn(data)} className="enterBtn">Prefix</button>
                    <div className="edit-dlt-btns">
                      <button className=" me-3" onClick={() => onTapEditWarehouseBtn(data)} ><EditIcon className="icon editicon" /></button>
                      <button><DeleteIcon onClick={() => onTapRemoveWarehouseBtn(data?.wareHouse?.id, data?.wareHouse?.name)} className="icon deleteIcon" /></button>
                    </div>
                    {/* <button onClick={() => onTapRemoveWarehouseBtn(data?.wareHouse?.id, data?.wareHouse?.name)} className="enterBtn">Remove</button> */}
                  </div>
                </div>
              );
            }) : null}
            {/* <Pagination>
              <Pagination.Prev />
              <Pagination.Item>{pageCountForWarehousList}</Pagination.Item>
              <Pagination.Item>{pageCountForWarehousList + 1}</Pagination.Item>
              <Pagination.Item active>{pageCountForWarehousList + 2}</Pagination.Item>
              <Pagination.Next onClick={() => pageCountForWarehousList + 3} />
            </Pagination> */}


          </div>
          <div className="col-lg-6 paddingG">
            <div className="warehouseL">
              <h5>User List</h5>
              <button className="addD" onClick={() => onTapAddUser()}>Add User</button>
            </div>
            {userList ? userList?.map((data: any) => {
              return (
                <div
                  className="warehousedetail"
                  key={data.id}
                >
                  <h6 className="firstN">{data.firstName + " " + data.lastName}</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <label>{data.email}</label>
                    <div className="edit-dlt-btns">
                      <button onClick={() => onTapEditUserBtn(data)} className=" me-3"><EditIcon className="icon editicon" /></button>
                      <button><DeleteIcon onClick={() => onTapRemoveUserBtn(data)} className="icon deleteIcon" /></button>
                    </div>
                  </div>
                </div>
              );
            }) : null}
            {/* <Pagination>
              <Pagination.Prev />
              <Pagination.Item>{pageCountForWarehousList}</Pagination.Item>
              <Pagination.Item>{pageCountForWarehousList + 1}</Pagination.Item>
              <Pagination.Item active>{pageCountForWarehousList + 2}</Pagination.Item>
              <Pagination.Next onClick={() => pageCountForWarehousList + 3} />
            </Pagination> */}

          </div>
        </div>
      </div>
    </div>

  );
};

export default DataManagementScreen;
