import { useEffect, useState } from "react";
import { Button, Spinner } from 'react-bootstrap';
import Logo from '../../assets/resale-full-logo.svg';
import LoginImage from "../../assets/sign-in-illustration.svg";
import { useAuth } from '../../shared/provider/authprovider';
import { useNavigate } from "react-router-dom";
import ErrorModal from '../../components/errorModal/ErrorModal';
import { useUsersAPI } from "../../shared/api/user";

const ForgotPasswordScreen = () => {

  const {  isLoading, forgotPasswordError, setForgotPasswordError, errorModalShow,
    setErrorModalShow, setErrorMsg } = useAuth();
    const {forgotPassword} = useUsersAPI()
  const [emailId, setEmailId] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    setForgotPasswordError("")
  }, [])

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };
  const onTapSendOtp = async() => {
    if (emailId !== "") {
      let details = {
        email: emailId
      }
      const resp = await forgotPassword(details);
      if(resp?.data?.status==200){
        setForgotPasswordError("If this email address was used to create an account, instructions to reset your password will be sent to you. Please check your email.")  
      }
    } else {
      // alert("Please Enter Email Id")
      setErrorModalShow(true);
      setErrorMsg("Please Enter Email Id");
    }
  };

  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      onTapSendOtp();
    }
  };

  const onTapCreatePassword = () => {
    navigate("/createpassword")
  }

  return (
    <>
      {errorHandle()}
      <div className='mainDiv forget-pwd-div'>
        {isLoading && <div className="loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>}
        {/* <div className="loginL">
        <img className='logo' src={Logo} alt="Logo" />
      </div> */}
        <div className="container padding-top-btm">
          <div className="row align-items-center flex-grow-1">
            <div className="col-lg-6 col-md-6 col-sm-12 loginImg text-center">

              <img className="loginSide img-fluid" src={LoginImage} />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="sign-in-form-sect">
                <div className="brand-section d-flex flex-column align-items-center">
                  <div className="brand text-center">
                    <a className="navbar-brand mr-0" href="#">
                      <img className='logo' src={Logo} alt="Logo" />
                    </a>
                  </div>
                  <h5>Warehouse <span>Utilities</span></h5>
                  {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p> */}
                </div>
                <div className="loginSec">
                  <h6 className="log-in">Forgot Password</h6>
                  <label className="forgot-pwd-error">{forgotPasswordError}</label>
                  <div className='inputFieldDiv'>
                    <input className="input-field" value={emailId} placeholder='Enter Email Id' onChange={(event) => setEmailId(event.target.value)} onKeyPress={(event) => handleKeypress(event)} />
                    <div className="btn-div d-flex">
                      <Button className="same-w-btn submitBtn" onClick={() => onTapSendOtp()}>Send Email</Button>
                      {/* <Button className="same-w-btn submitBtn" onClick={() => onTapCreatePassword()}>Create Password</Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6 loginImg">
          <img className="loginSide" src={LoginImage} />
        </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPasswordScreen;