import axios, { AxiosPromise } from "axios";
import { useAuth } from "../provider/authprovider";
// import { environmentUrl } from "../utils/interceptor";

export var environmentUrl = "https://app-dev.resale.com"
export var environmentUrlSaaS =  "https://app-dev.resale.com"
// export var environmentUrl = "https://50e3-49-248-88-42.ngrok-free.app"
// var environmentUrl = "http://ec2-3-144-159-125.us-east-2.compute.amazonaws.com:81"
// var environmentUrl = "https://c872-103-170-42-102.ngrok.io"
if (process.env.NODE_ENV === "production") {
  environmentUrl =
    process.env.REACT_APP_BACKEND_API_URI !== undefined
      ? process.env.REACT_APP_BACKEND_API_URI
      // : "https://4f6f-103-179-39-200.ngrok.io/";
      : environmentUrl;
}

export const useConnectionStatusAPI = () => {
  const { api, handleError, setErrorModalShow, setErrorMsg } = useAuth()
  const putActivateConnection = async (id: any) => {
    try {
      const result = await api.put(`/api/MasterDataContrroller/ActivateToken/${id}`);
      if (result?.data?.status == 200) {
        setErrorModalShow(true);
        setErrorMsg("Deactivated Successfully");
      }
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  const putDeactivateConnection = async (id: any) => {
    try {
      const result = await api.put(`/api/MasterDataContrroller/DeactivateToken/${id}`);
      if (result?.data?.status == 200) {
        setErrorModalShow(true);
        setErrorMsg("Activated Successfully");
      }
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  return { putActivateConnection, putDeactivateConnection } as const
}
