import { Box } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Field, FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from "react";
import * as Yup from 'yup';
import ErrorModal from "../../components/errorModal/ErrorModal";
import Header from "../../components/header/Header";
import Loader from "../../loader";
import { useAuth } from "../../shared/provider/authprovider";
import CustomSelect from "./CustomSelect";
import { ReactComponent as Search } from "../../../src/assets/icons/searchStatus.svg";
import { ReactComponent as Close } from "../../../src/assets/icons/close.svg";
import { useTaxonomyNotFoundAPI } from "../../shared/api/taxonomyNotFound";


const TaxonomyNotFoundScreen = () => {
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20)
    const [prefix, setPrefix] = useState("");
    const { isLoading, setSelectedIndex, errorModalShow, setErrorModalShow, setErrorMsg,
        exportTaxonomyNotFoundCalled } = useAuth()
    const { exportTaxonomyNotFound, getTaxonomyNotFoundRecords, taxonomyNotFoundList, taxonomyMissingProductsCount,
        getTaxonomyNotFoundRecordsDropdown, taxonomyNFDropdown, putTaxonomyNotFoundRecordsUpdate } = useTaxonomyNotFoundAPI()
    // const DisplayingErrorMessagesSchema = Yup.object().shape({
    //     // Taxonomyform: Yup.array().min(1, "Required").nullable(),
    //     Taxonomyform: Yup.array().of(Yup.object().shape({
    //         taxonomyReference: Yup.string().required("Required").nullable(),

    //     })),
    // });
    const [isNewExportLoader, setNewExportLoader] = useState(false)

    const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setOffset((value - 1) * limit);
        await getTaxonomyNotFoundRecords((value - 1) * limit, limit, prefix)
        setPage(value);
    };
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    const formik: any = useFormik({
        initialValues: {
            Taxonomyform: [
            ],
            dropdown: ''
        },
        // validationSchema: DisplayingErrorMessagesSchema,
        onSubmit: () => submitData(),
    })

    const fetchData = async () => {
        await getTaxonomyNotFoundRecords(offset, limit, prefix);
        getTaxonomyNotFoundRecordsDropdown(0, 100)
    };

    const submitData = async () => {
        console.log("formik", formik)
    }
    const updateProductsScreen = async () => {
        formik.handleSubmit()
        console.log("Value1234", formik.values.Taxonomyform.filter((v: any) => v.taxonomyReference != null))
        await putTaxonomyNotFoundRecordsUpdate(formik.values.Taxonomyform.filter((v: any) => v.taxonomyReference != null));
        getTaxonomyNotFoundRecords(0, limit, prefix);
        setPage(1)
    }
    useEffect(() => {
        fetchData()
        setSelectedIndex(10)
    }, [])

    const handleKeypress = (e: any) => {
        if (e.key === "Enter") {
            onTapSearch();
        }
    };
    const onTapSearch = () => {
        setOffset(0)
        setPage(1)
        getTaxonomyNotFoundRecords(0, limit, prefix);
    };

    const onTapReset = () => {
        setPrefix("");
        getTaxonomyNotFoundRecords(offset, limit, "");
    };
    // const exportToExcel = async () => {
    //     await exportTaxonomyNotFoundCalled(prefix)
    // }
    const exportToExcel = async () => {
        setNewExportLoader(true)
        const res = await exportTaxonomyNotFound(prefix)
        const url = URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', prefix?.length > 1 ? `Taxonomy-Not-Found-List(${prefix}).xlsx` : `Taxonomy-Not-Found-List.xlsx`);
        document.body.appendChild(link);
        link.click();
        setNewExportLoader(false)
    }

    useEffect(() => {
        if (taxonomyNotFoundList && taxonomyNFDropdown && taxonomyNFDropdown.length) {
            formik.setFieldValue('Taxonomyform', taxonomyNotFoundList)
            formik.setFieldValue('dropdown', taxonomyNFDropdown)
        }
    }, [taxonomyNotFoundList, taxonomyNFDropdown])


    return (
        <div>
            {errorHandle()}
            <Header />
            <Box
                className="contentMain updateProdWrapper guidedProdWrapper"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <div className='startMain'>
                    <div>
                        <h5 className="pageHead mb-0 me-2">Taxonomy Update</h5>
                        <p>Remaining: {taxonomyMissingProductsCount ? taxonomyMissingProductsCount : ''}</p>
                    </div>

                    <div className='d-flex justify-content-end w-50 '>
                        <div className="search-input serchContainer d-inline-flex me-3">
                            <Search className='searchIcon' />
                            <input type="search" placeholder="Search by prefix" value={prefix}
                                onKeyPress={(event) => handleKeypress(event)}
                                onChange={(event) => setPrefix(event.target.value)} />
                            <Close className="pointer" onClick={() => onTapReset()} />
                        </div>
                        <div className="me-3">
                            {!isNewExportLoader ?
                                <button onClick={(e) => exportToExcel()} className="FilledBtn">
                                    Export
                                </button>
                                :
                                <button onClick={(e) => exportToExcel()} className="FilledBtn" style={{ cursor: "not-allowed", background: "gray" }}>
                                    Exporting data...
                                </button>
                            }
                        </div>
                        <button className="FilledBtn me-3" onClick={() => updateProductsScreen()}>Update</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className='heightG'>
                            <FormikProvider value={formik} >
                                <table className='tableH'>
                                    <thead className='thead rounded'>
                                        <tr>
                                            <td>Sr. No.</td>
                                            <td>Image</td>
                                            <td>UPC</td>
                                            <td>Prefix</td>
                                            <td>Title</td>
                                            {/* <td>UPC</td> */}
                                            <td>Taxonomy Reference</td>
                                        </tr>
                                    </thead>
                                    {!isLoading ?
                                        <tbody className='tbody'>
                                            <FieldArray name="form">
                                                {(arrayHelper:any) => {
                                                    const { push, remove, form } = arrayHelper
                                                    const { values } = form
                                                    const { Taxonomyform } = values
                                                    // console.log("Vidya", Taxonomyform)
                                                    return (
                                                        <>
                                                            {taxonomyMissingProductsCount > 0 ?
                                                                Taxonomyform?.map(
                                                                    (item: any, index: any) => (
                                                                        <tr>
                                                                            <td>{(limit * (page - 1)) + Taxonomyform?.findIndex((x: any) => x?.id === item?.id) + 1}</td>
                                                                            <td><div className="imgCircle"><img className="img-fluid" src={item.imageUrl} /></div></td>
                                                                            <td>{item.upc}</td>
                                                                            <td>{item?.prefix}</td>
                                                                            <td>{item.title}</td>
                                                                            {/* <td>{item.upc}</td> */}
                                                                            <td className="taxonomyRefCol">
                                                                                <Field name={`Taxonomyform[${index}].taxonomyReference`} id="Taxonomyform.taxonomyReference">
                                                                                    {({ field: { value }, form: { setFieldValue } }: any) => (
                                                                                        <CustomSelect
                                                                                            value={''}
                                                                                            onChange={(value: any) => setFieldValue(`Taxonomyform[${index}].taxonomyReference`, value.taxonomyName)}
                                                                                            options={formik?.values?.dropdown} />
                                                                                    )}
                                                                                </Field>
                                                                                {/* {<span className="text-danger">{formik.errors?.Taxonomyform && formik.touched?.Taxonomyform && formik.touched?.Taxonomyform[index]?.taxonomyReference && formik.errors?.Taxonomyform[index]?.taxonomyReference}</span>} */}
                                                                                {/* {console.log("formik?.values", formik?.values)} */}

                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )
                                                                :
                                                                <tr>
                                                                    <td colSpan={7}>
                                                                        <p className='mb-0 py-2'>No records found</p>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </>
                                                    )
                                                }}

                                            </FieldArray>
                                        </tbody>
                                        :
                                        <Loader />
                                    }
                                </table>
                            </FormikProvider>
                        </div>
                    </div>
                    <div className='d-flex paginationSect align-items-center justify-content-between'>
                        <Stack spacing={2} className="d-flex flex-row align-items-center">
                            <label className='formLabel'>Showing {taxonomyMissingProductsCount > 0 ? offset + 1 : 0} to {taxonomyMissingProductsCount > (offset + limit) ? (offset + limit) : taxonomyMissingProductsCount} of {taxonomyMissingProductsCount} entries</label>
                            <Pagination className="m-0 paginatnNav" size="small" shape="rounded" count={Math.ceil(taxonomyMissingProductsCount / limit)} page={page} onChange={handleChange} />
                        </Stack>

                    </div>
                </div>
            </Box >
        </div >
    )
}
export default TaxonomyNotFoundScreen

