import { Box, IconButton, Snackbar, Stack, Typography } from "@mui/material"
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import { ReactComponent as SuccessLogo } from "../../../assets/images/icons/success-logo.svg";
import CloseIcon from "@mui/icons-material/Close";

const MessagePopup = () => {
    const { setOpen, messageText, open, shortMessage } = useClientAPI()
    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );
    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{ backgroundColor: "#fff" }}
                    >
                        <SuccessLogo />
                        <Stack ml={1.5}>
                            <Typography variant="h6" fontSize={12} fontWeight={500}>
                                {shortMessage}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="#000"
                                fontWeight={300}
                                fontSize={12}
                            >
                                {messageText}
                            </Typography>
                        </Stack>
                    </Box>}
                action={action}
            />
        </>
    )
}
export default MessagePopup