import { useAuth } from "../provider/authprovider";

export const useWinServicesAPI = () => {
    const { setLoading, api, handleError } = useAuth()
    const getWinServices = async (offset: any, limit: any) => {
        setLoading(true)
        try {
            const result = await api.get(`/api/Win/CronList?Offset=${offset}&Limit=${limit}`);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally {
            setLoading(false)
        }
    };
    const getWinServicesByName = async (id: any, offset: any, limit: any, startDate: any, endDate: any) => {
        setLoading(true)
        try {
            const result = await api.get(`/api/Win/WinServiceLog/${id}?offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally {
            setLoading(false)
        }
    };
    const getWinServicesDetails = async (index: any, offset: any, limit: any, prefix: any, startDate: any, endDate: any) => {
        setLoading(true)
        try {
            const result = await api.get(`/api/Win/TransactionLogs/${index}?offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&prefix=${prefix}`);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally {
            setLoading(false)
        }
    };
    const getTransactionLogsErrors = async (id: any, offset: any, limit: any, prefix: any, startDate: any, endDate: any) => {
        setLoading(true)
        try {
            const result = await api.get(`/api/Win/TransactionLogs/Error/${id}?offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&prefix=${prefix}`);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally {
            setLoading(false)
        }
    };
    const getErrorWinServicesDetails = async (index: any, offset: any, limit: any, startDate: any, endDate: any) => {
        setLoading(true)
        try {
            const result = await api.get(`/api/Win/WinLogs/IsError/${index}?offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally {
            setLoading(false)
        }
    };
    const putReolveCheckedErros = async (selected: any) => {
        setLoading(true)
        try {
            const result = await api.put(`/api/Win/TransactionLog/ResolveError`, selected);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally {
            setLoading(false)
        }
    };
    const putWinServicesSchedule = async (id: any, Schedule: any) => {
        try {
            const result = await api.put(`/api/Win/CronList/${id}`, Schedule);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const putWinServicesRun = async (id: any) => {
        try {
            const result = await api.put(`/api/Win/CronList/${id}/run`);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const putWinServicesInactive = async (id: any) => {
        try {
            const result = await api.put(`/api/Win/CronList/${id}/deactivate`);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const putWinServicesActive = async (id: any) => {
        try {
            const result = await api.put(`/api/Win/CronList/${id}/activate`);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    return {
        getWinServicesByName, getWinServicesDetails, getTransactionLogsErrors, getErrorWinServicesDetails, putReolveCheckedErros,
        putWinServicesSchedule, putWinServicesRun, putWinServicesInactive, putWinServicesActive, getWinServices
    } as const
}