import axios, { AxiosPromise } from "axios";
import { environmentUrl } from "./index.service";
export const scanItems = (details: any, token: any): AxiosPromise<any> => {
    return axios.post(environmentUrl + "/api/Scan", details, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const editScanItems = (
    details: any,
    id: any,
    token: any
): AxiosPromise<any> => {
    return axios.put(
        environmentUrl + `/api/Scan/Product/${id}`,
        details,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export const deleteScanItems = (
    details: any,
    id: any,
    token: any
): AxiosPromise<any> => {
    return axios.put(
        environmentUrl + `/api/Scan/${id}`,
        details,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export const getNonProcessedScanProducts = (token: any): AxiosPromise<any> => {
    let url = environmentUrl + "/api/Scan/NonProcessedProducts";
    const scanProducts = axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return scanProducts;
};
export const getReadyToProcessScanProducts = (
    token: any
): AxiosPromise<any> => {
    let url = environmentUrl + "/api/Scan/ReadyToProcess";
    const scanProducts = axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return scanProducts;
};
export const getSentToProcessScanProducts = (token: any): AxiosPromise<any> => {
    let url = environmentUrl + "/api/Scan/GetSentToProcessScanProducts";
    const scanProducts = axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return scanProducts;
};
export const getUserScanProdList = (token: any): AxiosPromise<any> => {
    let url = environmentUrl + "/api/Scan/UserProductList";
    const scanProducts = axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return scanProducts;
};

export const endBox = (token: any): AxiosPromise<any> => {
    // console.log("TOKEN IN SERVICE", token);
    return axios.get(environmentUrl + `/api/Scan/EndBox`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteUpc = (id: any, token: any): AxiosPromise<any> => {
    return axios.delete(environmentUrl + `/api/Scan/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};