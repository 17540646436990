import { useState } from "react";
import { useAuth } from "../provider/authprovider";
export const useBotConfigurationsAPI = () => {
  const { setLoading, api, handleError, setErrorModalShow, setErrorMsg } = useAuth()
  const [configRecordByIdData, setConfigRecordByIdData] = useState<any | null>(null)
  const [configTableRecords, setConfigTableRecords] = useState<any | null>(null)
  const [snackbarMessage, setSnackbarMessage] = useState<any | null>(null)
  const postNewConfigTableRecord = async (details: any) => {
    setLoading(true)
    try {
      const result = await api.post(`/api/Configuration`, details);
      if (result?.data?.status == 200) {
        setErrorModalShow(true);
        setErrorMsg("Record Added Successfully");
      }
      return result
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  };
  const getConfigRecordById = async (id: any) => {
    try {
      const result = await api.get(`/api/Configuration/${id}`);
      setConfigRecordByIdData(result?.data?.result)
      return result
    } catch (error: any) {
      handleError(error)
    }
  };
  const putConfigRecordById = async (id: any, values: any) => {
    setLoading(true)
    try {
      const result = await api.put(`/api/Configuration/${id}`, values);
      if (result?.data?.status == 200) {
        if (values?.value == "Yes") {
          setSnackbarMessage("Bot Activated");
        }
        else if (values?.value == "No") {
          setSnackbarMessage("Bot Deactivated");
        }
      }
      return result
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  };
  const putConfigRecordDetailsById = async (id: any, values: any) => {
    setLoading(true)
    try {
      const result = await api.put(`/api/Configuration/${id}`, values);
      if (result?.data?.status == 200) {
        setErrorModalShow(true);
        setErrorMsg("Configuration Edited Successfully");
      }
      return result
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  };
  const getConfigTableRecords = async (offset: any, limit: any) => {
    setLoading(true)
    try {
      const result = await api.get(`/api/Configuration?Offset=${offset}&Limit=${limit}&SortBy=key&OrderBy=ASC`);
      setConfigTableRecords(result?.data?.result)
      return result
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  };
  return {
    postNewConfigTableRecord, getConfigRecordById, putConfigRecordById, getConfigTableRecords, configRecordByIdData,
    configTableRecords, snackbarMessage, putConfigRecordDetailsById
  } as const
}