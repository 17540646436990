import React, { useEffect, useState } from 'react'
import { useAuth } from "../../shared/provider/authprovider";
import Header from "../../components/header/Header"
import { ImCheckmark, ImCross } from 'react-icons/im';
import { Button, Dropdown } from "react-bootstrap";
import Dots from '../../assets/action.svg'
import Modal from 'react-bootstrap/Modal';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import XLSX from 'sheetjs-style';
import saveAs from 'file-saver';
import FileSaver from 'file-saver';
import Loader from '../../loader';
import { Box, MenuItem, TextField } from '@mui/material';
import ErrorModal from '../../components/errorModal/ErrorModal';
import { useSquareSpaceAPI } from '../../shared/api/connectionSquareSpace';
import { useConnectionStatusAPI } from '../../shared/api/index.service';
interface MyFormValues {
    name: string,
    token: string,
    type: string,
    isActive: string,
    userName: string,
    password: string,
}
const DisplayingErrorMessagesSchema = Yup.object().shape({
    name: Yup.string().required('Required').min(2, "Too Short!").max(30, "Too Long!").matches(/^[A-Za-z ]*$/, 'Please enter valid name'),
    token: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    isActive: Yup.string().required('Required'),
    userName: Yup.string().required('Required'),
    password: Yup.string().required('Required').min(8, 'Password is too short - should be 8 chars minimum.').matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
});
const DisplayingErrorMessagesSchemaEdit = Yup.object().shape({
    name: Yup.string().required('Required').min(2, "Too Short!").max(30, "Too Long!").matches(/^[A-Za-z ]*$/, 'Please enter valid name'),
    token: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    isActive: Yup.string().required('Required'),
    userName: Yup.string().required('Required'),
});
const ConnectionsSquareSpace = () => {

    const { errorModalShow, setErrorModalShow, setSelectedIndex } = useAuth();
    const { getSquareSpaceById, putSquareSpace, postSquareSpace, getAllSquareSpace, squareSpaceById, squareSpaceCount, squareSpace } = useSquareSpaceAPI()
    const { putActivateConnection, putDeactivateConnection } = useConnectionStatusAPI()
    const loadInitialData = async () => {
        await getAllSquareSpace(offset, limit);
    }
    useEffect(() => {
        loadInitialData();
        setSelectedIndex(12)
    }, []);
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    const [offset, setOffset] = React.useState(0);
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setOffset((value - 1) * limit);
        await getAllSquareSpace((value - 1) * limit, limit);
        setPage(value);
    };
    const putInactive = async (id: any) => {
        await putDeactivateConnection(id)
        getAllSquareSpace(offset, limit)
    }
    const putActive = async (id: any) => {
        await putActivateConnection(id)
        getAllSquareSpace(offset, limit)
    }
    const [show, setShow] = useState(false);
    const showAddModal = () => {
        setShow(true)
    }
    const [editState, setEditState] = useState(false);
    let [Id, setId] = useState<any | null>()
    const showEditModal = async (id: any) => {
        setId(id)
        await getSquareSpaceById(id)
        setShow(true)
    }
    const putSquareSpaceFun = async (Id: any, values: any) => {
        await putSquareSpace(Id, values)
        getAllSquareSpace(offset, limit);
    }
    const postSquareSpaceFun = async (values: any) => {
        await postSquareSpace(values)
        getAllSquareSpace(offset, limit);
    }
    const initialValues: MyFormValues = { name: '', token: '', type: '', isActive: '', userName: '', password: '' };
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx'

    const ontabExport = () => {
        const ws = XLSX.utils.json_to_sheet(squareSpace);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, "Square Space Connections" + fileExtension)
    }
    const statusList = [
        { value: true, label: "True" },
        { value: false, label: "False" },
    ];
    return (
        <>
            {errorHandle()}
            <Header />
            <Box className="contentMain"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <div className='connectionContainer'>
                    <div className='startMain'>
                        <h5 className='pageHead mb-0 w-50'>Square Space</h5>
                        <div className='d-flex justify-content-end w-50'>
                            <button className="FilledBtn me-3" onClick={() => { showAddModal(); setEditState(false) }}>Add New</button>
                            <button className='FilledBtn' onClick={() => { ontabExport(); }}>Export</button>
                        </div>
                    </div>
                    <div className='heightG'>
                        <table className='tableH'>
                            <thead className='thead rounded'>
                                <tr>
                                    <td>Sr. No.</td>
                                    <td>Name</td>
                                    <td>User Name</td>
                                    <td>Password</td>
                                    <td>Token</td>
                                    <td>Type</td>
                                    <td>Active</td>
                                    <td>Actions</td>
                                </tr>
                            </thead>
                            {squareSpace ?
                                <tbody className='tbody'>
                                    {squareSpace?.map((value: any, index: any) => {
                                        return (
                                            <tr>
                                                <td>{(limit * (page - 1)) + squareSpace?.findIndex((x: any) => x.id === value?.id) + 1}</td>
                                                <td>{value?.name}</td>
                                                <td>{value?.userName}</td>
                                                <td>************</td>
                                                <td>************</td>
                                                <td>{value?.type}</td>
                                                <td>{value?.isActive == true ?
                                                    <span style={{ color: "green" }}><ImCheckmark /></span>
                                                    :
                                                    <span style={{ color: "red" }}><ImCross /></span>}
                                                </td>
                                                <td>
                                                    <Dropdown className="menuItem">
                                                        <Dropdown.Toggle variant="Warning" id="dropdown-basic"><img src={Dots}></img></Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropMenug">
                                                            <Dropdown.Item className="clickItem"
                                                                onClick={() => { showEditModal(value.id); setEditState(true) }}
                                                            >Edit</Dropdown.Item>
                                                            <Dropdown.Item className="clickItem"
                                                                onClick={() => { value.isActive == true ? putInactive(value.id) : putActive(value.id) }}
                                                            >{value?.isActive == true ? "Deactivate" : "Activate"}</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                :
                                <Loader />
                            }
                        </table>
                        <Modal
                            show={show}
                            onHide={() => setShow(false)}
                            dialogClassName="formModal modalHeight"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton className='border-bottom-0'>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    {editState == true ? "Edit Square Space Details" : "Add New Square Space"}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Formik
                                    initialValues={editState == true ? squareSpaceById : initialValues}
                                    enableReinitialize={true}
                                    validationSchema={editState == true ? DisplayingErrorMessagesSchemaEdit : DisplayingErrorMessagesSchema}
                                    onSubmit={(values, actions) => {
                                        console.log(values);
                                        // setEditValues(values);
                                        actions.setSubmitting(false);
                                        // postSquareSpaceCalled(values)
                                        { editState == true ? putSquareSpaceFun(Id, values) : postSquareSpaceFun(values) }

                                        setShow(false)
                                    }}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className='formContainer'>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="name">Name</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="name" name="name" placeholder='Enter Name' />
                                                        {touched.name && errors.name && <div className='errorMsg'>{errors.name}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="token">Token</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="token" name="token" placeholder='Enter Token' />
                                                        {touched.token && errors.token && <div className='errorMsg'>{errors.token}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="type">Type</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="type" name="type" placeholder='Enter Type' />
                                                        {touched.type && errors.type && <div className='errorMsg'>{errors.type}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="isActive">Select Status</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <div className='selectStatusField'>
                                                            <Field name="isActive">
                                                                {({ field }: any) => (
                                                                    <div>
                                                                        <TextField
                                                                            {...field}
                                                                            select
                                                                            fullWidth
                                                                        >
                                                                            {statusList.map((e: any) => (
                                                                                <MenuItem value={e.value}>{e.label}</MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                    </div>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        {touched.isActive && errors.isActive && <div className='errorMsg'>{errors.isActive}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="userName">User Name</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="userName" name="userName" placeholder='Enter User Name' />
                                                        {touched.userName && errors.userName && <div className='errorMsg'>{errors.userName}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="password">Password</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        {editState == true ?
                                                            <Field className='textfield' id="password" name="password" placeholder='********' disabled />
                                                            :
                                                            <>
                                                                <Field className='textfield' id="password" name="password" placeholder='Enter Password' />
                                                                {touched.password && errors.password && <div className='errorMsg'>{errors.password}</div>}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-4'></div>
                                                    <div className='col-8'>
                                                        <Button className="FilledBtn" type="submit">Submit</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <div className='d-flex paginationSect align-items-center justify-content-between'>
                        <Stack spacing={2} className="d-flex flex-row align-items-center">
                            <label className='formLabel'>Showing {offset + 1} to {squareSpaceCount > (offset + limit) ? (offset + limit) : squareSpaceCount} of {squareSpaceCount} entries</label>
                            <Pagination className="m-0 paginatnNav" size="small" shape="rounded" count={Math.ceil(squareSpaceCount / limit)} page={page} onChange={handleChange} />
                        </Stack>
                    </div>
                </div>
            </Box>
        </>
    )
}
export default ConnectionsSquareSpace