import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import ErrorModal from "../../components/errorModal/ErrorModal";
import Header from "../../components/header/Header";
import Loader from "../../loader";
import { useAuth } from "../../shared/provider/authprovider";
import "./RecappQuicklotz.scss";
import { ReactComponent as Quit } from "../../../src/assets/icons/quit.svg";
import { ReactComponent as UpcScanning } from "../../../src/assets/icons/upc-scanning.svg";
import { ReactComponent as ListableItemLocation } from "../../../src/assets/icons/listable-item-location.svg";
import { ReactComponent as ListableItem } from "../../../src/assets/icons/listable-item.svg";
import { useRecAppApiQuickLotz } from "../../shared/api/receivingappQuickLots";
import { Field, Form, Formik, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { ReactComponent as BlueFilledCheckCircle } from "../../../src/assets/icons/blue-filled-check-circle.svg";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Lock, LockOpen, Send } from '@mui/icons-material';
const ReceivingAppQuickLotz = () => {
    const {
        setSelectedIndex,
        setErrorModalShow,
        setErrorMsg,
        errorModalShow,
        isLoading,
    } = useAuth();
    const {
        upcValidationBoxQuickLotz,
        setupcValidationBoxQuickLotz,
        getUPCScanQuickLotzCalled,
        scanUPCProductQuickLotz,
        productDataBox,
        setProductDataBox,
        productFlagsBox,
        setProductFlagsBox,
        productWeightBox,
        setProductWeightBox,
        listableLocationBoxQbids,
        setListableLocationBoxQbids,
        postQbidsDataCalled,
    } = useRecAppApiQuickLotz();
    const [lastListedUPCQbids, setLastListedUPCQbids] = useState<any>("");
    const [lastListedLocationQbids, setLastListedLocationQbids] = useState<any>("");
    let [priceToSubmit, setPriceToSubmit] = useState<any>()

    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        );
    };
    const DisplayingErrorMessagesUPC = Yup.object().shape({
        upc: Yup.string().required("UPC/ASIN is required"),
    });
    const upcQuickLotz: any = useFormik({
        initialValues: {
            upc: "",
        },
        validationSchema: DisplayingErrorMessagesUPC,
        onSubmit: () => submitData(),
    });
    const [textListable, setTextListable] = useState<any>("");
    const submitData = async () => {
        console.log("formik", upcQuickLotz.values.upc);
        const result = await getUPCScanQuickLotzCalled(upcQuickLotz?.values?.upc);
        console.log(result, "77");
        if (result?.items?.isListable == true) {
            localStorage.setItem("UPC", upcQuickLotz?.values.upc);
            // let hasPriceGreaterThan25 = false;
            let highestPrice = result?.items?.prices[0].displayPrice;
            for (const item of result?.items?.prices) {
                if (item.displayPrice > highestPrice) {
                    highestPrice = item.displayPrice;
                }
            }
            let highestIndex: any = null;
            let highestDisplayPrice = 0;
            result?.items?.prices.forEach((item: any, index: any) => {
                if (item.displayPrice > highestDisplayPrice) {
                    highestDisplayPrice = item.displayPrice;
                    highestIndex = index;
                }
            });
            setSelectedProductIndex(highestIndex)
            setHeighestPrice(highestPrice);
            setPriceToSubmit(highestPrice)
            setTextListable("Item is Listable");
            setProductDataBox(true);
            setupcValidationBoxQuickLotz(false);
        } else if (result?.items?.isListable == false) {
            setTextListable("Item is Unlistable");
            setErrorMsg("Item is not listable")
            setErrorModalShow(true)
            // setDisableButtons(true);
            // setMainFunctionButton(false)
        }
    };
    const upcScannBoxUI = () => {
        return (
            <FormikProvider value={upcQuickLotz}>
                <div className="formContainer">
                    <div className="row align-items-center formItem">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <label htmlFor="upc">Enter UPC/ASIN : </label>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            <Field
                                autoFocus
                                className="textfield"
                                id="upc"
                                name="upc"
                                placeholder="Enter UPC Number"
                                onKeyPress={(event: any) => {
                                    event.key == "Enter"
                                        ? upcQuickLotz.handleSubmit()
                                        : console.log("null");
                                }}
                            />
                            {
                                <span className="text-danger">
                                    {upcQuickLotz.errors?.upc &&
                                        upcQuickLotz.touched?.upc &&
                                        upcQuickLotz.touched?.upc &&
                                        upcQuickLotz.errors?.upc}
                                </span>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-lg-4 col-sm-12 col-md-12 col-lg-8 btnGrp">
                            <Button
                                className="FilledBtn outlineBtns"
                                onClick={() => {
                                    upcQuickLotz.resetForm();
                                }}
                            >
                                Clear
                            </Button>
                            <Button
                                className="FilledBtn outlineBtns"
                                onClick={() => upcQuickLotz.handleSubmit()}
                            >
                                Continue
                            </Button>
                        </div>
                    </div>
                </div>
            </FormikProvider>
        );
    };

    const formikProductData: any = useFormik({
        initialValues: {
            upc: "",
        },
        onSubmit: () => submitUPC(),
    });
    const [heighestPrice2, setHeighestPrice] = useState<any>(null);
    const [disableButtons, setDisableButtons] = useState(false);
    const [selectedProductIndex, setSelectedProductIndex] = useState<any>();
    const submitUPC = async () => {
        const result = await getUPCScanQuickLotzCalled(formikProductData?.values?.upc);
        if (result?.items?.isListable == true) {
            localStorage.setItem("UPC", formikProductData?.values?.upc);
            let highestPrice = result?.items?.prices[0].displayPrice;
            for (const item of result?.items?.prices) {
                if (item.displayPrice > highestPrice) {
                    highestPrice = item.displayPrice;
                }
            }
            let highestIndex: any = null;
            let highestDisplayPrice = 0;
            result?.items?.prices.forEach((item: any, index: any) => {
                if (item.displayPrice > highestDisplayPrice) {
                    highestDisplayPrice = item.displayPrice;
                    highestIndex = index;
                }
            });
            setSelectedProductIndex(highestIndex)
            setHeighestPrice(highestPrice);
            setPriceToSubmit(highestPrice)
            setTextListable("Item is Listable")
            setDisableButtons(false)


        } else if (result?.items?.isListable == false) {
            setTextListable("Item is Unlistable")
            setDisableButtons(true)
        }
    };
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });



    const handleRadioChange = (index: any) => {
        setSelectedProductIndex(index);
        setPriceToSubmit(scanUPCProductQuickLotz?.prices[index].displayPrice);
    };


    const productDataBoxUI = () => {
        return (
            <FormikProvider value={formikProductData}>
                <div className="row align-items-center formItem">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label htmlFor="upc">UPC/ASIN Scanning : </label>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-4">
                        <Field
                            className="textfield"
                            id="upc"
                            name="upc"
                            placeholder="Enter UPC Number"
                            onKeyPress={(event: any) => {
                                event.key == "Enter"
                                    ? formikProductData.handleSubmit()
                                    : console.log("null");
                            }}
                        />
                        {
                            <span className="text-danger">
                                {formikProductData.errors?.upc &&
                                    formikProductData.touched?.upc &&
                                    formikProductData.touched?.upc &&
                                    formikProductData.errors?.upc}
                            </span>
                        }
                    </div>
                </div>
                <div className="d-flex productContainer">
                    <div className="imgSection">
                        {scanUPCProductQuickLotz?.images?.map((imglink: any) => {
                            return <img src={imglink} height={200} width={200} />;
                        })}
                    </div>
                    <div>
                        <Stack direction="row" alignItems="center" mb={1}>
                            <Typography variant="body2" fontSize={14} fontWeight={600}>
                                Product Name:{" "}
                                <span style={{ fontSize: "13px", fontWeight: 400 }}>
                                    {" "}
                                    {scanUPCProductQuickLotz?.title}
                                </span>
                            </Typography>
                        </Stack>
                        <Typography variant="body2" fontSize={14} fontWeight={600}>
                            Product Description :{" "}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ fontSize: "13px", fontWeight: 400, mb: 1 }}
                        >
                            {scanUPCProductQuickLotz?.description}
                        </Typography>
                        <Typography variant="body2" fontSize={14} fontWeight={600} mb={1}>
                            Product Prices :
                        </Typography>
                        <div className="heightG">
                            <table className="tableH">
                                <thead className="thead rounded">
                                    <tr>
                                        <th>
                                            <strong style={{ fontWeight: 600 }}>Source</strong>
                                        </th>
                                        <th>
                                            <strong style={{ fontWeight: 600 }}>Price</strong>
                                        </th>
                                        <th>
                                            <strong style={{ fontWeight: 600 }}> </strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="tbody">
                                    {scanUPCProductQuickLotz?.prices?.map((price: any, index: any) => {
                                        return (
                                            <>
                                                <tr style={{ backgroundColor: price?.merchant == "Retail Price" ? "#C8D6F8" : "white" }} key={index}>
                                                    <td>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: "13px",
                                                                fontWeight: 400,
                                                                mb: 1,
                                                            }}
                                                        >
                                                            {price?.merchant}
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: "13px",
                                                                fontWeight: 400,
                                                                mb: 1,
                                                            }}
                                                        >
                                                            <span className="me-3">{price?.displayPriceFrom}</span>
                                                            <span>{formatter.format(price?.displayPrice)}</span>
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontSize: "13px",
                                                                fontWeight: 400,
                                                                mb: 1,
                                                            }}
                                                        >
                                                            <input
                                                                type="radio"
                                                                value={index}
                                                                checked={selectedProductIndex === index}
                                                                onChange={() => handleRadioChange(index)}
                                                            />
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Box className="btnGrp mt-4 mb-4 btnBox" sx={{ textAlign: "right" }}>
                    <Button
                        className="FilledBtn outlineBtns"
                        onClick={async () => {

                            upcQuickLotz.resetForm();
                            formikProductData.resetForm()
                            setProductDataBox(false);
                            setupcValidationBoxQuickLotz(true);
                            // setHeighestPrice(heighestPrice)
                            // setSelectedRecord(0)
                        }}
                    >
                        {/* Sent to Store */}
                        Back
                    </Button>
                    {disableButtons == false ?
                        <Button
                            className="FilledBtn outlineBtns"
                            onClick={() => {
                                setProductDataBox(false);
                                setProductFlagsBox(true);
                            }}
                        >
                            Continue
                        </Button> : <Button className="FilledBtn outlineBtns disabledButton" style={{ cursor: "not-allowed", color: "gray", borderColor: "gray" }}>
                            Continue
                        </Button>}


                </Box>
            </FormikProvider>
        )
    }
    const validationSchemaroductFlags = Yup.object().shape({
        category: Yup.string()
            .required('Category is required'),
        condition: Yup.string()
            .required('Condition is required'),
    });
    const formikProductFlags: any = useFormik({
        initialValues: {
            category: "",
            condition: ""
        },
        validationSchema: validationSchemaroductFlags,
        onSubmit: () => {
            setProductFlagsBox(false);
            setProductWeightBox(true);
        },
    });
    const category = [
        { value: 3712, label: "Outdoor, Tools & Sporting Goods" },
        { value: 3692, label: "Home & Furniture" },
        { value: 3693, label: "Health, Beauty & Fitness" },
        { value: 3679, label: "Baby & Kids" },
        { value: 3687, label: "Electronics & Appliances" },
        { value: 3711, label: "Pallets" },
        { value: 3716, label: "Fashion" },
        { value: 3717, label: "Miscellaneous" }
    ]
    const conditionArray = [
        "New",
        "Open-Box",
        "Minor Imperfections"
    ]
    const productFlagsBoxUI = () => {
        return (
            <FormikProvider value={formikProductFlags}>
                <div className="formContainer">
                    <div className="row align-items-center formItem">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <label htmlFor="category">Category : </label>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            <Field name="category">
                                {({ field }: any) => (
                                    <select {...field} className="textfield">
                                        <option value="">Select Category</option>
                                        {category?.map((i: any) => (
                                            <option
                                                className="clickItem"
                                                key={i.value}
                                                value={i.value}
                                            >
                                                {i.label}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </Field>
                            {
                                <span className="text-danger">
                                    {formikProductFlags.errors?.category &&
                                        formikProductFlags.touched?.category &&
                                        formikProductFlags.touched?.category &&
                                        formikProductFlags.errors?.category}
                                </span>
                            }
                        </div>
                    </div>
                    <div className="row align-items-center formItem mt-4">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <label htmlFor="condition">Condition : </label>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-8">
                            <Field name="condition">
                                {({ field }: any) => (
                                    <select {...field} className="textfield">
                                        <option value="">Select Condition</option>
                                        {conditionArray?.map((i: any) => (
                                            <option
                                                className="clickItem"
                                                key={i}
                                                value={i}
                                            >
                                                {i}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </Field>
                            {
                                <span className="text-danger">
                                    {formikProductFlags.errors?.condition &&
                                        formikProductFlags.touched?.condition &&
                                        formikProductFlags.touched?.condition &&
                                        formikProductFlags.errors?.condition}
                                </span>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-lg-4 col-sm-12 col-md-12 col-lg-8 btnGrp">
                            <Button
                                className="FilledBtn outlineBtns"
                                onClick={async () => {
                                    setProductDataBox(true);
                                    setProductFlagsBox(false);
                                    formikProductFlags.resetForm()
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                className="FilledBtn outlineBtns"
                                onClick={() => {
                                    formikProductFlags.handleSubmit()
                                }}
                            >
                                Continue
                            </Button>

                        </div>
                    </div>
                </div>
            </FormikProvider>
        )
    }
    const validationSchemaWeight = Yup.object().shape({
        weight: Yup.number()
            .min(0, 'Weight must be at least 0.00')
            .max(250, 'Weight must be less than or equal to 250.00')
            .typeError('Weight must be a valid decimal number')
            .required('Weight is required')
    });
    const formikproductWeight: any = useFormik({
        initialValues: {
            weight: ""
        },
        validationSchema: validationSchemaWeight,
        onSubmit: () => {
            setProductWeightBox(false);
            setListableLocationBoxQbids(true);
        },
    });
    const productWeightBoxUI = () => {
        return (
            <FormikProvider value={formikproductWeight}>
                <div className="formContainer">
                    <div className="row align-items-center formItem">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <label htmlFor="location">Enter Weight : </label>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            <Field name="weight" className="textfield">
                                {({ field }: any) => (
                                    <TextField
                                        {...field}
                                        className="textfield"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Lbs (Pounds)</InputAdornment>,
                                        }}
                                        type="number"
                                        autoFocus
                                        placeholder="Enter weight"
                                        onKeyPress={(event: any) => {
                                            event.key == "Enter"
                                                ? formikproductWeight.handleSubmit()
                                                : console.log("null");
                                        }}
                                        sx={{
                                            '.MuiInputBase-input': {

                                                width: '100%',
                                                padding: '11px 10px',
                                                lineHeight: 0
                                            },
                                            '.MuiOutlinedInput-root': {
                                                '&.Mui-focused': {
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #004eff',
                                                    }
                                                }
                                            },
                                            '.MuiOutlinedInput-notchedOutline': {
                                                border: '1px solid #8b9297',
                                                borderRadius: '6px',
                                            }
                                        }}
                                    />
                                )}
                            </Field>
                            {
                                <span className="text-danger">
                                    {formikproductWeight.errors?.weight &&
                                        formikproductWeight.touched?.weight &&
                                        formikproductWeight.touched?.weight &&
                                        formikproductWeight.errors?.weight}
                                </span>
                            }
                        </div>

                    </div>
                    <div className="row">
                        <div className="offset-lg-4 col-sm-12 col-md-12 col-lg-8 btnGrp">
                            <Box className="btnGrp btnBox mt-0">
                                <Button
                                    className="FilledBtn outlineBtns"
                                    onClick={async () => {
                                        setProductFlagsBox(true);
                                        setProductWeightBox(false);
                                        formikproductWeight.resetForm()
                                    }}
                                >
                                    Back
                                </Button>
                                <Button
                                    className="FilledBtn outlineBtns"
                                    onClick={() => {
                                        formikproductWeight.handleSubmit()
                                    }}
                                >
                                    Continue
                                </Button>


                            </Box>
                        </div>
                    </div>
                </div>
            </FormikProvider>
        )
    }

    const submitQbidsData = async () => {
        const detailstoSend = {
            upc: scanUPCProductQuickLotz?.upc,
            category_id: formikProductFlags?.values?.category,
            name: scanUPCProductQuickLotz?.title,
            item_condition: formikProductFlags?.values?.condition,
            description: scanUPCProductQuickLotz?.description,
            warehouse_location_id: formik?.values?.location,
            images: scanUPCProductQuickLotz?.images,
            start_price: "5.00",
            retail_price: `${priceToSubmit}`,
            buyout_price: `${priceToSubmit}`,
            item_weight: `${formikproductWeight?.values?.weight}`,
            listing_location_id: "1"
        }
        const result = await postQbidsDataCalled(detailstoSend)
        if (result?.status == 200) {
            setListableLocationBoxQbids(false)
            setProductDataBox(false)
            setProductFlagsBox(false)
            setProductWeightBox(false)
            setupcValidationBoxQuickLotz(true)
            setLastListedUPCQbids(detailstoSend.upc)
            setLastListedLocationQbids(detailstoSend.warehouse_location_id)
            formikProductData.resetForm();
            formikProductFlags.resetForm();
            formikproductWeight.resetForm();
            upcQuickLotz.resetForm();
            { formik?.values?.locked ? console.log("true") : formik.resetForm() }
            // setHeighestPrice(null)
            // setSelectedRecord(0)
        }

    }
    const initialValues = {
        location: '',
        locked: false,
    };

    const validationScheaLocation = Yup.object().shape({
        location: Yup.string().max(50)
            .required('Location is required').nullable()
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationScheaLocation,
        onSubmit: (values) => {
            console.log("YYYYYY", values);
            submitQbidsData()
        },
    });

    const toggleLock = () => {
        formik.setValues({
            ...formik.values,
            locked: !formik.values.locked,
        });
    };
    const listableLocationBoxQbidsBoxUI = () => {
        return (
            <FormikProvider value={formik}>
                <div className="formContainer">
                    <div className="row align-items-center formItem">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <label htmlFor="location">Location : </label>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-8">
                            <div className="d-flex">
                                <TextField
                                    className="textfield"
                                    name="location"
                                    label="Location"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                    disabled={formik.values.locked}
                                />
                                <IconButton onClick={toggleLock}>
                                    {formik.values.locked ? <LockIcon /> : <LockOpenIcon />}
                                </IconButton>
                            </div>
                            <div className="d-flex">
                                <>
                                    {
                                        <span className="text-danger">
                                            {formik.errors?.location &&
                                                formik.touched?.location &&
                                                formik.touched?.location &&
                                                formik.errors?.location}
                                        </span>
                                    }
                                </>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-8 col-lg-8 offset-lg-4">
                            <Box className="btnGrp btnBox">
                                <Button
                                    className="FilledBtn outlineBtns"
                                    onClick={async () => {
                                        setProductWeightBox(true);
                                        setListableLocationBoxQbids(false)
                                        { formik.values.locked ? console.log("true") : formik.resetForm() }
                                    }}
                                >
                                    Back
                                </Button>
                                <Button
                                    className="FilledBtn outlineBtns"
                                    onClick={() => {
                                        formik.handleSubmit()
                                    }}
                                >
                                    Submit
                                </Button>


                            </Box>
                        </div>
                    </div>
                </div>
            </FormikProvider>
        )
    }
    useEffect(() => {
        setSelectedIndex(25);

    }, []);
    return (
        <>
            {errorHandle()}
            {isLoading ? <Loader /> : ""}
            <Header />
            <Box
                className="contentMain ROValidationWrapper"
                component="main"
                sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
            >
                <div className="startMain">
                    <div>
                        <h5 className="pageHead mb-2 me-2">Receiving Application</h5>
                        {/* <p className="mb-0">Products: 1,345</p> */}
                    </div>
                    <Button
                        className="FilledBtn btnStyleUnset"
                        onClick={() => {
                            setupcValidationBoxQuickLotz(true)
                            setProductDataBox(false)
                            setProductFlagsBox(false)
                            setProductWeightBox(false)
                            setListableLocationBoxQbids(false)
                            formikProductData.resetForm()
                            formikProductFlags.resetForm()
                            formikproductWeight.resetForm()
                            { formik.values.locked ? console.log("true") : formik.resetForm() }
                            upcQuickLotz.resetForm()
                        }}
                    >
                        <Quit style={{ height: "30px", width: "30px" }} />
                    </Button>
                </div>
                <div className="stepper d-flex">
                    <div
                        className={`step ${upcValidationBoxQuickLotz ||
                            productDataBox ||
                            productFlagsBox ||
                            productWeightBox ||
                            listableLocationBoxQbids == true
                            ? "active"
                            : ""
                            }`}
                    >
                        <div className="stepperLine"></div>
                        <div className="iconCircle">
                            <UpcScanning />
                        </div>
                        <p className="mb-0">UPC/ASIN Scanning</p>
                    </div>
                    <div
                        className={`step ${productDataBox ||
                            productFlagsBox ||
                            productWeightBox || listableLocationBoxQbids == true
                            ? "active"
                            : ""
                            }`}
                    >
                        <div className="stepperLine"></div>
                        <div className="iconCircle">
                            <ListableItem />
                        </div>
                        <p className="mb-0">Product Data</p>
                    </div>
                    <div
                        className={`step ${productFlagsBox ||
                            productWeightBox ||
                            listableLocationBoxQbids == true
                            ? "active"
                            : ""
                            }`}
                    >
                        <div className="stepperLine"></div>
                        <div className="iconCircle">
                            <ListableItem />
                        </div>
                        <p className="mb-0">Product Flags</p>
                    </div>
                    <div
                        className={`step ${productWeightBox ||
                            listableLocationBoxQbids == true
                            ? "active"
                            : ""
                            }`}
                    >
                        <div className="stepperLine"></div>
                        <div className="iconCircle">
                            <ListableItem />
                        </div>
                        <p className="mb-0">Product Weight</p>
                    </div>
                    <div
                        className={`step ${listableLocationBoxQbids == true ? "active" : ""}`}
                    >
                        <div className="stepperLine"></div>
                        <div className="iconCircle">
                            <ListableItemLocation />
                        </div>
                        <p className="mb-0">Location</p>
                    </div>
                </div>
                <div className="row middleRow">
                    <div className="col-sm-12 col-md-9 col-lg-9">
                        {upcValidationBoxQuickLotz == true ? upcScannBoxUI() : ""}
                        {productDataBox == true ? productDataBoxUI() : ""}
                        {productFlagsBox == true ? productFlagsBoxUI() : ""}
                        {productWeightBox == true ? productWeightBoxUI() : ""}
                        {listableLocationBoxQbids == true ? listableLocationBoxQbidsBoxUI() : ""}
                    </div>
                    {/* <div className="col-1"></div> */}
                    <div className="col-sm-12 col-md-3 col-lg-3">
                        <div className="whiteContainer">
                            {productDataBox ||
                                productFlagsBox ||
                                productWeightBox ||
                                listableLocationBoxQbids == true ? (
                                <div className="d-flex sucMsgContainer">
                                    <BlueFilledCheckCircle />
                                    <div className="message">
                                        <h6> UPC/ASIN scan is Successful</h6>
                                        <p>
                                            UPC/RO : <span>{localStorage.getItem("UPC")}</span>
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {productDataBox ||
                                productFlagsBox ||
                                productWeightBox ||
                                listableLocationBoxQbids == true ? (
                                <div className="d-flex sucMsgContainer">
                                    <BlueFilledCheckCircle />
                                    <div className="message">
                                        <h6>{textListable}</h6>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {upcValidationBoxQuickLotz == true && lastListedUPCQbids != "" ? (
                                <div className="d-flex sucMsgContainer">
                                    <BlueFilledCheckCircle />
                                    <div className="message">
                                        <h6> Last Receipt</h6>
                                        <p>
                                            UPC : <span>{lastListedUPCQbids ? lastListedUPCQbids : ""}</span>
                                        </p>
                                        <p>
                                            LOC :{" "}
                                            <span>
                                                {lastListedLocationQbids ? lastListedLocationQbids : ""}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {/* {listableItemBox ||
                                listableLocationBox ||
                                unlistableItemBox == true ? (
                                <div className="d-flex sucMsgContainer">
                                    <BlueFilledCheckCircle />
                                    <div className="message">
                                        <h6>UPC Scan Successful</h6>
                                        <p>
                                            UPC : <span>{UPC ? UPC : ""}</span>
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {listableItemBox || listableLocationBox == true ? (
                                <div className="d-flex sucMsgContainer">
                                    <BlueFilledCheckCircle />
                                    <div className="message">
                                        <h6>{textListable}</h6>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {unlistableItemBox == true ? (
                                <div>
                                    <h6>Item is UnListable</h6>
                                </div>
                            ) : (
                                <></>
                            )} */}
                        </div>
                    </div>
                </div>
            </Box>
        </>
    );
};
export default ReceivingAppQuickLotz;
