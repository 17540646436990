import { Box, Link } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import FileSaver from 'file-saver';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import XLSX from 'sheetjs-style';
import { ReactComponent as ResetFilter } from "../../../src/assets/icons/filter-reset.svg";
import { ReactComponent as Calendar } from "../../../src/assets/icons/searchCalendar.svg";
import filter from "../../assets/filter.svg";
import ErrorModal from '../../components/errorModal/ErrorModal';
import Header from "../../components/header/Header";
import Loader from '../../loader';
import { useAuth } from "../../shared/provider/authprovider";
import './vendorDetailsScreen.scss';
import { useVendorsAPI } from '../../shared/api/vendor';

const VendorDetailsScreen = (propsValue: any) => {
    const { id } = useParams();
    const vendor = localStorage.getItem("vendorClassification")
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const [vendorDetails, setVendorDetails] = useState<any | null>(null)
    const [vendorKPIDetails, setVendorKPIDetails] = useState<any | null>(null)

    const { errorModalShow, setErrorMsg, setErrorModalShow,
        isLoading, setSelectedIndex } = useAuth();
    const { getVendorDashboardCards, getVendorDashboardKPI } = useVendorsAPI()
    const KPI = [
        {
            column: "Products",
            orders: vendorKPIDetails ? vendorKPIDetails?.products : "-",
        },
        {
            column: "Orders",
            orders: vendorKPIDetails ? vendorKPIDetails?.orders : "-",
        },
        {
            column: "Total Units sold",
            orders: vendorKPIDetails ? vendorKPIDetails?.totalUnitSold : "-",
        },
        {
            column: "GMV",
            orders: vendorKPIDetails ? formatter.format(vendorKPIDetails?.gmv) : "-",
        },
        {
            column: "Refunds and Cancellations",
            orders: vendorKPIDetails ? formatter.format(vendorKPIDetails?.refundsAndCancellations) : "-",
        },
        {
            column: "Total GMV",
            orders: vendorKPIDetails ? formatter.format(vendorKPIDetails?.totalGMV) : "-",
        },
        {
            column: "AOV",
            orders: vendorKPIDetails ? formatter.format(vendorKPIDetails?.aov) : "-",
        },
        {
            column: "Postage",
            orders: vendorKPIDetails ? formatter.format(vendorKPIDetails?.postage) : "-",
        },
        {
            column: "Selling Fees",
            orders: vendorKPIDetails ? formatter.format(vendorKPIDetails?.sellingFess) : "-",
        },
        {
            column: "Avg Postage per Order",
            orders: vendorKPIDetails ? formatter.format(vendorKPIDetails?.avgPostagePerorder) : "-",
        },
        {
            column: "Net Revenue",
            orders: vendorKPIDetails ? formatter.format(vendorKPIDetails?.netRevenue) : "-",
        },
        {
            column: "Classification (70%)",
            orders: vendorKPIDetails ? formatter.format(vendorKPIDetails?.classification_70) : "-",
        },
        {
            column: "Steals (30%)",
            orders: vendorKPIDetails ? formatter.format(vendorKPIDetails?.classification_30) : "-",
        },
        {
            column: "Total Steals + Inbound Shipping",
            orders: vendorKPIDetails ? formatter.format(vendorKPIDetails?.total) : "-",
        }
    ]
    let navigate = useNavigate();
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };

    const handleOrders = () => {
        let propsValue: any = 5;
        navigate(encodeURI(`/order-details/${vendor}`), { state: propsValue })
    }
    const handleProducts = () => {
        let propsValue: any = 6;
        navigate(`/order-details/${vendor}`, { state: propsValue })
    }
    const handleRefundCancellation = () => {
        let propsValue: any = 1;
        navigate(`/order-details/${vendor}`, { state: propsValue })
    }

    var newdate = new Date()
    var dd: any = 1;
    var mm: any = newdate.getMonth() + 1;
    var yyyy = newdate.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm };
    var firstDay: any = (mm + '-' + dd + '-' + yyyy)
    var firstDayNew: any = new Date(newdate.getFullYear(), newdate.getMonth(), 1);
    console.log("Bug Date", firstDayNew);

    var newdate2 = new Date()
    newdate2.setDate(newdate2.getDate());
    var dd1: any = newdate2.getDate();
    var mm1: any = newdate2.getMonth() + 1;
    var yyyy1 = newdate2.getFullYear();
    if (dd1 < 10) { dd1 = '0' + dd1 }
    if (mm1 < 10) { mm1 = '0' + mm1 };
    var todayDate: any = (mm1 + '-' + dd1 + '-' + yyyy1);
    const [startDate, setStartDate] = useState<any>(firstDay);
    const [endDate, setEndDate] = useState<any>(todayDate);
    const [formatedStartDate, setFormatedStartDate] = useState<any>('');
    const [formatedEndDate, setFormatedEndDate] = useState<any>('');
    const [newStartDate, setNewStartDate] = useState<any>(null)
    const [newEndDate, setNewEndDate] = useState<any>(new Date())
    const handleStartDate = (value: any) => {
        if (value == null) {
            setStartDate(firstDay);
            setFormatedStartDate(firstDay);
            setNewStartDate(firstDay)
        }
        else {
            const result = moment(value).format("MM-DD-YYYY")
            setStartDate(value);
            setFormatedStartDate(result);
            setNewStartDate(value)
        }
    }
    const handleEndDate = (value: any) => {
        if (value == null) {
            setEndDate(todayDate);
            setFormatedEndDate(todayDate);
            setNewEndDate(todayDate)
        }
        else {
            const result = moment(value).format("MM-DD-YYYY")
            setEndDate(value);
            setFormatedEndDate(result);
            setNewEndDate(value)
        }
    }
    let details = {
        "formDate": firstDay,
        "toDate": todayDate,
        "classification": vendor
    }
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx'

    const onTapExport = () => {
        const ws = XLSX.utils.json_to_sheet(KPI);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, vendor + fileExtension)
    }
    const onTapApply = async () => {
        if (moment(startDate).isSameOrBefore(endDate)) {
            let details2 = {
                "formDate": formatedStartDate == '' ? firstDay : formatedStartDate,
                "toDate": formatedEndDate == '' ? todayDate : formatedEndDate,
                "classification": vendor
            }
            const result = await getVendorDashboardKPI(details2)
            setVendorKPIDetails(result?.data?.result)
            // getVendorOrderDashboardKPICalled(details2);
            localStorage.setItem("StartDateVendor", formatedStartDate == '' ? firstDay : formatedStartDate);
            localStorage.setItem("EndDateVendor", formatedEndDate == '' ? todayDate : formatedEndDate);
        }
        else {
            setErrorMsg("Please select start date less than end date");
            setErrorModalShow(true);
        }
    }

    const resetFilter = async () => {
        setStartDate(firstDay)
        setEndDate(todayDate)
        setFormatedStartDate(firstDay)
        setFormatedEndDate(todayDate)
        setNewStartDate(firstDayNew)
        setNewEndDate(new Date())
        const result = await getVendorDashboardKPI(details)
        setVendorKPIDetails(result?.data?.result)
        localStorage.setItem("StartDateVendor", firstDay);
        localStorage.setItem("EndDateVendor", todayDate);
    }
    const loadInitialData = async () => {
        const result = await getVendorDashboardCards(vendor)
        setVendorDetails(result?.data?.result)
        const response = await getVendorDashboardKPI(details)
        setVendorKPIDetails(response?.data?.result)
    }
    useEffect(() => {
        setNewStartDate(firstDayNew)
        localStorage.setItem("StartDateVendor", firstDay);
        localStorage.setItem("EndDateVendor", todayDate);
        loadInitialData()
        setSelectedIndex(4)
    }, []);

    return (
        <>
            {errorHandle()}
            <Header />
            <Box className="contentMain vendorDetailContainer"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}            >
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" onClick={() => navigate(-1)}>Vendors Dashboard</Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            aria-current="page">
                            {id} Dashboard
                        </Link>
                    </Breadcrumbs>
                </div>
                <div className='kpidetailContainer'>
                    <div className='d-flex flex-column'>
                        <h5 className="pageHead">{id}</h5>
                        <div className='mb-2'>
                            <p className='hHead'>KPI Scanning</p>
                            <p className='subHead'>See KPI event statistics </p>
                        </div>
                    </div>
                    <div className='d-flex kpicardDiv'>
                        <div className='kpifirstCard'>
                            <span className='kpiInfo'>Total Received </span>
                            <p className='subInfo'>{vendorDetails?.totalRecived.toLocaleString()}</p>
                        </div>
                        <div className=' kpiCard'>
                            <span className='kpiInfo'>Scanned </span>
                            <p className='subInfo'>{vendorDetails?.scanned.toLocaleString()}</p>
                        </div>
                        <div className='kpiCard '>
                            <span className='kpiInfo'>Total Ingested</span>
                            <p className='subInfo'>{vendorDetails?.totalIngested.toLocaleString()}</p>
                        </div>
                        <div className='kpiCard'>
                            <span className='kpiInfo'>Total Sold</span>
                            <p className='subInfo'>{vendorDetails?.totalSold.toLocaleString()}</p>
                        </div>
                        <div className='kpiCard'>
                            <span className='kpiInfo'>Quantity for Sale</span>
                            <p className='subInfo'>{vendorDetails?.qtyForSale.toLocaleString()}</p>
                        </div>
                        <div className='kpiCard'>
                            <span className='kpiInfo'>Quantity between SKU</span>
                            <p className='subInfo'>{vendorDetails?.qtyBtnSKU.toLocaleString()}</p>
                        </div>
                        <div className='kpiCard'>
                            <span className='kpiInfo'>Awaiting Brand Approvals</span>
                            <p className='subInfo'>{vendorDetails?.awaitingBrandApproval.toLocaleString()}</p>
                        </div>
                        <div className='kpiCard'>
                            <span className='kpiInfo'>Secondary QC</span>
                            <p className='subInfo'>{vendorDetails?.secondaryQc.toLocaleString()}</p>
                        </div>
                    </div>
                    <div>
                        <div className='startMain justify-content-between flex-column'>
                            <div className='mb-2'>
                                <p className='hHead'>KPI </p>
                                <p className='subHead'>KPI statistics</p>
                            </div>

                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <div className='d-flex align-items-start filterContainer'>
                                        <div className='d-flex align-items-center'>
                                            <div className='leftDiv filterItem'>
                                                <img src={filter} className="mRight" />
                                                <h5 className='m-0 text'>Filters</h5>
                                            </div>
                                            <div className='borderDiv filterItem'>
                                                <div className='dateInput from d-flex align-items-center'>
                                                    <label className='w-100 formLabel'>From: </label>
                                                    <div className="d-flex align-items-center outlinedInput">
                                                        <Calendar />
                                                        <DatePicker className='dateUi' selected={new Date(newStartDate)}
                                                            onChange={(selectedstart) => handleStartDate(selectedstart)}
                                                            dateFormat='MM-dd-yyyy'
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode='select'
                                                            maxDate={moment(todayDate).toDate()}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='dateInput d-flex align-items-center'>
                                                    <label className='w-100 formLabel'>To: </label>
                                                    <div className="d-flex align-items-center outlinedInput">
                                                        <Calendar />
                                                        <DatePicker className='dateUi' selected={new Date(newEndDate)}
                                                            onChange={(selectedstart) => handleEndDate(selectedstart)}
                                                            dateFormat='MM-dd-yyyy'
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode='select'
                                                            maxDate={moment(todayDate).toDate()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Tooltip TransitionComponent={Zoom} title="Reset" arrow placement="top">
                                        <ResetFilter className='ms-3 pointer' onClick={() => resetFilter()} />
                                    </Tooltip>
                                </div>
                                <div className="d-flex justify-content-end align-items-center w-100">
                                    <button className='FilledBtn me-3' onClick={() => onTapApply()}>Apply</button>
                                    <button className='FilledBtn' onClick={() => onTapExport()}>Export</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='heightG '>
                        <table className='tableH text-left'>
                            <thead className='thead rounded'>
                                <tr className='text-start'>
                                    <td>KPI</td>
                                    <td>Unique Listings</td>
                                </tr>
                            </thead>
                            {vendorKPIDetails ?
                                <tbody className='tbody'>
                                    {!isLoading ?
                                        <>
                                            {KPI.map((value: any, index: any) => {
                                                return (
                                                    <tr key={index} className='text-start'>
                                                        <td className={vendorKPIDetails?.orders >> 0 || vendorKPIDetails?.refundsAndCancellations >> 0 || vendorKPIDetails?.products >> 0 ? 'pointer' : ''} style={{ color: value.column == "Orders" || value.column == "Products" || value.column == "Refunds and Cancellations" ? "#004EFF" : "" }} onClick={() => {
                                                            {
                                                                (value.column == "Orders" && vendorKPIDetails?.orders >> 0) ? handleOrders() : console.log("No Orders");
                                                                (value.column == "Refunds and Cancellations" && vendorKPIDetails?.refundsAndCancellations >> 0) ? handleRefundCancellation() : console.log("No Refunds");
                                                                (value.column == "Products" && vendorKPIDetails?.products >> 0) ? handleProducts() : console.log("No products")
                                                            }
                                                        }}>{value.column}</td>
                                                        <td className='pointer'
                                                            onClick={() => {
                                                                {
                                                                    (value.column == "Orders" && vendorKPIDetails?.orders >> 0) ? handleOrders() : console.log("No Orders");
                                                                    (value.column == "Refunds and Cancellations" && vendorKPIDetails?.refundsAndCancellations >> 0) ? handleRefundCancellation() : console.log("No Refunds");
                                                                    (value.column == "Products" && vendorKPIDetails?.products >> 0) ? handleProducts() : console.log("No products")
                                                                }
                                                            }} style={{ color: value.column == "Orders" || value.column == "Products" || value.column == "Refunds and Cancellations" ? "#004EFF" : "" }}>

                                                            {value.column == "Orders" || value.column == "Products" || value.column == "Total Units sold" ? value.orders.toLocaleString() : value.orders}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                        :
                                        <Loader />
                                    }
                                </tbody>
                                :
                                <Loader />
                            }
                        </table>
                    </div>
                </div>
            </Box>
        </>
    )
}
export default VendorDetailsScreen