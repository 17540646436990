import { ReactComponent as VisibilityOff } from "../../../assets/images/icons/eye-slash.svg";
import { ReactComponent as Visibility } from "../../../assets/images/icons/open-eye.svg";
import { ReactComponent as Error } from "../../../assets/images/icons/error-triangle-icon.svg";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { Field, FormikProvider, useFormik } from "formik";
import jwt from "jwt-decode";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import ellipse from "../../../assets/images/ellipse.jpg";
import logo from "../../../assets/images/logo.svg";
import { style } from "../RegistrationScreen/index";
import styles from "./index.module.scss";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import CloseIcon from "@mui/icons-material/Close";

const ListingLoginScreen = () => {
  const {
    signInListing,
    messageText,
    setMessageText,
    open,
    setOpen,
    errorMessageText,
  } = useClientAPI();

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    userName: Yup.string()
      .required("Required")
      .email("Please enter valid email")
      .nullable(),
    password: Yup.string().required("Required"),
  });
  const [showPassword, setShowPassword] = useState<any>(false);
  const handleClickShowPassword = () => setShowPassword((show: any) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const formikLogin: any = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: () => submitDataLogin(),
  });
  const submitDataLogin = async () => {
    console.log(formikLogin?.values);
    await signInListing(formikLogin?.values);
  };
  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      submitDataLogin();
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={messageText}
        action={action}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={styles.formWrapper}
      >
        <Stack
          justifyContent="center"
          sx={{ background: "#fff" }}
          className={styles.formContainer}
        >
          <img
            src={ellipse}
            alt="ellipse"
            className={`${styles.responsiveImg} ${styles.formBgImg}`}
          />
          <Stack sx={{ px: 6, py: 4, position: "relative" }}>
            <Stack flexDirection="row">
              <img
                src={logo}
                alt="logo"
                className={styles.responsiveImg}
                style={{ maxWidth: "100px" }}
              />
            </Stack>
            {errorMessageText == "" ? (
              <Stack mt={3} mb={1}>
                <Typography variant="h4" fontSize={20} fontWeight={600}>
                  Welcome to Resale
                </Typography>
                <Typography variant="body2" fontSize={14} fontWeight={300}>
                  Please sign in to get started.
                </Typography>
              </Stack>
            ) : (
              <Stack mt={3} mb={1}>
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="h4"
                  fontSize={20}
                  fontWeight={600}
                >
                  <Error className={styles.errorTriangle} />
                  {errorMessageText}
                </Typography>
                <Typography variant="body2" fontSize={14} fontWeight={300}>
                  Please try again or click on forgot password.
                </Typography>
              </Stack>
            )}

            {/*incorrect password error msg*/}

            <FormikProvider value={formikLogin}>
              <Grid container rowSpacing={1} columnSpacing={5}>
                <Grid item xs={12}>
                  <Field name="userName">
                    {({ field, meta }: any) => (
                      <TextField
                        {...field}
                        id="filled-basic"
                        variant="filled"
                        required
                        autoComplete="off"
                        fullWidth
                        label="Email Address"
                        sx={style.textField}
                        {...{ error: meta.touched && meta.error }}
                        helperText={meta.touched && meta.error && meta.error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="password">
                    {({ field, meta }: any) => (
                      <TextField
                        {...field}
                        type={showPassword ? "text" : "password"}
                        onKeyPress={(event) => handleKeypress(event)}
                        label="Password"
                        id="filled-basic"
                        variant="filled"
                        autoComplete="off"
                        required
                        fullWidth
                        sx={style.textField}
                        {...{ error: meta.touched && meta.error }}
                        helperText={meta.touched && meta.error && meta.error}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                sx={{
                                  p: 0,
                                  mr: 1,
                                  "&:hover": { backgroundColor: "unset" },
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={1}
              >
                <FormControlLabel
                  sx={{
                    ml: 0,
                    ".MuiButtonBase-root": {
                      p: 0,
                      mr: 0.5,
                    },
                    ".MuiTypography-root": { fontSize: 12, fontWeight: 500 },
                  }}
                  control={<Checkbox size="small" />}
                  label="Remember me"
                />
                <Link
                  to="/listing-forget-password"
                  className={styles.bluelinks}
                >
                  Forgot?
                </Link>
              </Stack>
              <Button
                variant="contained"
                className={styles.filledBtn}
                sx={{
                  my: 2,
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", backgroundColor: "#004EFF" },
                }}
                onClick={() => formikLogin.handleSubmit()}
              >
                Login
              </Button>
              
             
              <Typography variant="body2" mb={0.5}>
                Don't have an account?{" "}
                <Link to="/listing-registration" className={styles.bluelinks}>
                  Sign up Now!
                </Link>
              </Typography>
              <Typography variant="body2">
                By signing in, you agree to our{" "}
                <Link to="" className={styles.bluelinks}>
                  Terms & Privacy Policy.
                </Link>
              </Typography>
            </FormikProvider>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
export default ListingLoginScreen;
// color: rgba(0, 0, 0, 0.6);
// font-family: "Roboto","Helvetica","Arial",sans-serif;
// font-weight: 400;
// font-size: 1rem;
// line-height: 1.4375em;
// letter-spacing: 0.00938em;
// padding: 0;
// position: relative;
// display: block;
// transform-origin: top left;
// white-space: nowrap;
// overflow: hidden;
// text-overflow: ellipsis;
// max-width: calc(133% - 24px);
// position: absolute;
// left: 0;
// top: 0;
// -webkit-transform: translate(12px, 7px) scale(0.75);

// MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-filled MuiFormLabel-colorPrimary Mui-required MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-filled css-e4w4as-MuiFormLabel-root-MuiInputLabel-root

// MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-filled MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-filled css-o943dk-MuiFormLabel-root-MuiInputLabel-root
