// import loaderGif from "../assets/loader.gif";
import  "../loader/index.scss";
import Spinner from 'react-bootstrap/Spinner';
const Loader = () => {
  return (<>
    {/* <div className="loaderContainer">
        <img src={loaderGif} className="img-fluid" width={100} height={100} />
    
    </div> */}
   <div className="loaderContainer">
    <Spinner animation="border" variant="primary" role="status" className="loadingLoder">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
    </div>


  </>
  );
};

export default Loader;
