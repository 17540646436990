import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Header from "../../components/header/Header";
import { useAuth } from "../../shared/provider/authprovider";
// import { CSVLink } from "react-csv";
import { Box, Link } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Close from "../../../src/assets/icons/close.svg";
import Search from "../../../src/assets/icons/searchStatus.svg";
import { ReactComponent as ResetFilter } from "../../../src/assets/icons/filter-reset.svg";
import { ReactComponent as Calendar } from "../../../src/assets/icons/searchCalendar.svg";
// import { ReactComponent as Search } from "../../../src/assets/icons/searchStatus.svg";
import { ReactComponent as SortIcon } from "../../../src/assets/icons/sorting/sorting.svg";
import ErrorModal from '../../components/errorModal/ErrorModal';
import Loader from '../../loader';
import { useVendorsAPI } from '../../shared/api/vendor';

const VendorDetailsOrders = (props: any) => {
    const id = localStorage.getItem("vendorClassification")
    let navigate = useNavigate();
    const location = useLocation();
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(50);
    const start = localStorage.getItem("StartDateVendor");
    const end = localStorage.getItem("EndDateVendor");
    const vendor = localStorage.getItem("vendorClassification")
    const [column, setColumn] = useState("createdDateUtc")
    const [column2, setColumn2] = useState("orderDate")
    const [order, setOrder] = useState("DSC")
    const [vendorOrderDetails, setVendorOrderDetails] = useState<any | null>(null)
    const [vendorOrdersCount, setVendorOrdersCount] = useState<any | null>(null)
    const [vendorProductDetails, setVendorProductDetails] = useState<any | null>(null)
    const [vendorProductCount, setVendorProductCount] = useState<any | null>(null)
    const [vendorCancelledDetails, setVendorCancelledDetails] = useState<any | null>(null)
    const [vendorCancelledCount, setVendorCancelledCount] = useState<any | null>(null)
    const [isNewExportLoader, setNewExportLoader] = React.useState(false)

    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setOffset((value - 1) * limit);
        if (location.state == 6) {
            const resp = await getVendorProductsDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, (value - 1) * limit, limit, column, order, prefix)
            setVendorProductDetails(resp?.data?.result)
            setVendorProductCount(resp?.data?.count)
        }
        else if (location.state == 5) {
            const result = await getVendorOrderDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, (value - 1) * limit, limit, column2, order, prefix)
            setVendorOrderDetails(result?.data?.result)
            setVendorOrdersCount(result?.data?.count)
        }
        else if (location.state == 1) {
            const response = await getVendorCancelledDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, (value - 1) * limit, limit, column2, order, prefix)
            setVendorCancelledDetails(response?.data?.result)
            setVendorCancelledCount(response?.data?.count)
        }
        setPage(value);
    };
    const { isLoading, setErrorMsg, setErrorModalShow, errorModalShow, setSelectedIndex } = useAuth();
    const { getVendorOrderDetails, getVendorProductsDetails, getVendorCancelledDetails, getVendorExportDetails } = useVendorsAPI()
    const [heading, setHeading] = useState<any>("Vendor Orders")
    const exportLinkFun = async (option: any) => {
        const resp = await getVendorExportDetails(id, startDate, endDate, 0)
        const url = URL.createObjectURL(new Blob([resp?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${id} ${option == 1 ? "Refunds & Cancellation" : ""} ${option == 0 ? "Orders" : ""}${(option == 2 ? "Products" : "")}(${startDate}-${endDate}).xlsx`);
        document.body.appendChild(link);
        link.click();
    }
    const exportToExcel = async () => {
        if (location.state == 5) {
            setNewExportLoader(true)
            await exportLinkFun(0)
            setNewExportLoader(false)
        }
        else if (location.state == 6) {
            setNewExportLoader(true)
            await exportLinkFun(2)
            setNewExportLoader(false)
        }
        else if (location.state == 1) {
            setNewExportLoader(true)
            await exportLinkFun(1)
            setNewExportLoader(false)
        }
    }
    const [prefix, setPrefix] = React.useState("");
    const handleKeypress = (e: any) => {
        if (e.key === "Enter") {
            onTapSearch();
        }
    };
    const onTapSearch = async () => {
        setOffset(0)
        setPage(1)
        if (location.state == 5) {
            const result = await getVendorOrderDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, 0, limit, column2, order, prefix)
            setVendorOrderDetails(result?.data?.result)
            setVendorOrdersCount(result?.data?.count)
        }
        else if (location.state == 6) {
            const resp = await getVendorProductsDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, 0, limit, column, order, prefix)
            setVendorProductDetails(resp?.data?.result)
            setVendorProductCount(resp?.data?.count)
        }
        else if (location.state == 1) {
            const response = await getVendorCancelledDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, 0, limit, column2, order, prefix)
            setVendorCancelledDetails(response?.data?.result)
            setVendorCancelledCount(response?.data?.count)
        }
    };
    const onTapReset = async () => {
        setPrefix("");
        if (location.state == 5) {
            const result = await getVendorOrderDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, offset, limit, column2, order, "")
            setVendorOrderDetails(result?.data?.result)
            setVendorOrdersCount(result?.data?.count)
        }
        else if (location.state == 6) {
            const resp = await getVendorProductsDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, offset, limit, column, order, "")
            setVendorProductDetails(resp?.data?.result)
            setVendorProductCount(resp?.data?.count)
        }
        else if (location.state == 1) {
            const response = await getVendorCancelledDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, offset, limit, column2, order, "")
            setVendorCancelledDetails(response?.data?.result)
            setVendorCancelledCount(response?.data?.count)
        }
    };
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const getSortBy = async (column: any) => {
        setColumn(column)
        setColumn2(column)
        setOrder("ASC")
        if (location.state == 5) {
            const result = await getVendorOrderDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, offset, limit, column, "ASC", prefix)
            setVendorOrderDetails(result?.data?.result)
            setVendorOrdersCount(result?.data?.count)
        }
        else if (location.state == 1) {
            const response = await getVendorCancelledDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, offset, limit, column, "ASC", prefix)
            setVendorCancelledDetails(response?.data?.result)
            setVendorCancelledCount(response?.data?.count)
        }
        else if (location.state == 6) {
            const resp = await getVendorProductsDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, offset, limit, column, "ASC", prefix)
            setVendorProductDetails(resp?.data?.result)
            setVendorProductCount(resp?.data?.count)
        }
    }
    const getSortByDesc = async (column: any) => {
        setColumn(column)
        setColumn2(column)
        setOrder("DSC")
        if (location.state == 5) {
            const result = await getVendorOrderDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, offset, limit, column, "DSC", prefix)
            setVendorOrderDetails(result?.data?.result)
            setVendorOrdersCount(result?.data?.count)
        }
        else if (location.state == 1) {
            const response = await getVendorCancelledDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, offset, limit, column, "DSC", prefix)
            setVendorCancelledDetails(response?.data?.result)
            setVendorCancelledCount(response?.data?.count)
        }
        else if (location.state == 6) {
            const resp = await getVendorProductsDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, offset, limit, column, "DSC", prefix)
            setVendorProductDetails(resp?.data?.result)
            setVendorProductCount(resp?.data?.count)
        }
    }

    const loadInitialDataOrder = async () => {
        const result = await getVendorOrderDetails(id, start, end, offset, limit, column2, order, prefix)
        setVendorOrderDetails(result?.data?.result)
        setVendorOrdersCount(result?.data?.count)
    }
    const loadInitialDataProducts = async () => {
        const resp = await getVendorProductsDetails(id, start, end, offset, limit, column2, order, prefix)
        setVendorProductDetails(resp?.data?.result)
        setVendorProductCount(resp?.data?.count)
    }
    const loadInitialDataCancelled = async () => {
        const response = await getVendorCancelledDetails(id, start, end, offset, limit, column, order, prefix)
        setVendorCancelledDetails(response?.data?.result)
        setVendorCancelledCount(response?.data?.count)
    }
    useEffect(() => {
        console.log("vendor", vendor)
        if (location.state == 5) {
            loadInitialDataOrder()
            setHeading("Orders")
        }
        else if (location.state == 6) {
            loadInitialDataProducts()
            setHeading("Products")
        }
        else if (location.state == 1) {
            setHeading("Refunds & Cancellation")
            loadInitialDataCancelled()
        }
        setSelectedIndex(4)

    }, [])
    const [startDate, setStartDate] = useState<any>(start);
    const [endDate, setEndDate] = useState<any>(end);
    const handleStartDate = (value: any) => {
        if (value == null) {
            setStartDate(start == null ? "" : start);
        }
        else {
            const result = moment(value).format("MM-DD-YYYY")
            setStartDate(result);
        }
    }
    const handleEndDate = (value: any) => {
        if (value == null) {
            setEndDate(end == null ? "" : end);
        }
        else {
            const result = moment(value).format("MM-DD-YYYY")
            setEndDate(result);
        }
    }
    const onTapApply = async () => {
        if (location.state == 5) {
            if (moment(startDate).isSameOrBefore(endDate)) {
                const result = await getVendorOrderDetails(id, startDate == null ? start : startDate, endDate == null ? end : endDate, offset, limit, column2, order, prefix)
                setVendorOrderDetails(result?.data?.result)
                setVendorOrdersCount(result?.data?.count)
            }
            else {
                setErrorMsg("Please select start date less than end date");
                setErrorModalShow(true);
            }
        }
        else if (location.state == 6) {
            if (moment(startDate).isSameOrBefore(endDate)) {
                const resp = await getVendorProductsDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, offset, limit, column, order, prefix)
                setVendorProductDetails(resp?.data?.result)
                setVendorProductCount(resp?.data?.count)
            }
            else {
                setErrorMsg("Please select start date less than end date");
                setErrorModalShow(true);
            }
        }
        if (location.state == 1) {
            if (moment(startDate).isSameOrBefore(endDate)) {
                const response = await getVendorCancelledDetails(id, startDate == "" ? start : startDate, endDate == "" ? end : endDate, offset, limit, column2, order, prefix)
                setVendorCancelledDetails(response?.data?.result)
                setVendorCancelledCount(response?.data?.count)
            }
            else {
                setErrorMsg("Please select start date less than end date");
                setErrorModalShow(true);
            }
        }
    }
    const resetFilter = async () => {
        setColumn("createdDateUtc")
        setColumn2("orderDate")
        setOrder("DSC")
        setOffset(0)
        setPrefix("")
        setEndDate(end == null ? "" : end)
        setStartDate(start == null ? "" : start)
        if (location.state == 5) {
            const result = await getVendorOrderDetails(id, start, end, 0, limit, column2, order, "")
            setVendorOrderDetails(result?.data?.result)
            setVendorOrdersCount(result?.data?.count)
        }
        else if (location.state == 6) {
            const resp = await getVendorProductsDetails(id, start, end, 0, limit, column2, order, "")
            setVendorProductDetails(resp?.data?.result)
            setVendorProductCount(resp?.data?.count)
        }
        else if (location.state == 1) {
            const response = await getVendorCancelledDetails(id, start, end, 0, limit, column, order, "")
            setVendorCancelledDetails(response?.data?.result)
            setVendorCancelledCount(response?.data?.count)
        }
    }
    return (
        <>
            {errorHandle()}
            <Header />
            <Box className="contentMain"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" onClick={() => navigate(-2)}>Vendors Dashboard</Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            onClick={() => navigate(-1)}>
                            {vendor} Dashboard
                        </Link>
                        <Link
                            underline="hover"
                            color="text.primary"
                            aria-current="page">
                            {vendor} {heading}
                        </Link>
                    </Breadcrumbs>
                </div>
                <div className='vendorOrderContainer'>
                    <div className='startMain'>
                        <h5 className='pageHead'>{vendor} {heading}</h5>
                        <div className='d-flex w-100 justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <div className="search-input serchContainer d-inline-flex">
                                    <img src={Search} className='searchIcon' />
                                    {/* <Search className='searchIcon' /> */}
                                    <input type="search" placeholder="Search" value={prefix}
                                        onKeyPress={(event) => handleKeypress(event)}
                                        onChange={(event) => setPrefix(event.target.value)} />
                                    <img src={Close} className="pointer" onClick={() => onTapReset()} />
                                </div>


                                <div className='d-flex align-items-center filterContainer'>
                                    <div className='d-flex align-items-center'>

                                        <div className='borderDiv filterItem'>
                                            <div className='dateInput from d-flex align-items-center'>
                                                <label className='w-100 formLabel'>From: </label>
                                                <div className="d-flex align-items-center outlinedInput">
                                                    <Calendar />
                                                    <DatePicker className='dateUi' selected={new Date(startDate == null ? start : startDate)}
                                                        onChange={(selectedstart) => handleStartDate(selectedstart)}
                                                        dateFormat='MM-dd-yyyy'
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode='select'
                                                        maxDate={moment(new Date()).toDate()} />
                                                </div>
                                            </div>
                                            <div className='dateInput d-flex align-items-center'>
                                                <label className='w-100 formLabel'>To: </label>
                                                <div className="d-flex align-items-center outlinedInput">
                                                    <Calendar />
                                                    <DatePicker className='dateUi' selected={new Date(endDate == null ? end : endDate)}
                                                        onChange={(selectedend) => handleEndDate(selectedend)}
                                                        dateFormat='MM-dd-yyyy'
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode='select'
                                                        maxDate={moment(new Date()).toDate()} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Tooltip TransitionComponent={Zoom} title="Reset" arrow placement="top">
                                    <ResetFilter className='ms-3 pointer' onClick={() => resetFilter()} />
                                </Tooltip>
                            </div>
                            <div className="d-flex justify-content-end align-items-center w-100">
                                <button className='FilledBtn' onClick={() => onTapApply()}>Apply</button>
                                {!isNewExportLoader ?
                                    <button onClick={(e) => exportToExcel()} className='FilledBtn'>
                                        Export
                                    </button>
                                    :
                                    <button className='FilledBtn processingBtn'>
                                        Exporting File...
                                    </button>

                                }
                            </div>
                        </div>

                    </div>

                    <div className='heightG'>
                        <table className='tableH text-left vendorDetailsTable'>
                            <thead className='thead rounded'>
                                <tr>
                                    {location.state == 6 ?
                                        <td>
                                            <div className='d-flex justify-content-center'>
                                                <p className='mb-0'>Created Date</p>
                                                <div className='sortingIconContainer'>
                                                    <SortIcon className={column == "createdDateUtc" && order == "ASC" ? "sort activeSort" : "sort"} onClick={() => getSortBy("createdDateUtc")} />
                                                    <SortIcon className={column == "createdDateUtc" && order == "DSC" ? "sort activeSort" : "sort"} onClick={() => getSortByDesc("createdDateUtc")} />
                                                </div>
                                            </div>
                                        </td> : ''}
                                    {location.state == 5 || location.state == 1 ?
                                        (<>
                                            <td style={{ width: "247px" }}>
                                                <div className='d-flex justify-content-center'>
                                                    <p className='mb-0'>Order Date</p>
                                                    <div className='sortingIconContainer'>
                                                        <SortIcon className={column2 == "orderDate" && order == "ASC" ? "sort activeSort" : "sort"} onClick={() => getSortBy("orderDate")} />
                                                        <SortIcon className={column2 == "orderDate" && order == "DSC" ? "sort activeSort" : "sort"} onClick={() => getSortByDesc("orderDate")} />
                                                    </div>
                                                </div>
                                            </td>
                                            <td onClick={() => getSortBy("orderId")}>Order Id</td>
                                        </>) : (<></>)
                                    }
                                    {location.state == 6 ? (<><td>Product Id</td><td>Manufacturer</td></>) : <></>}
                                    <td>SKU</td>
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <p className='mb-0'>Title</p>
                                            <div className='sortingIconContainer'>
                                                <SortIcon className={column == "title" && order == "ASC" ? "sort activeSort" : "sort"} onClick={() => getSortBy("title")} />
                                                <SortIcon className={column == "title" && order == "DSC" ? "sort activeSort" : "sort"} onClick={() => getSortByDesc("title")} />
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <p className='mb-0'>Brand</p>
                                            <div className='sortingIconContainer'>
                                                <SortIcon className={column == "brand" && order == "ASC" ? "sort activeSort" : "sort"} onClick={() => getSortBy("brand")} />
                                                <SortIcon className={column == "brand" && order == "DSC" ? "sort activeSort" : "sort"} onClick={() => getSortByDesc("brand")} />
                                            </div>
                                        </div>
                                    </td>
                                    {location.state == 6 ?
                                        (<><td>
                                            <div className='d-flex justify-content-center'>
                                                <p className='mb-0'>Qty Sold</p>
                                                <div className='sortingIconContainer'>
                                                    <SortIcon className={column == "quantitySoldLast30Days" && order == "ASC" ? "sort activeSort" : "sort"} onClick={() => getSortBy("quantitySoldLast30Days")} />
                                                    <SortIcon className={column == "quantitySoldLast30Days" && order == "DSC" ? "sort activeSort" : "sort"} onClick={() => getSortByDesc("quantitySoldLast30Days")} />
                                                </div>
                                            </div>
                                        </td>
                                            <td>Store Price</td></>)
                                        :
                                        (<></>)
                                    }
                                    {location.state == 5 ?
                                        (<>
                                            <td>
                                                <div className='d-flex justify-content-center'>
                                                    <p className='mb-0'>Qty Sold</p>
                                                    <div className='sortingIconContainer'>
                                                        <SortIcon className={column == "qtySold" && order == "ASC" ? "sort activeSort" : "sort"} onClick={() => getSortBy("qtySold")} />
                                                        <SortIcon className={column == "qtySold" && order == "DSC" ? "sort activeSort" : "sort"} onClick={() => getSortByDesc("qtySold")} />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>ASP</td>
                                            <td>GMV</td>
                                            <td>Selling Fee</td>
                                            <td>Postage</td>
                                            <td>GP</td>

                                        </>)
                                        :
                                        (<></>)
                                    }
                                    {location.state == 1 ?
                                        (<>
                                            <td>
                                                <div className='d-flex justify-content-center'>
                                                    <p className='mb-0'>Qty Sold</p>
                                                    <div className='sortingIconContainer'>
                                                        <SortIcon className={column == "qtySold" && order == "ASC" ? "sort activeSort" : "sort"} onClick={() => getSortBy("qtySold")} />
                                                        <SortIcon className={column == "qtySold" && order == "DSC" ? "sort activeSort" : "sort"} onClick={() => getSortByDesc("qtySold")} />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>ASP</td>
                                            <td>GMV</td></>)
                                        :
                                        (<></>)
                                    }
                                </tr>
                            </thead>
                            {location.state === 5 && vendorOrderDetails ?
                                <tbody className='tbody'>
                                    {!isLoading ?
                                        <>
                                            {vendorOrderDetails?.length > 0 ?
                                                vendorOrderDetails?.map((value: any, index: any) => {
                                                    return (
                                                        <tr key={index}>
                                                            {/* <td>{(limit * (page - 1)) + vendorOrderDetails?.findIndex((x: any) => x.productId === value?.productId) + 1}</td> */}
                                                            <td>{value?.orderDate.slice(5, 7)}-{value?.orderDate.slice(8, 10)}-{value?.orderDate.slice(0, 4)}</td>
                                                            <td>{value?.orderId}</td>
                                                            {/* <td>{value?.site}</td> */}
                                                            <td>{value?.sku}</td>
                                                            <td>
                                                                <div style={{ position: "relative" }}>
                                                                    <div className='txtWidth'>
                                                                        <div className='nonhoverDiv'>{value?.title}</div>
                                                                        <div className='hoverDiv hovertxt'><p>{value?.title}</p></div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{value?.brand}</td>
                                                            <td>{value?.qtySold}</td>
                                                            <td>{formatter.format(value?.asp)}</td>
                                                            <td>{formatter.format(value?.gmv)}</td>
                                                            <td>{formatter.format(value?.sellingFee)}</td>
                                                            <td>{formatter.format(value?.postage)}</td>
                                                            <td>{formatter.format(value?.gp)}</td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={10}>
                                                        <p className='mb-0 py-2'>No records found</p>
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                        :
                                        <Loader />
                                    }
                                </tbody>
                                :
                                <>
                                    {location.state === 5 ? <Loader /> : ''}
                                </>
                            }
                            {
                                location.state === 1 && vendorCancelledDetails ?
                                    <tbody className='tbody'>
                                        {!isLoading ?
                                            <>
                                                {vendorCancelledDetails?.length > 0 ?
                                                    vendorCancelledDetails?.map((value: any, index: any) => {
                                                        return (
                                                            <tr key={index}>
                                                                {/* <td>{(limit * (page - 1)) + vendorCancelledDetails?.findIndex((x: any) => x?.productId === value?.productId) + 1}</td> */}
                                                                <td>{value?.orderDate.slice(5, 7)}-{value?.orderDate.slice(8, 10)}-{value?.orderDate.slice(0, 4)}</td>
                                                                <td>{value?.orderId}</td>
                                                                {/* <td>{value?.site}</td> */}
                                                                <td>{value?.sku}</td>
                                                                <td>
                                                                    <div style={{ position: "relative" }}>
                                                                        <div className='txtWidth'>
                                                                            <div className='nonhoverDiv'>{value?.title}</div>
                                                                            <div className='hoverDiv hovertxt'><p>{value?.title}</p></div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{value?.brand}</td>
                                                                <td>{value?.qtySold}</td>
                                                                <td>{formatter.format(value?.asp)}</td>
                                                                <td>{formatter.format(value?.gmv)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={8}>
                                                            <p className='mb-0 py-2'>No records found</p>
                                                        </td>
                                                    </tr>
                                                }
                                            </>
                                            :
                                            <Loader />
                                        }
                                    </tbody>
                                    :
                                    <>
                                        {location.state === 1 ? <Loader /> : ''}
                                    </>
                            }
                            {location.state === 6 && vendorProductDetails ?
                                <tbody className='tbody'>
                                    {!isLoading ?
                                        <>
                                            {vendorProductDetails?.length > 0 ?
                                                vendorProductDetails?.map((value: any, index: any) => {
                                                    return (
                                                        <tr key={index}>
                                                            {/* <td>{(limit * (page - 1)) + vendorProductDetails?.findIndex((x: any) => x?.productId === value?.productId) + 1}</td> */}
                                                            <td>{value?.createDateUtc.slice(5, 7)}-{value?.createDateUtc.slice(8, 10)}-{value?.createDateUtc.slice(0, 4)}</td>
                                                            <td>{value?.id}</td>
                                                            <td>{value?.manufacturer}</td>
                                                            <td>{value?.sku}</td>
                                                            <td>
                                                                <div style={{ position: "relative" }}>
                                                                    <div className='txtWidth'>
                                                                        <div className='nonhoverDiv'>{value?.title}</div>
                                                                        <div className='hoverDiv hovertxt'><p>{value?.title}</p></div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{value?.brand}</td>
                                                            <td>{value?.quantitySoldLast30Days}</td>
                                                            <td>{formatter.format(value?.storePrice)}</td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={8}>
                                                        <p className='mb-0 py-2'>No records found</p>
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                        :
                                        <Loader />
                                    }
                                </tbody>
                                :
                                <>
                                    {location.state === 6 ? <Loader /> : ''}
                                </>
                            }
                        </table>

                    </div>
                    <div className='d-flex paginationSect align-items-center justify-content-between'>
                        <Stack spacing={2} className="d-flex flex-row align-items-center">
                            <label className='formLabel'>Showing &nbsp;
                                {location.state == 6 ? (vendorProductCount > 0 ? offset + 1 : 0) : ''}
                                {location.state == 5 ? (vendorOrdersCount > 0 ? offset + 1 : 0) : ''}
                                {location.state == 1 ? (vendorCancelledCount > 0 ? offset + 1 : 0) : ''}
                                &nbsp;to&nbsp;
                                {location.state == 6 ? (vendorProductCount > (offset + limit) ? (offset + limit) : vendorProductCount) : ''}
                                {location.state == 5 ? (vendorOrdersCount > (offset + limit) ? (offset + limit) : vendorOrdersCount) : ''}
                                {location.state == 1 ? (vendorCancelledCount > (offset + limit) ? (offset + limit) : vendorCancelledCount) : ''}
                                &nbsp;of <span>{location.state == 6 ? vendorProductCount : ''} {location.state == 5 ? vendorOrdersCount : ''}{location.state == 1 ? vendorCancelledCount : ''}</span> entries</label>
                            <Pagination className="m-0 paginatnNav" size="small" shape="rounded"
                                count={location.state == 6 ? Math.ceil(vendorProductCount / limit) : location.state == 5 ? Math.ceil(vendorOrdersCount / limit) : Math.ceil(vendorCancelledCount / limit)}
                                page={page} onChange={handleChange} />
                        </Stack>
                        {/* <div className='d-flex'>
                            <label className='me-2'>Total Records :</label>
                            <p>{location.state == 6 ? vendorProductCount : ''} {location.state == 5 ? vendorOrdersCount : ''}{location.state == 1 ? vendorCancelledCount : ''}</p>
                        </div> */}

                    </div>
                </div>
            </Box>
        </>
    )
}
export default VendorDetailsOrders