import { useAuth } from "../provider/authprovider";
export const useMisReportsAPI = () => {
  const { setLoading, api } = useAuth()
  const MisReports = async (values: any) => {
    setLoading(true)
    try {
      const result = await api.post(`/api/Report/MIS_LastTwoMonth`, values);
      return result
    } catch {
      console.log("")
    }
    finally {
      setLoading(false)
    }
  };
  const misClassifications = async() => {
    setLoading(true)
    try {
      const result = await api.get(`/api/Report/Classifications`);
      return result
    } catch {
      console.log("")
    }
    finally {
      setLoading(false)
    }
  };
  const getMisBrands = async(classification: any) => {
    try {
      const result = await api.get(`/api/Report/Brands/${classification}`);
      return result
    } catch {
      console.log("")
    }
  };
  const postMisDailyRecords = async( values: any) => {
    setLoading(true)
    try {
      const result = await api.post(`/api/Report/MIS_Daily`, values);
      return result
    } catch {
      console.log("")
    }
    finally {
      setLoading(false)
    }
  };
  return {MisReports, misClassifications, getMisBrands, postMisDailyRecords} as const
}