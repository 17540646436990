import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import Close from "../../../src/assets/icons/close.svg";
import { ReactComponent as Search } from "../../../src/assets/icons/searchStatus.svg";
import { ReactComponent as SortIcon } from "../../../src/assets/icons/sorting/sorting.svg";
import ErrorModal from "../../components/errorModal/ErrorModal";
import Header from "../../components/header/Header";
import Loader from "../../loader";
import { useAuth } from '../../shared/provider/authprovider';

const UpdateProductsScreen = () => {
    const { getManifestMissingLocationsCalled, locationsList, locationsCount, setSelectedIndex, isLoading, errorModalShow, setErrorModalShow } = useAuth()
    const [prefix, setPrefix] = useState("");
    const [order, setOrder] = useState("DSC")
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(30);
    const [page, setPage] = useState(1);
    let navigate = useNavigate();
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setOffset((value - 1) * limit);
        getManifestMissingLocationsCalled("", (value - 1) * limit, limit, order)
        setPage(value);
        console.log("count", locationsCount, "Offset", offset, "limit", limit)

    };
    const onTapSearch = () => {
        setOffset(0)
        setLimit(50)
        setPage(1)
        getManifestMissingLocationsCalled(prefix, offset, limit, order)
    }
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    const handleKeypress = (e: any) => {
        if (e.key === "Enter") {
            onTapSearch();
        }
    };
    const onTapReset = () => {
        setPage(1)
        setPrefix("")
        getManifestMissingLocationsCalled("", offset, limit, order)
    }
    const sortASC = () => {
        setOrder("ASC")
        getManifestMissingLocationsCalled(prefix, offset, limit, "ASC")
    }
    const sortDSC = () => {
        setOrder("DSC")
        getManifestMissingLocationsCalled(prefix, offset, limit, "DSC")

    }
    const onTapLocation = (value: any) => {
        // console.log("location", value)
        navigate(`/location/${value}`)
    }

    useEffect(() => {
        getManifestMissingLocationsCalled("", 0, limit, order)
        console.log("count", locationsCount, "Offset", offset, "limit", limit)
        setSelectedIndex(22)
    }, [])
    return (
        <>
            {errorHandle()}
            <Header />
            <Box className="contentMain updateProdWrapper"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <div className="warehouseL d-flex">
                    <div className="d-flex align-items-start headerM">
                        <div className="headingD">
                            <h5 className="pageHead">Warehouse Locations</h5>
                            <p className="mb-0">{locationsCount} Warehouse Locations </p>
                        </div>
                        <div className="search-input serchContainer d-inline-flex">
                            <Search className='searchIcon' />
                            <input type="search" placeholder="Search" value={prefix}
                                onKeyPress={(event) => handleKeypress(event)}
                                onChange={(event) => setPrefix(event.target.value)} />
                            <img src={Close} className="pointer" onClick={() => onTapReset()} />
                        </div>
                    </div>
                    <div className="sortContainer pointer">
                        <div className="d-flex flex-column sortD">
                            <SortIcon onClick={() => sortASC()} className={order == "ASC" ? "sort activeSort" : "sort"} />
                            <SortIcon onClick={() => sortDSC()} className={order == "DSC" ? "sort activeSort" : "sort"} />
                        </div>
                        <span>Sort</span>
                    </div>
                </div>
                <div className="ProdByL">
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} className="prodStack">
                        {!isLoading ?
                            locationsList?.map((e: any) => (
                                <div className="ProdItem">
                                    <p className=" mb-0 text-start">Location #{locationsList.findIndex((x: any) => x === e) + 1}</p>
                                    <button className="FilledBtn"
                                        onClick={() => onTapLocation(e)} >
                                        {e}
                                    </button>
                                </div>
                            ))
                            :
                            <Loader />
                        }
                    </Stack>
                </div>
                <div className='d-flex paginationSect align-items-center justify-content-between'>
                    <Stack spacing={2} className="d-flex flex-row align-items-center">
                        <label className='formLabel'>Showing {locationsCount > 0 ? offset + 1 : 0} to {locationsCount > (offset + limit) ? (offset + limit) : locationsCount} of {locationsCount} entries</label>
                        <Pagination className="m-0 paginatnNav" size="small" shape="rounded" count={Math.ceil(locationsCount / limit)} page={page} onChange={handleChange} />
                    </Stack>
                </div>
            </Box>

        </>
    )
}
export default UpdateProductsScreen;
