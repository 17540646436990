import { useState } from "react";
import { useAuth } from "../provider/authprovider";
import axios from "axios";
import { environmentUrl } from "./index.service";

export const useDashboardAPI = () => {
    const { setLoading, api, handleError } = useAuth()
    const [dataList, setDataList] = useState<any>([])
    const [countList, setCount] = useState<any>()
    const [adminDashboardLatestProductReportList, setAdminDashboardLatestProductReportList] = useState([])
    const [adminDashboardLatestProductReportListLength, setAdminDashboardLatestProductReportListLength] = useState(0)
    const [adminDashboardScannedProductReportList, setAdminDashboardScannedProductReportList] = useState([])

    const adminDashboardProductsReportNew = async (startDate: any, endDate: any) => {
        setLoading(true)
        try {
            const result = await axios.get(environmentUrl +`/api/AdminDashboard/ProductsReport?FromDate=${startDate}&ToDate=${endDate}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            setDataList(result?.data?.results)
            setCount(result?.data?.count)
            return result
        } catch (error: any) {
            handleError(error)
        } finally {
            setLoading(false)
        }
    };
    const adminDashboardLatestProductReport = async (startDate: any, endDate: any, prefixId: any, prefix: any, offset: any, limit: any, ststusFilter: any) => {
        setLoading(true)
        try {
            const result = await api.get(`/api/AdminDashboard/LatestProductReport?Prifixid=${prefixId}&FromDate=${startDate}&ToDate=${endDate}&Prefix=${prefix}&Offset=${offset}&Limit=${limit}&ScanStatus=${ststusFilter}`);
            setAdminDashboardLatestProductReportList(result?.data?.results)
            setAdminDashboardLatestProductReportListLength(result?.data?.count)
            return result
        } catch (error: any) {
            handleError(error)
        } finally {
            setLoading(false)
        }
    };
    const adminDashboardScannedProductReport = async (startDate: any, endDate: any, scanId: any, offset: any, limit: any) => {
        try {
            const result = await api.get(`/api/AdminDashboard/ScannedProductReport?ScanId=${scanId}&FromDate=${startDate}&ToDate=${endDate}&Offset=${offset}&Limit=${limit}`);
            setAdminDashboardScannedProductReportList(result?.data?.results)
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const adminDashboardScannedProductReportExport = async (prefixId: any, startDate: any, endDate: any, prefix: any, ststusFilter: any) => {
        try {
            const result = await api.get(`/api/AdminDashboard/ExportLatestProductReport?Prifixid=${prefixId}&FromDate=${startDate}&ToDate=${endDate}&Prefix=${prefix}&Status=${ststusFilter}`, {
                responseType: "arraybuffer"
            });
            return result
        } catch (error: any) {
            handleError(error)
        }
    };

    const postCleanData = async () => {
        try {
            const result = await api.post(`/api/Win/Barcode/ResetStatus`);
            return result
        } catch (error: any) {
            handleError(error)
        }
    }
    return {
        adminDashboardProductsReportNew, adminDashboardLatestProductReport, adminDashboardScannedProductReport,
        adminDashboardScannedProductReportExport, postCleanData, dataList, countList,
        adminDashboardLatestProductReportList, adminDashboardLatestProductReportListLength, adminDashboardScannedProductReportList,
        setAdminDashboardScannedProductReportList
    } as const
}