import axios, { AxiosPromise } from "axios";
import { environmentUrl } from "./index.service";
import { useAuth } from "../provider/authprovider";


// export const getVendorExportDetails = (token: any, classification: any, startDate: any, endDate: any, option: any): AxiosPromise<any> => {
//   let url = `${environmentUrl}/api/Vendor/ExportFile?classfication=${classification}&fromDate=${startDate}&toDate=${endDate}&exportType=${option}`;
//   const getVendorDetails = axios.get(url, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//     responseType: "arraybuffer"
//   });
//   return getVendorDetails;
// };

export const useVendorsAPI = () => {
  const { api, handleError, setLoading } = useAuth()
  //add new vendor
  const postNewVendor = async (details: any) => {
    try {
      const result = await api.post(`/api/Vendor`, details);
      return result

    } catch (error: any) {
      handleError(error)
    }
  };
  // delete vendor
  const deleteVendor = async (id: any) => {
    try {
      const result = await api.delete(`/api/Vendor/${id}`);
      return result
    } catch (error: any) {
      handleError(error)
    }
  };
  // get vendor data by id
  const getVendorById = async (id: any) => {
    try {
      const result = await api.get(`/api/Vendor/${id}`);
      return result
    } catch (error: any) {
      handleError(error)
    }
  };
  // edit vendor
  const putVendor = async (id: any, values: any) => {
    try {
      const result = await api.put(`/api/Vendor/${id}`, values);
      return result
    } catch (error: any) {
      handleError(error)
    }

  };
  // classification options
  const getVendorClassificationOption = async () => {
    try {
      const result = await api.get(`/api/Vendor/Classifications`);
      return result
    } catch (error: any) {
      handleError(error)
    }
  };
  //get all vendors list
  const getVendorList = async (offset: any, limit: any) => {
    try {
      const result = await api.get(`/api/Vendor?Offset=${offset}&Limit=${limit}`);
      return result
    } catch (error: any) {
      handleError(error)
    }
  };
  const postImportPostageExcel = async (file: any) => {
    try {
      const result = await api.post(`/api/Vendor/ImportExcel`, file, { responseType: "arraybuffer" });
      return result
    } catch (error: any) {
      handleError(error)
    }
  };
  const getVendorDashboardCards = async (classification: any) => {
    try {
      const result = await api.get(`/api/Vendor/KPI_Scanning?classification=${classification}`);
      return result
    } catch (error: any) {
      handleError(error)
    }
  };
  const getVendorDashboardKPI = async (details: any) => {
    setLoading(true)
    try {
      const result = await api.post(`/api/Vendor/KPI`, details);
      return result
    } catch (error: any) {
      handleError(error)
    }
    finally {
      setLoading(false)
    }
  };
  const getVendorOrderDetails = async(classification: any, startDate: any, endDate: any, offset: any, limit: any, column: any, order: any, prefix: any) => {
    setLoading(true)
    try {
      const result = await api.get(`/api/Vendor/GetSales?classfication=${classification}&fromDate=${startDate}&toDate=${endDate}&offset=${offset}&limit=${limit}&sortBy=${column}&orderBy=${order}&prefix=${prefix}`);
      return result
    } catch (error: any) {
      handleError(error)
    }
    finally {
      setLoading(false)
    }
  };
  const getVendorProductsDetails = async(classification: any, startDate: any, endDate: any, offset: any, limit: any, column: any, order: any, prefix: any) => {
    setLoading(true)
    try {
      const result = await api.get(`/api/Vendor/GetProducts?classfication=${classification}&fromDate=${startDate}&toDate=${endDate}&offset=${offset}&limit=${limit}&sortBy=${column}&orderBy=${order}&prefix=${prefix}`);
      return result
    } catch (error: any) {
      handleError(error)
    }
    finally {
      setLoading(false)
    }
  };
  const getVendorCancelledDetails = async(classification: any, startDate: any, endDate: any, offset: any, limit: any, column: any, order: any, prefix: any) => {
    setLoading(true)
    try {
      const result = await api.get(`/api/Vendor/GetCanceledOrders?classfication=${classification}&fromDate=${startDate}&toDate=${endDate}&offset=${offset}&limit=${limit}&sortBy=${column}&orderBy=${order}&prefix=${prefix}`);
      return result
    } catch (error: any) {
      handleError(error)
    }
    finally {
      setLoading(false)
    }
  };
  const getVendorExportDetails = async(classification: any, startDate: any, endDate: any, option: any) => {
    try {
      const result = await api.get(`/api/Vendor/ExportFile?classfication=${classification}&fromDate=${startDate}&toDate=${endDate}&exportType=${option}`, {responseType: "arraybuffer"});
      return result
    } catch (error: any) {
      handleError(error)
    }
  };
  return {
    postNewVendor, deleteVendor, getVendorById, putVendor, getVendorClassificationOption, getVendorList,
    postImportPostageExcel, getVendorDashboardCards, getVendorDashboardKPI, getVendorOrderDetails,
    getVendorProductsDetails, getVendorCancelledDetails, getVendorExportDetails
  } as const
}