import axios, { AxiosPromise } from "axios";
import { environmentUrl } from "./index.service";
import { useAuth } from "../provider/authprovider";

export const signIn = (details: any): AxiosPromise<any> => {
    let url = environmentUrl + "/api/Authentication/Token";
    // console.log("SignIn URL", url);
    return axios.post(url, details);
};
export const getUserList = (token: any, prefix: any, offset: any, limit: any): AxiosPromise<any> => {
    let url =
        environmentUrl + `/api/User/List?Prefix=${prefix}&Offset=${offset}&Limit=${limit}&IsActivate=true`;
    const user = axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return user;
};
export const useUsersAPI = () => {
    const { api, handleError, setLoading } = useAuth()
    const createUser = async (details: any) => {
        try {
            const result = await api.post(`/api/User`, details);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const editUser = async(details: any, id: any) => {
        try {
            const result = await api.put(`/api/User/${id}`, details);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const deleteUser = async(id: any) => {
        try {
            const result = await api.delete(`/api/User/Deactivate/${id}`);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const getUserById = async(id: any) => {
        try {
            const result = await api.get(`/api/User/${id}`);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const resetPassword = async(details: any) => {
        try {
            const result = await api.post(`/api/User/ResetPassword`, details);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const forgotPassword = async(details: any) => {
        setLoading(true)
        try {
            const result = await api.post(`/api/User/ForgotPassword`, details);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally{
            setLoading(false)
        }
    };
    const getUserRoles = async() => {
        try {
            const result = await api.get(`/api/Roles`);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const addAccount = async (details: any) => {
        try {
            const result = await api.post(`/api/Account`, details);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    const getAccountsList =async() => {
        try {
            const result = await api.get(`/api/Account?offset=0&limit=1000`);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    return {
        createUser, editUser, deleteUser, getUserById, resetPassword, forgotPassword, getUserRoles,
        addAccount, getAccountsList
    } as const
}