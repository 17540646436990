import { Box, MenuItem, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import Header from "../../components/header/Header"
import { useAuth } from "../../shared/provider/authprovider";
import filter from "../../assets/filter.svg";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { ReactComponent as ResetFilter } from "../../../src/assets/icons/filter-reset.svg";
import { ReactComponent as RightArrow } from "../../../src/assets/icons/left-right/Right-Arrow.svg";
import { ReactComponent as Calendar } from "../../../src/assets/icons/searchCalendar.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { ISO_8601 } from 'moment';
import ErrorModal from "../../components/errorModal/ErrorModal";
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import { Field, Form, Formik, FormikProvider, useFormik } from "formik";
import './resetdata.scss';
import { useResetDataAPI } from "../../shared/api/resetData";
interface MyFormValues {
    fromDate: string,
    toDate: string,
    statusFrom: number,
    statusTo: string,
    comments: string,
    prefix: string
}
const ResetDataScreen = () => {
    const { setSelectedIndex, errorModalShow, setErrorModalShow, setErrorMsg } = useAuth()
    const { getResetDataPrefixList, putResetDataChangeProductStatus } = useResetDataAPI()
    const resetFilter = () => {
        console.log("reset")
    }
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };

    var day1: any = moment().startOf('month')
    var firstDay = moment(day1, "en-us").format("MM-DD-YYYY")

    var date = new Date();
    var firstDayNew = new Date(date.getFullYear(), date.getMonth(), 1);

    var day2: any = moment()
    var todayDate = moment(day2, "en-us").format("MM-DD-YYYY")

    var todayDateNew = moment()
    const [startDate, setStartDate] = useState<any>(new Date());
    const [endDate, setEndDate] = useState<any>(new Date());
    const [newStartDate, setNewStartDate] = useState<any>(new Date())
    const [newEndDate, setNewEndDate] = useState<any>(new Date())
    const [resetDataPrefixList, setResetDataPrefixList] = useState<any | null>(null)

    const handleStartDate = (value: any) => {
        if (value == null) {
            setStartDate(firstDay);
            setNewStartDate(firstDayNew)
            formik.setFieldValue("fromDate", firstDay);
        }
        else {
            const result = moment(value, ISO_8601).format("MM-DD-YYYY")
            setStartDate(result);
            setNewStartDate(new Date(value))
            formik.setFieldValue("fromDate", result);
        }
    }

    const handleEndDate = (value: any) => {
        if (value == null) {
            setEndDate(todayDate);
            setNewEndDate(todayDateNew)
            formik.setFieldValue("toDate", todayDate);
        }
        else {
            const result = moment(value, ISO_8601).format("MM-DD-YYYY")
            setEndDate(result);
            setNewEndDate(new Date(value))
            formik.setFieldValue("toDate", result);
        }
    }
    const onTapApply = () => {
        if (Date.parse(newStartDate) <= Date.parse(newEndDate)) {
            // putResetDataChangeProductStatusCalled(formik?.values);
        }
        else {
            setErrorMsg("Please select start date less than end date");
            setErrorModalShow(true);
        }
    }
    const formik: any = useFormik({
        initialValues: {
            fromDate: firstDay,
            toDate: todayDate,
            statusFrom: 0,
            statusTo: 0,
            comments: "",
            prefix: ""
        },
        onSubmit: async () => await submitData(),
    })
    const submitData = async () => {
        console.log("formik", formik.values)
        if (Date.parse(newStartDate) <= Date.parse(newEndDate)) {
            const res = await putResetDataChangeProductStatus(formik?.values);
            if (res?.data?.status === 200) {
                setErrorModalShow(true);
                setErrorMsg(res?.data?.results);
            }
        }
        else {
            setErrorMsg("Please select start date less than end date");
            setErrorModalShow(true);
        }

        formik.resetForm();
    }

    const statusList2 = [
        { value: 1, label: 1 },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
        { value: 11, label: "11" },
        { value: 12, label: "12" },
        { value: 13, label: "13" },
        { value: 14, label: "14" }
    ]
    const loadInitialData = async () => {
        const response = await getResetDataPrefixList()
        setResetDataPrefixList(response?.data?.results)
    }
    useEffect(() => {
        setSelectedIndex(50)
        setNewStartDate(firstDayNew)
        setNewEndDate(todayDateNew)
        setStartDate(firstDay)
        setEndDate(todayDate)
        loadInitialData();
    }, [])
    return (
        <>
            {errorHandle()}
            <Header />
            <Box
                className="contentMain updateProdWrapper guidedProdWrapper"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <h5 className="pageHead">Reset Data</h5>
                <div className='startMain'>
                    <FormikProvider value={formik} >

                        <div className='formContainer'>

                            <div className='row align-items-center  formItem'>
                                <div className="d-flex align-items-center">
                                    <div className='col-3'>
                                        <label htmlFor="location">Date  </label>
                                    </div>
                                    <div className='col-1'>
                                        <label>From </label>
                                    </div>
                                    <div className='col-8'>

                                        <div className="d-flex align-items-center dateDiv">

                                            <Calendar />
                                            <DatePicker className='textfielddate' selected={new Date(newStartDate)}
                                                onChange={(selectedstart) => handleStartDate(selectedstart)}
                                                dateFormat='MM-dd-yyyy'
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode='select'
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mt-3">
                                    <div className='col-3'>

                                    </div>
                                    <div className='col-1'>
                                        <label>To </label>
                                    </div>
                                    <div className='col-8'>

                                        <div className="d-flex align-items-center dateDiv">

                                            <Calendar />
                                            <DatePicker className='textfielddate' selected={new Date(newEndDate)}
                                                onChange={(selectedstart) => handleEndDate(selectedstart)}
                                                dateFormat='MM-dd-yyyy'
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode='select'
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row align-items-center formItem mt-3'>
                                <div className="d-flex align-items-center">
                                    <div className='col-4'>
                                        <label className='w-100 formLabel'>Prefix</label>
                                    </div>
                                    <div className='col-8'>
                                        <Field name="prefix" className="outlinedInput">
                                            {({ field }: any) => (
                                                <select {...field} className='textfield'>
                                                    <option value="">Select Prefix</option>
                                                    {resetDataPrefixList?.map((v: any) => {
                                                        return (
                                                            <option className="clickItem">{v}</option>
                                                        )
                                                    })}
                                                </select>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>

                            <div className='row align-items-center formItem mt-3'>
                                <div className="d-flex align-items-center">
                                    <div className='col-3'>
                                        <label htmlFor="location">Status </label>
                                    </div>
                                    <div className='col-1'>
                                        <label>From </label>
                                    </div>
                                    <div className='col-8'>
                                        <div className='selectStatusField'>
                                            <Field name="statusFrom">
                                                {({ field }: any) => (

                                                    <TextField
                                                        {...field}
                                                        select
                                                        fullWidth
                                                    >
                                                        <MenuItem value={0}>0</MenuItem>

                                                        {statusList2.map((e: any) => (
                                                            <MenuItem value={e.value}>{e.label}</MenuItem>
                                                        ))}
                                                    </TextField>

                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row align-items-center formItem'>
                                <div className="d-flex align-items-center">
                                    <div className='col-3'>

                                    </div>
                                    <div className='col-1'>
                                        <label>To </label>
                                    </div>
                                    <div className='col-8'>
                                        <div className='selectStatusField'>
                                            <Field name="statusTo">
                                                {({ field }: any) => (

                                                    <TextField
                                                        {...field}
                                                        select
                                                        fullWidth
                                                    >
                                                        <MenuItem value={0}>0</MenuItem>
                                                        {statusList2.map((e: any) => (
                                                            <MenuItem value={e.value}>{e.label}</MenuItem>
                                                        ))}
                                                    </TextField>

                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row align-items-center formItem'>
                                <div className="d-flex align-items-center">
                                    <div className='col-4'>
                                        <label className='w-100 formLabel'>Comments</label>
                                    </div>
                                    <div className='col-8'>
                                        <Field className='textfield' id="comments" name="comments" placeholder="Enter Comments" />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>

                                <div className="d-flex mt-3">
                                    <div className=' col-4 '>

                                    </div>
                                    <div className=' col-4'>

                                        <Button className="FilledBtn" type="submit" onClick={() => formik.handleSubmit()}>Submit</Button>
                                    </div>
                                    <div className='col-4'>

                                        <Button className="FilledBtn" type="submit" onClick={() => formik.resetForm()}>Cancel</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormikProvider>

                </div>
            </Box>
        </>
    )
}
export default ResetDataScreen