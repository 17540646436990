import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Divider, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import * as React from "react";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import HeaderListing from "../../../components/headerListing";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import MessagePopup from "../MessagePopop";
import AproovedUserManagement from "./AproovedUserManagement";
import UserReqestManagement from "./UserRequestManagement";
import "./index.module.scss";
import styles from "./index.module.scss";
import ManageSubscriptionScreen from "./manageSubscriptionScreen";

// import ManageUser from "./manageUser";

export const style = {
  tabContainer: {
    ".MuiTabs-indicator": {
      backgroundColor: "#000000",
      height: "1px",
    },
  },
  tabList: {
    ".MuiButtonBase-root": {
      color: "#68737D",
      fontWeight: 500,
      fontSize: 14,
      textTransform: "capitalize",
      "&.Mui-selected": {
        color: "#1E1F21",
      },
    },
  },
  borderStack: {
    border: "1px solid #E0E0E0",
    borderRadius: 0.5,
    height: 32,
  },
  fiiledBtn: {
    background: "linear-gradient(95.28deg, #0038BA -5.39%, #4F18FF 102.93%)",
    borderRadius: 0.5,
    textTransform: "capitalize",
    height: 32,
    width: 100,
    ml: 2,
    boxShadow: "unset",
    fontSize: 14,
  },
  dialogContainer: {
    ".MuiInputLabel-root": {
      color: "#1A1918",
      fontSize: 14,
      fontWeight: 500,
      pl: 1.5,
    },
    ".MuiInputBase-root": {
      pl: 1.5,
      "&:before": {
        borderBottom: "1px solid #E0E0E0",
      },
    },
    ".Mui-error": {
      pl: 1.5,
    },
  },
  modalBtn: {
    width: "100%",
    maxWidth: "100px",
    borderRadius: 0.5,
    textTransform: "capitalize",
    fontSize: 14,
    height: 34,
  },
};

const SuperAdminDashboard = () => {
  const { getListingUserList } = useClientAPI();
  const [requestdUserCount, setRequestdUserCount] = useState<any>(0);
  const [approovedUserCount, setApprooveddUserCount] = useState<any>(0);

  const [value, setValue] = React.useState("1");

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const loadInitialData = async () => {
    const result = await getListingUserList("", 0, 1000, true, 0);
    setRequestdUserCount(result?.data?.result?.count);
    const resultUser = await getListingUserList("", 0, 1000, true, 1);
    setApprooveddUserCount(resultUser?.data?.result?.count);
  };
  useEffect(() => {
    loadInitialData();
  }, []);
  return (
    <>
      <MessagePopup />
      <HeaderListing />
      <Box
        className={styles.contentMain}
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", py: 3 }}
      >
        <Typography variant="h4" ml={2} fontSize={20}>
          Account Management
        </Typography>
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Box sx={style.tabContainer}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
                sx={style.tabList}
              >
                <Tab label={`Users ${approovedUserCount}`} value="1" />
                <Tab
                  label={`Request ${requestdUserCount}`}
                  value="2"
                  sx={{ color: "#fff" }}
                />
                <Tab label="Subscription" value="3" />
              </TabList>
            </Box>
            <Divider
              sx={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#E0E0E0",
                borderBottomWidth: "inherit",
                opacity: 1,
              }}
            />
            <TabPanel value="1">
              <AproovedUserManagement />
            </TabPanel>
            <TabPanel value="2">
              <UserReqestManagement />
            </TabPanel>
            <TabPanel value="3">
              <ManageSubscriptionScreen />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
};
export default SuperAdminDashboard;
