import { Box, Link } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import FileSaver from 'file-saver';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import XLSX from 'sheetjs-style';
import { ReactComponent as ResetFilter } from "../../../src/assets/icons/filter-reset.svg";
import { ReactComponent as Calendar } from "../../../src/assets/icons/searchCalendar.svg";
import filter from "../../assets/filter.svg";
import ErrorModal from '../../components/errorModal/ErrorModal';
import Header from '../../components/header/Header';
import Loader from '../../loader';
import { useAuth } from "../../shared/provider/authprovider";
import './WinServices.scss';
import { useWinServicesAPI } from '../../shared/api/winservices';

const WinServicesByName = (propsValue: any) => {
    let navigate = useNavigate();
    const CronName = localStorage.getItem("CronName");
    const location = useLocation();
    const { id } = useParams();
    const [startDate, setStartDate] = useState(null);
    const [formatedStartDate, setFormatedStartDate] = useState("");
    const [endDate, setEndDate] = useState(null);
    const [formatedEndDate, setFormatedEndDate] = useState("");
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(50);
    const handleStartDate = (value: any) => {
        const result: any = moment(value).format("MM-DD-YYYY")
        setStartDate(value);
        setFormatedStartDate(result);
    }
    const handleEndDate = (value: any) => {
        const result: any = moment(value).format("MM-DD-YYYY")
        setEndDate(value);
        setFormatedEndDate(result);
    }
    var newdate2 = new Date()
    newdate2.setDate(newdate2.getDate());
    var dd1: any = newdate2.getDate();
    var mm1: any = newdate2.getMonth() + 1;
    var yyyy1 = newdate2.getFullYear();
    if (dd1 < 10) { dd1 = '0' + dd1 }
    if (mm1 < 10) { mm1 = '0' + mm1 };
    var todayDate: any = (mm1 + '-' + dd1 + '-' + yyyy1);

    const onTapApply = async () => {
        console.log("Start", startDate)
        if ((Date.parse(startDate == null ? "" : startDate) < Date.parse(endDate == null ? "" : endDate)) || (Date.parse(startDate == null ? "" : startDate) == Date.parse(endDate == null ? "" : endDate))) {
            if (location.state == 5) {
                const result = await getWinServicesByName(id, offset, limit, formatedStartDate, formatedEndDate)
                setWinServicesListByName(result?.data?.results)
                setWinServicesListByNameCount(result?.data?.count)
            }
            else if (location.state == "New") {
                const result = await getErrorWinServicesDetails(id, offset, limit, formatedStartDate, formatedEndDate)
                setWinServicesListByName(result?.data?.results)
                setWinServicesListByNameCount(result?.data?.count)
            }
        }
        else if ((startDate == null) && (endDate == null)) {
            setErrorMsg("Please select start date and end date");
            setErrorModalShow(true);
        }
        else if (startDate == null) {
            setErrorMsg("Please select start date");
            setErrorModalShow(true);
        }
        else if (endDate == null) {
            setErrorMsg("Please select end date");
            setErrorModalShow(true);
        }
        else {
            setErrorMsg("Please select start date less than end date");
            setErrorModalShow(true);
        }
    }
    const { isLoading, setErrorMsg, setErrorModalShow, errorModalShow, setSelectedIndex } = useAuth();
    const { getWinServicesByName, getErrorWinServicesDetails } = useWinServicesAPI()
    const [winServicesListByName, setWinServicesListByName] = useState<any | null>(null);
    const [winServicesListByNameCount, setWinServicesListByNameCount] = useState<any | null>(null);


    const loadInitDataWinByName = async () => {
        if (location.state == 5) {
            const result = await getWinServicesByName(id, offset, limit, startDate == null ? "" : formatedStartDate, endDate == null ? "" : formatedEndDate)
            setWinServicesListByName(result?.data?.results)
            setWinServicesListByNameCount(result?.data?.count)
        }
        else if (location.state == "New") {
            const result = await getErrorWinServicesDetails(id, offset, limit, startDate == null ? "" : formatedStartDate, endDate == null ? "" : formatedEndDate)
            setWinServicesListByName(result?.data?.results)
            setWinServicesListByNameCount(result?.data?.count)
        }
    }
    useEffect(() => {
        loadInitDataWinByName()
        setSelectedIndex(1)
    }, []);
    const WinServiceDetails = (index: any) => {
        console.log("123456789", index)
        const propsValue: any = 1;
        navigate(`/winservicesuserdetails/${index}`, { state: propsValue })
    }
    const ErrorList = (index: any) => {
        const propsValue: any = 3;
        navigate(`/winservicesuserdetails/${winServicesListByName[index].id}`, { state: propsValue })
    }
    const getWinservicesFun = async (value: any) => {
        if (location.state == 5) {
            const result = await getWinServicesByName(id, (value - 1) * limit, limit, startDate == null ? "" : formatedStartDate, endDate == null ? "" : formatedEndDate)
            setWinServicesListByName(result?.data?.results)
            setWinServicesListByNameCount(result?.data?.count)
        }
        else {
            const result = await getErrorWinServicesDetails(id, (value - 1) * limit, limit, startDate == null ? "" : formatedStartDate, endDate == null ? "" : formatedEndDate)
            setWinServicesListByName(result?.data?.results)
            setWinServicesListByNameCount(result?.data?.count)
        }
    }
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setOffset((value - 1) * limit);

        getWinservicesFun(value)

        setPage(value);
    };
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx'

    const ontabExport = () => {
        const ws = XLSX.utils.json_to_sheet(winServicesListByName);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, ((CronName?.replace(/[A-Z]/g, ' $&').trim()) + " Cron Logs") + fileExtension)
    }
    const funWinName = async () => {
        if (location.state == 5) {
            const result = await getWinServicesByName(id, 0, limit, "", "")
            setWinServicesListByName(result?.data?.results)
            setWinServicesListByNameCount(result?.data?.count)
        }
        else if (location.state == "New") {
            const result = await getErrorWinServicesDetails(id, 0, limit, "", "")
            setWinServicesListByName(result?.data?.results)
            setWinServicesListByNameCount(result?.data?.count)
        }
    }
    const resetFilter = () => {
        setFormatedStartDate("")
        setFormatedEndDate("")
        setStartDate(null)
        setEndDate(null)
        setOffset(0);
        setPage(1)
        funWinName();
    }
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    return (
        <>
            {errorHandle()}
            <Header />
            <Box className="contentMain vendorDetailContainer"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <div className=''>

                    {/* <div className="backBtnContainer">
                        <Link className='d-flex align-items-center backBtn'><ChevronLeftIcon /> Cron List </Link>
                    </div> */}
                    <div role="presentation">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" onClick={() => navigate(-1)}>Cron List</Link>
                            {/* <Link
                                underline="hover"
                                color="inherit"
                                href="/material-ui/getting-started/installation/">
                                Core
                            </Link> */}
                            <Link
                                underline="hover"
                                color="text.primary"
                                aria-current="page">
                                {CronName?.replace(/[A-Z]/g, ' $&').trim()} Cron Logs
                            </Link>
                        </Breadcrumbs>
                    </div>
                    <div className='startMain'>
                        <h5 className="pageHead">{CronName?.replace(/[A-Z]/g, ' $&').trim()}</h5>
                        <div className='d-flex w-100 justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <div className='d-flex align-items-center filterContainer'>
                                    <div className='leftDiv filterItem'>
                                        <img src={filter} className="mRight" />
                                        <h5 className='m-0 text'>Filters</h5>
                                    </div>
                                    <div className='borderDiv filterItem'>
                                        <div className='dateInput from d-flex align-items-center'>
                                            <label className='w-100 formLabel'>From:</label>
                                            <div className="d-flex align-items-center outlinedInput">
                                                <Calendar />
                                                <DatePicker
                                                    className='dateUi'
                                                    placeholderText="MM-dd-yyyy"
                                                    dateFormat='MM-dd-yyyy'
                                                    selected={startDate}
                                                    onChange={(date) => handleStartDate(date)}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    maxDate={moment(todayDate).toDate()}
                                                />
                                            </div>
                                        </div>
                                        <div className='dateInput d-flex align-items-center'>
                                            <label className='w-100 formLabel'>To:</label>
                                            <div className="d-flex align-items-center outlinedInput">
                                                <Calendar />
                                                <DatePicker
                                                    className='dateUi'
                                                    placeholderText="MM-dd-yyyy"
                                                    dateFormat='MM-dd-yyyy'
                                                    selected={endDate}
                                                    onChange={(date) => handleEndDate(date)}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode='select'
                                                    maxDate={moment(todayDate).toDate()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Tooltip TransitionComponent={Zoom} title="Reset" arrow placement="top">
                                    <ResetFilter className='ms-3 pointer' onClick={() => resetFilter()} />
                                </Tooltip>
                            </div>
                            <div className='d-flex justify-content-end align-items-center w-100'>
                                <button className='FilledBtn me-3' onClick={() => onTapApply()}>Apply</button>
                                <button className='FilledBtn' onClick={() => { ontabExport(); }}>Export</button>
                            </div>
                        </div>

                    </div>
                    <div className='heightG'>
                        <table className='tableH'>
                            <thead className='thead rounded'>
                                <tr>
                                    <td>Sr. No.</td>
                                    <td>Start Date</td>
                                    <td>End Date</td>
                                    <td>Status</td>
                                    {/* <td>Error</td> */}
                                    <td>Response</td>
                                    <td>Error</td>
                                    <td>Type</td>
                                    <td>Detail</td>
                                </tr>
                            </thead>
                            {!isLoading ?
                                <tbody className='tbody'>
                                    {
                                        winServicesListByName?.length > 0 ?
                                            winServicesListByName?.map((v: any, index: any) => {
                                                return (
                                                    <tr>
                                                        <td>{(limit * (page - 1)) + winServicesListByName?.findIndex((x: any) => x.id === v?.id) + 1}</td>
                                                        <td>{v.startTime.replace(/-/g, "/")}</td>
                                                        <td>{v.endTime.replace(/-/g, "/")}</td>
                                                        <td>{v.isSuccess == true ? "Successful" : "Failed"}</td>
                                                        {/* <td>{v.isError}</td> */}
                                                        <td>{v.exception}</td>
                                                        <td className={v.error > 0 ? "pointer link" : ''} onClick={() => ErrorList(index)}>{v.error}</td>
                                                        <td>{v.cronExecutionType == 0 ? "SYSTEM" : "MANUAL"}</td>
                                                        <td><button className='tableBtn'
                                                            onClick={() => WinServiceDetails(v.id)}
                                                        >Detail</button></td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={8}>
                                                    <p className='mb-0 py-2'>No records found</p>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                                :
                                <Loader />
                            }
                        </table>
                    </div>

                    <div className='d-flex paginationSect align-items-center justify-content-between'>
                        <Stack spacing={2} className="d-flex flex-row align-items-center">
                            <label className='formLabel'>Showing {winServicesListByNameCount > 1 ? (offset + 1) : 0} to {winServicesListByNameCount > (offset + limit) ? (offset + limit) : winServicesListByNameCount} of {winServicesListByNameCount} entries</label>
                            <Pagination className="m-0 paginatnNav" size="small" shape="rounded" count={Math.ceil(winServicesListByNameCount / limit)
                            } page={page} onChange={handleChange} />
                        </Stack>
                    </div>


                </div>
            </Box>
        </>
    )
}
export default WinServicesByName