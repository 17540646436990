import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import { useSubScriptionAPI } from "../../../shared/api/userSubscriptionSAAS";
import styles from "./index.module.scss";
import axios from "axios";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import { Box, IconButton, Snackbar, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as SuccessLogo } from "../../../assets/images/icons/success-logo.svg";
import PaymentSuccessPopup from "./PaymentSuccessPopup";

const style: any = {
  layout: "vertical",
  color: "gold",
  shape: "rect",
  label: "paypal",
  height: 45,
};

const ButtonWrapper: any = ({ showSpinner, currency, amount }: any) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const { setMessageText, setOpen, messageText, open, shortMessage } = useClientAPI()
  // const amount = 25;
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [error, setError] = useState("");
  const {
    paypalCapturePayment,
    getSubscriptionList,
    subscriptionList,
    paypalCancelPayment,
    paypalErrorPayment,
    CreateOrderForSubscription,
    getUserInvoiceDetails,
    flag, setFlag,
    //   getOrderId,
    orderIdFromBackend,
  } = useSubScriptionAPI();
  const user = localStorage.getItem("userId");
  const [paymentSuccessShow, setPaymentSuccessShow] = useState(false)
  const [apiMessage, setApiMessage] = useState("")
  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency, showSpinner]);
  //useEffect order, error
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}

        message={<Box
          display="flex"
          alignItems="center"
          sx={{ backgroundColor: "#fff" }}
        >
          <SuccessLogo />
          <Stack ml={1.5}>
            <Typography variant="h6" fontSize={12} fontWeight={500}>
              {shortMessage}
            </Typography>
            <Typography
              variant="body2"
              color="#000"
              fontWeight={300}
              fontSize={12}
            >
              {messageText}
            </Typography>
          </Stack>
        </Box>}
        action={action}
      />
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        className={styles.btnContainer}
        style={style}
        disabled={false}
        forceReRender={[currency, style]}
        fundingSource={undefined}
        createOrder={async (data: any, actions: any) => {
          return await CreateOrderForSubscription(
            localStorage.getItem("subcr")
          ).then((response: any) => {
            return response?.data?.id;
          });
        }}
        onApprove={async (data, actions: any) => {
          return await paypalCapturePayment(
            localStorage.getItem("order"),
            localStorage.getItem("subcr")
          ).then(async (response: any) => {
            if (response?.status === 200) {
              setApiMessage("Payment Successful");
              setPaymentSuccessShow(true);
              // window.location.reload();
            }
          });

        }}
        onCancel={async (data: any, actions: any) => {
          return await paypalCancelPayment(localStorage.getItem("order")).then(
            (response: any) => response?.data?.id
          );
        }}
        onError={async (data: any) => {

          return await paypalErrorPayment(localStorage.getItem("order")).then((response: any) => {
            {
              setError(error);
              setApiMessage("Sorry, Payment Failed")
              setPaymentSuccessShow(true)
              return response?.data?.id;

            }
          });
        }}
      />
      <PaymentSuccessPopup
        paymentSuccessShow={paymentSuccessShow}
        setPaymentSuccessShow={setPaymentSuccessShow}
        orderId={localStorage.getItem("order")}
        amount={amount}
        respMessage={apiMessage}
      />
    </>
  );
};
export default ButtonWrapper;
