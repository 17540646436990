import * as React from "react";
import "./ListingReportScreen.scss";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useAuth } from "../../shared/provider/authprovider";
import Header from "../../components/header/Header";
import { Button, Spinner } from "react-bootstrap";
import ErrorModal from "../../components/errorModal/ErrorModal";
import { ReactComponent as Search } from "../../../src/assets/icons/searchStatus.svg";
import { ReactComponent as Close } from "../../../src/assets/icons/close.svg";
import { useState } from "react";
import Loader from "../../loader";
import { Pagination, Stack, TextField } from "@mui/material";
import { useReportsAPI } from "../../shared/api/reports-listing";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
function Row(props: {
  item: any;
  onClickRow: any;
  open: any;
  list: any;
  limit: any;
  page: any;
}) {
  const { item, onClickRow, open, list, limit, page } = props;
  const [listingScanReportList, setListingScanReportList] = useState([]);
  const { isLoading, errorModalShow, setErrorModalShow } = useAuth();
  const { getListingScanReport } = useReportsAPI();
  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    );
  };
  const listingScanApiCall = async (scanId: any) => {
    const result = await getListingScanReport(scanId);
    setListingScanReportList(result?.data?.results);
  };

  return (
    <React.Fragment>
      {errorHandle()}
      {isLoading && (
        <div className="loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center">
          {limit * (page - 1) +
            list.findIndex((x: any) => x?.id === item?.id) +
            1}
        </TableCell>
        <TableCell align="center">
          {new Date(item?.dateTime).toLocaleString()}
        </TableCell>
        <TableCell align="center">{item?.upc}</TableCell>
        <TableCell align="center">
          {item?.status === 0
            ? "Pending(0)"
            : item?.status === 1
              ? "Pending(1)"
              : item?.status === 2
                ? "Pending(2)"
                : item?.status === 3
                  ? "Listed(3)"
                  : item?.status === 4
                    ? "Listed(4)"
                    : item?.status === 5
                      ? "Listed(5)"
                      : item?.status === 6
                        ? "Error(6)"
                        : item?.status === 7
                          ? "Color & Size Missing(7)"
                          : null}
        </TableCell>
        <TableCell align="center">{item?.sku}</TableCell>
        <TableCell align="center">{item?.prefix}</TableCell>
        <TableCell align="center">{item?.location}</TableCell>
        <TableCell align="center">
          {item?.comments?.substring(0, item?.comments?.indexOf(":"))}
        </TableCell>

        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setListingScanReportList([]);
              onClickRow(open ? "" : item?.scanId);
              if (!open) {
                // getListingScanReportCalled(item?.scanId)
                listingScanApiCall(item?.scanId);
              }
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className="inner-table">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Log
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Sr. No.</TableCell>
                    <TableCell align="center">Date & Time</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listingScanReportList
                    ? listingScanReportList?.map((value: any) => (
                      <TableRow key={value.status}>
                        <TableCell align="center" component="th" scope="row">
                          {limit * (page - 1) +
                            listingScanReportList?.findIndex(
                              (x: any) => x.status === value?.status
                            ) +
                            1}
                        </TableCell>
                        <TableCell align="center">
                          {new Date(value?.dateTime).toLocaleString()}
                        </TableCell>
                        <TableCell align="center">
                          {value?.status === 0
                            ? "Pending(0)"
                            : value?.status === 1
                              ? "Pending(1)"
                              : value?.status === 2
                                ? "Pending(2)"
                                : value?.status === 3
                                  ? "Listed(3)"
                                  : value?.status === 4
                                    ? "Listed(4)"
                                    : value?.status === 5
                                      ? "Listed(5)"
                                      : value?.status === 6
                                        ? "Error(6)"
                                        : value?.status === 7
                                          ? "Color & Size Missing(7)"
                                          : null}
                        </TableCell>
                        <TableCell align="center">
                          {value?.comments}
                        </TableCell>
                      </TableRow>
                    ))
                    : null}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ListingReportScreen() {
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [openRow, setOpenRow] = React.useState("");
  const { errorModalShow, setErrorModalShow, setSelectedIndex, isLoading } =
    useAuth();
  const [prefix, setPrefix] = React.useState<any>(null);
  const [processLogsList, setProcessLogsList] = useState<any>([]);
  const [processLogsListCount, setProcessLogsListCount] = useState<any>(0);
  // const [details, setDetails] = useState({ "upc": [prefix] })
  const { getProcessLogsList } = useReportsAPI();
  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setOffset((value - 1) * limit);
    const details: any = { upc: [prefix] };
    const result = await getProcessLogsList(details);
    setProcessLogsList(result?.data?.results);
    setProcessLogsListCount(result?.data?.count);
    setPage(value);
  };
  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      onTapSearch();
    }
  };
  const onTapSearch = async () => {
    setPage(1);
    setOffset(0);
    let details: any = {
      upc: prefix?.replace(/\s+/g, "").split(","),
    };
    const result = await getProcessLogsList(details);
    setProcessLogsList(result?.data?.results);
    setProcessLogsListCount(result?.data?.count);
  };

  const onTapReset = async () => {
    setPrefix(null);
    const details: any = { upc: [prefix] };
    const result = await getProcessLogsList(details);
    setProcessLogsList(result?.data?.results);
    setProcessLogsListCount(result?.data?.count);
  };

  React.useEffect(() => {
    setSelectedIndex(5);
    setPrefix(null);
  }, []);

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    );
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = ".xlsx";

  const ontabExport = () => {
    const ws = XLSX.utils.json_to_sheet(processLogsList);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Listing Report" + fileExtension);
  };

  return (
    <>
      {errorHandle()}
      <Header />
      <Box
        className="contentMain"
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 2 }}
      >
        <div className="">
          <div className="startMain mb-2">
            <h5 className="pageHead mb-0">Listing Report</h5>
            <button
              className="FilledBtn"
              onClick={() => {
                ontabExport();
              }}
            >
              Export
            </button>
          </div>
          <div className="d-flex">
            <Box width="100%" className="me-2">
            <TextField
              fullWidth
              multiline
              rows={5}
              margin="dense"
              id="value"
              label="Enter UPC (for exapmle: 123456789012,123456789012)"
              value={prefix}
              onKeyPress={(event) => handleKeypress(event)}
              onChange={(event) => setPrefix(`${event.target.value}`)}
            />
          </Box>
          <button
            className="FilledBtn me-2 mt-2"
            onClick={() => {
              onTapSearch()
            }}
          >
            Search
          </button>
        </div>

        {/* table start */}
        <div className="heightG">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              sx={{ maxHeight: 385 }}
              component={Paper}
              className="scanned-table-container"
            >
              <Table
                stickyHeader
                aria-label="collapsible table"
                className="tableH"
              >
                <TableHead className="thead">
                  <TableRow>
                    <TableCell align="center">Sr. No.</TableCell>
                    <TableCell align="center">Date & Time</TableCell>
                    <TableCell align="center">UPC</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">SKU</TableCell>
                    <TableCell align="center">Prefix</TableCell>
                    <TableCell align="center">Location</TableCell>
                    <TableCell align="center">Comments</TableCell>
                    <TableCell align="center">Log</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading ? (
                    <>
                      {processLogsList
                        ? processLogsList.map((item: any) => (
                          <Row
                            key={item?.id}
                            open={item?.scanId == openRow}
                            item={item}
                            onClickRow={(item: any) => setOpenRow(item)}
                            list={processLogsList}
                            limit={limit}
                            page={page}
                          />
                        ))
                        : null}
                    </>
                  ) : (
                    <Loader />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        {/* <div className='d-flex paginationSect align-items-center justify-content-between'>
            <Stack spacing={2} className="d-flex flex-row align-items-center">
              <label className='formLabel'>Showing {processLogsListCount > 0 ? offset + 1 : 0} to {processLogsListCount >= (offset + limit) ? (offset + limit) : (processLogsListCount == null ? "0" : processLogsListCount)} of {processLogsListCount == null ? "0" : processLogsListCount} entries</label>
              <Pagination className="m-0 paginatnNav" size="small" shape="rounded" count={Math.ceil(processLogsListCount / limit)} page={page} onChange={handleChange} />
            </Stack>

          </div> */}
      </div>
    </Box >
    </>
  );
}
