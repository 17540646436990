import axios, { AxiosPromise } from "axios";
import { environmentUrl } from "./index.service";
export const getManifestMissingLocations = (token: any, prefix: any, offset: any, limit: any, order: any): AxiosPromise<any> => {
    let url = `${environmentUrl}/api/Scan/ManifestNotFoundLocations?Prefix=${prefix}&Offset=${offset}&Limit=${limit}&OrderBy=${order}`;
    const manifestMissingLocations = axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return manifestMissingLocations;
};

export const getManifestMissingUPC = (token: any, location: any, offset: any, limit: any, order: any): AxiosPromise<any> => {
    let url = `${environmentUrl}/api/Scan/Location/Upcs?Prefix=${location}&Offset=${offset}&Limit=${limit}&OrderBy=${order}`;
    const manifestMissingUPC = axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return manifestMissingUPC;
};
export const getManifestProductDetails = (token: any, UPC: any): AxiosPromise<any> => {
    let url = `${environmentUrl}/api/Scan/GetDetailsByUpc?upc=${UPC}`;
    const manifestMissingProduct = axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return manifestMissingProduct;
};
export const putManifestMissingProducts = (token: any, values: any): AxiosPromise<any> => {
    return axios.put(
        environmentUrl + `/api/Scan/UpdateMissingSkuDetails`, values, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    );
};
// guided product update
// get product
export const getGuidedProductDetails = (token: any, override: any, count: any): AxiosPromise<any> => {
    let url = `${environmentUrl}/api/Manifest/GuidedProductDetails?Override=${override}&Skip=${count}`;
    const guidedManifestMissingProduct = axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return guidedManifestMissingProduct;
};
// update product
export const putGuidedManifestMissingProduct = (token: any, values: any): AxiosPromise<any> => {
    return axios.put(
        environmentUrl + `/api/Scan/UpdateMissingSkuDetails`, values, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    );
};
// skip / not found guided products
export const putSkipNotFoundGuidedManifestMissingProduct = (token: any, id: any, status: any): AxiosPromise<any> => {
    let url =
        environmentUrl + `/api/Scan/UpdateScanStatus/${id}?status=${status}`;
    const skip = axios.put(url, {}, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return skip;
};
// Manifest/SkipOrQuitGuidedProduct
export const putQuitdManifestMissingProduct = (token: any, id: any): AxiosPromise<any> => {
    let url =
        environmentUrl + `/api/Manifest/SkipOrQuitGuidedProduct?id=${id}`;
    const quit = axios.put(url, {}, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return quit;
};