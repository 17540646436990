import React from "react";
import { useAuth } from "../../shared/provider/authprovider";
import './Header.scss';
import UserL from "../../assets/user-1.svg";
import { useNavigate } from "react-router-dom";

//my imports
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { ReactComponent as LogOut } from "../../../src/assets/icons/log-off.svg";
import { ReactComponent as User } from "../../../src/assets/user-1.svg";
import { ReactComponent as Chart } from "../../../src/assets/icons/menuIcons/chart.svg";
import { ReactComponent as Crown } from "../../../src/assets/icons/menuIcons/crown.svg";
import { ReactComponent as Dashboard } from "../../../src/assets/icons/menuIcons/dashboard.svg";
import { ReactComponent as Data } from "../../../src/assets/icons/menuIcons/data.svg";
import { ReactComponent as Share } from "../../../src/assets/icons/menuIcons/share.svg";
import { ReactComponent as Storage } from "../../../src/assets/icons/menuIcons/storage.svg";
import { ReactComponent as Logo } from "../../../src/assets/resale-full-logo.svg";
import { ReactComponent as Texonomy } from "../../../src/assets/icons/menuIcons/taxonomy.svg";
import { useRecAppApiQuickLotz } from "../../shared/api/receivingappQuickLots";

const drawerWidth = 60;

const Header = (props: any) => {
  const { signout, roles, firstName, lastName, scanItemsArrayy, account,
    selectedIndex, setSelectedIndex,
    setRoValidationBox, setupcValidationBox, setNotScanableBox, setListableItemBox, setListableLocationBox, setUnlistableItemBox } = useAuth();
  const { setupcValidationBoxQuickLotz, setListableLocationBoxQbids, setProductWeightBox, setProductFlagsBox, setProductDataBox } = useRecAppApiQuickLotz();
  let navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const onTapLogo = () => {
    // if (roles === "User") {
    //   if (scanItemsArrayy.length > 0) {
    //     navigate("/boxtablescreen")
    //   } else {
    //     navigate("/userdashboard")
    //   }
    // } else {
    //   navigate("/dashboard")
    // }
  };


  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };
  return (
    <>
      <div className="sidebarNav">
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
          >
            <Toolbar>
              <div className="logoDiv pointer" onClick={() => {
                navigate("/dashboard");
              }}>
                <Logo className="resaleLogo" />
              </div>
              <div className="profileContainer d-flex align-items-center">
                <div className="profileImg">
                  <img src={UserL} />
                </div>
                <div className="userName">
                  <p className="mb-0">{firstName}</p>
                  <p className="mb-0">{lastName}</p>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="permanent"
            anchor="left"
          >
            {/* <Toolbar /> */}

            {roles === "Admin" ?
              <List>
                {/* dashboard */}
                < ListItem
                  key={"dashboard"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => {
                      navigate("/dashboard");
                      handleListItemClick(event, 0)
                    }}
                  >
                    <ListItemIcon>
                      <Dashboard className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"dashboard"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Dashboard"}
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>

                {/* Integration */}
                <ListItem
                  key={"Integration"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    selected={selectedIndex === 1} >
                    <ListItemIcon sx={{ minWidth: 0, justifyContent: "center" }} >
                      <Data className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"Integration"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Integration"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"winservices"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 1}
                              onClick={(event) => {
                                navigate("/winservices");
                                handleListItemClick(event, 1)
                              }}
                              style={{ background: selectedIndex == 1 ? "rgba(25, 118, 210, 0.08)" : "" }}
                            >
                              <ListItemText
                                primary={"WinServices"}
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>

                {/* master data */}
                <ListItem
                  key={"kcmcHome"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    selected={selectedIndex === 2 || selectedIndex == 3 || selectedIndex == 4 || selectedIndex == 15 || selectedIndex == 50}>
                    <ListItemIcon sx={{ minWidth: 0, justifyContent: "center" }} >
                      <Crown className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>

                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"dashboard"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Master data"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"warehouse"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              style={{ background: selectedIndex == 2 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              selected={selectedIndex === 2}
                              onClick={(event) => {
                                handleListItemClick(event, 2)
                                navigate("/warehouse")
                              }}
                            >
                              <ListItemText
                                primary={"Warehouse"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"dashboard"}
                            disablePadding
                            sx={{ display: "block" }}

                          >
                            <ListItemButton
                              style={{ background: selectedIndex == 3 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              selected={selectedIndex === 3}
                              onClick={(event) => {
                                navigate("/user");
                                handleListItemClick(event, 3)
                              }}
                            >
                              <ListItemText
                                primary={"Users"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"vendordashboard"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              style={{ background: selectedIndex == 4 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              selected={selectedIndex === 4}
                              onClick={(event) => {
                                navigate("/vendor-dashboard");
                                handleListItemClick(event, 4)
                              }}
                            >
                              <ListItemText
                                primary={"Vendor"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"Reset Data"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 50}
                              style={{ background: selectedIndex == 50 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/reset-data");
                                handleListItemClick(event, 50)
                              }}
                            >
                              <ListItemText
                                primary={"Reset Data"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"warehouse"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              style={{ background: selectedIndex == 15 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              selected={selectedIndex === 15}
                              onClick={(event) => {
                                handleListItemClick(event, 15)
                                navigate("/settings")
                              }}
                            >
                              <ListItemText
                                primary={"Settings"}
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>

                {/* Reports */}
                <ListItem
                  key={"kcmcHome"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    selected={selectedIndex === 5 || selectedIndex === 6 || selectedIndex === 7 || selectedIndex === 8 || selectedIndex === 13}
                  >
                    <ListItemIcon sx={{ minWidth: 0, justifyContent: "center" }} >
                      <Chart className="hoverColor" />
                    </ListItemIcon>

                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"Reports"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Reports"}
                              />
                            </ListItemButton>
                          </ListItem>

                          <ListItem
                            key={"listingreport"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 5}
                              style={{ background: selectedIndex == 5 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/listingreport");
                                handleListItemClick(event, 5)
                              }}
                            >
                              <ListItemText
                                primary={"Listing Report"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"userreport"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 6}
                              style={{ background: selectedIndex == 6 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/userreport");
                                handleListItemClick(event, 6)
                              }}
                            >
                              <ListItemText
                                primary={"User Report"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"summaryreport"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 13}
                              style={{ background: selectedIndex == 13 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/summary-listing-report");
                                handleListItemClick(event, 13)
                              }}
                            >
                              <ListItemText
                                primary={"Listing Summary Report"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"misreport"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 7}
                              style={{ background: selectedIndex == 7 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/misreport");
                                handleListItemClick(event, 7)
                              }}
                            >
                              <ListItemText
                                primary={"MIS Report"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"Daily MIS Report"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 8}
                              style={{ background: selectedIndex == 8 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/daily-misreport");
                                handleListItemClick(event, 8)
                              }}
                            >
                              <ListItemText
                                primary={"Daily MIS Report"}
                              />
                            </ListItemButton>
                          </ListItem>

                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>

                {/* Connections */}
                <ListItem
                  key={"Connections"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton selected={selectedIndex === 9 || selectedIndex === 10 || selectedIndex === 11 || selectedIndex === 12}>
                    <ListItemIcon sx={{ minWidth: 0, justifyContent: "center" }} >
                      <Share className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"Connections"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Connections"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"Channel Adviser"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 9}
                              style={{ background: selectedIndex == 9 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/connections-channeladvisor");
                                handleListItemClick(event, 9)
                              }}
                            >
                              <ListItemText
                                primary={"Channel Adviser"}
                              />
                            </ListItemButton>
                          </ListItem>

                          <ListItem
                            key={"Shipping Easy"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 10}
                              style={{ background: selectedIndex == 10 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/connections-shippingeasy");
                                handleListItemClick(event, 10)
                              }}
                            >
                              <ListItemText
                                primary={"Shipping Easy"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"Sku Vault"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 11}
                              style={{ background: selectedIndex == 11 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/connections-skuvault");
                                handleListItemClick(event, 11)
                              }}
                            >
                              <ListItemText
                                primary={"Sku Vault"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"Square Space"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 12}
                              style={{ background: selectedIndex == 12 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/connections-squarespace");
                                handleListItemClick(event, 12);
                              }}
                            >
                              <ListItemText
                                primary={"Square Space"}
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>

                {/* logout */}
                < ListItem
                  key={"dashboard"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={logout}>
                    <ListItemIcon>
                      <LogOut className="hoverColor logout" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>

                          <ListItem
                            className="MainMenu"
                            key={"logout"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Logout"}
                              />
                            </ListItemButton>

                          </ListItem>
                        </List>

                      </div>
                    </div>
                  </div>
                </ListItem>

              </List> : null}
            {roles === "Demo" ?
              <List>
                < ListItem
                  key={"ScanUPC"}
                  disablePadding
                  sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedIndex === 35}
                  >
                    <ListItemIcon>
                      <Share className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          <ListItem
                            className="MainMenu"
                            key={"ScanUPC"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"UPC Scan"}
                              />
                            </ListItemButton>

                          </ListItem>
                          <ListItem
                            key={"ScanUPC"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              style={{ background: selectedIndex == 35 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              selected={selectedIndex === 35}
                              onClick={(event) => {
                                navigate("/scan-upc");
                                handleListItemClick(event, 35)

                              }}
                            >
                              <ListItemText
                                primary={"UPC SCan"}
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
                {/* logout */}
                < ListItem
                  key={"dashboard"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={logout}>
                    <ListItemIcon>
                      <LogOut className="hoverColor logout" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"logout"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Logout"}
                              />
                            </ListItemButton>

                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
              </List> :
              null}
            {roles === "User" ?
              <List>
                {/* User Dashboard */}
                < ListItem
                  key={"dashboard"}
                  disablePadding
                  sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => {
                      navigate("/userdashboard");
                      handleListItemClick(event, 0)
                    }}

                  >
                    <ListItemIcon>
                      <Dashboard className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"dashboard"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Dashboard"}
                              />
                            </ListItemButton>

                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
                {/* Update Missing products */}
                {/* Integration */}

                < ListItem
                  key={"Integration"}
                  disablePadding
                  sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedIndex === 20 || selectedIndex === 22 || selectedIndex === 23}
                  // onClick={(event) => {
                  //   navigate("/update-products");
                  //   handleListItemClick(event, 1)
                  // }}
                  >
                    <ListItemIcon>
                      <Storage className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          <ListItem
                            className="MainMenu"
                            key={"Integration"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Product Update"}
                              />
                            </ListItemButton>

                          </ListItem>
                          <ListItem
                            key={"Channel Adviser"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 20}
                              style={{ background: selectedIndex == 20 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/update-products-guided");
                                handleListItemClick(event, 20)
                              }}
                            >
                              <ListItemText
                                primary={"Guided Product Update"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"Channel Adviser"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 22}
                              style={{ background: selectedIndex == 22 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/update-products");
                                handleListItemClick(event, 22)
                              }}
                            >
                              <ListItemText
                                primary={"Manual Product Update"}
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
                {/* Taxonomy not found */}
                < ListItem
                  key={"Integration"}
                  disablePadding
                  sx={{ display: "block" }}                >
                  <ListItemButton
                    selected={selectedIndex === 10}
                  // onClick={(event) => {
                  //   navigate("/update-products");
                  //   handleListItemClick(event, 1)
                  // }}
                  >
                    <ListItemIcon>
                      <Texonomy className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          <ListItem
                            className="MainMenu"
                            key={"Integration"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Product Update"}
                              />
                            </ListItemButton>

                          </ListItem>
                          <ListItem
                            key={"Channel Adviser"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 10}
                              style={{ background: selectedIndex == 10 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/taxonomy-not-found");
                                handleListItemClick(event, 10)
                              }}
                            >
                              <ListItemText
                                primary={"Taxonomy Not Found"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"Channel Adviser"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 15}
                              style={{ background: selectedIndex == 15 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/product-details-web-form");
                                handleListItemClick(event, 15)
                              }}
                            >
                              <ListItemText
                                primary={"Product Detais"}
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
                {/* listring products */}
                < ListItem
                  key={"Integration"}
                  disablePadding
                  sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedIndex === 5}
                  // onClick={(event) => {
                  //   navigate("/receiving-app");
                  //   handleListItemClick(event, 5)
                  //   setRoValidationBox(true);
                  //     setupcValidationBox(false);
                  //     setNotScanableBox(false);
                  //     setListableItemBox(false);
                  //     setListableLocationBox(false);
                  //     setUnlistableItemBox(false)
                  // }}
                  >
                    <ListItemIcon>
                      <Data className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          <ListItem
                            className="MainMenu"
                            key={"Integration"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Receiving Application"}
                              />
                            </ListItemButton>

                          </ListItem>
                          <ListItem
                            key={"Channel Adviser"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              style={{ background: selectedIndex == 5 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              selected={selectedIndex === 5}
                              onClick={(event) => {
                                navigate("/receiving-app");
                                handleListItemClick(event, 5)
                                setRoValidationBox(true);
                                setupcValidationBox(false);
                                setNotScanableBox(false);
                                setListableItemBox(false);
                                setListableLocationBox(false);
                                setUnlistableItemBox(false)
                              }}
                            >
                              <ListItemText
                                primary={"Receiving Application"}
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
                {/* UPC Scan */}

                {/* logout */}
                < ListItem
                  key={"dashboard"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={logout}>
                    <ListItemIcon>
                      <LogOut className="hoverColor logout" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"logout"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Logout"}
                              />
                            </ListItemButton>

                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
              </List>
              :
              null}
            {roles === "RMUser" && account === "Recom LLC" ?
              <List>
                < ListItem
                  key={"Integration"}
                  disablePadding
                  sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedIndex === 5}
                  >
                    <ListItemIcon>
                      <Data className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          <ListItem
                            className="MainMenu"
                            key={"Integration"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Receiving Application"}
                              />
                            </ListItemButton>

                          </ListItem>
                          <ListItem
                            key={"Channel Adviser"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              style={{ background: selectedIndex == 5 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              selected={selectedIndex === 5}
                              onClick={(event) => {
                                navigate("/receiving-app");
                                handleListItemClick(event, 5)
                                setRoValidationBox(true);
                                setupcValidationBox(false);
                                setNotScanableBox(false);
                                setListableItemBox(false);
                                setListableLocationBox(false);
                                setUnlistableItemBox(false)
                              }}
                            >
                              <ListItemText
                                primary={"Receiving Application"}
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
                {/* logout */}
                < ListItem
                  key={"dashboard"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={logout}>
                    <ListItemIcon>
                      <LogOut className="hoverColor logout" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"logout"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Logout"}
                              />
                            </ListItemButton>

                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
              </List>
              :
              null}
            {roles === "RMUser" && account === "QBIDs" ?
              <List>
                < ListItem
                  key={"Integration"}
                  disablePadding
                  sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedIndex === 25 || selectedIndex === 26}

                  >
                    <ListItemIcon>
                      <Data className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          <ListItem
                            className="MainMenu"
                            key={"Integration"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Receiving Application"}
                              />
                            </ListItemButton>

                          </ListItem>
                          <ListItem
                            key={"Channel Adviser"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 25}
                              style={{ background: selectedIndex == 25 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/receiving-app-quicklotz");
                                handleListItemClick(event, 25)
                                setupcValidationBoxQuickLotz(true);
                                setProductDataBox(false);
                                setProductFlagsBox(false);
                                setProductWeightBox(false);
                                setListableLocationBoxQbids(false)
                              }}
                            >
                              <ListItemText
                                primary={"Receiving Application"}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            key={"Channel Adviser"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 26}
                              style={{ background: selectedIndex == 26 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/receiving-app-reports");
                                handleListItemClick(event, 26)
                              }}
                            >
                              <ListItemText
                                primary={"Reports"}
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
                {/* logout */}
                < ListItem
                  key={"dashboard"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={logout}>
                    <ListItemIcon>
                      <LogOut className="hoverColor logout" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"logout"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Logout"}
                              />
                            </ListItemButton>

                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
              </List>
              :
              null}
            {/* <Divider /> */}
            {roles === "RMUser" && account === "SkuVault" ?
              <List>
                < ListItem
                  key={"Integration"}
                  disablePadding
                  sx={{ display: "block" }}>
                  <ListItemButton
                    selected={selectedIndex === 27}

                  >
                    <ListItemIcon>
                      <Data className="hoverColor" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          <ListItem
                            className="MainMenu"
                            key={"Integration"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Receiving Application"}
                              />
                            </ListItemButton>

                          </ListItem>
                          <ListItem
                            key={"Channel Adviser"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              selected={selectedIndex === 27}
                              style={{ background: selectedIndex == 27 ? "rgba(25, 118, 210, 0.08)" : "" }}
                              onClick={(event) => {
                                navigate("/receiving-skuvault");

                              }}
                            >
                              <ListItemText
                                primary={"Receiving Application"}
                              />
                            </ListItemButton>
                          </ListItem>

                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
                {/* logout */}
                < ListItem
                  key={"dashboard"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={logout}>
                    <ListItemIcon>
                      <LogOut className="hoverColor logout" />
                    </ListItemIcon>
                  </ListItemButton>
                  <div className="mainDiv">
                    <div className="hoverMenuContainer">
                      <div className="arrow-left"></div>
                      <div className="hoverSubmenu">
                        <List>
                          {/* submenu */}
                          <ListItem
                            className="MainMenu"
                            key={"logout"}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={"Logout"}
                              />
                            </ListItemButton>

                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </div>
                </ListItem>
              </List>
              :
              null}
          </Drawer>
        </Box>
      </div>

      {
        roles === "User" ?
          <div className="mobileNav">
            <div className="menuContainer">
              <div className="menuItem">
                <List>
                  {/* User Dashboard */}
                  < ListItem
                    key={"dashboard"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={selectedIndex === 0}
                      onClick={(event) => {
                        navigate("/userdashboard");
                        handleListItemClick(event, 0)
                      }}

                    >
                      <ListItemIcon>
                        <Dashboard className="hoverColor" />
                      </ListItemIcon>
                    </ListItemButton>

                  </ListItem>

                  {/* update Dashboard */}
                  < ListItem
                    key={"Integration"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={selectedIndex === 1}
                    // onClick={(event) => {
                    //   navigate("/update-products");
                    //   handleListItemClick(event, 1)
                    // }}
                    >
                      <ListItemIcon>
                        <Storage className="hoverColor" />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>

                  {/* logout */}
                  < ListItem
                    key={"dashboard"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      onClick={logout}>
                      <ListItemIcon>
                        <LogOut className="hoverColor logout" />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                </List>
              </div>

            </div>
          </div>
          :
          null
      }
    </>
  )
};

export default Header;
