import { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import { useAuth } from "../../shared/provider/authprovider";
import { Spinner } from 'react-bootstrap';
import ErrorModal from '../../components/errorModal/ErrorModal';
import moment from 'moment';

const SkuVaultScreen = () => {

  const { postInsertSkuVaultProductsCalled, isLoading, errorModalShow, setErrorModalShow, } = useAuth();
  const [todayDate, setTodayDate] = useState("")
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [formatedStartDate, setFormatedStartDate] = useState("");
  const [formatedEndDate, setFormatedEndDate] = useState("");

  useEffect(() => {
    handleStartDate(todayDate);
    handleEndDate(todayDate);
  }, [])

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };

  const handleStartDate = (value: any) => {
    let curr = new Date();
    curr.setDate(curr.getDate());
    let date = moment(curr).format("YYYY-MM-DD")
    if (value) {
      const [year, month, day] = value.split('-');
      const result = [year, month, day].join('-');
      setStartDate(value);
      setFormatedStartDate(result);
    } else {
      const [year, month, day] = date.split('-');
      const result = [year, month, day].join('-');
      setTodayDate(date)
      setStartDate(date);
      setFormatedStartDate(result);
    }
  };

  const handleEndDate = (value: any) => {
    let curr = new Date();
    curr.setDate(curr.getDate());
    let date = moment(curr).format("YYYY-MM-DD")
    if (value) {
      const [year, month, day] = value.split('-');
      const result = [year, month, day].join('-');
      setEndDate(value);
      setFormatedEndDate(result);
    } else {
      const [year, month, day] = date.split('-');
      const result = [year, month, day].join('-');
      setTodayDate(date)
      setEndDate(date);
      setFormatedEndDate(result);
    }

  };

  const onTapSubmit = () => {
    let details = {
      "ModifiedAfterDateTimeUtc": formatedStartDate,
      "ModifiedBeforeDateTimeUtc": formatedEndDate
    }
    // console.log("DETAILS", details);
    postInsertSkuVaultProductsCalled(details);
  };

  return (
    <>
      {errorHandle()}
      <Header />
      {isLoading && <div className="loader">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>}
      <div className='container'>
        <div className='startMain'>
          <span>Sort By Date: </span>
          <div className='start'>
            <label>Start Date</label>
            <input className='dateUi' onChange={(event) => handleStartDate(event?.target?.value)} type="date" defaultValue={todayDate}></input>
          </div>
          <div className='end'>
            <label>End Date</label>
            <input className='dateUi' onChange={(event) => handleEndDate(event?.target?.value)} type="date" defaultValue={todayDate}></input>
          </div>
          {(Date.parse(startDate) < Date.parse(endDate)) || (Date.parse(startDate) == Date.parse(endDate)) ?
            <button className='BtnGO' onClick={() => onTapSubmit()}>Submit</button>
            :
            <button className='BtnGO disabled-btn' disabled>Submit </button>}
        </div>
      </div>
    </>
  )
}

export default SkuVaultScreen;