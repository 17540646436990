import React, { useCallback, useRef } from "react";
import Header from "../../components/header/Header"
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../shared/provider/authprovider";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { ImCross, ImCheckmark } from 'react-icons/im'
import { useNavigate } from "react-router-dom";
import './winservices.css'
import Dots from '../../assets/action.svg'
import Timer from '../../assets/timer.svg';
import Crosspopup from '../../assets/icons/close.svg'
import editPencil from '../../assets/edit.png'
import run from '../../assets/logout.png'
import userRemove from '../../assets/user-remove.png'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Cron, { CronError } from "react-js-cron";
// import "./styles.css";
import "antd/dist/antd.css";
import { Divider, Input } from "antd";
import XLSX from 'sheetjs-style';
import saveAs from 'file-saver';
import FileSaver from 'file-saver';
import Loader from "../../loader";
import { Box } from "@mui/material";
import ErrorModal from "../../components/errorModal/ErrorModal";
import { useWinServicesAPI } from "../../shared/api/winservices";

const WinServicesScreen = (propsValue: any) => {
    // winServicesList
    let navigate = useNavigate();
    const [page, setPage] = useState(1);
    const prefixId = localStorage.getItem("prefixIdForAdmin");
    const startDate = localStorage.getItem("startDate");
    const endDate = localStorage.getItem("endDate");
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(50);
    const [prefix, setPrefix] = useState("");
    const [winServicesList, setWinServicesList] = useState<any>([])
    const [winServicesCount, setWinServicesCount] = useState<any>(0)
    const { putWinServicesSchedule, putWinServicesRun, putWinServicesInactive, putWinServicesActive, getWinServices } = useWinServicesAPI()
    const { setErrorMsg, setWinServicesCronName, errorModalShow, setErrorModalShow, setSelectedIndex } = useAuth();
    const loadInitialData = async () => {
        const res = await getWinServices(offset, limit);
        setWinServicesList(res?.data?.results)
        setWinServicesCount(res?.data?.count)
    }
    useEffect(() => {
        loadInitialData()
        setSelectedIndex(1)
    }, []);
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setOffset((value - 1) * limit);
        const res = await getWinServices((value - 1) * limit, limit);
        setWinServicesList(res?.data?.results)
        setWinServicesCount(res?.data?.count)
        setPage(value);
    };
    const WinServiceByName = (index: any) => {
        // console.log("name", winServicesList[index].cronName)
        setWinServicesCronName(winServicesList[index].cronName)
        localStorage.setItem("CronName", winServicesList[index].cronName);
        const propsValue: any = 5;
        navigate(`/winservicesbyname/${winServicesList[index].id}`, { state: propsValue })
    }
    const ErrorList = (index: any) => {
        console.log("id2", winServicesList[index].id)
        setWinServicesCronName(winServicesList[index].cronName)
        const propsValue: any = "New";
        navigate(`/winservicesbyname/${winServicesList[index].id}`, { state: propsValue })
    }
    // putWinServicesRunCalled
    const runWinService = async (id: any) => {
        const result = await putWinServicesRun(id);
        console.log(result)
        if (result?.data?.status == 200) {
            setErrorModalShow(true);
            setErrorMsg("CronJob Running Successfully");
            const res = await getWinServices(offset, limit)
            setWinServicesList(res?.data?.results)
            setWinServicesCount(res?.data?.count)
        }
    }
    const putInactive = async (id: any) => {
        const result = await putWinServicesInactive(id);
        if (result?.data?.status == 200) {
            setErrorModalShow(true);
            setErrorMsg("WinServices Inactivated Successfully");
            const res = await getWinServices(offset, limit);
            setWinServicesList(res?.data?.results)
            setWinServicesCount(res?.data?.count)
        }

    }
    const putActive = async (id: any) => {
        const result = await putWinServicesActive(id);
        if (result?.data?.status == 200) {
            setErrorModalShow(true);
            setErrorMsg("WinServices Activated Successfully");
            const res = await getWinServices(offset, limit);
            setWinServicesList(res?.data?.results)
            setWinServicesCount(res?.data?.count)
        }
    }


    const [id2, setId] = useState('')

    const setValueToApply = async (value: any) => {
        console.log("e", value)
        let Schedule = {
            "frequency": value
        }
        await putWinServicesSchedule(id2, Schedule)
        const res = await getWinServices(offset, limit);
        setWinServicesList(res?.data?.results)
        setWinServicesCount(res?.data?.count)
    }
    const [defaultFreq, setDefaultFreq] = useState<string>('')
    console.log("defaulty Freq", typeof defaultFreq, defaultFreq)
    const [show, setShow] = useState(false);

    const inputRef = useRef<any>(null);
    const defaultValue = "30 5 1,15 * *";
    const [value, setValue] = useState(defaultValue);
    const customSetValue = useCallback(
        (newValue: string) => {
            setValue(newValue)
            if (inputRef?.current?.setValue) {
                inputRef?.current?.setValue(newValue)
            }
        },
        [inputRef]
    )
    const [error, onError] = useState<CronError>();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx'

    const ontabExport = () => {
        const ws = XLSX.utils.json_to_sheet(winServicesList);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, "Cron List" + fileExtension)
    }
    return (
        <>
            {errorHandle()}
            <Header />
            <Box className="contentMain"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <div className='winservicesContainer'>
                    <div className='startMain'>
                        <h5 className="pageHead mb-0">Cron List</h5>
                        <button className='FilledBtn' onClick={() => { ontabExport(); }}>Export</button>
                    </div>
                    <div className='heightG'>
                        <table className='tableH'>
                            <thead className='thead rounded'>
                                <tr>
                                    <td>Sr. No.</td>
                                    <td>Cron Name</td>
                                    <td>Type</td>
                                    <td>Start Date</td>
                                    <td>End Date</td>
                                    <td>Next Run</td>
                                    <td>Last Run</td>
                                    <td>Schedule</td>
                                    <td>Active</td>
                                    <td>Status</td>
                                    <td>Error</td>
                                    <td>Actions</td>
                                </tr>
                            </thead>
                            {winServicesList ?
                                <tbody className='tbody'>
                                    {winServicesList?.map((v: any, index: any) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td className="pointer link" onClick={() => WinServiceByName(index)}>{v.cronName}</td>
                                                <td>{v.type}</td>
                                                <td>{v.startTime.replace(/-/g, "/").slice(0, 10)}</td>
                                                <td>{v.endTime.replace(/-/g, "/").slice(0, 10)}</td>
                                                <td>{v.nextRun}</td>
                                                <td>{v.endTime}</td>
                                                <td>{v.frequency}</td>
                                                <td>{v.isActive == true ? <span style={{ color: "green" }}><ImCheckmark /></span> : <span style={{ color: "red" }}><ImCross /></span>}</td>
                                                <td>{v.cronStatus == 1 ? "Running" : "Completed"}</td>
                                                <td className={v.error > 0 ? "pointer link" : ''} onClick={() => ErrorList(index)}>{v.error}</td>
                                                <td>
                                                    <Dropdown className="menuItem">
                                                        <Dropdown.Toggle variant="Warning" id="dropdown-basic" ><img src={Dots} onClick={() => { setDefaultFreq(v.frequency) }}></img></Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropMenug">
                                                            {v?.cronStatus == 1 ?
                                                                <Dropdown.Item style={{ color: 'gray', cursor: 'no-drop' }}>
                                                                    Run
                                                                </Dropdown.Item> :
                                                                <Dropdown.Item className="clickItem" onClick={() => runWinService(v.id)}>
                                                                    Run
                                                                </Dropdown.Item>}
                                                            <Dropdown.Item className="clickItem" onClick={() => { setShow(true); setId(v.id); setValue(v.frequency) }}>Edit</Dropdown.Item>
                                                            <Dropdown.Item className="clickItem" onClick={() => { v.isActive == true ? putInactive(v.id) : putActive(v.id) }}>
                                                                {v.isActive == true ? <div><img src={userRemove} className="me-2" /> Disable</div> : <div>Enable</div>}</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>

                                            </tr>
                                        )
                                    })}
                                </tbody>
                                :
                                <Loader />
                            }
                        </table>
                    </div>
                    <div className='d-flex paginationSect align-items-center justify-content-between'>
                        <Stack spacing={2} className="d-flex flex-row align-items-center">
                            <label className='formLabel'>Showing {offset + 1} to {winServicesCount > (limit + offset) ? (limit + offset) : winServicesCount} of {winServicesCount} entries</label>
                            <Pagination className="m-0 paginatnNav" size="small" shape="rounded"
                                count={Math.ceil(winServicesCount / limit)} page={page} onChange={handleChange} />
                        </Stack>
                    </div>

                    <div className="schedularModal" style={{ display: show ? "flex" : "none" }}>

                    </div>
                    <div className="winEditpopup" style={{ display: show ? "inline-block" : "none" }}>
                        <div className="d-flex align-items-center justify-content-between" >
                            <h5 className="mb-0">Scheduler</h5>
                            <button className="btn p-0" onClick={() => setShow(false)}><span style={{ color: "red" }}><img src={Crosspopup} className="crossImg" /></span></button></div>

                        <div>
                            <Input className="inputField mt-3"
                                ref={inputRef}
                                onBlur={(event) => {
                                    setValue(event.target.value)
                                }}
                                onPressEnter={() => {
                                    setValue(inputRef.current?.input.value || '')
                                }}
                                value={value}
                            />

                            <Cron value={value} setValue={customSetValue} onError={onError} className="" />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className="FilledBtn" onClick={() => { setValue("30 5 * * 1,6") }}>Clear</button>
                            <button className="FilledBtn" style={{ marginLeft: "20px" }} onClick={() => setValueToApply(value)}>Apply</button>
                        </div>
                    </div>

                    {/* <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Modal title</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='cronContainer'>
                            <Cron
                                value={"30 5 * * 1,6"} setValue={(e: any) => { setValue2(e); }}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={() => setValue2(value2)}>Apply</button>
                        <button onClick={() => { setValue2("* * * * *,*") }}>Clear</button>
                    </Modal.Footer>
                </Modal> */}


                    {/* <Stack spacing={2} className="ms-auto">
                        <Pagination className="me-auto paginatnNav" count={3} page={page} onChange={handleChange}/>
                    </Stack> */}


                </div>
            </Box>
        </>

    )
}
export default WinServicesScreen
