import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import moment, { ISO_8601 } from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ResetFilter } from "../../../src/assets/icons/filter-reset.svg";
import { ReactComponent as RightArrow } from "../../../src/assets/icons/left-right/Right-Arrow.svg";
import { ReactComponent as Calendar } from "../../../src/assets/icons/searchCalendar.svg";
import filter from "../../assets/filter.svg";
import ErrorModal from '../../components/errorModal/ErrorModal';
import Header from '../../components/header/Header';
import Loader from '../../loader/index';
import { useAuth } from "../../shared/provider/authprovider";
import './DashBoardScreen.scss';
import { useDashboardAPI } from '../../shared/api/dashboardReports';

const DashBoardScreen = () => {
  let navigate = useNavigate()
  var day1: any = moment().startOf('month')
  var firstDay = moment(day1, "en-us").format("MM-DD-YYYY")

  var date = new Date();
  var firstDayNew = new Date(date.getFullYear(), date.getMonth(), 1);

  var day2: any = moment()
  var todayDate = moment(day2, "en-us").format("MM-DD-YYYY")

  var todayDateNew = moment()
  const { adminDashboardProductsReportNew, postCleanData, dataList, countList } = useDashboardAPI()
  const { errorModalShow, setErrorModalShow, isLoading, setErrorMsg, setSelectedIndex, } = useAuth();
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [newStartDate, setNewStartDate] = useState<any>(new Date())
  const [newEndDate, setNewEndDate] = useState<any>(new Date())
  const [isButtonLoading, setButtonLoading] = useState<boolean>(false);


  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };

  const handleStartDate = (value: any) => {
    if (value == null) {
      setStartDate(firstDay);
      setNewStartDate(firstDayNew)
    }
    else {
      const result = moment(value, ISO_8601).format("MM-DD-YYYY")
      setStartDate(result);
      setNewStartDate(new Date(value))
    }
  }

  const handleEndDate = (value: any) => {
    if (value == null) {
      setEndDate(todayDate);
      setNewEndDate(todayDateNew)
    }
    else {
      const result = moment(value, ISO_8601).format("MM-DD-YYYY")
      setEndDate(result);
      setNewEndDate(new Date(value))
    }
  }

  const onTapApply = async () => {
    // console.log("NewDate", newStartDate, newEndDate)
    if (Date.parse(newStartDate) <= Date.parse(newEndDate)) {
      await adminDashboardProductsReportNew(startDate, endDate);
      localStorage.setItem("StartDatePass", startDate);
      localStorage.setItem("EndDatePass", endDate);
    }
    else {
      setErrorMsg("Please select start date less than end date");
      setErrorModalShow(true);
    }
  }
  const resetFilter = async () => {
    setStartDate(firstDay)
    setEndDate(todayDate)
    setNewStartDate(firstDayNew)
    setNewEndDate(todayDateNew)
    await adminDashboardProductsReportNew(firstDay, todayDate);
  }
  const onTapCleanData = async () => {
    setButtonLoading(true)
    const result = await postCleanData()
    console.log("Result", result)
    if (result?.data?.status == 200) {
      setButtonLoading(false)
      setErrorModalShow(true)
      setErrorMsg("Cleaned Data Successfully")
    }
    else {
      setButtonLoading(false)
      setErrorModalShow(true)
      setErrorMsg(result?.data?.status)
    }
  }
  const onTapScannedItem = async (id: any) => {
    localStorage.setItem("prefixIdForAdmin", id);
    navigate("/dashboardlistingreport");
  }
  //prev month start , end date
  var prevMonthLastDate = moment(startDate, "MM-DD-YYYY").subtract(1, 'months').endOf('month').format('MM-DD-YYYY');
  var prevMonthFirstDate = moment(startDate, "MM-DD-YYYY").subtract(1, 'months').startOf('month').format('MM-DD-YYYY');
  var nextMonthFirstDate = moment(endDate, "MM-DD-YYYY").add(1, 'M').startOf('month').format('MM-DD-YYYY');
  var nextMonthLastDate = moment(endDate, "MM-DD-YYYY").add(1, 'M').endOf('month').format('MM-DD-YYYY');

  const getPrevMonthData = async () => {
    setStartDate(prevMonthFirstDate)
    setEndDate(prevMonthLastDate)
    setNewStartDate(moment(prevMonthFirstDate, "MM-DD-YYYY").toDate())
    setNewEndDate(moment(prevMonthLastDate, "MM-DD-YYYY").toDate())
    await adminDashboardProductsReportNew(prevMonthFirstDate, prevMonthLastDate);
    localStorage.setItem("StartDatePass", prevMonthFirstDate);
    localStorage.setItem("EndDatePass", prevMonthLastDate);
  }

  const getNextMonthData = async () => {
    // console.log("endDate", endDate, todayDate, moment(nextMonthFirstDate, "MM-DD-YYYY").toDate(), moment().toDate())
    if (moment(moment(nextMonthFirstDate, "MM-DD-YYYY").toDate()).isBefore(moment().toDate())) {
      setStartDate(nextMonthFirstDate)
      setEndDate(nextMonthLastDate)
      setNewStartDate(moment(nextMonthFirstDate, "MM-DD-YYYY").toDate())
      setNewEndDate(moment(nextMonthLastDate, "MM-DD-YYYY").toDate())
      await adminDashboardProductsReportNew(nextMonthFirstDate, nextMonthLastDate);
      localStorage.setItem("StartDatePass", nextMonthFirstDate);
      localStorage.setItem("EndDatePass", nextMonthLastDate);
    }
    else {
      setErrorMsg("Please select month less than current month");
      setErrorModalShow(true);
    }
  }
  ///////////
 
  useEffect(() => {
    (async () => {
      await adminDashboardProductsReportNew(firstDay, todayDate);
    })();
    
    setNewStartDate(firstDayNew)
    setNewEndDate(todayDateNew)
    setStartDate(firstDay)
    setEndDate(todayDate)
    localStorage.setItem("StartDatePass", firstDay);
    localStorage.setItem("EndDatePass", todayDate);
    setSelectedIndex(0)
  }, []);


  return (
    <>
      {errorHandle()}
      <Header />
      <Box className="contentMain dashboardM"
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <div className=''>
          <h5 className="pageHead">Dashboard</h5>
          <div className='startMain'>
            <div className='d-flex align-items-center'>
              <div className='d-flex align-items-center filterContainer'>
                <div className='d-flex align-items-center'>
                  <div className='leftDiv filterItem'>
                    <img src={filter} className="mRight" />
                    <h5 className='m-0 text'>Filters</h5>
                  </div>
                  <div className='borderDiv filterItem'>
                    <div className='dateInput from d-flex align-items-center'>
                      <label className='w-100 formLabel'>From: </label>
                      <Tooltip TransitionComponent={Zoom} title="Previous Month" arrow placement="top">
                        <div className='me-2 pointer' onClick={() => getPrevMonthData()}>
                          <RightArrow className='leftArrow arrow' />
                        </div>
                      </Tooltip>
                      <div className="d-flex align-items-center outlinedInput">
                        <Calendar />
                        <DatePicker className='dateUi' selected={new Date(newStartDate)}
                          onChange={(selectedstart) => handleStartDate(selectedstart)}
                          dateFormat='MM-dd-yyyy'
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode='select'
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className='dateInput d-flex align-items-center'>
                      <label className='w-100 formLabel'>To: </label>
                      <div className="d-flex align-items-center outlinedInput">
                        <Calendar />
                        <DatePicker className='dateUi' selected={new Date(newEndDate)}
                          onChange={(selectedstart) => handleEndDate(selectedstart)}
                          dateFormat='MM-dd-yyyy'
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode='select'
                          maxDate={new Date()}
                        />
                      </div>
                      <Tooltip TransitionComponent={Zoom} title="Next Month" arrow placement="top">
                        <div className='ms-2 pointer' onClick={() => getNextMonthData()}>
                          <RightArrow className='arrow' />
                        </div>
                      </Tooltip>
                    </div>

                  </div>
                </div>
              </div>
              <Tooltip TransitionComponent={Zoom} title="Reset" arrow placement="top">
                <ResetFilter className='ms-3 pointer' onClick={() => resetFilter()} />
              </Tooltip>
            </div>
            <div className='d-flex justify-content-end w-50'>
              <button className='FilledBtn me-3' onClick={() => onTapApply()}>Apply</button>
              {!isButtonLoading ?
                <button className='FilledBtn' onClick={() => onTapCleanData()}>Clean Data</button>
                :
                <button className='FilledBtn processingBtn'>Cleaning Data..</button>}
            </div>
          </div>
          <div className='heightG'>
            <table className='tableH'>
              <thead className='thead rounded'>
                <tr>
                  <td>Sr. No.</td>
                  <td>Prefix</td>
                  <td>Warehouse</td>
                  <td>Scanned</td>
                  <td>Scanned(Pending)</td>
                  <td>Under Process</td>
                  <td>Listed</td>
                  <td>Error</td>
                </tr>
              </thead>
              <tbody className='tbody'>
                {!isLoading ?
                  <>
                    {countList > 0 ?
                      dataList?.map((value: any) => {
                        return (
                          <tr>
                            <td>{dataList?.findIndex((x: any) => x.prefixId === value?.prefixId) + 1}</td>
                            <td>{value?.prefix}</td>
                            <td>{value?.wareHouse}</td>
                            <td onClick={() => onTapScannedItem(value.prefixId)}><div className='clicked'>{value?.totalScanned}</div></td>
                            <td>{value?.totalPending}</td>
                            <td>{value?.totalNotFound}</td>
                            <td>{value?.totalProcessed}</td>
                            <td>{value?.totalError}</td>
                          </tr>
                        )
                      }) :
                      <tr>
                        <td colSpan={7}>
                          <p className='mb-0 py-2'>No records found</p>
                        </td>
                      </tr>}
                  </>
                  :
                  <Loader />
                }
              </tbody>
            </table>
          </div>
        </div>
      </Box>
    </>
  )
}

export default DashBoardScreen;