import { useAuth } from "../provider/authprovider";

export const useReportsAPI = () => {
    const { setLoading, api, handleError } = useAuth()
 
    const getUserReport = async(warehouseId: any, startDate: any, endDate: any) => {
        setLoading(true)
        try {
            const result = await api.get(`/api/AdminDashboard/GetUserReports?Warehouse=${warehouseId}&FromDate=${startDate}&ToDate=${endDate}`);
            return result
        } catch (error: any) {
            handleError(error)
        }
        finally {
            setLoading(false)
        }
    };
    return { getUserReport } as const
}