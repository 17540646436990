import { useState, useEffect } from "react";
import Logo from "../../assets/resale-logo.svg";
import Warehouse from "../../assets/warhouseImg.svg";
import { Modal, Button, Spinner } from "react-bootstrap";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../../shared/provider/authprovider";
import { useNavigate } from "react-router-dom";
import './UserDashboard.scss';
import Header from '../../components/header/Header';
import ErrorModal from '../../components/errorModal/ErrorModal';
import { Box } from "@mui/material";

const UserDashboard = (props: any) => {
  const { isLoading, getWarehouseListCalledForUser, errorModalShow, setSelectedIndex,
    setErrorModalShow, setErrorMsg } = useAuth();
  const [boxNumber, setBoxNumber] = useState("");
  const [warehouseName, setWarehouseName] = useState("");
  const [prefixName, setPrefixName] = useState("");
  const [warehouseList, setWarehouseList] = useState<any[]>([]);
  const [warehouseListOffset, setWarehouseListOffset] = useState(0);
  const [warehouseListLimit, setWarehouseListLimit] = useState(999);

  let navigate = useNavigate();

  useEffect(() => {
    getWarehouseList();
    setSelectedIndex(0)
  }, []);

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };

  const getWarehouseList = async () => {
    const data = await getWarehouseListCalledForUser(warehouseListOffset, warehouseListLimit);
    // console.log("list warehouse - ", data);
    setWarehouseList(data);
    setWarehouseName(data[0].wareHouse?.name);
    setPrefixName(data[0]?.wareHouse?.warehousePrefix[0]?.prefixName);
    localStorage.setItem("prefixName", data[0]?.wareHouse?.warehousePrefix[0]?.prefixName);
    localStorage.setItem("prefixId", data[0]?.wareHouse?.warehousePrefix[0]?.id);
    localStorage.setItem("warehouseName", data[0].wareHouse?.name);
  };

  const onTapEnterBtn = (item: any, data: any) => {
    // navigate("/boxscreen");
    setPrefixName(item.prefixName);
    setWarehouseName(data?.wareHouse?.name)
    localStorage.setItem("prefixName", item.prefixName);
    localStorage.setItem("prefixId", item.id);
    localStorage.setItem("warehouseName", data?.wareHouse?.name);
  }

  const listItems = warehouseList.map((data: any) => (
    // <li>{number}</li>
    <div className="warehousedetail">
      <h6 className="firstN">{data?.wareHouse?.name}</h6>
      <div className="">
        {/* <label className="warehouse-heading">{data?.wareHouse?.location}</label> */}
        <div className="warehouse-list d-flex">
          {data?.wareHouse?.warehousePrefix ? data?.wareHouse?.warehousePrefix.map((item: any) => (

            <div className="warehouse-list-item">
              <Button
                className="enterBtn active"
                onClick={() => onTapEnterBtn(item, data)}
              >
                {item?.prefixName}
              </Button>
            </div>

          )) : null}
        </div>

      </div>
    </div>
  ));

  const onTapStartBtn = () => {
    {
      boxNumber ?
        navigate("/boxtablescreen") :
        // alert("Please enter box number to procced")
        setErrorModalShow(true);
      setErrorMsg("Please enter box number to procced");
    }
  }

  return (<>
    {errorHandle()}
    <Header />
    <Box className="contentMain vendorDetailContainer"
      component="main"
      sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
    >
      <div className="container dashbody">
        {isLoading && <div className="loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>}
        <div className="MainWarelist">
          <div className="warehouseContainer">
            <h6 className="dashH6">Warehouse List</h6>
            <ul className="warhouseUl">{listItems}</ul>
          </div>
          <div className="boxList">
            <h5>Details</h5>
            <h6>{warehouseName}</h6>
            <h6 className="prefixname">{prefixName}</h6>
            <div className="boxMainDetail">
              <label>Box No.</label>
              <input
                value={boxNumber}
                placeholder="Enter Box No."
                onChange={(event) => { setBoxNumber(event.target.value); localStorage.setItem("boxNumber", event.target.value) }}></input>

              {/* TODO:: ADD IMAGE */}
            </div>
            <Button className="startBtn" onClick={() => onTapStartBtn()}>Start</Button>
            <div className="text-center">
              <img className="warehouseImg" src={Warehouse} alt="Warehouse Image" />
            </div>
          </div>
        </div>
      </div>
    </Box>
  </>
  );
};

export default UserDashboard;
