import { Box, Checkbox, Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { Field, Form, Formik, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ReactComponent as BlueFilledCheckCircle } from "../../../src/assets/icons/blue-filled-check-circle.svg";
import { ReactComponent as ListableItemLocation } from "../../../src/assets/icons/listable-item-location.svg";
import { ReactComponent as ListableItem } from "../../../src/assets/icons/listable-item.svg";
import { ReactComponent as Quit } from "../../../src/assets/icons/quit.svg";
import { ReactComponent as ROValidation } from "../../../src/assets/icons/ro-validation.svg";
import { ReactComponent as UpcScanning } from "../../../src/assets/icons/upc-scanning.svg";
import ErrorModal from "../../components/errorModal/ErrorModal";
import Header from "../../components/header/Header";
import Loader from "../../loader/index";
import { useAuth } from "../../shared/provider/authprovider";
import { useRecAppApi } from "../../shared/api/receivingApplication";
import "./ROValidationScree.scss";
import { style } from "../listingScreens/RegistrationScreen";

interface MyFormValues {
  code: string;
}
interface MyFormValues2 {
  upc: string;
}
interface MyFormValues3 {
  upc: string;
  brand: string;
}
interface MyFormValues4 {
  location: string;
  quantity: any;
}
const DisplayingErrorMessagesSchema2 = Yup.object().shape({
  upc: Yup.string().required("Required"),
});
const DisplayingErrorMessagesSchema3 = Yup.object().shape({
  upc: Yup.string().required("Required"),
  brand: Yup.string().required("Required"),
});

const DisplayingErrorMessagesSchema = Yup.object().shape({
  code: Yup.string().required("Required"),
});
const DisplayingErrorMessagesSchema4 = Yup.object().shape({
  location: Yup.string().required("Required"),
  quantity: Yup.number().required("Required"),
});

const ROValidationScree = () => {
  let navigate = useNavigate();
  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    );
  };

  const {
    setSelectedIndex,
    getReceivingOrderNumberCalled,
    isROValid,
    setErrorModalShow,
    setErrorMsg,
    errorModalShow,
    // getValidatScanUPCCalled,
    productCategoryList,
    getProductCategoryListCalled,
    putReceivingSentToStoreOrTrashCalled,
    roValidationBox,
    setRoValidationBox,
    upcValidationBox,
    setupcValidationBox,
    notScanableBox,
    setNotScanableBox,
    listableItemBox,
    setListableItemBox,
    listableLocationBox,
    setListableLocationBox,
    postListableLocationCalled,
    unlistableItemBox,
    setUnlistableItemBox,
    isLoading,
    scanResponse,
    // scanResponseProduct,
    lastListedQuantity,
    lastListedLocation,
    lastListedUPC,
    productFlagsBox, setProductFlagsBox,
    productWeightBox, setProductWeightBox,
  } = useAuth();
  const { getValidatScanUPCCalled } = useRecAppApi();
  const [scanResponseProduct, setScanResponseProduct] = useState<any | null>(
    null
  );

  const RO = localStorage.getItem("RONumber");
  const UPC = localStorage.getItem("UPC");
  const unListableText = localStorage.getItem("UnlistableText");

  const [disableButtons, setDisableButtons] = useState(false)

  const initialValues: MyFormValues = { code: "" };
  const initialValues2: MyFormValues2 = { upc: "" };
  const initialValues3: MyFormValues3 = { upc: "", brand: "" };
  const initialValues4: MyFormValues4 = { location: "", quantity: 1 };
  const formikListableItem: any = useFormik({
    initialValues: {
      upc: "",
    },
    onSubmit: () => submitUPC(),
  });
  const [textListable, setTextListable] = useState<any>("")
  const [mainFunctionButton, setMainFunctionButton] = useState<any>(true)
  const [isPriceGreaterThan25, setIsPriceGreaterThan25] = useState(false);
  const submitUPC = async () => {
    const result = await getValidatScanUPCCalled(
      formikListableItem?.values?.upc, RO
    );
    if (result?.items?.isListable == true) {
      localStorage.setItem("UPC", formikListableItem?.values.upc);
      setIsChecked(false)
      setIsCheckedRPE(false)
      setScanResponseProduct(result?.items);
      // setSelectedImage(result?.items?.channelImages[0])
      setSelectedImageRPE(result?.items?.images[0])
      let hasPriceGreaterThan25 = false;
      for (const item of result?.items?.prices) {
        if (item.displayPrice > 25) {
          hasPriceGreaterThan25 = true;
          setDisableButtons(false);
          setMainFunctionButton(true)
          break;
        }
        else {
          hasPriceGreaterThan25 = false
          setDisableButtons(true)
          setMainFunctionButton(false)
        }
      }
      setIsPriceGreaterThan25(hasPriceGreaterThan25);
      setTextListable("Item is Listable")
      let highestPrice = result?.items?.prices[0].displayPrice;
      for (const item of result?.items?.prices) {
        if (item.displayPrice > highestPrice) {
          highestPrice = item.displayPrice;
        }
      }
      let highestIndex: any = null;
      let highestDisplayPrice = 0;
      result?.items?.prices.forEach((item: any, index: any) => {
        if (item.displayPrice > highestDisplayPrice) {
          highestDisplayPrice = item.displayPrice;
          highestIndex = index;
        }
      });
      setSelectedProductIndex(highestIndex)
      setHeighestPrice(highestPrice);
      setPriceToSubmit(highestPrice)
    } else if (result?.items?.isListable == false) {
      setTextListable("Item is Unlistable")
      localStorage.setItem("UPC", formikListableItem?.values.upc);
      setScanResponseProduct(result?.items);
      setDisableButtons(true);
      setMainFunctionButton(false)
    }
  };
  const formik: any = useFormik({
    initialValues: {
      upc: "",
      brand: "",
    },
    validationSchema: DisplayingErrorMessagesSchema3,
    onSubmit: () => submitData(),
  });
  const submitData = async () => {
    console.log("formik", formik.values.upc);
  };
  const formikRO: any = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: () => submitDataRO(),
  });
  const isQbids = localStorage.getItem("isQbidAccount");
  console.log("isQbids", isQbids)
  const submitDataRO = async () => {
    await getReceivingOrderNumberCalled(formikRO.values.code);
    localStorage.setItem("RONumber", formikRO.values.code);
    const ROValidationResp: any = localStorage.getItem("ROValidation");
    if (ROValidationResp == "true") {
      setupcValidationBox(true);
      setRoValidationBox(false);
    } else {
      setErrorModalShow(true);
      setErrorMsg("RO Invalid.");
    }
  };

  const roValidationBoxUI = () => {
    return (
      <FormikProvider value={formikRO}>
        <div className="formContainer">
          <div className="row align-items-center formItem">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="upc">RO Number : </label>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
              <Field
                autoFocus
                className="textfield"
                id="code"
                name="code"
                placeholder="Enter RO Number"
                onKeyPress={(event: any) => {
                  event.key == "Enter"
                    ? formikRO.handleSubmit()
                    : console.log("null");
                }}
              />
              {formikRO?.touched?.code && formikRO?.errors?.code && (
                <div className="errorMsg">{formikRO?.errors?.code}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="offset-lg-4 col-sm-12 col-md-12 col-lg-8 btnGrp">
              <Button
                className="FilledBtn"
                onClick={() => formikRO.handleSubmit()}
              >
                Continue
              </Button>
              <Button
                className="FilledBtn"
                onClick={() => formikRO.resetForm()}
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
      </FormikProvider>
    );
  };
  const [heighestPrice2, setHeighestPrice] = useState<any>(null);

  const upcScannBoxUI = () => {
    return (
      <Formik
        initialValues={initialValues2}
        enableReinitialize={true}
        validationSchema={DisplayingErrorMessagesSchema2}
        onSubmit={async (values) => {
          const result = await getValidatScanUPCCalled(values?.upc, RO);
          console.log(result, "77");
          if (result?.items?.isListable == true) {
            localStorage.setItem("UPC", values.upc);
            setIsChecked(false)
            setIsCheckedRPE(false)
            setScanResponseProduct(result?.items);
            // setSelectedImage(result?.items?.channelImages[0])
            setSelectedImageRPE(result?.items?.images[0])
            let hasPriceGreaterThan25 = false;
            for (const item of result?.items?.prices) {
              if (item.displayPrice > 25) {
                hasPriceGreaterThan25 = true;
                setDisableButtons(false);
                setMainFunctionButton(true)
                break;
              }
              else {
                hasPriceGreaterThan25 = false
                setDisableButtons(true)
                setMainFunctionButton(false)
              }
            }
            setIsPriceGreaterThan25(hasPriceGreaterThan25);
            setTextListable("Item is Listable")
            setListableItemBox(true);
            setupcValidationBox(false);
            let highestPrice = result?.items?.prices[0].displayPrice;
            for (const item of result?.items?.prices) {
              if (item.displayPrice > highestPrice) {
                highestPrice = item.displayPrice;
              }
            }
            let highestIndex: any = null;
            let highestDisplayPrice = 0;
            result?.items?.prices.forEach((item: any, index: any) => {
              if (item.displayPrice > highestDisplayPrice) {
                highestDisplayPrice = item.displayPrice;
                highestIndex = index;
              }
            });
            setSelectedProductIndex(highestIndex)
            setHeighestPrice(highestPrice);
            setPriceToSubmit(highestPrice)
          } else if (result?.items?.isListable == false) {
            setListableItemBox(true);
            setupcValidationBox(false);
            setTextListable("Item is Unlistable")
            localStorage.setItem("UPC", values.upc);
            setScanResponseProduct(result?.items);
            setDisableButtons(true);
            setMainFunctionButton(false)
          }
          // else {
          //   setErrorModalShow(true);
          //   setErrorMsg(result?.res);
          // }
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="formContainer">
              <div className="row align-items-center formItem">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="upc">UPC : </label>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-8">
                  <Field
                    autoFocus
                    className="textfield"
                    id="upc"
                    name="upc"
                    placeholder="Enter UPC Number"
                  />
                </div>
                <div className="col-sm-12 col-md-8 col-lg-8 offset-md-4">
                  {errors.upc && <div className="errorMsg">{errors.upc}</div>}
                </div>
              </div>
              <div className="row">
                <div className="offset-lg-4 col-sm-12 col-md-12 col-lg-8 btnGrp">
                  <Button className="FilledBtn" type="submit">
                    Continue
                  </Button>
                  <Button
                    className="FilledBtn"
                    onClick={() => {
                      setNotScanableBox(true);
                      setupcValidationBox(false);
                    }}
                  >
                    Not Scannable
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  };
  const NotScanableBoxUI = () => {
    return (
      <FormikProvider value={formik}>
        <div className="formContainer">
          <div className="row align-items-center formItem">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="location">Enter UPC : </label>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
              <Field
                autoFocus
                className="textfield"
                id="upc"
                name="upc"
                placeholder="Enter UPC Number"
              />
              {
                <span className="text-danger">
                  {formik.errors?.upc &&
                    formik.touched?.upc &&
                    formik.touched?.upc &&
                    formik.errors?.upc}
                </span>
              }
            </div>
          </div>
          <div className="row align-items-center formItem">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="location">Select brand : </label>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
              <Field name="brand" className="outlinedInput">
                {({ field }: any) => (
                  <select {...field} className="textfield">
                    <option value="">Select Brand</option>
                    {productCategoryList?.map((i: any) => (
                      <option
                        className="clickItem"
                        key={i.category}
                        value={i.category}
                      >
                        {i.category}
                      </option>
                    ))}
                  </select>
                )}
              </Field>
              {
                <span className="text-danger">
                  {formik.errors?.brand &&
                    formik.touched?.brand &&
                    formik.touched?.brand &&
                    formik.errors?.brand}
                </span>
              }
            </div>
          </div>
          <div className="row">
            <div className="offset-lg-4 col-sm-12 col-md-12 col-lg-8 btnGrp">
              <Button
                className="FilledBtn"
                onClick={async () => {
                  formik.handleSubmit();
                  await putReceivingSentToStoreOrTrashCalled(
                    RO,
                    formik?.values?.upc,
                    9,
                    formik?.values?.brand
                  );
                  // localStorage.removeItem("ROValidation");
                  localStorage.removeItem("UPC");
                  // localStorage.removeItem("RONumber");
                  localStorage.removeItem("UnlistableText");
                  formik.resetForm();
                  formikRO.resetForm();
                  formikListableItem.resetForm()

                }}
              >
                Sent To Store
              </Button>
              <Button
                className="FilledBtn"
                onClick={async () => {
                  formik.handleSubmit();
                  await putReceivingSentToStoreOrTrashCalled(
                    RO,
                    formik?.values?.upc,
                    10,
                    formik?.values?.brand
                  );
                  // localStorage.removeItem("ROValidation");
                  localStorage.removeItem("UPC");
                  // localStorage.removeItem("RONumber");
                  localStorage.removeItem("UnlistableText");
                  formik.resetForm();
                  formikRO.resetForm();
                  formikListableItem.resetForm()

                }}
              >
                Sent to Trash
              </Button>
            </div>
          </div>
        </div>
      </FormikProvider>
    );
  };

  const validationSchemaroductFlags = Yup.object().shape({
    category: Yup.string()
      .required('Category is required'),
    condition: Yup.string()
      .required('Condition is required'),
  });
  const formikProductFlags: any = useFormik({
    initialValues: {
      category: "",
      condition: ""
    },
    validationSchema: validationSchemaroductFlags,
    onSubmit: () => {
      setProductFlagsBox(false);
      setProductWeightBox(true);
    },
  });
  const category = [
    { value: 3712, label: "Outdoor, Tools & Sporting Goods" },
    { value: 3692, label: "Home & Furniture" },
    { value: 3693, label: "Health, Beauty & Fitness" },
    { value: 3679, label: "Baby & Kids" },
    { value: 3687, label: "Electronics & Appliances" },
    { value: 3711, label: "Pallets" },
    { value: 3716, label: "Fashion" },
    { value: 3717, label: "Miscellaneous" }
  ]
  const conditionArray = [
    "New",
    "Open-Box",
    "Minor Imperfections"
  ]
  const productFlagsBoxUI = () => {
    return (
      <FormikProvider value={formikProductFlags}>
        <div className="formContainer">
          <div className="row align-items-center formItem">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="category">Category : </label>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
              <Field name="category">
                {({ field }: any) => (
                  <select {...field} className="textfield">
                    <option value="">Select Category</option>
                    {category?.map((i: any) => (
                      <option
                        className="clickItem"
                        key={i.value}
                        value={i.value}
                      >
                        {i.label}
                      </option>
                    ))}
                  </select>
                )}
              </Field>
              {
                <span className="text-danger">
                  {formikProductFlags.errors?.category &&
                    formikProductFlags.touched?.category &&
                    formikProductFlags.touched?.category &&
                    formikProductFlags.errors?.category}
                </span>
              }
            </div>
          </div>
          <div className="row align-items-center formItem mt-4">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="condition">Condition : </label>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-8">
              <Field name="condition">
                {({ field }: any) => (
                  <select {...field} className="textfield">
                    <option value="">Select Condition</option>
                    {conditionArray?.map((i: any) => (
                      <option
                        className="clickItem"
                        key={i}
                        value={i}
                      >
                        {i}
                      </option>
                    ))}
                  </select>
                )}
              </Field>
              {
                <span className="text-danger">
                  {formikProductFlags.errors?.condition &&
                    formikProductFlags.touched?.condition &&
                    formikProductFlags.touched?.condition &&
                    formikProductFlags.errors?.condition}
                </span>
              }
            </div>
          </div>
          <div className="row">
            <div className="offset-lg-4 col-sm-12 col-md-12 col-lg-8 btnGrp">
              <Button
                className="FilledBtn outlineBtns"
                onClick={async () => {
                  setListableItemBox(true);
                  setProductFlagsBox(false);
                  formikProductFlags.resetForm()
                }}
              >
                Back
              </Button>
              <Button
                className="FilledBtn outlineBtns"
                onClick={() => {
                  formikProductFlags.handleSubmit()
                }}
              >
                Continue
              </Button>

            </div>
          </div>
        </div>
      </FormikProvider>
    )
  }
  const validationSchemaWeight = Yup.object().shape({
    weight: Yup.number()
      .min(0, 'Weight must be at least 0.00')
      .max(250, 'Weight must be less than or equal to 250.00')
      .typeError('Weight must be a valid decimal number')
      .required('Weight is required')
  });
  const formikproductWeight: any = useFormik({
    initialValues: {
      weight: ""
    },
    validationSchema: validationSchemaWeight,
    onSubmit: async () => {
      const detailstoSend = {
        qbidsDto: {
          upc: scanResponseProduct?.upc,
          category_id: formikProductFlags.values.category,
          name: scanResponseProduct?.title,
          item_condition: formikProductFlags.values.condition,
          description: scanResponseProduct?.description,
          warehouse_location_id: "A-01-01-A-01",
          images: scanResponseProduct?.images,
          start_price: "5.00",
          retail_price: `${priceToSubmit}`,
          buyout_price: `${priceToSubmit}`,
          item_weight: `${formikproductWeight?.values?.weight}`,
          listing_location_id: "4"
        },
        rmDto: {
          quantity: 1,
          location: "RE",
          upc: UPC,
          receiptOrderId: RO,
          // needsUpdate: formikImages.values.needsUpdate
          image: scanResponseProduct?.images[0]
        }
      }
      postListableLocationCalled(detailstoSend)
      setListableLocationBox(false)
      setProductFlagsBox(false)
      setProductWeightBox(false)
      setupcValidationBox(true)
      formikListableItem.resetForm();
      formikProductFlags.resetForm();
      formikproductWeight.resetForm();
      formikImages.resetForm()
      setIsChecked(false)
      setIsCheckedRPE(false)
    },
  });
  const productWeightBoxUI = () => {
    return (
      <FormikProvider value={formikproductWeight}>
        <div className="formContainer">
          <div className="row align-items-center formItem">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="location">Enter Weight : </label>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
              <Field name="weight" className="textfield">
                {({ field }: any) => (
                  <TextField
                    {...field}
                    className="textfield"
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Lbs (Pounds)</InputAdornment>,
                    }}
                    type="number"
                    autoFocus
                    placeholder="Enter weight"
                    onKeyPress={(event: any) => {
                      event.key == "Enter"
                        ? formikproductWeight.handleSubmit()
                        : console.log("null");
                    }}
                    sx={{
                      '.MuiInputBase-input': {

                        width: '100%',
                        padding: '11px 10px',
                        lineHeight: 0
                      },
                      '.MuiOutlinedInput-root': {
                        '&.Mui-focused': {
                          '.MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #004eff',
                          }
                        }
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #8b9297',
                        borderRadius: '6px',
                      }
                    }}
                  />
                )}
              </Field>
              {
                <span className="text-danger">
                  {formikproductWeight.errors?.weight &&
                    formikproductWeight.touched?.weight &&
                    formikproductWeight.touched?.weight &&
                    formikproductWeight.errors?.weight}
                </span>
              }
            </div>

          </div>
          <div className="row">
            <div className="offset-lg-4 col-sm-12 col-md-12 col-lg-8 btnGrp">
              <Box className="btnGrp btnBox mt-0">
                <Button
                  className="FilledBtn outlineBtns"
                  onClick={async () => {
                    setProductFlagsBox(true);
                    setProductWeightBox(false);
                    formikproductWeight.resetForm()
                  }}
                >
                  Back
                </Button>
                <Button
                  className="FilledBtn outlineBtns"
                  onClick={() => {
                    formikproductWeight.handleSubmit()
                  }}
                >
                  Submit
                </Button>


              </Box>
            </div>
          </div>
        </div>
      </FormikProvider>
    )
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    // console.log("checked", !isChecked ? true : false)
  };
  const [isCheckedRPE, setIsCheckedRPE] = useState(false);
  const handleCheckboxChangeRPE = () => {
    setIsCheckedRPE(!isCheckedRPE);
    console.log("checked", !isCheckedRPE ? true : false)
    formikImages.setFieldValue("needsUpdate", !isCheckedRPE ? true : false)
  };
  const formikImages: any = useFormik({
    initialValues: {
      needsUpdate: isCheckedRPE,
    },
    onSubmit: () => submitUPC(),
  });

  const [selectedProductIndex, setSelectedProductIndex] = useState<any>();
  let [priceToSubmit, setPriceToSubmit] = useState<any>()

  const handleRadioChange = (index: any) => {
    setSelectedProductIndex(index);
    setPriceToSubmit(scanResponseProduct?.prices[index].displayPrice);
  };
  // let [selectedImage, setSelectedImage] = useState<any>(scanResponseProduct?.channelImages[0]);

  // const handleTabClick = (image: any) => {
  //   setSelectedImage(image);
  // };
  let [selectedImageRPE, setSelectedImageRPE] = useState<any>(scanResponseProduct?.images[0]);

  const handleTabClickRPE = (image: any) => {
    setSelectedImageRPE(image);
  };
  const listableItemBoxUI = () => {
    return (
      <>
        <FormikProvider value={formikListableItem}>
          <div className="row align-items-center formItem">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="location">UPC Scanning : </label>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Field
                autoFocus
                className="textfield"
                id="upc"
                name="upc"
                // style={{
                //   borderRadius: "6px",
                //   border: "1px solid #8B9297",
                //   padding: "8px",
                //   background: "#fff",
                // }}
                placeholder="Enter UPC Number"
                onKeyPress={(event: any) => {
                  event.key == "Enter"
                    ? formikListableItem.handleSubmit()
                    : console.log("null");
                }}
              />
              {
                <span className="text-danger">
                  {formik.errors?.upc &&
                    formik.touched?.upc &&
                    formik.touched?.upc &&
                    formik.errors?.upc}
                </span>
              }
            </div>
          </div>
          <div className="d-flex productContainer">

            {textListable == "Item is Listable" ?
              <Box>

                <Stack className="imgSection" alignItems='flex-start'>
                  {/* <Typography variant="h5" fontSize={20} fontWeight={600} color='#000' mb={5}>Click the image that matches the item in your hand.</Typography> */}

                  <Grid container spacing={4}>
                    {/* <Grid item xs={12} md={6} lg={6}> */}
                    {/* <Grid container spacing={2}>
                        <Grid item xs={12} md={8} lg={8}>
                          <Typography variant="body1" fontSize={18} fontWeight={600} mb={2} textAlign='center'>RPE</Typography>
                        </Grid>
                      </Grid> */}

                    <Grid container spacing={2.5}>
                      <Grid item xs={12} md={8} lg={8}>
                        <Stack alignItems='center'>
                          <img src={selectedImageRPE} style={{ width: '100%', objectFit: 'cover' }}
                          // onClick={() => handleCheckboxChangeRPE}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <Stack gap={2.5} flexDirection={{ xs: 'row', md: 'column' }} flexWrap='wrap' >
                          {scanResponseProduct?.images?.map((imglink: any) => {
                            return (
                              <img onClick={() => handleTabClickRPE(imglink)} src={imglink} style={{ width: '100%', maxWidth: '100px', objectFit: 'cover', borderColor: selectedImageRPE == imglink ? "blue" : "" }} />
                            )
                          })}
                        </Stack>

                      </Grid>
                    </Grid>

                    {/* <Grid container spacing={2}>
                        <Grid item xs={12} md={8} lg={8} textAlign='center'>
                          <Checkbox checked={isCheckedRPE}
                            onChange={handleCheckboxChangeRPE} sx={{
                              '&:hover': {
                                backgroundColor: 'unset',
                              },
                              '&.Mui-focusVisible': {
                                backgroundColor: 'transparent',
                              },
                              color: 'transparent',
                              width: 'max-content',

                              '&.Mui-checked': {
                                color: '#2babab',
                              },
                              '& .MuiSvgIcon-root': { fontSize: 60, border: '1px solid #6D6E6F', }
                            }} />
                        </Grid>
                      </Grid> */}
                    {/* </Grid> */}
                    {/* <Grid item xs={12} md={6} lg={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={8} lg={8}>
                          <Typography variant="body1" fontSize={18} fontWeight={600} mb={2} textAlign='center'>Channel Advisor</Typography>
                        </Grid>
                      </Grid> */}

                    {/* <Grid container spacing={2.5}>
                        <Grid item xs={12} md={8} lg={8}>
                          <Stack alignItems='center'>
                            <img src={selectedImage} style={{ width: '100%', objectFit: 'cover' }}
                            // onClick={() => handleCheckboxChange()} 
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                          <Stack gap={2.5} flexDirection={{ xs: 'row', md: 'column' }} flexWrap='wrap'>
                            {scanResponseProduct?.channelImages?.map((imglink: any) => {
                              return (

                                <img onClick={() => handleTabClick(imglink)} src={imglink} style={{ width: '100%', maxWidth: '100px', objectFit: 'cover', borderColor: selectedImageRPE == imglink ? "blue" : "" }} />

                              )
                            })}
                          </Stack>
                        </Grid>
                      </Grid> */}
                    {/* <Grid container spacing={2}>
                        <Grid item xs={12} md={8} lg={8} textAlign='center'>
                          <Checkbox checked={isChecked}
                            onChange={() => handleCheckboxChange()} sx={{
                              '&:hover': {
                                backgroundColor: 'unset'
                              },
                              color: 'transparent',
                              width: 'max-content',
                              '&.Mui-checked': {
                                color: '#2babab',
                              },
                              '& .MuiSvgIcon-root': { fontSize: 60, border: '1px solid #6D6E6F', }
                            }} />
                        </Grid>
                      </Grid> */}
                    {/* </Grid> */}
                  </Grid>

                </Stack>

                <div>
                  <Stack direction="row" alignItems="center" mb={1}>
                    <Typography variant="body2" fontSize={14} fontWeight={600}>
                      Product Name:{" "}
                      <span style={{ fontSize: "13px", fontWeight: 400 }}>
                        {" "}
                        {scanResponseProduct?.title}
                      </span>
                    </Typography>
                  </Stack>
                  <Typography variant="body2" fontSize={14} fontWeight={600}>
                    Product Description :{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "13px", fontWeight: 400, mb: 1 }}
                  >
                    {scanResponseProduct?.description}
                  </Typography>
                  <Typography variant="body2" fontSize={14} fontWeight={600} mb={1}>
                    Product Prices :
                  </Typography>
                  <div className="heightG">
                    <table className="tableH">
                      <thead className="thead rounded">
                        <tr>
                          <th>
                            <strong style={{ fontWeight: 600 }}>Source</strong>
                          </th>
                          <th>
                            <strong style={{ fontWeight: 600 }}>Price</strong>
                          </th>
                          {/* {isQbids == "true" ? */}
                          <th>
                            <strong style={{ fontWeight: 600 }}>Price</strong>
                          </th>
                          {/* :
                            <></>} */}
                        </tr>
                      </thead>
                      <tbody className="tbody">
                        {scanResponseProduct?.prices?.map((price: any, index: any) => {
                          return (
                            <>
                              <tr style={{ backgroundColor: price?.merchant == "Retail Price" ? "#C8D6F8" : "white" }}>
                                <td>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: 400,
                                      mb: 1,
                                    }}
                                  >
                                    {price?.merchant}
                                  </Typography>
                                </td>
                                <td>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: 400,
                                      mb: 1,
                                    }}
                                  >
                                    <span className="me-3">{price?.displayPriceFrom}</span>
                                    <span>{formatter.format(price?.displayPrice)}</span>
                                  </Typography>
                                </td>
                                {/* {isQbids == "true" ? */}
                                <td>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: 400,
                                      mb: 1,
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      value={index}
                                      checked={selectedProductIndex === index}
                                      onChange={() => handleRadioChange(index)}
                                    />
                                  </Typography>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Box >
              :
              <Stack direction="row" alignItems="center" mb={1}>
                <div className="imgSection">
                  <img src="" height={200} width={200} />
                </div>
                <>
                  <Typography variant="body2" fontSize={14} fontWeight={600}>
                    Product Name:{" "}
                    <span style={{ fontSize: "13px", fontWeight: 400 }}>
                      {" "}
                      {scanResponseProduct?.title}
                    </span>
                    <Typography variant="body2" fontSize={14} fontWeight={600} className="mt-3">
                      Item is Unlistable
                    </Typography>
                  </Typography>
                </>

              </Stack>
            }

          </div >
          <Box className="btnGrp mt-4 mb-4 btnBox">
            <Stack className="firstTwoBtnStack">
              {isQbids == "true" ?
                <>
                  <Button
                    className="FilledBtn outlineBtns"
                    onClick={async () => {
                      setListableItemBox(false)
                      setupcValidationBox(true)
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    className="FilledBtn outlineBtns"
                    onClick={() => {
                      setListableItemBox(false);
                      setProductFlagsBox(true);
                    }}
                  >
                    Continue
                  </Button>
                </>
                :
                <>
                  {
                    mainFunctionButton ?
                      <>
                        <Button
                          className="FilledBtn flexBtn"
                          onClick={async () => {
                            // setListableItemBox(false);
                            // setListableLocationBox(true);
                            // await postListableLocationCalled(RO, UPC, "RE", 1);
                            const detailsRecom = {
                              qbidsDto: {
                                upc: "",
                                category_id: "",
                                name: "",
                                item_condition: "",
                                description: "",
                                warehouse_location_id: "",
                                images: [
                                  ""
                                ],
                                start_price: "",
                                retail_price: "",
                                buyout_price: "",
                                item_weight: "",
                                listing_location_id: ""
                              },
                              rmDto: {
                                quantity: 1,
                                location: "RE",
                                upc: UPC,
                                receiptOrderId: RO,
                                // needsUpdate: formikImages.values.needsUpdate
                                image: scanResponseProduct?.images[0]
                              }
                            }
                            await postListableLocationCalled(detailsRecom)
                            formikListableItem.resetForm()

                          }}
                        >
                          Staging
                        </Button>
                      </> :
                      <>
                        <Button className="FilledBtn flexBtn disabledButton" style={{ cursor: "not-allowed", color: "gray", borderColor: "gray" }}>
                          Staging
                        </Button>
                      </>
                  }
                  {
                    disableButtons ?
                      <>
                        <Button
                          className="FilledBtn flexBtn"
                          onClick={async () => {
                            await putReceivingSentToStoreOrTrashCalled(RO, UPC, 9, null);
                            // localStorage.removeItem("ROValidation");
                            localStorage.removeItem("UPC");
                            // localStorage.removeItem("RONumber");
                            localStorage.removeItem("UnlistableText");
                            formik.resetForm();
                            formikRO.resetForm();
                            formikListableItem.resetForm()
                            formikImages.resrtForm()
                            setIsChecked(false)
                            setIsCheckedRPE(false)
                          }}
                        >
                          Sent to Store
                        </Button>
                        <Button
                          className="FilledBtn flexBtn"
                          onClick={() => {
                            putReceivingSentToStoreOrTrashCalled(RO, UPC, 10, null)
                            localStorage.removeItem("UPC");
                            // localStorage.removeItem("RONumber");
                            localStorage.removeItem("UnlistableText");
                            formik.resetForm();
                            formikRO.resetForm();
                            formikListableItem.resetForm()
                            formikImages.resrtForm()
                            setIsChecked(false)
                            setIsCheckedRPE(false)
                          }
                          }
                        >
                          Sent to Trash
                        </Button>
                      </> :
                      <>
                        <Button className="FilledBtn flexBtn disabledButton" style={{ cursor: "not-allowed", color: "gray", borderColor: "gray" }}>
                          Sent to Store
                        </Button>
                        <Button className="FilledBtn flexBtn disabledButton" style={{ cursor: "not-allowed", color: "gray", borderColor: "gray" }}>
                          Sent to Trash
                        </Button>
                      </>
                  }
                </>
              }
            </Stack>
          </Box>
        </FormikProvider >
      </>
    );
  };
  const listableLocationBoxUI = () => {
    return (
      <>
        <Formik
          initialValues={initialValues4}
          enableReinitialize={true}
          onSubmit={async (values) => {
            const details = {
              qbidsDto: {
                upc: "",
                category_id: "",
                name: "",
                item_condition: "",
                description: "",
                warehouse_location_id: "",
                images: [
                  ""
                ],
                start_price: "",
                retail_price: "",
                buyout_price: "",
                item_weight: "",
                listing_location_id: ""
              },
              rmDto: {
                quantity: 1,
                location: "RE",
                upc: UPC,
                receiptOrderId: RO,
                // needsUpdate: formikImages.values.needsUpdate
                image: scanResponseProduct?.images[0]
              }
            }
            await postListableLocationCalled(details);
            formikListableItem.resetForm()
            formikProductFlags.resetForm();
            formikproductWeight.resetForm();
            formikImages.restForm();
          }}
          validationSchema={DisplayingErrorMessagesSchema4}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="formContainer">
                <div className="row align-items-center formItem">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="location">Location : </label>
                  </div>
                  <div className="col-sm-12 col-md-8 col-lg-8">
                    <Field
                      autoFocus
                      type="string"
                      className="textfield"
                      id="location"
                      name="location"
                      placeholder="Enter Location"
                    />
                  </div>
                  <div className="col-sm-12 col-md-8 col-lg-8 offset-md-4">
                    {touched.location && errors.location && (
                      <div className="errorMsg">{errors.location}</div>
                    )}
                  </div>
                </div>
                <div className="row align-items-center formItem">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="quantity">Quantity : </label>
                  </div>
                  <div className="col-sm-12 col-md-8 col-lg-8">
                    <Field
                      type="string"
                      className="textfield"
                      id="quantity"
                      name="quantity"
                      placeholder="Enter Quantity"
                    />
                    {touched.quantity && errors.quantity && (
                      <div className="errorMsg">{errors.quantity}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="offset-lg-4 col-sm-12 col-md-12 col-lg-8 btnGrp">
                    <Button className="FilledBtn" type="submit">
                      Accept
                    </Button>
                    <Button
                      className="FilledBtn"
                      onClick={() => {
                        setListableItemBox(true);
                        setListableLocationBox(false);
                      }}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  };
  const unlistableItemBoxUI = () => {
    return (
      <>
        <div className="formContainer">
          <div className="row align-items-center formItem">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label>Select Brand : </label>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
              <span>{unListableText ? unListableText : ""}</span>
            </div>
          </div>
          <div className="row">
            <div className="offset-lg-4 col-sm-12 col-md-12 col-lg-8 btnGrp">
              <Button
                className="FilledBtn"
                onClick={async () => {
                  await putReceivingSentToStoreOrTrashCalled(RO, UPC, 9, null);
                  // localStorage.removeItem("ROValidation");
                  localStorage.removeItem("UPC");
                  // localStorage.removeItem("RONumber");
                  localStorage.removeItem("UnlistableText");
                  formik.resetForm();
                  formikRO.resetForm();
                  formikListableItem.resetForm()
                }}
              >
                Sent to Store
              </Button>
              <Button
                className="FilledBtn"
                onClick={async () => {
                  await putReceivingSentToStoreOrTrashCalled(RO, UPC, 10, null);
                  // localStorage.removeItem("ROValidation");
                  localStorage.removeItem("UPC");
                  // localStorage.removeItem("RONumber");
                  localStorage.removeItem("UnlistableText");
                  formik.resetForm();
                  formikRO.resetForm();
                  formikListableItem.resetForm()
                }}
              >
                Sent to Trash
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };
  useEffect(() => {
    setSelectedIndex(5);
    getProductCategoryListCalled(0, 100);
    setIsChecked(false)
    setIsCheckedRPE(false)
    // setSelectedImage(scanResponseProduct?.channelImages[0])
    setSelectedImageRPE(scanResponseProduct?.images[0])
  }, []);
  return (
    <>
      {errorHandle()}
      {isLoading ? <Loader /> : ""}
      <Header />
      <Box
        className="contentMain ROValidationWrapper"
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <div className="startMain">
          <div>
            <h5 className="pageHead mb-2 me-2">Receiving Application</h5>
            {/* <p className="mb-0">Products: 1,345</p> */}
          </div>

          <Button
            className="FilledBtn btnStyleUnset"
            onClick={() => {
              localStorage.removeItem("ROValidation");
              localStorage.removeItem("UPC");
              localStorage.removeItem("RONumber");
              localStorage.removeItem("UnlistableText");
              formik.resetForm();
              formikRO.resetForm();
              formikListableItem.resetForm()
              setRoValidationBox(true);
              setupcValidationBox(false);
              setNotScanableBox(false);
              setListableItemBox(false);
              setListableLocationBox(false);
              setUnlistableItemBox(false);
              setProductFlagsBox(false);
              setProductWeightBox(false)
              formikProductFlags.resetForm();
              formikproductWeight.resetForm()
              formikImages.resetForm()
              setIsChecked(false)
              setIsCheckedRPE(false)
            }}
          >
            <Quit style={{ height: "30px", width: "30px" }} />
          </Button>
        </div>
        <div className="stepper d-flex">
          <div
            className={`step ${roValidationBox ||
              upcValidationBox ||
              notScanableBox ||
              listableItemBox ||
              unlistableItemBox ||
              productWeightBox ||
              productFlagsBox ||
              listableLocationBox == true
              ? "active"
              : ""
              }`}
          >
            <div className="stepperLine"></div>
            <div className="iconCircle">
              <ROValidation />
            </div>
            <p className="mb-0">RO Validation</p>
          </div>
          <div
            className={`step ${upcValidationBox ||
              notScanableBox ||
              listableItemBox ||
              unlistableItemBox ||
              productFlagsBox ||
              productWeightBox ||
              listableLocationBox == true
              ? "active"
              : ""
              }`}
          >
            <div className="stepperLine"></div>
            <div className="iconCircle">
              <UpcScanning />
            </div>
            <p className="mb-0">UPC Scanning</p>
          </div>
          <div
            className={`step ${listableItemBox ||
              unlistableItemBox ||
              listableLocationBox ||
              productFlagsBox ||
              productWeightBox == true
              ? "active"
              : ""
              }`}
          >
            <div className="stepperLine"></div>
            <div className="iconCircle">
              <ListableItem />
            </div>
            <p className="mb-0">Listable / Unlistable Item</p>
          </div>
          {isQbids == "true" ?
            <>
              <div
                className={`step ${productWeightBox || productFlagsBox == true ? "active" : ""}`}
              >
                <div className="stepperLine"></div>
                <div className="iconCircle">
                  <ListableItemLocation />
                </div>
                <p className="mb-0">Product Flags</p>
              </div>
              <div
                className={`step ${productWeightBox == true ? "active" : ""}`}
              >
                <div className="stepperLine"></div>
                <div className="iconCircle">
                  <ListableItemLocation />
                </div>
                <p className="mb-0">Product Weight</p>
              </div>
            </>
            :
            <div
              className={`step ${listableLocationBox == true ? "active" : ""}`}
            >
              <div className="stepperLine"></div>
              <div className="iconCircle">
                <ListableItemLocation />
              </div>
              <p className="mb-0">Listable Location</p>
            </div>
          }

        </div>
        {/* <h6>Receiving:</h6> */}
        <div className="row middleRow">
          <div className="col-sm-12 col-md-9 col-lg-9">
            {roValidationBox == true ? roValidationBoxUI() : ""}
            {upcValidationBox == true ? upcScannBoxUI() : ""}
            {notScanableBox == true ? NotScanableBoxUI() : ""}
            {listableItemBox == true ? listableItemBoxUI() : ""}
            {productFlagsBox == true ? productFlagsBoxUI() : ""}
            {productWeightBox == true ? productWeightBoxUI() : ""}
            {listableLocationBox == true ? listableLocationBoxUI() : ""}
            {unlistableItemBox == true ? unlistableItemBoxUI() : ""}
          </div>
          {/* <div className="col-1"></div> */}
          <div className="col-sm-12 col-md-3 col-lg-3">
            <div className="whiteContainer">
              {upcValidationBox ||
                notScanableBox ||
                listableItemBox ||
                listableLocationBox ||
                productFlagsBox ||
                productWeightBox ||
                unlistableItemBox == true ? (
                <div className="d-flex sucMsgContainer">
                  <BlueFilledCheckCircle />
                  <div className="message">
                    <h6> RO validation is Successful</h6>
                    <p>
                      RO Number : <span>{RO ? RO : ""}</span>
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {upcValidationBox == true && lastListedUPC != "" ? (
                <div className="d-flex sucMsgContainer">
                  <BlueFilledCheckCircle />
                  <div className="message">
                    <h6> Last Receipt</h6>
                    <p>
                      UPC : <span>{lastListedUPC ? lastListedUPC : ""}</span>
                    </p>
                    {isQbids == "false" ?
                      <>
                        <p>
                          LOC :{" "}
                          <span>
                            {lastListedLocation ? lastListedLocation : ""}
                          </span>
                        </p>
                        <p>
                          QTY :{" "}
                          <span>
                            {lastListedQuantity ? lastListedQuantity : ""}
                          </span>
                        </p>
                      </>
                      :
                      <></>
                    }
                  </div>
                </div>
              ) : (
                <></>
              )}
              {listableItemBox ||
                listableLocationBox ||
                productFlagsBox ||
                productWeightBox ||
                unlistableItemBox == true ? (
                <div className="d-flex sucMsgContainer">
                  <BlueFilledCheckCircle />
                  <div className="message">
                    <h6>UPC Scan Successful</h6>
                    <p>
                      UPC : <span>{UPC ? UPC : ""}</span>
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {listableItemBox || listableLocationBox == true ? (
                <div className="d-flex sucMsgContainer">
                  <BlueFilledCheckCircle />
                  <div className="message">
                    <h6>{textListable}</h6>
                    <p>{/* Selling Prediction: <span>82%</span> */}</p>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {unlistableItemBox == true ? (
                <div>
                  <h6>Item is UnListable</h6>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};
export default ROValidationScree;
