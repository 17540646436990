import { useAuth } from "../provider/authprovider";

export const useReportsAPI = () => {
    const { setLoading, api, handleError } = useAuth()
    // const getProcessLogsList = async (prefix: any, offset: any, limit: any) => {
    //     setLoading(true)
    //     try {
    //         const result = await api.get(`/api/ProcessLogs/ProcessLogsList?prefix=${prefix}&offset=${offset}&limit=${limit}`);
    //         return result
    //     } catch (error: any) {
    //         handleError(error)
    //     } finally {
    //         setLoading(false)
    //     }
    // };
    const getProcessLogsList = async (details:any) => {
        setLoading(true)
        try {
            const result = await api.post(`/api/ProcessLogs/ProcessDetailLogsList`, details);
            return result
        } catch (error: any) {
            handleError(error)
        } finally {
            setLoading(false)
        }
    };
    const getListingScanReport = async (scanId: any) => {
        try {
            const result = await api.get(`/api/AdminDashboard/ListingScanReport?ScanId=${scanId}`);
            return result
        } catch (error: any) {
            handleError(error)
        }
    };
    return { getProcessLogsList, getListingScanReport } as const
}