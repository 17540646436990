import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Delete from "../../assets/delete.svg";
import Edit from "../../assets/edit.svg";
import CreateWarehouseModal from "../../components/createWarehouseModal/CreateWarehouseModal";
import Header from '../../components/header/Header';
import { useAuth } from "../../shared/provider/authprovider";
// import AddUserWhite from "../../assets/add-white.svg";
import { Box } from '@mui/material';
import { ReactComponent as Search } from "../../../src/assets/icons/searchStatus.svg";
import ErrorModal from '../../components/errorModal/ErrorModal';
// import { ReactComponent as AddNew } from "../../../src/assets/icons/add-new-icon.svg";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Close from "../../../src/assets/icons/close.svg";
import Loader from '../../loader';
import './warehouseManagement.scss';
import { useWarehousesAPI } from "../../shared/api/warehouse";


const WarehouseManagement = () => {
  const { isLoading, setErrorMsg, validWarehouseName, setValidWarehouseName, validLocation, setValidLocation,
    validDescription, setValidDescription, errorModalShow, setErrorModalShow, setSelectedIndex, getWarehouseListCalled,
    wareHouseList, wareHousesCount } = useAuth();
  const { deleteWarehouse, createWarehousePrefix, editWarehousePrefix, deleteWarehousePrefix,
    editWarehouseDetails } = useWarehousesAPI()
  const [warehousrModalShow, setWarehouseModalShow] = useState(false);
  const [addPrefixModalShow, setAddPrefixModalShow] = useState(false);
  const [removeWarehouseModalShow, setRemoveWarehouseModalShow] = useState(false);
  const [removePrefixModalShow, setRemovePrefixModalShow] = useState(false);
  // const [warehouseList, setWarehouseList] = useState<any[]>([]);
  const [warehouseListOffset, setWarehouseListOffset] = useState(0);
  const [warehouseListLimit, setWarehouseListLimit] = useState(1000);
  const [warehouseId, setWarehouseId] = useState("");
  const [warehouseName, setWareHouseName] = useState("");
  const [editWarehouseModalShow, setEditWarehouseModalShow] = useState(false);
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [prefix, setPrefix] = useState("");
  const [prefixName, setPrefixName] = useState("");
  const [prefixId, setPrefixId] = useState("");
  const [showEditPrefixOptions, setShowEditPrefixOptions] = useState(false);
  const [editBtnTapped, setEditBtnTapped] = useState(false);
  const [edit, setEdit] = useState(false)
  const [apiPrefix, setApiPrefix] = useState("")
  const loadInitialData = async () => {
    await getWarehouseListCalled(apiPrefix, 0, 1000)
  }
  useEffect(() => {
    loadInitialData()
    setSelectedIndex(2)
  }, []);

  //prefix edit delete 
  const [isShown, setIsShown] = useState(false);

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };

  // remove warehouse modal
  const renderRemoveWarehouseModal = () => {
    return (
      <Modal className="formModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={removeWarehouseModalShow}
        onHide={() => {
          setRemoveWarehouseModalShow(false);
        }}
      >
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className="modalTitle">
            Remove Warehouse
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='formContainer text-center'>
            <p className="fieldTitle text-start">Are you sure you want to remove warehouse : <b>{warehouseName}</b></p>
            <button className="FilledBtn" onClick={() => onTapRemoveBtn()}>Remove</button>
          </div>
        </Modal.Body>
      </Modal>
    )
  };



  // edit warehouse modal
  const renderEditWarehouseModal = () => {
    return (
      <Modal className="formModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={editWarehouseModalShow}
        onHide={() => {
          setEditWarehouseModalShow(false);
          setValidWarehouseName(false)
          setValidLocation(false)
          setValidDescription(false)
        }}
      >
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className="modalTitle">
            Edit Warehouse Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='formContainer'>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label>Warehouse Name</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input value={warehouseName} className="textfield" placeholder="Enter Warehouse Name" onChange={(event) => setWareHouseName(event.target.value)} />
                {!validWarehouseName ? null : <div className="errorMsg">Invalid Warehouse Name</div>}
              </div>
            </div>

            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label>Location</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input value={location} className="textfield" placeholder="Enter Location" onChange={(event) => setLocation(event.target.value)} />
                {!validLocation ? null : <div className="errorMsg">Invalid Location</div>}
              </div>
            </div>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label>Description</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input value={description} className="textfield" placeholder="Enter Description" onChange={(event) => setDescription(event.target.value)} />
                {!validDescription ? null : <div className="errorMsg">Invalid Description</div>}
              </div>
            </div>
            <div className='row align-items-center formItem'>
              <div className='col-4'></div>
              <div className='col-8'>
                <button onClick={() => onTapUpdateWarehouseDetails()} className="FilledBtn">Update</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    );
  }


  // add prefix modal
  const renderAddPrefixModal = () => {
    return (
      <Modal className="formModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={addPrefixModalShow}
        onHide={() => {
          setAddPrefixModalShow(false);
        }}
      >
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className="modalTitle d-flex">Add Prefix
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='formContainer'>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label>Prefix Name/No.</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input className="textfield" placeholder="Enter Prefix" onChange={(event) => setPrefix(event.target.value)} />
              </div>
            </div>
            <div className='row align-items-center formItem'>
              <div className='col-4'></div>
              <div className='col-8'>
                {prefix ?
                  <button onClick={() => onTapAddPrefix()} className="FilledBtn">Add</button> :
                  <button className="FilledBtn disabled-btn" disabled>Add</button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };


  // remove prefix modal
  const renderRemovePrefixModal = () => {
    return (
      <Modal className="formModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={removePrefixModalShow}
        onHide={() => {
          setRemovePrefixModalShow(false);
        }}
      >
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className="modalTitle">
            Remove Prefix
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='formContainer text-center'>
            <p className="fieldTitle text-start">Are you sure you want to remove prefix : <b>{prefixName}</b></p>
            <button className="FilledBtn" onClick={() => onTapRemovePrefixBtn()}>Remove</button>
          </div>
        </Modal.Body>
      </Modal>
    )
  };


  const onTapCreateWarehouse = () => {
    setWarehouseModalShow(true);
  };

  const onTapPrefixBtn = (data: any) => {
    setAddPrefixModalShow(true);
    setWareHouseName(data?.wareHouse?.name);
    setWarehouseId(data?.wareHouse?.id);
  };

  const onTapEditWarehouseBtn = (data: any) => {
    setEditWarehouseModalShow(true);
    setWareHouseName(data?.wareHouse?.name);
    setLocation(data?.wareHouse?.location);
    setDescription(data?.wareHouse?.description);
    setWarehouseId(data?.wareHouse?.id);
  };

  const onTapRemoveWarehouseBtn = (id: any, name: any) => {
    window?.event?.stopPropagation();
    setEditWarehouseModalShow(false);
    setRemoveWarehouseModalShow(true);
    setWarehouseId(id);
    setWareHouseName(name);
  };

  const onTapRemoveBtn = async () => {
    deleteWarehouse(warehouseId);
    setRemoveWarehouseModalShow(false);
    getWarehouseListCalled("", 0, 1000)
  };

  const onTapUpdateWarehouseDetails = async () => {
    if (warehouseName.trim() && description.trim() && location.trim()) {
      let details = {
        "name": warehouseName.trim(),
        "description": description.trim(),
        "location": location.trim()
      }
      const resp = await editWarehouseDetails(details, warehouseId);
      if (resp?.data?.status == 200) {
        getWarehouseListCalled("", 0, 1000)
        setErrorModalShow(true);
        setErrorMsg("Warehouse Details Edited Successfully");
      }
      setEditWarehouseModalShow(false);
      setValidWarehouseName(false)
      setValidLocation(false)
      setValidDescription(false)
    } else {
      if (warehouseName.trim() !== "") {
        setValidWarehouseName(false);
      } else {
        setValidWarehouseName(true);
      }

      if (location.trim() !== "") {
        setValidLocation(false);
      } else {
        setValidLocation(true);
      }

      if (description.trim() !== "") {
        setValidDescription(false);
      } else {
        setValidDescription(true);
      }
    }
  };

  const onTapAddPrefix = async () => {
    if (prefix.trim()) {
      let details = {
        "prefixName": prefix,
        "warehouseIdDto": {
          "id": warehouseId
        }
      }
      const resp = await createWarehousePrefix(details);
      if (resp?.data?.status == 200) {
        getWarehouseListCalled(apiPrefix, 0, 1000)
        setErrorModalShow(true);
        setErrorMsg("Prefix Added Successfully");
      }
      setAddPrefixModalShow(false);
    }
  };

  const onTapPrefix = (prefix: any) => {
    setShowEditPrefixOptions(true);
    setPrefixId(prefix?.id);
    setPrefixName(prefix?.prefixName);
  };

  const onTapUpdatePrefix = async (value: any) => {
    value?.stopPropagation()
    if (value !== prefixName) {
      if (prefixName.trim()) {
        let details = {
          "prefixName": prefixName.trim()
        }
        const res = await editWarehousePrefix(details, prefixId);
        if (res?.data?.status == 200) {
          await getWarehouseListCalled("", 0, 1000)
          setErrorModalShow(true);
          setErrorMsg("Prefix Edited Successfully");
        }
        setShowEditPrefixOptions(false);
        setEditBtnTapped(false);
        // getWarehouseListCalled();
      } else {
        setShowEditPrefixOptions(false);
        setEditBtnTapped(false);
      }
    }
  };

  const onTapRemovePrefixBtn = async () => {
    await deleteWarehousePrefix(prefixId);
    await getWarehouseListCalled("", 0, 1000)
    setRemovePrefixModalShow(false);
    setShowEditPrefixOptions(false);
    setEditBtnTapped(false);
    // getWarehouseListCalled();
  }
  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      onTapUpdatePrefix(e)
      setEdit(false)
      e?.stopPropagation()
    }
  };
  const handleKeypress2 = (e: any) => {
    if (e.key === "Enter") {
      onTapSearch();
    }
  };
  const onTapSearch = async() => {
    console.log("prefix", apiPrefix)
    await getWarehouseListCalled("", 0, 1000)
  };
  const onTapReset = async() => {
    setApiPrefix("");
    await getWarehouseListCalled("", 0, 1000)
  };
  return (
    <>
      {renderRemoveWarehouseModal()}
      {renderEditWarehouseModal()}
      {renderAddPrefixModal()}
      {renderRemovePrefixModal()}
      {errorHandle()}
      <Header />
      <CreateWarehouseModal
        show={warehousrModalShow}
        onHide={() => {
          setWarehouseModalShow(false);
          // getWarehouseListCalled();
          setValidWarehouseName(false);
          setValidLocation(false);
          setValidDescription(false);
        }}
      />

      <Box className="contentMain"
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <div className="warhouseStart">
          {isLoading &&
            <Loader />}
          <div className="adminDashboard row">
            <div className="paddingG">
              <div className="warehouseL">
                <div className="d-flex align-items-start">
                  <div className="headingD">
                    <h5 className="pageHead">Warehouse List</h5>
                    <p className="mb-0">{wareHousesCount} Warehouses</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="search-input serchContainer d-inline-flex">
                      <Search className='searchIcon' />
                      <input type="search" placeholder="Search" value={apiPrefix}
                        onKeyPress={(event) => handleKeypress2(event)}
                        onChange={(event) => setApiPrefix(event.target.value)} />
                      <img src={Close} className="pointer" onClick={() => onTapReset()} />
                    </div>
                  </div>
                </div>
                <button className="FilledBtn" onClick={() => onTapCreateWarehouse()}>Add New Warehouse</button>
              </div>

              <div className="warhouseS">
                {wareHousesCount > 0 ?
                  <>
                    {wareHouseList ? wareHouseList?.map((data: any) => {
                      return (
                        <div
                          className="warehousedetail"
                          key={data.id}
                          onClick={() => onTapEditWarehouseBtn(data)}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <h6 className="firstN">{data?.wareHouse?.name}</h6>
                          </div>
                          <div className="d-flex align-items-center justify-content-between warhouseLast">
                            <div className="d-flex  align-items-end warehouseMaind">
                              {data?.wareHouse?.warehousePrefix ?
                                data?.wareHouse?.warehousePrefix.map((prefix: any) => {
                                  return (
                                    <div className="warehouseItem">
                                      <div className="warehouseNew">
                                        <div className="warehouseNode">
                                          <div className="d-flex justify-content-end warhouseedits">
                                            <button onClick={(event: any) => {
                                              setPrefixName(prefix?.prefixName);
                                              setEditBtnTapped(true);
                                              setPrefixId(prefix?.id);
                                              setEdit(true)
                                              event?.stopPropagation()
                                            }} className=" me-3" ><Tooltip TransitionComponent={Zoom} title="Edit" arrow placement="top"><img src={Edit} className="icon editicon" /></Tooltip></button>
                                            <button onClick={(event: any) => {
                                              setPrefixName(prefix?.prefixName);
                                              setRemovePrefixModalShow(true);
                                              setPrefixId(prefix?.id);
                                              event?.stopPropagation()
                                            }}><Tooltip TransitionComponent={Zoom} title="Delete" arrow placement="top"><img src={Delete} className="icon deleteIcon" /></Tooltip></button>
                                          </div>
                                          <div className="d-flex">
                                            {prefixId === prefix?.id && edit ?
                                              // <input className="dashed-input" defaultValue={prefix?.prefixName} onChange={(event) => setPrefixName(event.target.value)} />
                                              <div>
                                                <input className="dashed-input" value={prefixName} onChange={(event) => { setPrefixName(event.target.value); event?.stopPropagation() }}
                                                  // onClick={() => onTapUpdatePrefix(prefix?.prefixName)}
                                                  onClick={(event: any) => event?.stopPropagation()}
                                                  onKeyPress={(event) => { handleKeypress(event); event?.stopPropagation() }}
                                                />
                                                <p className="mb-0 updateMsg"><i>Press Enter to update</i></p>
                                              </div>
                                              :
                                              <div>
                                                <button className="prefixBtn" onClick={(event: any) => { setEditBtnTapped(false); event?.stopPropagation() }}>{prefix?.prefixName}</button>
                                                <p className="mb-0 updateMsg updateMsgHide"><i>enter</i></p>
                                              </div>
                                            }
                                            {/* <button className="updateBtn" onClick={() => onTapUpdatePrefix(prefix?.prefixName)}>Update</button> */}
                                          </div>
                                        </div>
                                        {/* :
                                    <button className="prefixBtn" onClick={() => onTapPrefix(prefix)}>{prefix?.prefixName}</button>
                                    } */}
                                        {/* <button className="prefixBtn" onClick={() => onTapPrefix(prefix)}>{prefix?.prefixName}</button> */}
                                      </div>
                                    </div>
                                  )
                                }) : null
                              }
                              {/* <button onClick={() => setEditWarehouseModalShow(data)} className="enterBtn">Edit</button> */}
                            </div>
                            <div className="d-flex align-items-center addPrefixD">
                              <button onClick={(event: any) => { onTapPrefixBtn(data); event?.stopPropagation() }} className="FilledBtn">
                                {/* <AddNew className="addNew" /> */}
                                Add New Prefix</button>
                              <Tooltip TransitionComponent={Zoom} title="Delete" arrow placement="top"><img src={Delete} className="icon deleteIcon" onClick={(event: any) => { onTapRemoveWarehouseBtn(data?.wareHouse?.id, data?.wareHouse?.name); event?.stopPropagation() }} /></Tooltip>
                            </div>

                          </div>
                        </div>
                      );
                    }) : null}
                  </>
                  :
                  <div>No Warehouse Found</div>
                }
              </div>
              {/* <Pagination>
              <Pagination.Prev />
              <Pagination.Item>{pageCountForWarehousList}</Pagination.Item>
              <Pagination.Item>{pageCountForWarehousList + 1}</Pagination.Item>
              <Pagination.Item active>{pageCountForWarehousList + 2}</Pagination.Item>
              <Pagination.Next onClick={() => pageCountForWarehousList + 3} />
            </Pagination> */}
            </div>
          </div>
        </div>
      </Box>

    </>
  )
}

export default WarehouseManagement;