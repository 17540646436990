import * as React from 'react';
import './MISReportScreen.scss';
import Box from '@mui/material/Box';
import { useAuth } from '../../shared/provider/authprovider';
import Header from "../../components/header/Header";
import filter from "../../assets/filter.svg";
import ErrorModal from '../../components/errorModal/ErrorModal';
import { Dropdown } from "react-bootstrap";
import { useEffect, useState, useRef } from 'react';
// import Calender from "../../assets/calender.png";
import { BarChart, Bar, XAxis, YAxis, LabelList, Tooltip, CartesianGrid, Line } from 'recharts'
import Multiselect from 'multiselect-react-dropdown';
import Autocomplete from "@mui/material/Autocomplete";
import XLSX from 'sheetjs-style';
import FileSaver from 'file-saver';
import Loader from '../../loader';
import { ReactComponent as Yearcalendar } from "../../../src/assets/icons/searchCalendar.svg";
import Zoom from '@mui/material/Zoom';
import { ReactComponent as ResetFilter } from "../../../src/assets/icons/filter-reset.svg";
import { useMisReportsAPI } from '../../shared/api/reports-mis';
import { Checkbox, TextField, Tooltip as TooltipNew } from '@mui/material';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

export default function MISReportScreen(props: any) {
  const [name, setName] = useState("gmv")
  const [fullName, setFullName] = useState<any>("Gross Merchandise Value")
  const d = new Date();
  const currYear: any = d.getFullYear()
  const [year, setYear] = useState(`${currYear}`);
  const [classificationName, setclassificationName] = useState("Manifest");
  const [brand, setBrandName] = useState([])

  const { isLoading, errorModalShow, setErrorModalShow, setErrorMsg, setSelectedIndex } = useAuth();

  const { MisReports, misClassifications, getMisBrands } = useMisReportsAPI()
  const [misReportsData, setMisReports] = useState<any | null>(null)
  const [misReportsTotal, setMisReportsTotal] = useState<any | null>(null)
  const [misReportsClassification, setMisReportsClassification] = useState<any | null>(null)

  const [misReportBrands, setMisReportBrands] = useState<any | null>(null)
  var myJsonString = JSON.stringify(misReportBrands);
  console.log(myJsonString)
  const [items, setItems] = useState<any>([]);
  const handleSelect = (selectedList: any) => {
    setItems(selectedList);
  };
  const handleRemove = (selectedList: any) => {
    setItems(selectedList);
  };
  const handleSubmit = () => {
    console.log(items);
  };
  let values = {
    "year": year,
    "classifications": [
      classificationName
    ],
    "brands": items.length === 0 ? [] : items
  }
  const resetFilter = async () => {
    setYear(`${currYear}`)
    setclassificationName("Manifest")
    setItems([])
    let valuesReset = {
      "year": `${currYear}`,
      "classifications": [
        "Manifest"
      ],
      "brands": []
    }
    const res = await MisReports(valuesReset)
    setMisReports(res?.data?.result)
    setMisReportsTotal(res?.data?.total)
  }
  const data = misReportsData?.map((i: any) => {
    if (name == "gmv") {
      return {
        country: i?.monthName,
        Value: i.gmv,
      };
    }
    else if (name == "orders") {
      return {
        country: i?.monthName,
        Value: i.orders,
      };
    }
    else if (name == "avgDailyGMV") {
      return {
        country: i?.monthName,
        Value: i.avgDailyGMV,
      };
    }
    else if (name == "avgDailyOrders") {
      return {
        country: i?.monthName,
        Value: i.avgDailyOrders,
      };
    }
    else if (name == "units") {
      return {
        country: i?.monthName,
        Value: i.units,
      };
    }
    else if (name == "aov") {
      return {
        country: i?.monthName,
        Value: i.aov,
      };
    }
    else if (name == "avgUnitsPerOrder") {
      return {
        country: i?.monthName,
        Value: i.avgUnitsPerOrder,
      };
    }
    else if (name == "uniqueListings") {
      return {
        country: i?.monthName,
        Value: i.uniqueListings,
      };
    }
    else if (name == "strUniqueListings") {
      return {
        country: i?.monthName,
        Value: i.strUniqueListings,
      };
    }
    else if (name == "momAovChange") {
      return {
        country: i?.monthName,
        Value: i.momAovChange,
      };
    }
    else if (name == "momGmvChange") {
      return {
        country: i?.monthName,
        Value: i.momGmvChange,
      };
    }
  })

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };
  const setClassificationFun = async (v: any) => {
    setclassificationName(v);
    setBrandName(items)
    const res = await getMisBrands(v)
    setMisReportBrands(res?.data?.result)
  }
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const fileExtension = '.xlsx'

  const ontabExport = () => {
    const ws = XLSX.utils.json_to_sheet(misReportsData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, classificationName + fileExtension)
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const loadInitialData = async () => {
    const res = await MisReports(values)
    setMisReports(res?.data?.result)
    setMisReportsTotal(res?.data?.total)
    const response = await misClassifications()
    setMisReportsClassification(response?.data?.result)
    const result = await getMisBrands("Manifest")
    setMisReportBrands(result?.data?.result)
  }
  
  useEffect(() => {
    loadInitialData()
    setSelectedIndex(7)

  }, []);
  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            onChange={() => null}
          />{" "}
          <label>{props}</label>
        </components.Option>
      </div>
    );
  };
  // const handleChange = (selected: any) => {
  //   setItems({ optionSelected: selected });
  // };
  return (
    <>
      {errorHandle()}
      <Header />
      <Box className="contentMain"
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <div className='misReportContainer'>
          <div className="">
            {/* <h6>Monthly Report</h6> */}
            <h5 className="pageHead">MIS Report</h5>
            <div className="startMain">
              <div className='d-flex align-items-center filterContainer'>
                <div className='leftDiv filterItem'>
                  <img src={filter} className="mRight" />
                  <h5 className='m-0 text'>Filters</h5>
                </div>
                <div className='borderDiv filterItem'>
                  <label className='w-100 formLabel'>Year</label>
                  <Dropdown className="outlinedInput margin">
                    <Dropdown.Toggle variant="Warning" id="dropdown-basic" className="outlinegreenBtn" ><Yearcalendar className='margin' />{year}</Dropdown.Toggle>
                    <Dropdown.Menu className="dropMenug">
                      <Dropdown.Item className="clickItem" onClick={() => { setYear("2023"); }}>2023</Dropdown.Item>
                      <Dropdown.Item className="clickItem" onClick={() => { setYear("2022"); }}>2022</Dropdown.Item>
                      <Dropdown.Item className="clickItem" onClick={() => { setYear("2021"); }}>2021</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <label className='w-100 formLabel'>Vendor</label>
                  <Dropdown className="outlinedInput">
                    <Dropdown.Toggle variant="Warning" id="dropdown-basic" className='outlinegreenBtn' >{classificationName}</Dropdown.Toggle>
                    <Dropdown.Menu className="dropMenug vendorDropdown">
                      {misReportsClassification?.map((v: any) => {
                        return (
                          <Dropdown.Item className="clickItem" onClick={() => setClassificationFun(v)}>{v}</Dropdown.Item>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className='borderDiv filterItem'>
                  <label className='formLabel'>Brand</label>
                  <Multiselect
                    className='multiSelectField'
                    isObject={false}
                    options={misReportBrands == null ? [] : misReportBrands}
                    showCheckbox
                    onSelect={handleSelect}
                    onRemove={handleRemove}

                  ></Multiselect>
                  {/* <ReactSelect
                    options={misReportBrands == null ? [] : misReportBrands}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option
                    }}
                    onChange={handleChange}
                    value={items}
                  /> */}
                </div>
              </div>
              <TooltipNew TransitionComponent={Zoom} title="Reset" arrow placement="top">
                <ResetFilter className='ms-3 pointer' onClick={() => resetFilter()} />
              </TooltipNew>
              <div className='d-flex justify-content-end w-50'>
                <button className='FilledBtn me-3' onClick={async () => {
                  handleSubmit();
                  const res = await MisReports(values)
                  setMisReports(res?.data?.result)
                  setMisReportsTotal(res?.data?.total)
                }}>Apply</button>
                <button className='FilledBtn' onClick={() => { ontabExport(); }}>Export</button>
              </div>
            </div>
            <h5>{fullName}</h5>
            {/* {isLoading ? } */}
            {!isLoading ?
              <>
                {misReportsData ?
                  <div className='barChart'>
                    <BarChart width={1100} height={400} data={data} >

                      <Bar dataKey="Value" fill="#004EFF" barSize={70} />
                      <Line dataKey="Value"
                        dot={false}
                        strokeWidth={2}
                        strokeLinecap="round"
                        type="monotone"
                        stroke="#3B7AD9"
                        yAxisId="right"
                        legendType="rect"
                        name="Value"
                      />
                      <Tooltip cursor={false} />
                      <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                      <XAxis dataKey="country" domain={[0, 12]} reversed={true} />
                      <YAxis
                        // type="number" domain={[0, 100000]}
                        tickFormatter={(tick) => {
                          if (name == "gmv" || name == "avgDailyGMV" || name == "aov") {
                            return formatter.format(tick);
                          }
                          else if (name == "strUniqueListings" || name == "momAovChange" || name == "momGmvChange") {
                            return `${tick}%`;
                          }
                          else if (name == "orders" || name == "avgDailyOrders" || name == "units" || name == "uniqueListings") {
                            return `${tick.toLocaleString()}`;
                          }
                          else {
                            return `${tick}`;
                          }
                        }} />
                    </BarChart>

                  </div>
                  :
                  ''
                }
              </>
              :
              <Loader />
            }
          </div>
          {misReportsData ?
            <div className='heightG'>
              <table className='tableH'>
                <thead className='thead rounded'>
                  <tr style={{ cursor: "pointer" }}>
                    <td>
                      <div>
                        <label>{year}</label>
                        {/* <img src={Calender} /> */}
                      </div>
                    </td>
                    <td style={{ marginTop: "100px", backgroundColor: name == "gmv" ? "#CDEAFF" : "white" }} onClick={() => { setName('gmv'); setFullName("Gross Merchandise Value") }}>GMV</td>
                    <td style={{ backgroundColor: name == "orders" ? "#CDEAFF" : "white" }} onClick={() => { setName('orders'); setFullName("Orders") }}>Orders</td>
                    <td style={{ backgroundColor: name == "avgDailyGMV" ? "#CDEAFF" : "white" }} onClick={() => { setName('avgDailyGMV'); setFullName("Average Daily Gross Merchandise Value") }}>Daily GMV</td>
                    <td style={{ backgroundColor: name == "avgDailyOrders" ? "#CDEAFF" : "white" }} onClick={() => { setName('avgDailyOrders'); setFullName("Average Daily Orders") }}>Avg Daily Orders</td>
                    <td style={{ backgroundColor: name == "units" ? "#CDEAFF" : "white" }} onClick={() => { setName('units'); setFullName('Units') }}>Units</td>
                    <td style={{ backgroundColor: name == "aov" ? "#CDEAFF" : "white" }} onClick={() => { setName('aov'); setFullName('AOV') }}>AOV</td>
                    <td style={{ backgroundColor: name == "avgUnitsPerOrder" ? "#CDEAFF" : "white" }} onClick={() => { setName('avgUnitsPerOrder'); setFullName('Average Units Per Order') }}>Avg Units per order</td>
                    <td style={{ backgroundColor: name == "uniqueListings" ? "#CDEAFF" : "white" }} onClick={() => { setName('uniqueListings'); setFullName('Unique Listings') }}>Unique Listings</td>
                    <td style={{ backgroundColor: name == "strUniqueListings" ? "#CDEAFF" : "white" }} onClick={() => { setName('strUniqueListings'); setFullName('STR Unique Listings') }}>STR-Unique Listings</td>
                    <td style={{ backgroundColor: name == "momAovChange" ? "#CDEAFF" : "white" }} onClick={() => { setName('momAovChange'); setFullName('MOM AOV Change') }}>MOV AOV Change</td>
                    <td style={{ backgroundColor: name == "momGmvChange" ? "#CDEAFF" : "white" }} onClick={() => { setName('momGmvChange'); setFullName('MOM GMV Change') }}>MOM GMV Change</td>
                  </tr>
                </thead>
                <tbody className='tbody'>
                  {!isLoading ?
                    <>
                      {misReportsData ?
                        misReportsData.map((value: any) => {
                          return (
                            <tr>
                              <td>{value?.monthName}</td>
                              <td style={{ marginTop: "100px", backgroundColor: name == "gmv" ? "#CDEAFF" : "white" }}>{formatter.format(value?.gmv)}</td>
                              <td style={{ backgroundColor: name == "orders" ? "#CDEAFF" : "white" }}>{value?.orders.toLocaleString()}</td>
                              <td style={{ backgroundColor: name == "avgDailyGMV" ? "#CDEAFF" : "white" }}>{formatter.format(value?.avgDailyGMV)}</td>
                              <td style={{ backgroundColor: name == "avgDailyOrders" ? "#CDEAFF" : "white" }}>{value?.avgDailyOrders.toLocaleString()}</td>
                              <td style={{ backgroundColor: name == "units" ? "#CDEAFF" : "white" }}>{value?.units.toLocaleString()}</td>
                              <td style={{ backgroundColor: name == "aov" ? "#CDEAFF" : "white" }}>{formatter.format(value?.aov)}</td>
                              <td style={{ backgroundColor: name == "avgUnitsPerOrder" ? "#CDEAFF" : "white" }}>{Math.round(value?.avgUnitsPerOrder * 100) / 100}</td>
                              <td style={{ backgroundColor: name == "uniqueListings" ? "#CDEAFF" : "white" }}>{value?.uniqueListings.toLocaleString()}</td>
                              <td style={{ backgroundColor: name == "strUniqueListings" ? "#CDEAFF" : "white" }}>{Math.round(value?.strUniqueListings * 100) / 100}%</td>
                              <td style={{ color: value?.momAovChange > 0 ? "rgb(68, 189, 149)" : "red", backgroundColor: name == "momAovChange" ? "#CDEAFF" : "white" }}>{Math.round(value?.momAovChange * 100) / 100}%</td>
                              <td style={{ color: value?.momGmvChange > 0 ? "rgb(68, 189, 149)" : "red", backgroundColor: name == "momGmvChange" ? "#CDEAFF" : "white" }}>{Math.round(value?.momGmvChange * 100) / 100}%</td>
                            </tr>
                          )
                        }) : ''}
                    </>
                    :
                    ''
                  }
                </tbody>
              </table>
            </div>
            :
            <Loader />
          }
        </div>
      </Box>
    </>
  );
}


