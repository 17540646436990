import { useState, useEffect } from "react";
import Logo from '../../assets/resale-full-logo.svg';
import LoginImage from "../../assets/sign-in-illustration.svg";
import OpenEye from "../../assets/open-eye.svg";
import CloseEye from "../../assets/eye-closed.svg";
import { Button, Modal, Spinner } from "react-bootstrap";
import './LoginScreen.scss';
import { useAuth } from '../../shared/provider/authprovider';
import { Link } from "react-router-dom";
import ErrorModal from '../../components/errorModal/ErrorModal';

const LoginScreen = () => {
  const { signInCalled, token, isLoading, error, setError, errorModalShow,
    setErrorModalShow } = useAuth();
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [validMail, setValidMail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [isSecureEntry, setisSecureEntry] = useState(true);

  useEffect(() => {
    // setError("")
  }, [])

  const passwordIconTapped = () => {
    setisSecureEntry(prev => !prev)
  }

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };

  const onTapLogInBtn = () => {
    if (isValidForm()) {
      let details = {
        "userName": emailId,
        "password": password
      };
      signInCalled(details);
      setError("")
    }
  };

  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      onTapLogInBtn();
    }
  };

  const isValidMail = (value: any) => {
    const MAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValidMail(MAIL_REGEX.test(value));
    return MAIL_REGEX.test(value);
  };

  const isValidForm = () => {
    if (isValidMail(emailId)) {
      return true
    }
  };

  return (
    <>
      {/* {errorHandle()} */}
      <div className='mainDiv'>
        {isLoading && <div className="loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>}
        <div className="container padding-top-btm">
          <div className="row flex-grow-1">
            <div className="col-lg-6 col-md-6 col-sm-12 loginImg text-center">
              <img className="loginSide img-fluid" src={LoginImage} />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="sign-in-form-sect">
                <div className="brand-section d-flex flex-column align-items-center">
                  <div className="brand text-center">
                    <a className="navbar-brand mr-0" href="#">
                      <img className='logo' src={Logo} alt="Logo" />
                    </a>
                  </div>
                  <h5>Warehouse <span>Utilities</span></h5>
                  {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p> */}
                </div>
                <div className="loginSec">
                  <h6 className="log-in">Sign In Please...</h6>
                  <label className="incorrect-pwd">{error}</label>
                  {validMail ? null : <label className="incorrect-email">Enter valid email address</label>}

                  <div className='inputFieldDiv'>
                    <div className="validationB">
                      <input className="input-field firstfield" placeholder='E-mail' onChange={(event) => setEmailId(event.target.value)} />
                    </div>
                    <div className="show-hide-pwd">
                      {isSecureEntry ?
                        <input className="input-field" placeholder='Password' type='password' onKeyPress={(event) => handleKeypress(event)} onChange={(event) => setPassword(event.target.value)} /> : <input className="input-field" placeholder='Password' onKeyPress={(event) => handleKeypress(event)} onChange={(event) => setPassword(event.target.value)} />}
                      {isSecureEntry ?
                        <span onClick={() => passwordIconTapped()}>
                          <img src={CloseEye} />
                        </span> :
                        <span onClick={() => passwordIconTapped()}>
                          <img src={OpenEye} />
                        </span>
                      }


                    </div>
                    <div className="text-end"><Link className="forgot" to="/forgotpassword">Forgot Password?</Link></div>
                    <div className="btn-div">
                      {emailId && password ?
                        <Button onClick={onTapLogInBtn} className="submitBtn d-block w-100" >Sign In</Button> :
                        <Button className="submitBtn d-block w-100 disabled-btn" disabled >Sign In</Button>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div >
      </div>
    </>
  )
};

export default LoginScreen;