import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { environmentUrl, environmentUrlSaaS } from "../../api/index.service";
import * as UserAPIs from "../../api/user";
import * as WareHouseAPIs from '../../api/warehouse'
import * as ManifestMissingAPIs from '../../api/manifestMissing'
import * as ReceivingApplicationAPIs from '../../api/receivingApplication'
import * as TaxonomyNotFoundAPIs from '../../api/taxonomyNotFound'
import * as ScanProductReportAPIs from '../../api/scanProductsReport'
import * as SKUVaultAPIs from '../../api/skuVault'
import axios from "axios";
import { useLocalStorageState } from 'ahooks';
export interface IUseAuth {
  token: any | null;
  setToken(value: any): any;
  roles: any | null;
  setRoles(value: any): any;
  firstName: any | null;
  setFirstName(value: any): any;
  lastName: any | null;
  setLastName(value: any): any;
  signInCalled(details: any): any;
  signout(): void;
  userList: any | null;
  setUserList(value: any): any;
  userCount: any | null;
  setUserCount(value: any): any;
  getUserListCalled(prefix: any, offset: any, limit: any): any;

  wareHouseList: any | null;
  setWareHouseList(value: any): any;
  wareHousesCount: any | null;
  setWareHousesCount(value: any): any;
  getWarehouseListCalled(apiPrefix: any, offset: any, limit: any): any;
  scanItemsCalled(details: any): any;
  editScanItemsCalled(details: any, id: any): any;

  getNonProcessedScanProductsCalled(): any;
  getReadyToProcessScanProductsCalled(): any;
  getSentToProcessScanProductsCalled(): any;
  getUserScanProdListCalled(): any;
  isLoading: boolean;
  setLoading(value: any): any;
  isButtonLoading: boolean;


  endBoxCalled(): any;
  scanProductList: any | null;
  setScanProductList(value: any): any;
  deleteUpcCalled(id: any): any;
  setScanItemsArrayy(value: any): any;
  scanItemsArrayy: any | null;

  error: any | null;
  setError(value: any): any;
  forgotPasswordError: any | null;
  setForgotPasswordError(value: any): any;

  getWarehouseListCalledForUser(offset: any, limit: any): any;
  postInsertSkuVaultProductsCalled(details: any): any;
  dashPassStartDate: any | null;
  setDashPassStartDate(value: any): any;
  validWarehouseName: any | null;
  setValidWarehouseName(value: any): any;
  validLocation: any | null;
  setValidLocation(value: any): any;
  validDescription: any | null;
  setValidDescription(value: any): any;
  errorModalShow: any | null;
  setErrorModalShow(value: any): any;
  errorMsg: any | null;
  setErrorMsg(value: any): any;

  winServicesCronName: any | null;
  setWinServicesCronName(value: any): any;


  loader: any | null;
  setLoader(value: any): any;
  selectedIndex: any | null;
  setSelectedIndex(value: any): any;

  locationsList: any | null;
  setLocations(value: any): any;
  locationsCount: any | null;
  setLocationsCount(value: any): any;
  getManifestMissingLocationsCalled(prefix: any, offset: any, limit: any, order: any): any;
  setUPCbyLocationCount(value: any): any;
  uPCbyLocationCount: any | null;
  setUPCbyLocation(value: any): any;
  uPCbyLocation: any | null;
  getManifestMissingUPCCalled(location: any, offset: any, limit: any, order: any): any;
  manifestMissingProductDetails: any | null;
  setManifestMissingProductDetails(value: any): any;
  getManifestProductDetailsCalled(UPC: any): any;
  putManifestMissingProductsCalled(values: any): any;
  getGuidedManifestProductDetailsCalled(override: any, count: any): any
  guidedProductDetails: any | null;
  setGuidedProductDetails(value: any): any;
  guidedProductsCount: any | null;
  setGuidedProductsCount(value: any): any;
  putGuidedManifestMissingProductCalled(values: any): any;
  putSkipNotFoundGuidedManifestMissingProductCalled(id: any, status: any): any;
  putQuitdManifestMissingProductCalled(id: any): any;
  // getTaxonomyNotFoundRecordsCalled(offset: any, limit: any, prefix: any): any;
  // taxonomyNotFoundList: any | null;
  // setTaxonomyNotFoundList(valuess: any): any;
  // taxonomyNFDropdown: any | null;
  // setTaxonomyNFDropdown(valuesnew: any): any;
  // getTaxonomyNotFoundRecordsDropdownCalled(offset: any, limit: any): any;
  // taxonomyMissingProductsCount: any | null;
  // setTaxonomyMissingProductsCount(values: any): any
  // putTaxonomyNotFoundRecordsUpdateCalled(values: any): any;
  exportTaxonomyNotFoundCalled(prefix: any): any;
  getReceivingOrderNumberCalled(code: any): any;
  isROValid: any | null;
  setROisValid(value: any): any;
  // getValidatScanUPCCalled(upc: any): any;
  scanResponse: any | null;
  setScanResponse(value: any): any;
  scanResponseProduct: any | null;
  setScanResponseProduct(value: any): any;
  putReceivingSentToStoreOrTrashCalled(RONumber: any, upc: any, status: any, brand: any): any;
  getProductCategoryListCalled(offset: any, limit: any): any;
  productCategoryList: any | null;
  setProductCategoryList(offset: any, limit: any): any;
  postListableLocationCalled(details: any): any;
  roValidationBox: any | null;
  setRoValidationBox(value: any): any;
  upcValidationBox: any | null;
  setupcValidationBox(value: any): any;
  notScanableBox: any | null;
  setNotScanableBox(value: any): any;
  listableItemBox: any | null;
  setListableItemBox(value: any): any;
  listableLocationBox: any | null;
  setListableLocationBox(value: any): any;
  unlistableItemBox: any | null;
  setUnlistableItemBox(value: any): any;
  productFlagsBox: any | null;
  setProductFlagsBox(value: any): any;
  productWeightBox: any | null;
  setProductWeightBox(value: any): any;
  newData: any | null;
  setNewData(value: any): any;
  setNewExportLoader(value: any): any;
  api: any;
  handleError(error: any): any;
  apinew: any

  setLastListedQuantity(value: any): any;
  lastListedQuantity: any | null;
  setLastListedLocation(value: any): any;
  lastListedLocation: any | null;
  setLastListedUPC(value: any): any;
  lastListedUPC: any | null;

  account: any | null;
  setAccount(value: any): any;
}

const AuthContext = createContext({});

export const useAuth = () => useContext<IUseAuth>(AuthContext as any);

export const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useLocalStorageState<any | null>('');
  const [roles, setRoles] = useState<any | null>(null);
  const [firstName, setFirstName] = useState<any | null>(null);
  const [lastName, setLastName] = useState<any | null>(null);
  const [userList, setUserList] = useState<any | null>(null);
  const [userCount, setUserCount] = useState<any | null>(null)
  const [wareHouseList, setWareHouseList] = useState<any | null>(null);
  const [wareHousesCount, setWareHousesCount] = useState<any | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [scanItemsArrayy, setScanItemsArrayy] = useState<any>([]);

  const [dashPassStartDate, setDashPassStartDate] = useState<any | null>(null)
  const [error, setError] = useState("");
  const [forgotPasswordError, setForgotPasswordError] = useState("");

  const [validWarehouseName, setValidWarehouseName] = useState(false);
  const [validLocation, setValidLocation] = useState(false)
  const [validDescription, setValidDescription] = useState(false);
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  //WinServices
  const [winServicesCronName, setWinServicesCronName] = useState<any | null>(null);

  const [locationsList, setLocations] = useState<any | null>(null)
  const [locationsCount, setLocationsCount] = useState<any | null>(null)
  const [uPCbyLocation, setUPCbyLocation] = useState<any | null>(null)
  const [uPCbyLocationCount, setUPCbyLocationCount] = useState<any | null>(null)
  const [manifestMissingProductDetails, setManifestMissingProductDetails] = useState<any | null>(null)
  //guided product update
  const [guidedProductDetails, setGuidedProductDetails] = useState<any | null>(null)
  const [guidedProductsCount, setGuidedProductsCount] = useState<any | null>(null)
  //taxonomy not found
  // const [taxonomyNotFoundList, setTaxonomyNotFoundList] = useState([])
  // const [taxonomyNFDropdown, setTaxonomyNFDropdown] = useState<any | null>(null)
  // const [taxonomyMissingProductsCount, setTaxonomyMissingProductsCount] = useState<any | null>(null)
  // receiving application
  const [roValidationBox, setRoValidationBox] = useState(true)
  const [upcValidationBox, setupcValidationBox] = useState(false)
  const [notScanableBox, setNotScanableBox] = useState(false)
  const [listableItemBox, setListableItemBox] = useState(false)
  const [listableLocationBox, setListableLocationBox] = useState(false)
  const [unlistableItemBox, setUnlistableItemBox] = useState(false)
  const [productFlagsBox, setProductFlagsBox] = useState(false)
  const [productWeightBox, setProductWeightBox,] = useState(false)
  const [scanResponse, setScanResponse] = useState<any | null>(null)
  // const [scanResponseProduct, setScanResponseProduct] = useState<any | null>(null)

  //lisable non listable products
  const [isROValid, setROisValid] = useState<any | null>(null)
  // active inactive sidebar
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const [actieDeactiveValue, setActieDeactiveValue] = useState<any | null>(null)
  //Loader
  const [loader, setLoader] = useState(false)
  const [productCategoryList, setProductCategoryList] = useState<any | null>(null)
  let navigate = useNavigate();
  const [newData, setNewData] = useState<any | null>(null)

  const [lastListedUPC, setLastListedUPC] = useState<any>('')
  const [lastListedLocation, setLastListedLocation] = useState<any>('')
  const [lastListedQuantity, setLastListedQuantity] = useState<any>('')

  const [account, setAccount] = useLocalStorageState<any | null>('');
  useEffect(() => {
    getDataFromStorage();
  }, []);

  const getDataFromStorage = () => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    setRoles(role);
    setToken(token);
    setFirstName(firstName);
    setLastName(lastName);
  };
  const signInCalled = async (details: any) => {
    setLoading(true)
    try {
      const result = await axios.post(`${environmentUrl}/api/Authentication/Token`, details);
      setToken(result?.data?.token);
      setRoles(result?.data?.roles);
      setFirstName(result?.data?.firstName);
      setLastName(result?.data?.lastName);
      setAccount(result?.data?.account)
      localStorage.setItem("role", result?.data?.roles);
      localStorage.setItem("token", result?.data?.token);
      localStorage.setItem("firstName", result?.data?.firstName);
      localStorage.setItem("lastName", result?.data?.lastName);
      if (result?.data?.roles == "Admin") {
        navigate("/dashboard", { replace: true });
        navigate(0);
      }
      else if (result?.data?.roles == "Vendor") {
        navigate(`/vendor-details/${result.data.classifications}`, { replace: true })
      }
      else if (result?.data?.roles == "Demo") {
        navigate("/scan-upc")
      }
      else if (result?.data?.roles == "RMUser") {
        localStorage.setItem("account", result?.data?.account)
        if (result?.data?.account == "Recom LLC") {
          navigate("/receiving-app", { replace: true })
        }
        else if (result?.data?.account == "QBIDs") {
          navigate("/receiving-app-quicklotz", { replace: true })
        }
        else if (result?.data?.account == "SkuVault") {
          navigate("/receiving-skuvault", { replace: true })
        }
      }     
      else {
        await ScanProductReportAPIs.getUserScanProdList(result?.data?.token)
          .then((result) => {
            if (result?.data?.results?.items.length > 0) {
              localStorage.setItem("boxNumber", result?.data?.results?.items[0].boxName);
              localStorage.setItem("warehouseName", result?.data?.results?.items[0]?.warehousePrefixDto?.warehouseDto?.name);
              localStorage.setItem("prefixName", result?.data?.results?.items[0]?.warehousePrefixDto?.prefixName);
              localStorage.setItem("prefixId", result?.data?.results?.items[0]?.warehousePrefixDto?.id);
              navigate("/boxtablescreen", { replace: true });
              navigate(0);
            } else {
              navigate("/userdashboard", { replace: true });
              navigate(0);
            }
            setScanItemsArrayy(result?.data?.results?.items);
          })
          .catch((error) => {
            alert(error?.response?.data?.results)
          });
      }
    } catch (error: any) {
      console.log("Error", error)
      setLoading(false);
      if (error.response.status === 400) {
        setError(error.response.data);
        // setErrorModalShow(true);
        // setErrorMsg(error?.response?.data);
      } else {
        setErrorModalShow(true);
        setErrorMsg(error?.response?.data?.results);
      }
    } finally {
      setLoading(false)
    }
  };
  const handleError = (error: any) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else {
      setErrorModalShow(true);
      setErrorMsg(error?.response?.data?.results);
    }
  }
  const signout = () => {
    localStorage.clear();
    window.location.href = '/';
  }

  const getUserListCalled = async (prefix: any, offset: any, limit: any) => {
    const token = localStorage.getItem("token");
    await UserAPIs.getUserList(token, prefix, offset, limit)
      .then((res) => {
        setUserList(res?.data?.results?.items)
        setUserCount(res?.data?.results?.count)
        // console.log("getUserListCalled successfully", res);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
        // console.log("getUserListCalled error => ", JSON.stringify(error));
      });
  };

  const getWarehouseListCalled = async (apiPrefix: any, offset: any, limit: any) => {
    const token = localStorage.getItem("token");
    await WareHouseAPIs.getWarehouseList(token, apiPrefix, offset, limit)
      .then((res) => {
        setWareHouseList(res?.data?.results?.items)
        setWareHousesCount(res?.data?.results?.count)
        // console.log("getWarehouseListCalled successfully", res);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
        // console.log("getWarehouseListCalled error => ", JSON.stringify(error));
      });
  };

  const getWarehouseListCalledForUser = async (offset: any, limit: any) => {
    const token = localStorage.getItem("token");
    let list;
    // console.log("token sdf", token);
    setLoading(true);
    await WareHouseAPIs.getWarehouseList(token, "", offset, limit)
      .then((res) => {
        setLoading(false);
        setWareHouseList(res?.data?.results?.items);
        // console.log("getWarehouseListCalledForUser successfully",res?.data?.results?.items);
        list = res?.data?.results?.items;
        // console.log("list", list);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
        setLoading(false);
        // console.log("getWarehouseListCalledForUser error => ", JSON.stringify(error));
      });

    return list;
  };

  const scanItemsCalled = async (details: any) => {
    const token = localStorage.getItem("token");
    await ScanProductReportAPIs.scanItems(details, token)
      .then((res) => {
        setScanItemsArrayy((oldValue: any) => [...oldValue, res?.data?.results])
        // console.log("scanItemsCalled successfully", res?.data?.results);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
        // console.log("scanItemsCalled error => ", JSON.stringify(error));
      });
  };

  const editScanItemsCalled = async (details: any, id: any) => {
    // setLoading(true);
    await ScanProductReportAPIs.editScanItems(details, id, token)
      .then((res) => {
        getUserScanProdListCalled()
        // console.log("editScanItemsCalled successfully", res);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
        // console.log("editScanItemsCalled error => ", JSON.stringify(error));
        setLoading(false);
      });
  };

  const getNonProcessedScanProductsCalled = async () => {
    await ScanProductReportAPIs.getNonProcessedScanProducts(token)
      .then((res) => {
        // console.log("getNonProcessedScanProductsCalled successfully", res);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
        // console.log("getNonProcessedScanProductsCalled error => ",JSON.stringify(error));
      });
  };

  const getReadyToProcessScanProductsCalled = async () => {
    await ScanProductReportAPIs.getReadyToProcessScanProducts(token)
      .then((res) => {
        // console.log("getReadyToProcessScanProductsCalled successfully", res);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
        // console.log("getReadyToProcessScanProductsCalled error => ",JSON.stringify(error));
      });
  };

  const getSentToProcessScanProductsCalled = async () => {
    await ScanProductReportAPIs.getSentToProcessScanProducts(token)
      .then((res) => {
        // console.log("getSentToProcessScanProductsCalled successfully", res);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
        // console.log("getSentToProcessScanProductsCalled error => ",JSON.stringify(error));
      });
  };

  const getUserScanProdListCalled = async () => {
    const token = localStorage.getItem("token");
    await ScanProductReportAPIs.getUserScanProdList(token)
      .then((res) => {
        setScanItemsArrayy(res?.data?.results?.items);
        // console.log("getUserScanProdListCalled successfully", res?.data?.results?.items);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
        // console.log("getUserScanProdListCalled error => ", JSON.stringify(error));
      });
  };

  const endBoxCalled = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    await ScanProductReportAPIs.endBox(token)
      .then((res) => {
        setScanItemsArrayy([]);
        // alert("Box Proceed")
        navigate("/userdashboard")
        // console.log("endBoxCalled successfully", res);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
        // console.log("endBoxCalled error => ", JSON.stringify(error));
        setLoading(false);
      });
  };

  const deleteUpcCalled = async (id: any) => {
    // setLoading(true);
    const token = localStorage.getItem("token");
    await ScanProductReportAPIs.deleteUpc(id, token)
      .then((res) => {
        // console.log("deleteUpcCalled successfully", res?.data?.results);
        getUserScanProdListCalled()
        // setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
        // console.log("deleteUpcCalled error => ", JSON.stringify(error));
        // setLoading(false);
      });
  };

  const postInsertSkuVaultProductsCalled = async (details: any) => {
    setErrorModalShow(true);
    setErrorMsg("Processing");
    // setLoading(true);
    const token = localStorage.getItem("token");
    await SKUVaultAPIs.postInsertSkuVaultProducts(token, details)
      .then((res) => {
        // setLoading(false);
        setErrorModalShow(true);
        setErrorMsg(`Imported ${res.data.insertCount} unique UPC out of ${res.data.totalCount} total new scans`);
        console.log("postInsertSkuVaultProductsCalled successfully", res?.data);
      })
      .catch((error) => {
        // setLoading(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
        console.log("postInsertSkuVaultProductsCalled error => ", JSON.stringify(error));
      });
  };

  ////manifest missing products
  const getManifestMissingLocationsCalled = async (prefix: any, offset: any, limit: any, order: any) => {
    const token = localStorage.getItem("token");
    setLoading(true)
    await ManifestMissingAPIs.getManifestMissingLocations(token, prefix, offset, limit, order)
      .then((res) => {
        console.log("locations", res.data.result);
        setLocationsCount(res?.data?.count);
        setLocations(res?.data?.result);
        setLoading(false)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setLoader(false)
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data);
          // setErrorModalShow(true);
          // setErrorMsg(error?.response?.data?.results);
        }
      });
  };
  const getManifestMissingUPCCalled = async (location: any, offset: any, limit: any, order: any) => {
    const token = localStorage.getItem("token");
    setLoading(true)
    await ManifestMissingAPIs.getManifestMissingUPC(token, location, offset, limit, order)
      .then((res) => {
        console.log("UPCs", res.data.result);
        setUPCbyLocationCount(res?.data?.count)
        setUPCbyLocation(res?.data?.result);
        setLoading(false)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          // setErrorModalShow(true);
          // setErrorMsg(error?.response?.data?.results);
        }
      });
  };
  const getManifestProductDetailsCalled = async (UPC: any) => {
    const token = localStorage.getItem("token");
    setLoading(true)
    await ManifestMissingAPIs.getManifestProductDetails(token, UPC)
      .then((res) => {
        console.log("Product Details", res.data.result);
        setManifestMissingProductDetails(res?.data?.result);
        setLoading(false)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          // setErrorModalShow(true);
          // setErrorMsg(error?.response?.data?.results);
        }
      });
  };
  const putManifestMissingProductsCalled = async (values: any) => {
    const token = localStorage.getItem("token");
    await ManifestMissingAPIs.putManifestMissingProducts(token, values)
      .then((res) => {
        console.log("put Manifest Missing Products", res?.data?.status);
        if (res?.data?.status == 200) {
          setErrorModalShow(true)
          setErrorMsg("Details added successfully")
          navigate(-1)
        }
        else {
          setErrorModalShow(true)
          setErrorMsg(res?.data?.results)
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          // setErrorModalShow(true);
          // setErrorMsg(error?.response?.data?.results);
        }
      });
  };
  // guided product update
  const getGuidedManifestProductDetailsCalled = async (override: any, countnumber: any) => {
    const token = localStorage.getItem("token");
    setLoading(true)
    await ManifestMissingAPIs.getGuidedProductDetails(token, override, countnumber)
      .then((res) => {
        // console.log("Guided Product Details", res.data.result);
        setGuidedProductDetails(res?.data?.result);
        console.log("Count", res)
        setGuidedProductsCount(res?.data?.count)
        setLoading(false)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setLoading(false)
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
      });
  };
  const putGuidedManifestMissingProductCalled = async (values: any) => {
    const token = localStorage.getItem("token");
    setLoading(true)
    await ManifestMissingAPIs.putGuidedManifestMissingProduct(token, values)
      .then((res) => {
        console.log("put Manifest Missing Products", res?.data?.status);
        if (res?.data?.status == 200) {
          setLoading(false)
          setErrorModalShow(true)
          setErrorMsg("Details added successfully")
          // getGuidedManifestProductDetailsCalled();
        }
        else {
          setLoading(false)
          setErrorModalShow(true)
          setErrorMsg(res?.data?.results)
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setLoading(false)
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
      });
  };
  // putSkipNotFoundGuidedManifestMissingProduct
  const putSkipNotFoundGuidedManifestMissingProductCalled = async (id: any, status: any) => {
    const token = localStorage.getItem("token");
    setLoading(true)
    await ManifestMissingAPIs.putSkipNotFoundGuidedManifestMissingProduct(token, id, status)
      .then((res) => {
        console.log("skip not found Manifest Missing Products", res?.data?.status);
        if (res?.data?.status == 200) {
          setLoading(false)
          setErrorModalShow(true)
          setErrorMsg("Skipped successfully")
        }
        else {
          setLoading(false)
          setErrorModalShow(true)
          setErrorMsg(res?.data?.results)
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setLoading(false)
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
      });
  };
  //putQuitdManifestMissingProduct
  const putQuitdManifestMissingProductCalled = async (id: any) => {
    const token = localStorage.getItem("token");
    setLoading(true)
    await ManifestMissingAPIs.putQuitdManifestMissingProduct(token, id)
      .then((res) => {
        console.log("Quit Manifest Missing Products", res?.data?.status);
        if (res?.data?.status == 200) {
          setLoading(false)
          setErrorModalShow(true)
          setErrorMsg("Quit successfully")
        }
        else {
          setLoading(false)
          setErrorModalShow(true)
          setErrorMsg(res?.data?.results)
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setLoading(false)
          setErrorModalShow(true);
          setErrorMsg(error?.response?.data?.results);
        }
      });
  };
  // listable nonlistable products
  // getReceivingOrderNumber
  const getReceivingOrderNumberCalled = async (code: any) => {
    setLoading(true)
    const token = localStorage.getItem("token");
    await ReceivingApplicationAPIs.getReceivingOrderNumber(token, code)
      .then((res) => {
        setLoading(false)
        setROisValid(res?.data?.result)
        localStorage.setItem("ROValidation", res?.data?.result?.isRoValid)
        localStorage.setItem("isQbidAccount", res?.data?.result?.isAccountQBID)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setLoading(false)
          setErrorMsg(error?.response?.data?.results);
        }
      });
  };
  // getValidateUPC

  // const getValidatScanUPCCalled = async (upc: any) => {
  //   setLoading(true)
  //   const token = localStorage.getItem("token");
  //   await ReceivingApplicationAPIs.getValidatScanUPC(token, upc)
  //     .then((res) => {
  //       setLoading(false)
  //       console.log("Resp", res?.data?.result)
  //       setScanResponse(res?.data?.result)
  //       setScanResponseProduct(res?.data?.result?.items)
  //       localStorage.setItem("UPCMessage", res?.data?.result)
  //       return res
  //     })
  //     .catch((error) => {
  //       if (error?.response?.status === 401) {
  //         localStorage.clear();
  //         window.location.href = '/';
  //       } else {
  //         setErrorModalShow(true);
  //         setLoading(false)
  //         setErrorMsg(error?.response?.data?.results);
  //       }
  //     });
  // };
  // putReceivingSentToStoreOrTrash
  const putReceivingSentToStoreOrTrashCalled = async (RONumber: any, upc: any, status: any, brand: any) => {
    const token = localStorage.getItem("token");
    setLoading(true)
    await ReceivingApplicationAPIs.putReceivingSentToStoreOrTrash(token, RONumber, upc, status, brand)
      .then((res) => {
        console.log("Resp", res?.data?.status)
        if (res?.data?.status == 200) {
          setLoading(false)
          setNotScanableBox(false)
          setUnlistableItemBox(false)
          setListableLocationBox(false)
          setListableItemBox(false)
          setRoValidationBox(false)

          // setErrorModalShow(true);
          // if (status == 9) {
          //   setErrorMsg("Sent to Store Successfully");
          // }
          // else if (status == 10) {
          //   setErrorMsg("Sent to Trash Successfully");
          // }
          setupcValidationBox(true)
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        }
        // else if (error?.response?.status === 500) {
        //   setErrorModalShow(true);
        //   setErrorMsg("500: Server Error. Try again");
        //   setNotScanableBox(false)
        //   setupcValidationBox(true)
        // }
        else {
          setErrorModalShow(true);
          setLoading(false)
          setErrorMsg(error?.response?.data?.results);
        }
      });
  };
  // getProductCategoryList
  const getProductCategoryListCalled = async (offset: any, limit: any) => {
    const token = localStorage.getItem("token");
    setLoading(true)
    await ReceivingApplicationAPIs.getProductCategoryList(token, offset, limit)
      .then((res) => {
        console.log("Product Details", res.data.result);
        setProductCategoryList(res?.data?.result)
        setLoading(false)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          // setErrorModalShow(true);
          // setErrorMsg(error?.response?.data?.results);
        }
      });
  };
  // postListableLocation
  const postListableLocationCalled = async (details: any) => {
    setLoading(true)
    const token = localStorage.getItem("token");
    await ReceivingApplicationAPIs.postListableLocation(token, details)
      .then((res) => {
        if (res?.data?.status == 200) {
          setLoading(false)
          // setErrorMsg("Location Added Succcessfully")
          // setErrorModalShow(true)
          setListableLocationBox(false)
          setListableItemBox(false)
          setRoValidationBox(false)
          setupcValidationBox(true)
          setProductFlagsBox(false)
          setProductWeightBox(false)
          setLastListedUPC(details?.qbidsDto?.upc)
          // setLastListedLocation(location)
          // setLastListedQuantity(quantity)
        }
        else {
          setLoading(false)
          setErrorModalShow(true)
          setErrorMsg(res?.data?.results)
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          setErrorModalShow(true);
          setLoading(false)
          setErrorMsg(error?.response?.data?.results);
        }
      });
  };
  // getTaxonomyNotFoundRecords
  // const getTaxonomyNotFoundRecordsCalled = async (offset: any, limit: any, prefix: any) => {
  //   const token = localStorage.getItem("token");
  //   setLoading(true)
  //   await TaxonomyNotFoundAPIs.getTaxonomyNotFoundRecords(token, offset, limit, prefix)
  //     .then((res) => {
  //       // console.log("getTaxonomyNotFoundRecordsCalled", res.data);
  //       setTaxonomyNotFoundList(res?.data?.result)
  //       setTaxonomyMissingProductsCount(res?.data?.count)
  //       setLoading(false)
  //     })
  //     .catch((error) => {
  //       if (error?.response?.status === 401) {
  //         localStorage.clear();
  //         window.location.href = '/';
  //       } else {
  //         setLoading(false)
  //         setErrorModalShow(true);
  //         setErrorMsg(error?.response?.data?.results);
  //       }
  //     });
  // };
  // getTaxonomyNotFoundRecordsDropdown
  // const getTaxonomyNotFoundRecordsDropdownCalled = async (offset: any, limit: any) => {
  //   const token = localStorage.getItem("token");
  //   setLoading(true)
  //   await TaxonomyNotFoundAPIs.getTaxonomyNotFoundRecordsDropdown(token, offset, limit)
  //     .then((res) => {
  //       // console.log("dropdown", res.data.result);
  //       setTaxonomyNFDropdown(res?.data?.result)
  //       setLoading(false)
  //     })
  //     .catch((error) => {
  //       if (error?.response?.status === 401) {
  //         localStorage.clear();
  //         window.location.href = '/';
  //       } else {
  //         setLoading(false)
  //         setErrorModalShow(true);
  //         setErrorMsg(error?.response?.data?.results);
  //       }
  //     });
  // };
  // const putTaxonomyNotFoundRecordsUpdateCalled = async (values: any) => {
  //   const token = localStorage.getItem("token");
  //   setLoading(true)
  //   await TaxonomyNotFoundAPIs.putTaxonomyNotFoundRecordsUpdate(token, values)
  //     .then((res) => {
  //       console.log("Update Taxonomy Product", res?.data?.status);
  //       if (res?.data?.status == 200) {
  //         setLoading(false)
  //         setErrorModalShow(true)
  //         setErrorMsg(values?.length > 1 ? `${values?.length} Products Updated Successfully` : `${values?.length} Product Updated Successfully`)
  //       }
  //       else {
  //         setLoading(false)
  //         setErrorModalShow(true)
  //         setErrorMsg(res?.data?.results)
  //       }
  //     })
  // }



  const api = axios.create({
    baseURL: environmentUrl, headers: {
      'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });
  const apinew = axios.create({
    baseURL: environmentUrlSaaS, headers: {
      'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });
  return (
    <AuthContext.Provider
      value={{
        token,
        roles,
        firstName,
        lastName,
        signInCalled,
        userList,
        getUserListCalled,
        userCount,
        setUserCount,
        wareHouseList,
        getWarehouseListCalled,
        scanItemsCalled,
        editScanItemsCalled,
        getNonProcessedScanProductsCalled,
        getReadyToProcessScanProductsCalled,
        getSentToProcessScanProductsCalled,
        getUserScanProdListCalled,
        signout,
        isLoading,
        setLoading,
        wareHousesCount,
        endBoxCalled,
        deleteUpcCalled,
        scanItemsArrayy,
        setScanItemsArrayy,

        // adminDashboardScannedProductReportList,
        dashPassStartDate,
        setDashPassStartDate,
        error,
        setError,
        forgotPasswordError,
        setForgotPasswordError,

        getWarehouseListCalledForUser,
        postInsertSkuVaultProductsCalled,
        validWarehouseName,
        setValidWarehouseName,
        validLocation,
        setValidLocation,
        validDescription,
        setValidDescription,
        errorModalShow,
        setErrorModalShow,
        errorMsg,
        setErrorMsg,

        winServicesCronName,
        setWinServicesCronName,


        // getPrefixListCalled,

        loader,
        setLoader,
        // adminDashboardLatestProductReportExportList,
        // setAdminDashboardLatestProductReportExportList,
        selectedIndex,
        setSelectedIndex,

        getManifestMissingLocationsCalled,
        locationsList,
        setLocations,
        locationsCount,
        setLocationsCount,
        uPCbyLocationCount,
        setUPCbyLocationCount,
        setUPCbyLocation,
        uPCbyLocation,
        getManifestMissingUPCCalled,
        manifestMissingProductDetails,
        setManifestMissingProductDetails,
        getManifestProductDetailsCalled,
        putManifestMissingProductsCalled,
        getGuidedManifestProductDetailsCalled,
        guidedProductDetails,
        setGuidedProductDetails,
        guidedProductsCount,
        setGuidedProductsCount,
        putGuidedManifestMissingProductCalled,
        putSkipNotFoundGuidedManifestMissingProductCalled,
        putQuitdManifestMissingProductCalled,
        // getTaxonomyNotFoundRecordsCalled,
        // taxonomyNotFoundList,
        // setTaxonomyNotFoundList,
        // setTaxonomyNFDropdown,
        // taxonomyNFDropdown,
        // getTaxonomyNotFoundRecordsDropdownCalled,
        // taxonomyMissingProductsCount,
        // setTaxonomyMissingProductsCount,
        // putTaxonomyNotFoundRecordsUpdateCalled,
        getReceivingOrderNumberCalled,
        isROValid,
        setROisValid,
        scanResponse,
        // scanResponseProduct,
        // getValidatScanUPCCalled,
        putReceivingSentToStoreOrTrashCalled,
        getProductCategoryListCalled,
        productCategoryList,
        postListableLocationCalled,
        roValidationBox,
        setRoValidationBox,
        upcValidationBox,
        setupcValidationBox,
        notScanableBox,
        setNotScanableBox,
        listableItemBox,
        setListableItemBox,
        listableLocationBox,
        setListableLocationBox,
        unlistableItemBox,
        setUnlistableItemBox,
        productFlagsBox, setProductFlagsBox,
        productWeightBox, setProductWeightBox,
        // resetDataPrefixList,
        // getResetDataPrefixListCalled,
        // putResetDataChangeProductStatusCalled,
        actieDeactiveValue,
        setActieDeactiveValue,
        newData,
        setNewData,
        api,
        handleError,
        apinew,

        lastListedQuantity,
        lastListedLocation,
        lastListedUPC,

        account
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
