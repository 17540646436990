import { useState } from "react";
import { useAuth } from "../provider/authprovider";

export const useRecAppApiSkuVault = () => {
    const { setLoading, api, handleError, setErrorModalShow, setErrorMsg } = useAuth();
    const [upcValidationBoxSkuVault, setUpcValidationBoxSkuVault] = useState(false)
    const [roValidationSkuVaultBox, setRoValidationSkuVaultBox] = useState(true)
    const [scanUPCProductSkuVault, setScanUPCProductSkuVault] = useState<any>(null)
    const [productDataSkuVaultBox, setProductDataSkuVaultBox] = useState(false)
    const getUPCScanSkuVaultCalled = async (upc: any) => {
        setLoading(true)
        try {
            const result = await api.get(`/api/SkuVault/ScanItems?upc=${upc}`);
            setScanUPCProductSkuVault(result?.data?.result?.items)
            return result?.data?.result
        } catch (error: any) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = '/';
            } else {
                setErrorMsg(error?.response?.data?.results);
                setErrorModalShow(true);
            }
        } finally {
            setLoading(false)
        }
    };
    const postSkuVaultProductDataCalled = async (details: any) => {
        setLoading(true)
        try {
            const result = await api.post(`/api/SkuVault/ListItem`, details);
            return result
        } catch (error: any) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = '/';
            } else {
                setErrorMsg(error?.response?.data?.results);
                setErrorModalShow(true);
            }
        }
        finally {
            setLoading(false)
        }
    }


    return {
        upcValidationBoxSkuVault, setUpcValidationBoxSkuVault, scanUPCProductSkuVault, getUPCScanSkuVaultCalled,
        productDataSkuVaultBox, setProductDataSkuVaultBox, postSkuVaultProductDataCalled,
        roValidationSkuVaultBox, setRoValidationSkuVaultBox
    } as const
}