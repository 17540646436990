import { Box, Button, Grid, TextField } from "@mui/material";
import Zoom from '@mui/material/Zoom';
import { useEffect, useState } from 'react';
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import Close from "../../../src/assets/icons/close.svg";
import { ReactComponent as Search } from "../../../src/assets/icons/searchStatus.svg";
import Delete from "../../assets/delete.svg";
import User from "../../assets/user-1.svg";
import ErrorModal from '../../components/errorModal/ErrorModal';
import Header from "../../components/header/Header";
import { useAuth } from "../../shared/provider/authprovider";
import "./UserManagement.scss";
import { useUsersAPI } from "../../shared/api/user";
import { Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

const UserManagement = () => {
  const { token, getUserListCalled, isLoading, userList, errorModalShow,
    userCount, setErrorModalShow, setSelectedIndex, setErrorMsg } = useAuth();
  const { createUser, editUser, deleteUser, getUserRoles, addAccount, getAccountsList } = useUsersAPI();
  // const [userList, setUserList] = useState<any[]>([]);
  const [userListOffset, setUserListOffset] = useState(0);
  const [userListLimit, setUserListLimit] = useState(1000);
  const [addUserModal, setAddUserModal] = useState(false);
  const [editUserModalShow, setEditUserModalShow] = useState(false);
  const [removeUserModalShow, setRemoveUserModalShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("")
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [role, setRole] = useState("Set Role");
  const [password, setPassword] = useState("");
  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validMail, setValidMail] = useState(true);
  const [validNumber, setValidNumber] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [apiPrefix, setApiPrefix] = useState("")
  const [usersRolesList, setUsersRolesList] = useState<any>([])
  const [addAccountModal, setAddAccountModal] = useState(false);
  const [accountList, setAccountList] = useState<any>([])
  const [account, setAccount] = useState("Set Account");

  const getRolesFun = async () => {
    const RolesList: any = await getUserRoles()
    setUsersRolesList(RolesList?.data?.result)
  }
  const getAccountListFun = async () => {
    const results = await getAccountsList()
    if (results?.status == 200) {
      setAccountList(results?.data?.results?.items)
    }
  }
  useEffect(() => {
    // getUserList();
    getUserListCalled(apiPrefix, 0, 1000)
    setSelectedIndex(3)
    getRolesFun()
    getAccountListFun()
  }, []);
  const onTapAddUser = () => {
    getAccountListFun()
    setAddUserModal(true);
    setRole("Set Role")
    setAccount("Set Account")
  };
  const onTapAddAccount = () => {
    setAddAccountModal(true);
  };

  const errorHandle = () => {
    return (
      <ErrorModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
      />
    )
  };

  const isValidFirstName = (value: any) => {
    const NAME_REGEX = /^[a-zA-Z ]{2,50}$/;
    setValidFirstName(NAME_REGEX.test(value));
    return NAME_REGEX.test(value);
  };

  const isValidLastName = (value: any) => {
    const NAME_REGEX = /^[a-zA-Z ]{2,50}$/;
    setValidLastName(NAME_REGEX.test(value));
    return NAME_REGEX.test(value);
  };

  const isValidMail = (value: any) => {
    const MAIL_REGEX = /^([0-9a-z]([-.\w]*[0-9a-z])*@([0-9a-z][-\w]*[0-9a-z]\.)+[a-z]{2,3})$/;
    setValidMail(MAIL_REGEX.test(value));
    return MAIL_REGEX.test(value);
  };

  const isValidNumber = (value: any) => {
    const NUMBER_REGEX = /^\d{10}$/;
    setValidNumber(NUMBER_REGEX.test(value));
    return NUMBER_REGEX.test(value)
  };

  const isValidPassword = (value: any) => {
    const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\^&\*])(?=.{8,24})(?!=.[\>\<])/
    setValidPassword(PASSWORD_REGEX.test(value));
    return PASSWORD_REGEX.test(value);
  }

  const isValidForm = () => {
    if (isValidFirstName(firstName.trim()) && isValidLastName(lastName.trim()) && isValidMail(email) && isValidPassword(password) && isValidNumber(mobileNumber)) {
      return true
    }
    else {
      if (isValidFirstName(firstName.trim())) setValidFirstName(true)
      if (isValidLastName(lastName.trim())) setValidLastName(true)
      if (isValidMail(email)) setValidMail(true)
      if (isValidPassword(password)) setValidPassword(true)
      if (isValidNumber(mobileNumber)) setValidNumber(true)
      return false
    }
  };

  const isValidEditForm = () => {
    if (isValidFirstName(firstName.trimEnd()) && isValidLastName(lastName.trimEnd()) && isValidMail(email) && isValidNumber(mobileNumber)) {
      return true
    }
    else {
      if (isValidFirstName(firstName.trim())) setValidFirstName(true)
      if (isValidLastName(lastName.trim())) setValidLastName(true)
      if (isValidMail(email)) setValidMail(true)
      if (isValidNumber(mobileNumber)) setValidNumber(true)
      return false
    }
  };
  //add account modal
  const DisplayingErrorMessagesSchema = Yup.object().shape({
    companyName: Yup.string().required("Required"),
    accountName: Yup.string().required("Required"),
  });
  const formikAddAccount: any = useFormik({
    initialValues: {
      companyName: "",
      accountName: "",
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: async () => {
      const result = await addAccount(formikAddAccount?.values);
      if (result?.status == 200) {
        setAddAccountModal(false)
        setErrorModalShow(true);
        setErrorMsg("Account Added SuccessFully");
        getUserListCalled(apiPrefix, 0, 1000)
      }
      formikAddAccount.resetForm()
    },
  });
  const renderAddAccountModal = () => {
    return (

      <Modal className="formModal addModal"
        aria-labelledby="contained-modal-title-vcenter"
        show={addAccountModal}
        onHide={() => {
          setAddAccountModal(false);
          formikAddAccount.resetForm()
        }}
      >
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className="modalTitle">Add Account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={formikAddAccount}>
            <div className="formContainer">
              <div className="row align-items-center formItem">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="upc">Company Name : </label>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-8">
                  <Field
                    autoFocus
                    className="textfield"
                    id="companyName"
                    name="companyName"
                    placeholder="Enter Company Name"
                  />
                  {
                    <span className="text-danger">
                      {formikAddAccount.errors?.companyName && formikAddAccount.touched?.companyName &&
                        formikAddAccount.touched?.companyName && formikAddAccount.errors?.companyName}
                    </span>
                  }
                </div>
              </div>
              <div className="row align-items-center formItem">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="upc">Account Name : </label>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-8">
                  <Field
                    className="textfield"
                    id="accountName"
                    name="accountName"
                    placeholder="Enter Account Name"
                  />
                  {
                    <span className="text-danger">
                      {formikAddAccount.errors?.accountName && formikAddAccount.touched?.accountName &&
                        formikAddAccount.touched?.accountName && formikAddAccount.errors?.accountName}
                    </span>
                  }
                </div>
              </div>
              <div className='row align-items-center formItem'>
                <div className='col-4'>
                </div>
                <div className='col-8'>
                  <button className="FilledBtn" onClick={() => formikAddAccount.handleSubmit()}>Add</button>
                </div>
              </div>
            </div>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    );
  }
  // add user modal
  const renderAddUserModal = () => {
    return (

      <Modal className="formModal addModal"
        aria-labelledby="contained-modal-title-vcenter"
        show={addUserModal}
        onHide={() => {
          setAddUserModal(false);
          setFirstName("");
          setLastName("");
          setMobileNumber("");
          setRole("Set Role");
          setAccount("Set Account")
          setEmail("");
          setPassword("");
          setValidPassword(true)
          setValidNumber(true)
          setValidMail(true)
          setValidLastName(true)
          setValidFirstName(true)
        }}
      >
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className="modalTitle">Add User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='formContainer'>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label className="required">First Name</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input className="textfield" placeholder="Enter First Name" onChange={(event) => setFirstName(event.target.value)} />
                {validFirstName ? null : <label>Invalid First Name</label>}
              </div>
            </div>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label className="required">Last Name</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input className="textfield" placeholder="Enter Last Name" onChange={(event) => setLastName(event.target.value)} />
                {validLastName ? null : <label>Invalid Last Name</label>}
              </div>
            </div>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label className="required">Mobile No.</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input className="textfield" placeholder="Enter Mobile No." onChange={(event) => setMobileNumber(event.target.value)} />
                {validNumber ? null : <label>Invalid Mobile No.</label>}
              </div>
            </div>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label className="required">Email Id</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input className="textfield" placeholder="Enter Email Id" onChange={(event) => setEmail(event.target.value)} />
                {validMail ? null : <label>Invalid Email Id</label>}
              </div>
            </div>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label className="required">Role</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <Dropdown>
                  <Dropdown.Toggle variant="Warning" id="dropdown-basic" className='role-btn'>
                    {role}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropMenug">
                    {usersRolesList?.map((value: any) => {
                      return (
                        <Dropdown.Item className="clickItem" onClick={() => setRole(`${value}`)} >{value}</Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label className="required">Account</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <Dropdown>
                  <Dropdown.Toggle variant="Warning" id="dropdown-basic" className='role-btn'>
                    {account}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropMenug">
                    {accountList?.map((value: any) => {
                      return (
                        <Dropdown.Item className="clickItem" onClick={() => {setAccount(`${value?.id}`); formikAddAccount.setFieldValue("accountId", value?.id)}} >{value?.accountName}</Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label className="required">Create Password</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input className="textfield" placeholder="Enter Password" onChange={(event) => setPassword(event.target.value)} />
                {validPassword ? null : <label className='pwd-label'>Password should contain 8 characters with at least 1 upper case, 1 lower case, 1 special character and 1 numeric character</label>}
              </div>
            </div>
            <div className='row align-items-center formItem'>
              <div className='col-4'></div>
              <div className='col-8'>
                {((role !== "Set Role") && (account !== "Set Account") && firstName && lastName && mobileNumber && email && password) ?
                  <button className="FilledBtn" onClick={() => onTapSubmitBtn()}>Add</button> :
                  <button className="FilledBtn disabled-btn" disabled>Add</button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  // edit user modal
  const renderEditUserDetailsModal = () => {
    return (
      <Modal className="formModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={editUserModalShow}
        onHide={() => {
          setEditUserModalShow(false);
          setFirstName("");
          setLastName("");
          setMobileNumber("");
          setEmail("");
          setValidPassword(true)
          setValidNumber(true)
          setValidMail(true)
          setValidLastName(true)
          setValidFirstName(true)
        }}
      >
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className="modalTitle">
            Edit User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='formContainer'>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label>First Name</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input value={firstName} className="textfield" placeholder="Enter First Name" onChange={(event) => setFirstName(event.target.value)} />
                {validFirstName ? null : <label>Invalid First Name</label>}
              </div>
            </div>

            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label>Last Name</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input value={lastName} className="textfield" placeholder="Enter Last Name" onChange={(event) => setLastName(event.target.value)} />
                {validLastName ? null : <label>Invalid Last Name</label>}
              </div>
            </div>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label>Mobile No.</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input value={mobileNumber} className="textfield" placeholder="Enter Mobile No." onChange={(event) => setMobileNumber(event.target.value)} />
                {validNumber ? null : <label>Invalid Mobile No.</label>}
              </div>
            </div>
            <div className='row align-items-center formItem'>
              <div className='col-sm-12 col-md-4 col-lg-4'>
                <label>Email Id</label>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-8'>
                <input disabled value={email} className="textfield" placeholder="Enter Email Id" onChange={(event) => setEmail(event.target.value)} />
                {validMail ? null : <label>Invalid Email Id</label>}
              </div>
            </div>
            <div className='row align-items-center formItem'>
              <div className='col-4'></div>
              <div className='col-8'>
                <button className="FilledBtn" onClick={() => onTapSaveEditUserDetailsBtn()} >Save</button>
              </div>
            </div>
          </div>
        </Modal.Body >
      </Modal >
    )
  };

  // remove user modal
  const renderRemoveUserModal = () => {
    return (
      <Modal className="formModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={removeUserModalShow}
        onHide={() => {
          setRemoveUserModalShow(false);
        }}
      >
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className="modalTitle">
            Remove User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='formContainer text-center'>
            <p className="fieldTitle text-start">Are you sure you want to remove user: {firstName} {lastName}</p>
            <button className="FilledBtn" onClick={() => tapRemoveUserBtn()}>Remove</button>
          </div>
        </Modal.Body>

      </Modal>
    )
  };

  const onTapSubmitBtn = async () => {
    if (isValidForm()) {
      // if ((role !== "Set Role") && firstName && lastName && mobileNumber && email && password) {
      let details = {
        "firstName": firstName.trim(),
        "lastName": lastName.trim(),
        "mobileNumber": mobileNumber,
        "role": role,
        "email": email,
        "password": password,
        "accountId": account
      };
      const res = await createUser(details)
      if (res?.data?.status == 200) {
        getUserListCalled("", 0, 1000);
        setErrorModalShow(true);
        setErrorMsg("User Added SuccessFully");
      }
      setAddUserModal(false);
      setFirstName("");
      setLastName("");
      setMobileNumber("");
      setRole("Set Role");
      setEmail("");
      setPassword("");
      setValidPassword(true)
      setValidNumber(true)
      setValidMail(true)
      setValidLastName(true)
      setValidFirstName(true)
      // }
    }
  };

  const onTapEditUserBtn = (data: any) => {
    setEditUserModalShow(true);
    setFirstName(data?.firstName);
    setLastName(data?.lastName);
    setMobileNumber(data?.mobileNumber);
    setEmail(data?.email);
    setUserId(data?.id);
  };

  const onTapSaveEditUserDetailsBtn = async () => {
    if (isValidEditForm()) {
      // if (email && firstName && lastName && mobileNumber) {
      let details = {
        "email": email,
        "firstName": firstName.trim(),
        "lastName": lastName.trim(),
        "mobileNumber": mobileNumber
      }
      const resp = await editUser(details, userId);
      if (resp?.data?.status == 200) {
        getUserListCalled("", 0, 1000);
        setErrorModalShow(true);
        setErrorMsg("User Details Edited Successfully");
      }

      setEditUserModalShow(false);
      setFirstName("");
      setLastName("");
      setMobileNumber("");
      setEmail("");
      setValidPassword(true)
      setValidNumber(true)
      setValidMail(true)
      setValidLastName(true)
      setValidFirstName(true)
      // getUserList()
      // }
    }
  };

  const onTapRemoveUserBtn = (data: any) => {
    setRemoveUserModalShow(true);
    setFirstName(data?.firstName);
    setLastName(data?.lastName);
    setUserId(data?.id);
  };

  const tapRemoveUserBtn = async () => {
    const resp = await deleteUser(userId);
    if (resp?.data?.status == 200) {
      getUserListCalled("", 0, 1000);
    }
    setRemoveUserModalShow(false);
    // getUserList();
  };
  const handleKeypress2 = (e: any) => {
    if (e.key === "Enter") {
      onTapSearch();
    }
  };
  const onTapSearch = () => {
    getUserListCalled(apiPrefix, 0, 1000)
  };
  const onTapReset = () => {
    setApiPrefix("");
    getUserListCalled("", 0, 1000)
  };
  return (
    <>
      {renderAddAccountModal()}
      {renderAddUserModal()}
      {renderEditUserDetailsModal()}
      {renderRemoveUserModal()}
      {errorHandle()}
      <Header />
      <Box className="contentMain"
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <div className='warhouseStart'>
          {isLoading && <div className="loader">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>}
          <div className="adminDashboard row">
            <div className="paddingG">
              <div className="warehouseL">
                <div className="d-flex align-items-start">
                  <div className="headingD">
                    <h5 className="pageHead">User List</h5>
                    <p className="mb-0">{userCount} Users</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="search-input serchContainer d-inline-flex">
                      <Search className='searchIcon' />
                      <input type="search" placeholder="Search" value={apiPrefix}
                        onKeyPress={(event) => handleKeypress2(event)}
                        onChange={(event) => setApiPrefix(event.target.value)} />
                      <img src={Close} className="pointer" onClick={() => onTapReset()} />
                    </div>
                  </div>
                </div>
                <div>
                  <button className="FilledBtn me-3" onClick={() => onTapAddAccount()}>Add Account</button>
                  <button className="FilledBtn" onClick={() => onTapAddUser()}>Add User</button>
                </div>
              </div>
              <div className="warhouseS">
                {userList ? userList?.map((data: any) => {
                  return (
                    <div
                      className="warehousedetail userDetails"
                      key={data.id}
                      onClick={() => { onTapEditUserBtn(data); }}
                    >
                      <div className="d-flex align-items-center justify-content-between text-center">
                        <div className='d-flex align-items-center w-25'>
                          <img src={User} className="add-user-icon" />
                          <h6 className="firstN">{data.firstName + " " + data.lastName}</h6>
                        </div>
                        <div className='w-25'>
                          <label className='user-role'>{data.role}</label>
                        </div>
                        <label className='user-email w-25'>{data.email}</label>
                        <div className="edit-dlt-btns text-end">
                          <img src={Delete} onClick={(event) => { onTapRemoveUserBtn(data); event.stopPropagation(); }} className="icon deleteIcon" />
                        </div>
                      </div>

                    </div>
                  );
                }) : null}

                {/* <Pagination>
              <Pagination.Prev />
              <Pagination.Item>{pageCountForWarehousList}</Pagination.Item>
              <Pagination.Item>{pageCountForWarehousList + 1}</Pagination.Item>
              <Pagination.Item active>{pageCountForWarehousList + 2}</Pagination.Item>
              <Pagination.Next onClick={() => pageCountForWarehousList + 3} />
            </Pagination> */}

              </div>
            </div>
          </div>

        </div>
      </Box>
    </>
  )
}

export default UserManagement;