import './ErrorScreen.scss';
import Error from "../../assets/error.svg";
import Header from "../../components/header/Header";
import { useNavigate } from "react-router-dom"

const ErrorScreen = () => {
  let navigate = useNavigate()
  const role = localStorage.getItem("role")
  const onTapBackToDashboard = () => {
    if (role === "Admin") {
      navigate("/dashbaord")
    } else if (role === "User") {
      navigate("/userdashbaord")
    }
  }
  return (

    <div className='container'>
      <Header />
      <div className='error-img text-center'>
        <img className="error" src={Error} />
      </div>
      <div className='error-text'>
        {/* <label>404 Error</label> */}
        <label>Page not found</label>

        {/* <button onClick={() => onTapBackToDashboard()} className="back-btn">Back to Dashboard</button> */}
      </div>
    </div>

  )
}


export default ErrorScreen;