import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Pagination,
  Stack,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { ReactComponent as Filter } from "../../../assets/images/icons/filter.svg";
import { ReactComponent as Reject } from "../../../assets/images/icons/reject.svg";
import { ReactComponent as Accept } from "../../../assets/images/icons/accept.svg";
import { ReactComponent as Calendar } from "../../../assets/images/icons/calendar.svg";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./index.module.scss";
import { useAuth } from "../../../shared/provider/authprovider";
import { useEffect, useState } from "react";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import Loader from "../../../loader";
import { style } from "../../listingScreens/superAdminDashboard/index";

const UserReqestManagement = () => {
  const { isLoading } = useAuth();
  const {
    getListingUserList,
    putAcceptUser,
    putRejectUser,
    getUser,
    userData,
    setMessageText,
    setOpen,
  } = useClientAPI();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(200);
  const [page, setPage] = useState(1);
  const [prefix, setPrefix] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [show, setShow] = useState(false);
  const [newStartDate, setNewStartDate] = useState<any>(new Date());
  const [newEndDate, setNewEndDate] = useState<any>(new Date());
  const [listingRequestCount, setListingRequestCount] = useState<any>([]);
  const [listingRequestList, setListingRequestList] = useState<any>([]);
  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setOffset((value - 1) * limit);
    const resultUser = await getListingUserList(
      "",
      (value - 1) * limit,
      limit,
      true,
      0
    );
    setListingRequestCount(resultUser?.data?.result?.count);
    setListingRequestList(resultUser?.data?.result);
    setPage(value);
  };
  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      onTapSearch();
    }
  };
  const onTapSearch = async () => {
    const resultUser = await getListingUserList(prefix, offset, limit, true, 0);
    setListingRequestCount(resultUser?.data?.result?.count);
    setListingRequestList(resultUser?.data?.result);
    setOffset(0);
    setLimit(50);
    setPage(1);
  };
  const putAcceptUserRequest = async (id: any) => {
    const response = await putAcceptUser([id]);
    if (response?.status == 200) {
      setShow(false);
      setMessageText("User Request Approved");
      setOpen(true);
      setTimeout(function () {
        setOpen(false);
      }, 5000);
    }
    const resultUser = await getListingUserList("", offset, limit, true, 0);
    setListingRequestCount(resultUser?.data?.result?.count);
    setListingRequestList(resultUser?.data?.result);
  };
  const putRejectUserRequest = async (id: any) => {
    await putRejectUser([id]);
    const resultUser = await getListingUserList("", offset, limit, true, 0);
    setListingRequestCount(resultUser?.data?.result?.count);
    setListingRequestList(resultUser?.data?.result);
  };
  const handleStartDate = (value: any) => {
    console.log("SDFG");
  };
  const handleEndDate = (value: any) => {
    console.log("SDFG");
  };
  const loadInitialData = async () => {
    const resultUser = await getListingUserList("", offset, limit, true, 0);
    setListingRequestCount(resultUser?.data?.result?.count);
    setListingRequestList(resultUser?.data?.result);
  };
  useEffect(() => {
    loadInitialData();
  }, []);
  return (
    <>
      <div className="startMain">
        <div className="d-flex align-items-center">
          <div className={`${styles.searchInput}`}>
            <Stack direction="row" alignItems="center">
              <Search className={styles.searchIcon} />
              <input
                type="search"
                placeholder="Search"
                value={prefix}
                onKeyPress={(event) => handleKeypress(event)}
                onChange={(event) => setPrefix(event.target.value)}
                style={{ width: "100%" }}
              />
            </Stack>
          </div>

          {/* {/ new code /} */}
          <Stack
            alignItems="center"
            sx={{
              ...style.borderStack,
              flexDirection: "row",
              ml: 2,
              px: "10px",
            }}
          >
            <Filter style={{ marginRight: "10px" }} />
            <span className={styles.numberWithBg}>4</span>
          </Stack>
          <Stack
            justifyContent="center"
            className={styles.datepickerWrapper}
            sx={{ ...style.borderStack, ml: 2, px: "10px" }}
          >
            <div className=" from d-flex align-items-center">
              {/* <div
                      className="me-2 pointer"
                      onClick={() => getPrevMonthData()}
                    ></div> */}
              <div className="d-flex align-items-center">
                <Calendar className={styles.calendarIcon} />
                <DatePicker
                  className={styles.dateUi}
                  selected={new Date(newStartDate)}
                  onChange={(selectedstart) => handleStartDate(selectedstart)}
                  dateFormat="MM-dd-yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                />
                <span>-</span>
                <DatePicker
                  className={styles.dateUi}
                  selected={new Date(newEndDate)}
                  onChange={(selectedstart) => handleEndDate(selectedstart)}
                  dateFormat="MM-dd-yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                />
              </div>
            </div>
          </Stack>
        </div>
        <div className="">
          <div className="d-flex paginationSect align-items-center justify-content-between mt-0">
            <Stack spacing={2} className="d-flex flex-row align-items-center">
              <label className="formLabel">
                {listingRequestCount > 0 ? offset + 1 : 0} -{" "}
                {listingRequestCount > offset + limit
                  ? offset + limit
                  : listingRequestCount}{" "}
                of {listingRequestCount}
              </label>
              <Pagination
                className="m-0 paginatnNav"
                size="small"
                shape="rounded"
                count={Math.ceil(listingRequestCount / limit)}
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </div>
        </div>
        {/* new code end*/}
      </div>
      <div className={styles.heightG}>
        <table className={styles.tableH}>
          <thead className={`${styles.thead} rounded`}>
            <tr>
              <td>Sr. No.</td>
              <td>Name</td>
              <td>Email</td>
              <td>Company</td>
              <td>Phone</td>
              <td>Date</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {!isLoading ? (
              <>
                {
                  // listingUserCount > 0 ?
                  listingRequestList &&
                  listingRequestList?.items?.map((value: any) => {
                    return (
                      <tr>
                        <td>
                          {listingRequestList?.items?.findIndex(
                            (x: any) => x.id === value?.id
                          ) + 1}
                        </td>
                        <td>{value?.firstName + " " + value?.lastName}</td>
                        <td>{value?.email}</td>
                        <td>{value?.company}</td>
                        <td>{value?.mobileNumber}</td>
                        <td>{value?.createdTime.slice(0, 10)}</td>
                        <td>
                          <button
                            onClick={async () => {
                              await getUser(value?.id);
                              setModalTitle("Accept");
                              setShow(true);
                            }}
                            className={styles.tBtn}
                          >
                            <Accept />
                            Accept
                          </button>
                          <button
                            onClick={async () => {
                              await getUser(value?.id);
                              setModalTitle("Reject");
                              setShow(true);
                            }}
                            className={styles.tBtn}
                          >
                            <Reject />
                            Reject
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  // :
                  // <tr>
                  //     <td colSpan={7}>
                  //         <p className='mb-0 py-2'>No records found</p>
                  //     </td>
                  // </tr>
                }
              </>
            ) : (
              <Loader />
            )}
          </tbody>
          <Dialog
            open={show}
            onClose={() => setShow(false)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={style.dialogContainer}
          >
            <DialogTitle sx={{ m: 0, p: 2.5, fontSize: 18, fontWeight: 300 }}>
              {/* {children} */}
              {/* {onClose ? ( */}
              <Typography variant="body2" fontWeight={300} fontSize={14}>
                {modalTitle}{" "}
                <span style={{ fontWeight: "500" }}>
                  {userData?.firstName}{" "}
                </span>
                Registartion Request
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => setShow(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              {/* ) : null} */}
            </DialogTitle>

            <DialogContent sx={{ p: 2.5 }}>
              <Box sx={{ borderRadius: 0.5, background: "#F5F5F5", p: 1.5 }}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel>Email Address</InputLabel>
                    <TextField
                      id="standard-read-only-input"
                      defaultValue={userData?.email}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                      fullWidth
                      sx={{ fontSize: 16 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel>Organisation Name</InputLabel>
                    <TextField
                      id="standard-read-only-input"
                      defaultValue={userData?.company}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                      fullWidth
                      sx={{ fontSize: 16 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <InputLabel>Comments</InputLabel>
                      <TextField
                        id="standard-required"
                        multiline
                        placeholder="State reason for rejecting users request..."
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        rows={2}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "center",
                pb: 3,
                "&>:not(:first-of-type)": { ml: 2.5 },
              }}
            >
              <Button
                onClick={() => {
                  putAcceptUserRequest(userData?.id);
                }}
                sx={{
                  ...style.modalBtn,
                  background:
                    "linear-gradient(144deg, #0038BA 0%, #4F18FF 100%)",
                  color: "#fff",
                }}
              >
                Approve
              </Button>
              <Button
                sx={{
                  ...style.modalBtn,
                  ml: 2,
                  border: "1px solid rgba(104, 115, 125, 0.48)",
                  color: "#68737D",
                }}
                onClick={() => {
                  putRejectUserRequest(userData?.id);
                }}
              >
                Reject
              </Button>
            </DialogActions>
          </Dialog>
        </table>
      </div>
    </>
  );
};
export default UserReqestManagement;
