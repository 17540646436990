import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Stack from 'react-bootstrap/Stack';
import { useNavigate, useParams } from 'react-router-dom';
import Close from "../../../src/assets/icons/close.svg";
import { ReactComponent as Search } from "../../../src/assets/icons/searchStatus.svg";
import { ReactComponent as SortIcon } from "../../../src/assets/icons/sorting/sorting.svg";
import Header from "../../components/header/Header";
import Loader from "../../loader";
import { useAuth } from '../../shared/provider/authprovider';

const ProductByLocation = () => {
    let navigate = useNavigate();
    const { value } = useParams();
    const { getManifestMissingUPCCalled, uPCbyLocation, setSelectedIndex, isLoading, uPCbyLocationCount } = useAuth();
    const [order, setOrder] = useState("DSC");
    const onTapLocationForm = (upc: any) => {
        navigate(`/update-product/${upc}`)
    }
    const sortASC = () => {
        setOrder("ASC")
        getManifestMissingUPCCalled(value, 0, 50, "ASC")
    }
    const sortDSC = () => {
        setOrder("DSC")
        getManifestMissingUPCCalled(value, 0, 50, "DSC")

    }
    useEffect(() => {
        getManifestMissingUPCCalled(value, 0, 50, order)
        setSelectedIndex(22)
    }, [])
    return (
        <>
            <Header />
            <Box
                className="contentMain updateProdWrapper"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>

                <div className="warehouseL d-flex">
                    <div className="d-flex align-items-start headerM">
                        <div className="headingD">
                            <h5 className="pageHead">UPC s for Location {value}</h5>
                            <p className="mb-0">{uPCbyLocationCount > 1 ? <div>{uPCbyLocationCount} missing products</div> : <div>{uPCbyLocationCount} missing product</div>}</p>
                        </div>
                        {/* <div className="search-input serchContainer d-inline-flex">
                            <Search className='searchIcon' />
                            <input type="search" placeholder="Search" />
                            <img src={Close} className="pointer" />
                        </div> */}
                    </div>
                    <div className="sortContainer pointer">
                       <div className="d-flex flex-column sortD">
                            <SortIcon onClick={() => sortASC()} className={order == "ASC" ? "sort activeSort" : "sort"}/>
                            <SortIcon onClick={() => sortDSC()} className={order == "DSC" ? "sort activeSort" : "sort"} />
                        </div>
                        <span>Sort</span>
                    </div>
                </div>
                <div className="ProdByL">
                    <Stack direction="horizontal" gap={4}>
                        {!isLoading ?
                            uPCbyLocation?.map((e: any) => (
                                <div className="ProdItem">
                                    <p className=" mb-0 text-start">UPC#{uPCbyLocation.findIndex((x: any) => x === e) + 1}</p>
                                    <button className="FilledBtn"
                                        onClick={() => onTapLocationForm(e)} >
                                        {e}
                                    </button>
                                </div>
                            ))
                            :
                            <Loader />
                        }
                    </Stack>
                </div>

            </Box>
        </>
    )
}
export default ProductByLocation;