import axios, { AxiosPromise } from "axios";
import { environmentUrl } from "./index.service";
import { useAuth } from "../provider/authprovider";
import { useState } from "react";

// export const putTaxonomyNotFoundRecordsUpdate = (token: any, values: any): AxiosPromise<any> => {
//   return axios.put(
//     environmentUrl + `/api/Taxonomoy/AddTaxonomyReference`, values, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   }
//   );
// };
export const useTaxonomyNotFoundAPI = () => {
  const { setLoading, api, handleError, setErrorModalShow, setErrorMsg } = useAuth()
  const [taxonomyNotFoundList, setTaxonomyNotFoundList] = useState([])
  const [taxonomyMissingProductsCount, setTaxonomyMissingProductsCount] = useState<any | null>(null)
  const [taxonomyNFDropdown, setTaxonomyNFDropdown] = useState<any | null>(null)

  const exportTaxonomyNotFound = async (prefix: any) => {
    try {
      const result = await api.get(`/api/Taxonomoy/NotFound/Export?prefix=${prefix}`, {
        responseType: "arraybuffer"
      });
      return result
    } catch (error: any) {
      handleError(error)
    }
  };
  const getTaxonomyNotFoundRecords = async (offset: any, limit: any, prefix: any) => {
    setLoading(true)
    try {
      const result = await api.get(`/api/Taxonomoy/NotFound?Offset=${offset}&limit=${limit}&prefix=${prefix}`);
      setTaxonomyNotFoundList(result?.data?.result)
      setTaxonomyMissingProductsCount(result?.data?.count)
      return result
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  };
  const getTaxonomyNotFoundRecordsDropdown = async (offset: any, limit: any) => {
    try {
      const result = await api.get(`/api/Taxonomy?Offset=${offset}&Limit=${limit}`);
      setTaxonomyNFDropdown(result?.data?.result)
      return result
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  };
  const putTaxonomyNotFoundRecordsUpdate = async (values: any) => {
    try {
      const result = await api.put(`/api/Taxonomoy/AddTaxonomyReference`, values);
      if (result?.data?.status == 200) {
        setErrorModalShow(true)
        setErrorMsg(values?.length > 1 ? `${values?.length} Products Updated Successfully` : `${values?.length} Product Updated Successfully`)
      }
      return result
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoading(false)
    }
  };
  return {
    exportTaxonomyNotFound, getTaxonomyNotFoundRecords, taxonomyNotFoundList, taxonomyMissingProductsCount,
    getTaxonomyNotFoundRecordsDropdown, taxonomyNFDropdown, putTaxonomyNotFoundRecordsUpdate
  } as const
}
