import axios, { AxiosPromise } from "axios";
import { environmentUrl } from "./index.service";
import { useAuth } from "../provider/authprovider";
import { useState } from "react";

export const getReceivingOrderNumber = (token: any, code: any): AxiosPromise<any> => {
    let url = `${environmentUrl}/api/ReceivingApplication/ValidateReceiptOrderId?code=${code}`;
    const guidedManifestMissingProduct = axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return guidedManifestMissingProduct
};
// export const getValidatScanUPC = (token: any, upc: any): AxiosPromise<any> => {
//     let url = `${environmentUrl}/api/ReceivingApplication/ScanItem?upc=${upc}`;
//     const guidedManifestMissingProduct = axios.get(url, {
//         headers: {
//             Authorization: `Bearer ${token}`,
//         }
//     });
//     return guidedManifestMissingProduct
// };

export const putReceivingSentToStoreOrTrash = (token: any, RONumber: any, upc: any, status: any, brand: any): AxiosPromise<any> => {
    let url =
        environmentUrl + `/api/ReceivingApplication/SentToStoreOrTrash?ReceiptOrderId=${RONumber}&Upc=${upc}&status=${status}&ProductCategory=${brand}`;
    const quit = axios.put(url, {}, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return quit;
};
export const getProductCategoryList = (token: any, offset: any, limit: any): AxiosPromise<any> => {
    let url = `${environmentUrl}/api/ProductCategory?Offset=${offset}&Limit=${limit}`;
    const manifestMissingProduct = axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return manifestMissingProduct;
};
//ListableLocation
export const postListableLocation = (token: any, details: any): AxiosPromise<any> => {
    let url = environmentUrl + `/api/ReceivingApplication/ListableLocation`;
    const getExportListing = axios.post(url, details, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return getExportListing;
};
export const useRecAppApi = () => {
    const { setLoading, api, handleError, setErrorModalShow, setErrorMsg } = useAuth();


    const getValidatScanUPCCalled = async (upc: any, RO: any) => {
        setLoading(true)
        try {
            const result = await api.get(`/api/ReceivingApplication/ScanItem?upc=${upc}&ro=${RO}`);
            localStorage.setItem("UPCMessage", result?.data?.result)
            // setScanResponseProduct(result?.data?.result?.items)
            return result?.data?.result
        } catch (error: any) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.href = '/';
            } else {
                setErrorMsg(error?.response?.data?.results);
                setErrorModalShow(true);
            }
        } finally {
            setLoading(false)
        }
    };
    return {
        getValidatScanUPCCalled,
    } as const
}