import { useState } from "react";
import { useAuth } from "../provider/authprovider";

export const useConnectionShippingEasyAPI = () => {
  const { api, handleError, setErrorModalShow, setErrorMsg } = useAuth()
  const [shippingEasyId, setShippingEasyById] = useState<any | null>(null)
  const [shippingEasy, setShippingEasy] = useState<any | null>(null)
  const [shippingEasyCount, setShippingEasyCount] = useState<any | null>(null)
  const getShippingEasyById = async (id: any) => {
    try {
      const result = await api.get(`/api/MasterDataContrroller/ShippingEasyToken/${id}`);
      setShippingEasyById(result?.data?.results)
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  const putShippingEasyById = async (id: any, values: any) => {
    try {
      const result = await api.put(`/api/MasterDataContrroller/ShippingEasyToken/${id}`, values);
      if (result?.data?.status == 200) {
        setErrorModalShow(true);
        setErrorMsg("Shipping Easy Edited Successfully")
      }
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  const postShippingEasy = async (values: any) => {
    try {
      const result = await api.post(`/api/MasterDataContrroller/ShippingEasyToken`, values);
      if (result?.data?.status == 200) {
        setErrorModalShow(true);
        setErrorMsg("Shipp ing Easy Added Successfully");
      }
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  const getAllShippingEasy = async (offset: any, limit: any) => {
    try {
      const result = await api.get(`/api/MasterDataContrroller/AllShippingEasyToken?Offset=${offset}&Limit=${limit}`);
      setShippingEasy(result?.data?.results)
      setShippingEasyCount(result?.data?.count)
      return result
    } catch (error: any) {
      handleError(error)
    };
  };
  return {
    getShippingEasyById, putShippingEasyById, postShippingEasy, getAllShippingEasy, shippingEasy,
    shippingEasyCount, shippingEasyId
  } as const
}