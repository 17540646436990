import {
  Box,
  Button,
  FilledInput,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  OutlinedInput,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import * as Yup from "yup";
import { ReactComponent as VisibilityOff } from "../../../assets/images/icons/eye-slash.svg";
import { ReactComponent as Visibility } from "../../../assets/images/icons/open-eye.svg";
import styles from "./index.module.scss";
import ellipse from "../../../assets/images/ellipse.jpg";
import { ReactComponent as Key } from "../../../assets/images/icons/key-icon.svg";
import { Field, FormikProvider, useFormik } from "formik";
import { style } from "../RegistrationScreen/index";
import { ReactComponent as ArrowTip } from "../../../assets/images/icons/arrow-tip.svg";
import { ReactComponent as HelpIcon } from "../../../assets/images/icons/help-icon.svg";
import { useClientAPI } from "../../../shared/api/userManagementSAAS";
import { useSearchParams } from "react-router-dom";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const SetNewPasswordScreen = () => {
  const [searchParams] = useSearchParams();

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
    passwordConfirmation: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const [showPassword, setShowPassword] = useState<any>(false);
  const [showPasswordConfPass, setShowPasswordConfPass] = useState<any>(false);
  const handleClickShowPassword = () => setShowPassword((show: any) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClickShowPasswordConfPass = () =>
    setShowPasswordConfPass((show: any) => !show);

  const handleMouseDownPasswordConfPass = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const formikResetPass: any = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: () => submitDataResetPass(),
  });
  const submitDataResetPass = async () => {
    console.log(formikResetPass?.values);
    let details = {
      token: searchParams.get("token"),
      email: searchParams.get("email"),
      newPassword: formikResetPass?.values?.passwordConfirmation,
    };
    await resetPasswordListing(details);
  };
  const { resetPasswordListing, open, messageText, setOpen } = useClientAPI();
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      submitDataResetPass();
    }
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={messageText}
        action={action}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={`${styles.formWrapper}`}
      >
        <Stack
          justifyContent="center"
          sx={{ background: "#fff" }}
          className={`${styles.formContainer} ${styles.setpwdformContainer}`}
        >
          <Stack className={`${styles.formBgImg} ${styles.setpwdImgStack}`}>
            <img
              src={ellipse}
              alt="ellipse"
              className={`${styles.responsiveImg} `}
            />
          </Stack>
          <Stack sx={{ px: 6, py: 3, position: "relative" }}>
            <Stack flexDirection="row" justifyContent="center">
              <Key className={styles.keyIcon} />
            </Stack>
            <Stack mt={3} mb={1} alignItems="center">
              <Typography variant="h4" fontSize={20} fontWeight={600}>
                Set new password
              </Typography>
              <Typography variant="body2" fontSize={14} fontWeight={300}>
                Your new password must be different to <br />
                previously used password.
              </Typography>
            </Stack>
            <FormikProvider value={formikResetPass}>
              <Field name="password">
                {({ field, meta }: any) => (
                  <TextField
                    {...field}
                    type={showPassword ? "text" : "password"}
                    autoComplete="off"
                    required
                    fullWidth
                    label="New Password"
                    id="filled-basic"
                    variant="filled"
                    sx={style.textField}
                    {...{ error: meta.touched && meta.error }}
                    helperText={meta.touched && meta.error && meta.error}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            sx={{
                              p: 0,
                              mr: 1,
                              "&:hover": { backgroundColor: "unset" },
                            }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                          <Box
                            sx={{ position: "relative" }}
                            className={styles.helptextContainer}
                          >
                            <IconButton sx={{ p: 0 }}>
                              <HelpIcon className={styles.helpIcon} />
                            </IconButton>
                            <Stack
                              alignItems="center"
                              flexDirection="row"
                              className={styles.helpBox}
                              sx={{ ...style.helpBox }}
                            >
                              <ArrowTip />
                              <Typography
                                variant="body2"
                                sx={{ ...style.helpText }}
                              >
                                Good passwords are hard to guess. Use uncommon
                                words or inside jokes, non-standard uppercasing,
                                creative spelling, and non-obvious numbers and
                                symbols.
                              </Typography>
                            </Stack>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Field>
              <Field name="passwordConfirmation">
                {({ field, meta }: any) => (
                  <TextField
                    label="Confirm New Password"
                    id="filled-basic"
                    variant="filled"
                    sx={style.textField}
                    {...field}
                    type={showPasswordConfPass ? "text" : "password"}
                    autoComplete="off"
                    required
                    fullWidth
                    {...{ error: meta.touched && meta.error }}
                    helperText={meta.touched && meta.error && meta.error}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordConfPass}
                            onMouseDown={handleMouseDownPasswordConfPass}
                            edge="end"
                            sx={{
                              p: 0,
                              mr: 3,
                              "&:hover": { backgroundColor: "unset" },
                            }}
                          >
                            {showPasswordConfPass ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(event) => handleKeypress(event)}
                  />
                )}
              </Field>
              <Button
                variant="contained"
                className={styles.filledBtn}
                onClick={() => formikResetPass.handleSubmit()}
                sx={{
                  my: 2,
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", backgroundColor: "#004EFF" },
                }}
              >
                Change Password
              </Button>
            </FormikProvider>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
export default SetNewPasswordScreen;
