import Header from "../../components/header/Header";
import { Box, FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { Field, Form, Formik, } from "formik";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import * as Yup from 'yup';
import { useAuth } from "../../shared/provider/authprovider";
import { Value } from "sass";
import ErrorModal from '../../components/errorModal/ErrorModal';
import Loader from "../../loader";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import './userGuidedProductUpdateScreen.scss';
import { ReactComponent as Quit } from "../../../src/assets/icons/quit.svg";
import { ReactComponent as Update } from "../../../src/assets/icons/update.svg";
import { ReactComponent as Skip } from "../../../src/assets/icons/skip.svg";
import { ReactComponent as NotFound } from "../../../src/assets/icons/not-found.svg";

interface MyFormValues {
    upc: string,
    retailPrice: string,
    title: string,
    imageUrl: string,
    size: string,
    fabric: string,
    color: string,
    isImageCorrect: boolean
}
const DisplayingErrorMessagesSchema = Yup.object().shape({
    retailPrice: Yup.string().matches(/^[0-9 ]*$/, 'Please enter valid price'),
});
const UserGuidedProductUpdateScreen = () => {
    let navigate = useNavigate();
    const [overRide, setOverride] = useState(false)
    const { setSelectedIndex, getGuidedManifestProductDetailsCalled, guidedProductDetails, putGuidedManifestMissingProductCalled,
        putSkipNotFoundGuidedManifestMissingProductCalled, putQuitdManifestMissingProductCalled, errorModalShow, setErrorModalShow,
        isLoading, guidedProductsCount,  scanResponse } = useAuth()
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    const [buttonState, setButtonState] = useState<boolean>(true)

    const statusList = [
        { value: true, label: "Correct" },
        { value: false, label: "Incorrect" },
    ];

    const initialValues: MyFormValues = { title: guidedProductDetails?.title, imageUrl: guidedProductDetails?.imageUrl, fabric: '', size: '', color: '', retailPrice: '', upc: guidedProductDetails?.upc, isImageCorrect: buttonState };
    const onHandleQuit = async () => {
        await putQuitdManifestMissingProductCalled(guidedProductDetails?.id)
        navigate('/userdashboard')
    }
    const [show, setShow] = useState(true);
    const [count, setCount] = useState(0)
    const OverrideModal = () => {
        return (
            <Modal
                show={show}
                onHide={() => setShow(false)}
                className="formModal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className='formContainer text-center'>
                        <p className="fieldTitle text-start">No Unassigned Updates Remaining</p>
                        <button className="FilledBtn btnStyleUnset me-4" onClick={() => { setErrorModalShow(false); navigate('/userdashboard')}}>Exit</button>
                        <button className="FilledBtn btnStyleUnset"
                            onClick={() => {
                                getGuidedManifestProductDetailsCalled(true, count);
                                setShow(false);
                                setOverride(true)
                            }}
                        >Override</button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    const skipProduct = async () => {
        await setCount(overRide == true ? (count + 1) : 0);
        putSkipNotFoundGuidedManifestMissingProductCalled(guidedProductDetails?.id, 13);
        getGuidedManifestProductDetailsCalled(overRide, count + 1)
    }

    useEffect(() => {
        setSelectedIndex(20)
        getGuidedManifestProductDetailsCalled(false, count);
    }, [])

    return (
        <>
            {errorHandle()}
            <Header />
            <Box
                className="contentMain updateProdWrapper guidedProdWrapper"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <div className='startMain'>
                    <div>
                        <h5 className="pageHead mb-0 me-2">Guided Product Update</h5>
                        <p>Remaining: </p>
                    </div>
                    <div>
                        <Button className="FilledBtn btnStyleUnset" onClick={() => { onHandleQuit() }}><Quit /></Button>
                    </div>
                </div>
                {!isLoading && guidedProductsCount <= 0 ?
                    OverrideModal()
                    :
                    null
                }
                {!isLoading ?
                    <div className="row">
                        <div className="col-12">
                            <p>Title : {guidedProductDetails?.title}</p>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="mb-3">
                                <img src={guidedProductDetails?.imageUrl} className="prodImg" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            <div className="userModal">
                                <Formik
                                    initialValues={initialValues}
                                    enableReinitialize={true}
                                    validationSchema={DisplayingErrorMessagesSchema}
                                    onSubmit={(values, actions) => {
                                        actions.setSubmitting(false);
                                        putGuidedManifestMissingProductCalled(values)
                                    }}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className='formContainer'>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="upc">Location : </label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <label>{guidedProductDetails?.location}</label>
                                                        <Field className='textfield d-none' id="faupcbric" name="upc"
                                                        // value={manifestMissingProductDetails?.upc} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="upc">UPC :</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <label>{guidedProductDetails?.upc}</label>
                                                        <Field className='textfield d-none' id="faupcbric" name="upc"
                                                        // value={manifestMissingProductDetails?.upc} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="fabric">Fabric :</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="fabric" name="fabric" placeholder="Enter fabric" />
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="size">Size :</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="size" name="size" placeholder="Enter size" />
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="color">Color :</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="color" name="color" placeholder="Enter color" />
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="retailPrice">Retail Price :</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">$</InputAdornment>
                                                                ),
                                                            }}
                                                            className='textfield' id="retailPrice" name="retailPrice" placeholder="0.00"
                                                        />
                                                        {touched.retailPrice && errors.retailPrice && <div className='errorMsg'>{errors.retailPrice}</div>}
                                                    </div>
                                                </div>
                                                <div className="row align-items-center formItem">
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                                            <label htmlFor="retailPrice">Image :</label>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <div className="radioBtnContainer">
                                                            {/* <Field type="radio" name="picked" value="One" /> */}
                                                            {statusList?.map((item: any) => {
                                                                return (
                                                                    <>
                                                                        <div className="radioBtn">
                                                                            <Field
                                                                                type="radio"
                                                                                name="isImageCorrect"
                                                                                onChange={() => setButtonState(item.value)}
                                                                                value={item.value}
                                                                                checked={buttonState == item.value}
                                                                            />
                                                                            <label htmlFor="correct" >{item.label}</label>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-12 col-lg-12'>
                                                        <div className="formBtns">
                                                            <Button className="FilledBtn" type="submit"><Update className="svgM"/> Update</Button>
                                                            <Button className="FilledBtn" onClick={() =>
                                                                skipProduct()
                                                                // {
                                                                //     putSkipNotFoundGuidedManifestMissingProductCalled(guidedProductDetails?.id, 13);
                                                                //     setCount(overRide == true ? (count+1) : 0);
                                                                //     getGuidedManifestProductDetailsCalled(overRide, count)
                                                                // }
                                                            }><Skip className="svgM"/> Skip</Button>
                                                            <Button className="FilledBtn" onClick={() => { putSkipNotFoundGuidedManifestMissingProductCalled(guidedProductDetails?.id, 12); getGuidedManifestProductDetailsCalled(overRide, count) }}><NotFound className="svgM"/> Not Found</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    :
                    <Loader />
                }

            </Box>
        </>
    )
}
export default UserGuidedProductUpdateScreen;