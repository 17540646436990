
import Loader from "../../loader";
import { useAuth } from "../../shared/provider/authprovider";
import ErrorModal from "../../components/errorModal/ErrorModal";
import { Box, Stack, Typography } from "@mui/material";
import Header from "../../components/header/Header";
import { useRecAppApiQuickLotz } from "../../shared/api/receivingappQuickLots";
import { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../../src/assets/icons/close.svg";
import { ReactComponent as ResetFilter } from "../../../src/assets/icons/filter-reset.svg";
import { ReactComponent as Search } from "../../../src/assets/icons/searchStatus.svg";
import filter from "../../assets/filter.svg";
import { Dropdown } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Calendar } from "../../../src/assets/icons/searchCalendar.svg";
import moment from "moment";
import Pagination from '@mui/material/Pagination';

const ReportsQbis = () => {
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        );
    };

    const { isLoading, errorModalShow, setErrorModalShow, setSelectedIndex
    } = useAuth();
    const [tableRecords, setTableRecords] = useState<any>([])
    const { getQbidsReportCalled, loadingExport, QbidsReportExport } = useRecAppApiQuickLotz()
    const initialData = async () => {
        const result: any = await getQbidsReportCalled(limit, 0, upc, prefix, "received", formatedStartDate, formatedEndDate)
        setTableRecords(result?.data?.result)
        setTableRecordsCount(result?.data?.result?.count)
    }
    useEffect(() => {
        setSelectedIndex(26);
        initialData()
    }, []);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [formatedStartDate, setFormatedStartDate] = useState("");
    const [endDate, setEndDate] = useState(null);
    const [formatedEndDate, setFormatedEndDate] = useState("");
    const [filterName, setFilterName] = useState("")
    const [ststusFilter, setCurrentVal] = useState("received");
    const [upc, setUpc] = useState("")
    const [count, setTableRecordsCount] = useState<any>(0)
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const [prefix, setPrefix] = useState<any>("");
    const handleKeypress = (e: any) => {
        if (e.key === "Enter") {
            onTapSearch();
        }
    };
    const onTapSearch = async () => {
        const result = await getQbidsReportCalled(limit, 0, upc, prefix, "received", formatedStartDate, formatedEndDate)
        setTableRecords(result?.data?.result)
        setTableRecordsCount(result?.data?.result?.count)
        setOffset(0)
        setLimit(50)
        setPage(1)
    };
    const onTapReset = async () => {
        setPage(1)
        setPrefix("");
        const result = await getQbidsReportCalled(limit, 0, upc, "", "received", formatedStartDate, formatedEndDate)
        setTableRecords(result?.data?.result)
        setTableRecordsCount(result?.data?.result?.count)
    };
    const statusList = [
        { value: "received", label: "Received" },
        { value: "pending", label: "Pending" },
    ]
    const onStatusChange = (v: any) => {
        setCurrentVal(v?.value);
        setFilterName(v?.label);
    };
    const applyFilter = async () => {
        const result = await getQbidsReportCalled(limit, 0, upc, prefix, ststusFilter, formatedStartDate, formatedEndDate)
        setTableRecordsCount(result?.data?.result?.count)
        setTableRecords(result?.data?.result)
        setOffset(0)
        setLimit(50)
        setPage(1)
    }
    const resetFilter = async () => {
        setFilterName("Select Status")
        setCurrentVal("received")
        setPrefix("")
        setOffset(0);
        setLimit(50)
        setPage(1)
        const result = await getQbidsReportCalled(limit, 0, upc, "", "received", "", "")
        setTableRecords(result?.data?.result)
        setTableRecordsCount(result?.data?.result?.count)
        setStartDate(null)
        setEndDate(null)
        setFormatedEndDate("")
        setFormatedStartDate("")
    }
    const resetFilterUPC = async () => {
        setUpc("")
        const result = await getQbidsReportCalled(limit, 0, "", "", "received", "", "")
        setTableRecords(result?.data?.result)
        setTableRecordsCount(result?.data?.result?.count)
    }
    const exportToExcel = async () => {
        const res = await QbidsReportExport(upc, prefix, ststusFilter, formatedStartDate, formatedEndDate)
        const url = URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Qbids_Report(${moment(new Date()).format('YYYYMMDD_hmm')}).xlsx`);
        document.body.appendChild(link);
        link.click();
    }
    var newdate2 = new Date()
    newdate2.setDate(newdate2.getDate());
    var dd1: any = newdate2.getDate();
    var mm1: any = newdate2.getMonth() + 1;
    var yyyy1 = newdate2.getFullYear();
    if (dd1 < 10) { dd1 = '0' + dd1 }
    if (mm1 < 10) { mm1 = '0' + mm1 };
    var todayDate: any = (mm1 + '-' + dd1 + '-' + yyyy1);
    const handleStartDate = (value: any) => {
        const result: any = moment(value).format("MM-DD-YYYY")
        setStartDate(value);
        setFormatedStartDate(result);
    }
    const handleEndDate = (value: any) => {
        const result: any = moment(value).format("MM-DD-YYYY")
        setEndDate(value);
        setFormatedEndDate(result);
    }
    const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setOffset((value - 1) * limit);
        const result = await getQbidsReportCalled(limit, (value - 1) * limit, upc, prefix, ststusFilter, formatedStartDate, formatedEndDate)
        setTableRecords(result?.data?.result)
        setTableRecordsCount(result?.data?.result?.count)
        setPage(value);
    };
    return (
        <>
            {errorHandle()}
            {isLoading ? <Loader /> : ""}
            <Header />
            <Box
                className="contentMain ROValidationWrapper"
                component="main"
                sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
            >
                <Stack direction='row' mb={3} alignItems='center'>
                    <div>
                        <h5 className="pageHead mb-2 me-2">UPC/ASIN</h5>
                        <p className="mb-0">UPC/ASIN info</p>
                    </div>
                    <Box sx={{ ml: 5 }} className="search-input serchContainer d-inline-flex">
                        <Search className='searchIcon' />
                        <input type="search" placeholder="Search title" value={prefix}
                            onKeyPress={(event) => handleKeypress(event)}
                            onChange={(event) => setPrefix(event.target.value)} />
                        <Close className="pointer" onClick={() => onTapReset()} />
                    </Box>
                </Stack>
                <Stack className='startMain' alignItems='revert' direction='row'>
                    <div className='d-flex align-items-center'>

                        {/* new code */}

                        <Box mr={2.5} className='d-flex align-items-center filterContainer'>
                            <div className='leftDiv filterItem'>
                                <img src={filter} className="mRight" alt="Filter" />
                                <h5 className='m-0 text'>Filters</h5>
                            </div>
                            <div className='borderDiv filterItem'>

                                <div className='dateInput from d-flex align-items-center'>
                                    <label className='w-100 formLabel'>From:</label>
                                    <div className="d-flex align-items-center outlinedInput">
                                        <Calendar />
                                        <DatePicker
                                            className='dateUi'
                                            placeholderText="MM-dd-yyyy"
                                            dateFormat='MM-dd-yyyy'
                                            selected={startDate}
                                            onChange={(date) => handleStartDate(date)}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode='select'
                                            maxDate={moment(todayDate).toDate()}
                                        />
                                    </div>
                                </div>
                                <div className='dateInput d-flex align-items-center'>
                                    <label className='w-100 formLabel'>To:</label>
                                    <div className="d-flex align-items-center outlinedInput">
                                        <Calendar />
                                        <DatePicker
                                            className='dateUi'
                                            placeholderText="MM-dd-yyyy"
                                            dateFormat='MM-dd-yyyy'
                                            selected={endDate}
                                            onChange={(date) => handleEndDate(date)}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode='select'
                                            maxDate={moment(todayDate).toDate()}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className='borderDiv filterItem dropdownS'>
                                <label className='formLabel'>Status :</label>
                                <Dropdown>
                                    <Dropdown.Toggle variant="Warning" id="dropdown-basic" className="outlinedInput">{filterName === "" ? "Select Status" : filterName}</Dropdown.Toggle>
                                    <Dropdown.Menu className="dropMenug">
                                        {statusList?.map((v: any) => {
                                            return (
                                                <Dropdown.Item className="clickItem" onClick={() => onStatusChange(v)} eventKey={v?.value}>{v?.label}</Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Box>
                        <button onClick={(e) => applyFilter()} className="FilledBtn">
                            Apply
                        </button>
                        <Tooltip TransitionComponent={Zoom} title="Reset" arrow placement="top">
                            <ResetFilter className='ms-3 pointer' onClick={() => resetFilter()} />
                        </Tooltip>
                    </div>
                    <Box>

                        {!loadingExport ?
                            <button onClick={(e) => exportToExcel()} className="FilledBtn">
                                Export
                            </button>
                            :
                            <button onClick={(e) => exportToExcel()} className="FilledBtn" style={{ cursor: "not-allowed", background: "gray" }}>
                                Exporting data...
                            </button>
                        }
                    </Box>
                    {/* new code end*/}
                </Stack>


                <Stack mb={3}>
                    <Typography variant="h5" fontSize={18} fontWeight={400} color='#1A1918' mb={2.5}>Enter UPC/ASIN to generate </Typography>
                    <Box>
                        <Box mr={2} className="search-input serchContainer d-inline-flex">
                            <input placeholder="Enter UPC/ASIN" value={upc}
                                onChange={(event) => setUpc(event.target.value)} />

                        </Box>
                        <button onClick={(e) => applyFilter()} className="FilledBtn ms-auto">
                            Apply
                        </button>
                        <Tooltip TransitionComponent={Zoom} title="Reset" arrow placement="top">
                            <ResetFilter className='ms-3 pointer' onClick={() => resetFilterUPC()} />
                        </Tooltip>
                    </Box>
                </Stack>

                <div className="heightG">
                    <table className="tableH">
                        <thead className="thead rounded">
                            <tr>
                                <th>
                                    <strong style={{ fontWeight: 600 }}>Barcode</strong>
                                </th>
                                <th>
                                    <strong style={{ fontWeight: 600 }}>Scanned By</strong>
                                </th>
                                <th>
                                    <strong style={{ fontWeight: 600 }}>Scanned DateTime</strong>
                                </th>
                                <th>
                                    <strong style={{ fontWeight: 600 }}>Scanned Location</strong>
                                </th>
                                <th>
                                    <strong style={{ fontWeight: 600 }}>Title</strong>
                                </th>
                                <th>
                                    <strong style={{ fontWeight: 600 }}>Description</strong>
                                </th>
                                <th>
                                    <strong style={{ fontWeight: 600 }}>Image</strong>
                                </th>
                                <th>
                                    <strong style={{ fontWeight: 600 }}>MSRP</strong>
                                </th>
                                <th>
                                    <strong style={{ fontWeight: 600 }}>QBids</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="tbody">
                            {count ?? count > 0 ?
                                tableRecords?.items?.map((item: any) => {
                                    return (
                                        <>
                                            <tr >
                                                <td>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: "13px",
                                                            fontWeight: 400,
                                                            mb: 1,
                                                        }}
                                                    >
                                                        {item?.barcode}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: "13px",
                                                            fontWeight: 400,
                                                            mb: 1,
                                                        }}
                                                    >
                                                        {item?.userName}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: "13px",
                                                            fontWeight: 400,
                                                            mb: 1,
                                                        }}
                                                    >
                                                        {item?.createdTime.slice(0, 19)}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: "13px",
                                                            fontWeight: 400,
                                                            mb: 1,
                                                        }}
                                                    >
                                                        {item?.warehouseLocationId}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: "13px",
                                                            fontWeight: 400,
                                                            mb: 1,
                                                        }}
                                                    >
                                                        {item?.name}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: "13px",
                                                            fontWeight: 400,
                                                            mb: 1,
                                                        }}
                                                    >
                                                        {item?.description}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: "13px",
                                                            fontWeight: 400,
                                                            mb: 1,
                                                        }}
                                                    >
                                                        {item?.images?.map((imglink: any) => {
                                                            return <img src={imglink} style={{ width: '100%', maxWidth: '200px' }} />;
                                                        })}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: "13px",
                                                            fontWeight: 400,
                                                            mb: 1,
                                                        }}
                                                    >
                                                        {formatter.format(item?.retailPrice)}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: "13px",
                                                            fontWeight: 400,
                                                            mb: 1,
                                                        }}
                                                    >
                                                        {item?.qBidStatus == "Received" ? "Yes" : "No"}
                                                    </Typography>
                                                </td>

                                            </tr>
                                        </>
                                    );
                                })
                                :
                                <tr>
                                    <td colSpan={7}>
                                        <p className='mb-0 py-2'>No records found</p>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className='d-flex paginationSect align-items-center justify-content-between'>
                    <div className="d-flex paginationSect align-items-center justify-content-between ">
                        <Stack spacing={2} className="d-flex flex-row align-items-center">
                            <label className='formLabel'>
                                {count > 1 ? offset + 1 : 0} to {count > (offset + limit) ? (offset + limit) : count} of {count} entries
                            </label>
                            <Pagination className="m-0 paginatnNav" size="small" shape="rounded" count={Math.ceil(count / limit)} page={page}
                                onChange={handleChange}
                            />
                        </Stack>
                    </div>
                </div>
            </Box>
        </>
    )
}
export default ReportsQbis