import { useEffect, useState } from "react"
import Header from "../../components/header/Header"
import { useAuth } from "../../shared/provider/authprovider";
import { Box, IconButton, InputLabel, MenuItem, Snackbar, SnackbarOrigin, TextField } from "@mui/material";
import { ImCross, ImCheckmark } from 'react-icons/im'
import { Button, Dropdown } from "react-bootstrap";
import Dots from '../../assets/action.svg'
import Modal from 'react-bootstrap/Modal';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ErrorModal from "../../components/errorModal/ErrorModal";
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useBotConfigurationsAPI } from "../../shared/api/botConfigurationTable";
import Switch from '@mui/material/Switch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Close } from "@mui/icons-material";
import Loader from "../../loader";
interface MyFormValues {
    key: string,
    value: string
}
const DisplayingErrorMessagesSchema = Yup.object().shape({
    key: Yup.string().required('Required'),
    value: Yup.string().required('Required'),
});
const DisplayingErrorMessagesSchemaEdit = Yup.object().shape({
    key: Yup.string().required('Required'),
    value: Yup.string().required('Required'),
});
const SettingsScreen = () => {
    const { setSelectedIndex, setErrorMsg, errorModalShow, setErrorModalShow, isLoading } = useAuth()
    const { postNewConfigTableRecord, getConfigRecordById, putConfigRecordById, getConfigTableRecords, configRecordByIdData,
        configTableRecords, snackbarMessage, putConfigRecordDetailsById } = useBotConfigurationsAPI()


    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setOffset((value - 1) * limit);
        await getConfigTableRecords((value - 1) * limit, limit,);
        setPage(value);

    };
    const [editState, setEditState] = useState(false);
    const [addShow, setAddShow] = useState(false);
    const initialValues: MyFormValues = { key: '', value: '' };
    let [Id, setId] = useState<any | null>()

    const showEditModal = async (id: any) => {
        setId(id)
        setAddShow(true)
        await getConfigRecordById(id)
    }
    interface State extends SnackbarOrigin {
        open: boolean;
    }
    const loadInitialData = async () => {
        await getConfigTableRecords(offset, limit);
    }
    const [state, setState] = useState<State>({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;
    const handleClick = (newState: SnackbarOrigin) => {
        setState({ ...newState, open: true });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };
    const setOffBot = async (id: any, key2: any) => {
        await putConfigRecordById(id, { id: id, key: key2, value: "No" })
        await loadInitialData()
        handleClick({ vertical: 'top', horizontal: 'right' })
    }
    const setOnBot = async (id: any, key2: any) => {
        await putConfigRecordById(id, { id: id, key: key2, value: "Yes" })
        await loadInitialData()
        handleClick({ vertical: 'top', horizontal: 'right' })
    }

    useEffect(() => {
        setSelectedIndex(15)
        loadInitialData()
    }, [])
    return (
        <>
            {errorHandle()}
            {isLoading ? <Loader /> : ""}
            <Header />
            <Box
                className="contentMain updateProdWrapper guidedProdWrapper"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    autoHideDuration={8000}
                    onClose={handleClose}
                    message={<><CheckCircleIcon fontSize="small" style={{ color: '#2196f3' }} /> {snackbarMessage}</>}
                    ContentProps={{
                        style: { backgroundColor: '#ffffff', color: '#000000' },
                        action: (
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                            >
                                <Close fontSize="small" style={{ color: 'gray' }} />
                            </IconButton>
                        ),
                    }}

                />

                <div className=''>
                    <div className='startMain d-flex justify-content-between'>
                        <h5 className='w-50 pageHead mb-0'>Configuration Table</h5>
                        <div className='w-50 d-flex justify-content-end'>
                            <button className="FilledBtn" onClick={() => { setAddShow(true); setEditState(false) }}>Add New</button>
                        </div>
                    </div>
                    <div className='heightG'>
                        <table className='tableH'>
                            <thead className='thead rounded'>
                                <tr>
                                    <td>Sr. No.</td>
                                    <td>Name</td>
                                    <td>Value</td>
                                    <td>Actions</td>
                                </tr>
                            </thead>
                            <tbody className='tbody'>
                                {configTableRecords?.map((v: any, index: any) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{v.key}</td>
                                            <td>{v.value}
                                                <Switch
                                                    checked={v.value == "Yes" ||v.value == "yes" ? true : false}
                                                    onChange={() => { v.value == "Yes" || v.value == "yes" ? setOffBot(v.id, v.key) : setOnBot(v.id, v.key) }}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                /></td>
                                            <td>
                                                <Button className="FilledBtn" onClick={() => { showEditModal(v?.id); setEditState(true) }}>Edit</Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <Modal
                            show={addShow}
                            onHide={() => setAddShow(false)}
                            dialogClassName="formModal modalHeight"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton className='border-bottom-0'>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    {editState == true ? "Edit Details" : "Add New Details"}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Formik
                                    initialValues={editState == true ? configRecordByIdData : initialValues}
                                    enableReinitialize={editState == true ? configRecordByIdData : initialValues}
                                    validationSchema={editState == true ? DisplayingErrorMessagesSchemaEdit : DisplayingErrorMessagesSchema}
                                    onSubmit={async (values, actions) => {
                                        actions.setSubmitting(false);
                                        editState == true ? await putConfigRecordDetailsById(Id, values) : await postNewConfigTableRecord(values)
                                        loadInitialData()
                                        setAddShow(false)
                                    }}>
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className='formContainer'>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="key">Name</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="key" name="key" placeholder='Enter Name' />
                                                        {/* {touched.key && errors.key && <div className='errorMsg'>{errors.key}</div>} */}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="value">Value</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="value" name="value" placeholder='Enter Last Name' />
                                                        {touched.value && errors.value && <div className='errorMsg'>{errors.value}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-4'></div>
                                                    <div className='col-8'>
                                                        <Button className="FilledBtn" type="submit">Submit</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                <div className='d-flex paginationSect align-items-center justify-content-between'>
                    <Stack spacing={2} className="d-flex flex-row align-items-center">
                        <label className='formLabel'>Showing {configTableRecords?.length > 0 ? offset + 1 : 0} to {configTableRecords?.length > (offset + limit) ? (offset + limit) : configTableRecords?.length} of {configTableRecords?.length} entries</label>
                        <Pagination className="m-0 paginatnNav" size="small" shape="rounded" count={Math.ceil(configTableRecords?.length / limit)} page={page} onChange={handleChange} />
                    </Stack>
                </div>
            </Box>
        </>
    )
}
export default SettingsScreen