import React, { useEffect, useState } from 'react'
import { useAuth } from "../../shared/provider/authprovider";
import Header from "../../components/header/Header"
import { Button, Dropdown } from "react-bootstrap";
import Dots from '../../assets/action.svg';
import Modal from 'react-bootstrap/Modal';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import './vendor.scss';
import Loader from '../../loader';
import { Box } from '@mui/material';
import ErrorModal from '../../components/errorModal/ErrorModal'
import { useVendorsAPI } from '../../shared/api/vendor';


interface MyFormValues {
    firstName: string,
    lastName: string,
    mobileNumber: string,
    classifications: string,
    email: string,
    password: string,
    // confirmPassword: string
}
interface MyFormValuesEdit {
    firstName: string,
    lastName: string,
    mobileNumber: string,
    classifications: string,
    email: string,
    password: string,
    // confirmPassword: string
}
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const DisplayingErrorMessagesSchema = Yup.object().shape({
    firstName: Yup.string().required('Required').min(3).max(20).matches(/^[A-Za-z ]*$/, 'Please enter valid firstame'),
    lastName: Yup.string().required('Required').min(3).max(20).matches(/^[A-Za-z ]*$/, 'Please enter valid lastname'),
    classifications: Yup.string().required('Required'),
    mobileNumber: Yup.string().required('Required').matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().required('Required').email(),
    password: Yup.string().required('Required').min(8, 'Password must be 8 characters long')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol'),
    // confirmPassword: Yup.string()
    //     .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

const VendorDashoard = () => {
    const [vendorById, setVendorById] = useState<any | null>(null)
    const [vendorClassificationOption, setVendorClassificationOption] = useState<any | null>(null)
    const [vendorList, setVendorList] = useState<any | null>(null)

    let navigate = useNavigate();
    const initialValues: MyFormValues = { firstName: '', lastName: '', classifications: '', mobileNumber: '', email: '', password: '' }

    const [showFileUpload, setShowFileUpload] = useState(false)
    const [file, setFile] = useState<any>()
    const [show, setNewShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [editState, setEditState] = useState(false);
    const [Id, setId] = useState('')
    const showAddModal = () => {
        setNewShow(true)
    }

    const vendorDeatils = (id: any) => {
        navigate(`/vendor-details/${encodeURIComponent(id)}`)
        localStorage.setItem("vendorClassification", encodeURIComponent(id))
    }
    const { setErrorMsg, errorModalShow, setErrorModalShow, setSelectedIndex } = useAuth();
    const { postNewVendor, deleteVendor, getVendorById, putVendor, getVendorClassificationOption, getVendorList, postImportPostageExcel } = useVendorsAPI()
    const loadInitialData = async () => {
        const result = await getVendorList(0, 100)
        setVendorList(result.data.result)
        const res = await getVendorClassificationOption()
        setVendorClassificationOption(res?.data?.result)
    }
    useEffect(() => {
        loadInitialData()
        setSelectedIndex(4)
    }, []);
    const initialEditValues: MyFormValuesEdit = { firstName: vendorById?.firstName, lastName: vendorById?.lastName, mobileNumber: vendorById?.mobileNumber, classifications: vendorById?.classifications, email: vendorById?.email, password: vendorById?.password }
    // const handleChange = (event:any) => [
    //     console.log(event.target.value)
    // ]

    const showImportFileModal = () => {
        setShowFileUpload(true)
    }
    const deleteVendorFunCall = async (v: any) => {
        const res = await deleteVendor(v)
        if (res?.data?.status == 200) {
            setErrorModalShow(true);
            setErrorMsg("Vendor Deleted Successfully");
            const result = await getVendorList(0, 100)
            setVendorList(result.data.result)
        }
    }
    const getVendorByIDFun = async (v: any) => {
        const res = await getVendorById(v)
        setVendorById(res?.data?.result)
    }

    const setFileFun = (e: any) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        setFile(formData)
    }
    const importPostageFile = async () => {
        postImportPostageExcel(file)
        setShowFileUpload(false)
    }
    const errorHandle = () => {
        return (
            <ErrorModal
                show={errorModalShow}
                onHide={() => setErrorModalShow(false)}
            />
        )
    };
    return (
        <>
            {errorHandle()}
            <Header />
            <Box className="contentMain vendorContainer"
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <div className=''>
                    <div className='startMain d-flex justify-content-between'>
                        <h5 className='w-50 pageHead mb-0'>Vendor Dashboard</h5>
                        <div className='w-50 d-flex justify-content-end'>
                            <button className="FilledBtn me-3 importBtn" onClick={() => showImportFileModal()}>Import Postage</button>
                            <button className="FilledBtn" onClick={() => { showAddModal(); setEditState(false) }}>Add New</button>
                        </div>
                    </div>
                    <div className='heightG'>
                        <table className='tableH'>
                            <thead className='thead rounded'>
                                <tr>
                                    <td>Sr. No.</td>
                                    {/* <td>Username</td> */}
                                    <td>Classification</td>
                                    {/* <td>Sku Prefix</td> */}
                                    <td>Actions</td>
                                </tr>
                            </thead>
                            {vendorList ?
                                <tbody className='tbody'>
                                    {vendorList?.map((v: any, index: any) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                {/* <td>{v?.firstName} {v.lastName}</td> */}
                                                <td style={{ color: 'blue' }} className="pointer" onClick={() => { vendorDeatils(v?.classifications); }}>{v?.classifications}</td>
                                                {/* <td>{v?.vendor?.vendorPrifix}</td> */}
                                                <td>
                                                    <Dropdown className="menuItem">
                                                        <Dropdown.Toggle variant="Warning" id="dropdown-basic" ><img src={Dots}></img></Dropdown.Toggle>
                                                        <Dropdown.Menu className="">
                                                            <Dropdown.Item className="clickItem" onClick={async () => { await getVendorByIDFun(v.id); setShowEdit(true); setId(v.id) }}>Edit</Dropdown.Item>
                                                            <Dropdown.Item className="clickItem" onClick={() => deleteVendorFunCall(v?.id)}>Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                :
                                <Loader />
                            }
                        </table>

                        {/* add new vendor modal */}
                        <Modal
                            show={show}
                            onHide={() => setNewShow(false)}
                            dialogClassName="formModal modalHeight"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton className='border-bottom-0'>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    Add New Vendor
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Formik
                                    // initialValues={editState == true ? shippingEasyId : initialValues}
                                    initialValues={initialValues}
                                    enableReinitialize={true}
                                    validationSchema={DisplayingErrorMessagesSchema}
                                    onSubmit={async (values, actions) => {
                                        console.log(values);
                                        actions.setSubmitting(false);
                                        const res = await postNewVendor(values)
                                        if (res?.data?.status == 200) {
                                            setErrorModalShow(true);
                                            setErrorMsg("Vendor Added Successfully");
                                            const result = await getVendorList(0, 100)
                                            setVendorList(result.data.result)
                                        }
                                        // { editState == true ? putShippingEasyCalled(Id, values) : postShippingEasyCalled(values) }
                                        setNewShow(false)
                                    }}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className='formContainer'>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="firstName">First Name</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="firstName" name="firstName" placeholder='Enter First Name' />
                                                        {touched.firstName && errors.firstName && <div className='errorMsg'>{errors.firstName}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="lastName">Last Name</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="lastName" name="lastName" placeholder='Enter Last Name' />
                                                        {touched.lastName && errors.lastName && <div className='errorMsg'>{errors.lastName}</div>}
                                                    </div>
                                                </div>


                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="classifications">Classification</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>

                                                        <div className='textfield selectfield'>
                                                            <Field as="select" name="classifications" className='select'>
                                                                <option value="" label="Select a Classification">Select a Classification</option>
                                                                {vendorClassificationOption?.map((v: any) => {
                                                                    return (
                                                                        <option value={v}>{v}</option>
                                                                    )
                                                                })}
                                                            </Field>

                                                        </div>
                                                        {touched.classifications && errors.classifications && <div className='errorMsg'>{errors.classifications}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="mobileNumber">Mobile Number</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="mobileNumber" name="mobileNumber" placeholder='Enter Mobile Number' />
                                                        {touched.mobileNumber && errors.mobileNumber && <div className='errorMsg'>{errors.mobileNumber}</div>}
                                                    </div>
                                                </div>


                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="email">Email</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="email" name="email" placeholder='Enter Email' />
                                                        {touched.email && errors.email && <div className='errorMsg'>{errors.email}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                                        <label htmlFor="password">Password</label>
                                                    </div>
                                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                                        <Field className='textfield' id="password" placeholderText="********" name="password" />
                                                        {touched.password && errors.password && <div className='errorMsg'>{errors.password}</div>}
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-4'></div>
                                                    <div className='col-8'>
                                                        <Button className="FilledBtn" type="submit">Submit</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Modal.Body>
                        </Modal>

                        {/* edit vendor modal */}
                        <Modal className="formModal"
                            show={showEdit}
                            onHide={() => setShowEdit(false)}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton className='border-bottom-0'>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    Edit Vendor
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Formik
                                    initialValues={initialEditValues}
                                    enableReinitialize={true}
                                    onSubmit={async (values2, actions) => {
                                        console.log("Edit", values2);
                                        actions.setSubmitting(false);
                                        const res = await putVendor(Id, values2)
                                        if (res?.data?.status == 200) {
                                            setErrorModalShow(true);
                                            setErrorMsg("Vendor Details Edited Successfully");
                                            const result = await getVendorList(0, 100)
                                            setVendorList(result.data.result)
                                        }
                                        setShowEdit(false)
                                    }}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className='formContainer'>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-4'>
                                                        <label htmlFor="firstName">First Name <span>*</span></label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <Field className='textfield' id="firstName" name="firstName" />
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-4'>
                                                        <label htmlFor="firstName">Last Name <span>*</span></label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <Field className='textfield' id="lastName" name="lastName" />
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-4'>
                                                        <label htmlFor="classifications">Classification<span>*</span></label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='textfield selectfield'>
                                                            <Field as="select" name="classifications" className='select'>
                                                                <option value="" label="Select a Classification">Select a Classification</option>
                                                                {vendorClassificationOption?.map((v: any) => {
                                                                    return (
                                                                        <option value={v}>{v}</option>
                                                                    )
                                                                })}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-4'>
                                                        <label htmlFor="mobileNumber">Mobile number<span>*</span></label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <Field className='textfield' id="mobileNumber" name="mobileNumber" />
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-4'>
                                                        <label htmlFor="email">Email<span>*</span></label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <Field className='textfield' id="email" name="email" disabled />
                                                    </div>
                                                </div>
                                                <div className='row align-items-center formItem'>
                                                    <div className='col-4'>
                                                        <label htmlFor="password">Password<span>*</span></label>
                                                    </div>
                                                    <div className='col-8'>
                                                        <Field className='textfield' id="password" placeholder="********" name="password" disabled />
                                                    </div>
                                                </div>

                                                <div className='row align-items-center formItem'>
                                                    <div className='col-4'></div>
                                                    <div className='col-8'>
                                                        <Button className="FilledBtn" type="submit">Submit</Button>
                                                    </div>
                                                </div>


                                                {/* <div className='row mb-2'>
                                               
                                                <div className='col-6 d-flex flex-column'>
                                                    <label htmlFor="mobileNumber">Mobile number</label>
                                                    <Field className='textfield' id="mobileNumber" name="mobileNumber" />
                                                </div>
                                                <div className='col-6 d-flex flex-column'>
                                                    <label htmlFor="email">Email</label>
                                                    <Field className='textfield' id="email" name="email" disabled />
                                                </div>
                                                <div className='col-6 d-flex flex-column'>
                                                    <label htmlFor="password">Password</label>
                                                    <label htmlFor="password">*********</label>
                                                    <Field className='textfield' id="password" name="password" disabled />
                                                </div>
                                            </div> */}

                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Modal.Body>
                        </Modal>
                        <Modal className="formModal"
                            show={showFileUpload}
                            onHide={() => setShowFileUpload(false)}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton className='border-bottom-0'>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    Import File
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className='formContainer'>
                                    <input className='textfield' id="file" name="file" type="file"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        onChange={(e: any) => setFileFun(e)} />

                                    <div className='row formItem text-center'>
                                        <div className='col-12'>
                                            <Button className="FilledBtn" onClick={() => importPostageFile()}>Submit</Button>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </Box >

        </>
    )
}
export default VendorDashoard